import React from 'react'
import { Table } from 'reactstrap'

import PremiumRows from './PremiumRows'

const PremiumTable = props => {
  const { data } = props

  if (data.length === 0) {
    return ''
  }

  return (
    <Table
      size={props.tableSize ? props.tableSize : ''}
      bordered={props.border !== 'no' ? true : false}
    >
      <thead>
        <tr>
          <th>Year</th>
          <th>End of Year Age</th>
          <th>Beginning of Year Account Value</th>
          <th>Interest Rate</th>
          <th>Interest Credit</th>
          <th>End of Year Account Value</th>
        </tr>
      </thead>
      <tbody>
        <PremiumRows data={data} />
      </tbody>
    </Table>
  )
}

export default PremiumTable
