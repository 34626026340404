import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import { EProduct } from '../../../../_enums/EProduct'
import vegaLogo from '../../../agent-components/Images/vega-placeholder.jpg'
import FirelightLogin from '../../firelight'
import './ProductsDetailVega.css'

function ProductDetailVega() {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Vega FIA Series
          </li>
        </ol>
      </nav>

      <div className="row">
        <div className="col-md-3">
          <Card>
            <CardImg src={vegaLogo} />
          </Card>
        </div>
        <div className="col-md-9 productText">
          <Row>
            <Col md="12">
              <Button
                sx={{ marginTop: 1, marginRight: 1 }}
                onClick={() =>
                  window.open(
                    'https://media.SILACins.com/annuity/Vega Brochure.pdf',
                    '_blank'
                  )
                }
              >
                Brochure
              </Button>
              <Link to={`/agent/sales-tools/illustration`}>
                <Button sx={{ marginTop: 1, marginRight: 1 }}>
                  Run an Illustration
                </Button>
              </Link>
              <Button
                sx={{ marginTop: 1, marginRight: 1 }}
                onClick={() =>
                  window.open(
                    'https://www.silacins.com/agent/training',
                    '_blank'
                  )
                }
              >
                Product Training
              </Button>
              <Link to={`/agent/products/sales-materials/${EProduct.vega.id}`}>
                <Button sx={{ marginTop: 1, marginRight: 1 }}>
                  Sales Materials
                </Button>
              </Link>
              <FirelightLogin sx={{ marginTop: 1, marginRight: 1 }}>
                Start eApp
              </FirelightLogin>
            </Col>
          </Row>
          <hr />
          <br />
          <article>
            <p>
              Vega is one of the brightest stars and is a reliable point in an
              ever-changing night sky.
              <br></br>
              <br></br>
              The SILAC Vega Series is a comprehensive fixed index annuity
              focused on the accumulation of retirement benefits. The benefit
              value that automatically comes with all Vega FIAs offers multiple
              income options, growth, and an enhanced legacy benefit to navigate
              retirement. This supercharged benefit accumulation, paired with
              multiple benefits that can be fine-tuned to your specific needs,
              provides A GALAXY OF RETIREMENT OPPORTUNITIES.
            </p>
          </article>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailVega
