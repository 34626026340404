import { EIndexSeriesType } from './_enums/EIndexSeriesType'
import Atlas from './assets/images/sales-tools/indices/Atlas.png'
import Ndxgen5 from './assets/images/sales-tools/indices/Ndxgen5.png'
import SP500DuoSwift from './assets/images/sales-tools/indices/SP500DuoSwift.png'
import SP500RavenPack from './assets/images/sales-tools/indices/SP500RavenPack.png'
import { IIdice } from './pages/SalesTools/Indices'

export const CONFETTI_KEY = 'confetti'
export const INDICES: IIdice[] = [
  {
    title: 'Barclays Atlas Index',
    link: 'https://www.silacins.com/atlas',
    image: Atlas,
    series: EIndexSeriesType.BXIIAT5E,
  },
  {
    title: 'S&P 500 RavenPack AI Index',
    link: 'https://www.silacins.com/ravenpack',
    image: SP500RavenPack,
    series: EIndexSeriesType.CSRPAI5E,
  },
  {
    title: 'Nasdaq Generations 5 Index',
    link: 'https://www.silacins.com/generations5',
    image: Ndxgen5,
    series: EIndexSeriesType.NDXGEN5,
  },
  {
    title: 'S&P 500® Duo Swift Index',
    link: 'https://www.silacins.com/duoswift',
    image: SP500DuoSwift,
    series: EIndexSeriesType.SPDUWE,
  },
]

export const MAX_EXPECTED_AGE = 111
