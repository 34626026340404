import axios from 'axios'
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Alert,
} from 'reactstrap'

import silacLogo from './SILAC-vector.png'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {
        passwordMessage: null,
      },
      fields: {
        newPassword: '',
        confirmPassword: '',
      },
      messageFromServer: '',
      redirect: false,
      showError: false,
      submitted: false,
      showTokenLink: false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { fields } = this.state

    this.setState({ showError: false })

    if (!fields.newPassword || !fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'Please Enter a Password.',
        },
        showError: true,
      })
    } else if (fields.newPassword !== fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'Passwords do not match',
        },
        showError: true,
      })
    } else {
      this.submitAPI()
    }
  }

  handleChange = e => {
    const key = e.target.name
    const value = e.target.value

    this.setState(prevState => ({
      fields: { ...prevState.fields, [key]: value },
    }))
  }

  submitAPI = () => {
    const { fields } = this.state
    const { location } = this.props

    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    console.log('token', token)

    // configs to make auth request
    let connect = {
      url: `${window._env_.REACT_APP_API_URL}/login/password_reset/confirm/`,
      body: JSON.stringify({ password: fields.newPassword, token }),
      headers: {
        headers: { 'Content-Type': 'application/json', Authorization: 'none' },
      },
    }

    axios
      .post(connect.url, connect.body, connect.headers)
      .then(res => {
        this.setState({ redirect: true })
      })
      .catch(reason => {
        console.log('reason: ', reason)
        if (reason === 'Error: Request failed with status code 404') {
          this.setState({
            errors: {
              passwordMessage: 'This password reset link is no longer active.',
            },
            showError: true,
            showTokenLink: true,
          })
        } else {
          this.setState({
            errors: {
              passwordMessage: reason.response.data,
            },
            showError: true,
          })
        }
      })
  }

  showMessage = (classnames, messages, show) => {
    if (!show) {
      return null
    }
    if (Array.isArray(messages)) {
      return (
        <div className={classnames}>
          <ul>
            {messages.map(message => (
              <li>{message}</li>
            ))}
          </ul>
        </div>
      )
    }
    return <div className={classnames}>{messages}</div>
  }

  render() {
    const { errors, redirect, showError, showTokenLink } = this.state
    if (redirect) {
      return <Redirect to="/login" />
    }
    return (
      <Container fluid className="full-height bg-light">
        <Row className="h-100 justify-content-center full-height align-items-center bg-light">
          <Col xs="10" lg="3" className="p-0">
            <h1 className="text-center login-title">Reset Password</h1>

            <Card>
              <div className="account-wall">
                <img className="profile-img img-fluid" src={silacLogo} alt="" />
                <Form className="form-signin">
                  <FormGroup>
                    <label htmlFor="newPassword">Password</label>
                    <Input
                      type="password"
                      name="newPassword"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="confirmPassword">Verify Password</label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                  </FormGroup>

                  {showError
                    ? this.showMessage(
                        'help-block alert alert-danger',
                        errors.passwordMessage,
                        showError
                      )
                    : null}
                  {showTokenLink ? (
                    <React.Fragment>
                      <Link to="/forgot-password" className="text-center">
                        Please click here to request a new token
                      </Link>
                      <br></br>
                    </React.Fragment>
                  ) : null}
                  <br></br>
                  <Alert color="warning">
                    <small>
                      <b>
                        Your password can't be too similar to your other
                        personal information. Your password must contain:
                        <br />
                      </b>
                      &nbsp;&nbsp;at least 8 characters.
                      <br />
                      &nbsp;&nbsp;at least 1 special character
                      <br />
                      &nbsp;&nbsp;at least 1 upper case character
                      <br />
                      &nbsp;&nbsp;at least 1 lower case character
                      <br />
                      &nbsp;&nbsp;at least 1 number
                      <br />
                    </small>
                  </Alert>
                  <br />
                  <button
                    className="btn btn-lg btn-primary btn-block"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                  <br />
                </Form>
              </div>
            </Card>
            <Link to="/login" className="text-center new-account">
              Cancel
            </Link>
            <span className="clearfix" />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ResetPassword

// class ShowRequired extends Component {
//   render() {
//     if (this.props.display === true) {
//       return <div className={this.props.className}>{this.props.message}</div>
//     } else {
//       return null
//     }
//   }
// }
