import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import AgentContractingProvider from '../_contexts/AgentContracting.context'
import AgentContracting from '../components/agent-components/agent-contracting/AgentContracting'
import RecruitAgent from '../components/agent-components/agent-contracting/RecruitAgent'

const AgentContractingRoutes = () => {
  const routes = [
    {
      path: '',
      component: AgentContracting,
    },
    {
      path: '/recruit',
      component: RecruitAgent,
    },
  ]

  return (
    <AgentContractingProvider>
      <Switch>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            exact
            strict
            component={route.component}
            path={`/agent/contracting${route.path}`}
          />
        ))}
      </Switch>
    </AgentContractingProvider>
  )
}

export default AgentContractingRoutes
