import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap'

const AgentHomeNewsItem = ({
  id,
  link,
  type,
  subject,
  description,
  actionDate,
  isInternal,
}) => (
  <>
    <ListGroupItem>
      <ListGroupItemHeading>{subject}</ListGroupItemHeading>
      <hr />
      <ListGroupItemText>
        <span className="h6 card-subtitle mb-2 text-muted">{actionDate}</span>
        <br />
        <span>{description}</span>
        <br />
        <br />
        {isInternal ? (
          <Link to={link}>
            <Button>Learn More</Button>
          </Link>
        ) : (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Button>Learn More</Button>
          </a>
        )}
      </ListGroupItemText>
    </ListGroupItem>
    <br />
  </>
)

export default AgentHomeNewsItem
