export enum ELifetimeWithdrawalType {
  Single = 'single',
  Joint = 'joint',
}

export class ELifetimeWithdrawal {
  static readonly Single = new ELifetimeWithdrawal(
    ELifetimeWithdrawalType.Single,
    'Single'
  )
  static readonly Joint = new ELifetimeWithdrawal(
    ELifetimeWithdrawalType.Joint,
    'Joint'
  )

  private constructor(
    public readonly code: string,
    public readonly name: string
  ) {}

  toString() {
    return this.name
  }
}
