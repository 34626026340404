import { EProductId } from '../_enums/EProduct'

export const isMyga = (storeData?: any) =>
  [EProductId.SecureSavings, EProductId.SecureSavingsElite].some(
    e => e === storeData?.product.id
  )

export const isVega = (storeData?: any) =>
  [EProductId.Vega, EProductId.VegaBonus].some(e => e === storeData?.product.id)

export const isTeton = (id: EProductId) =>
  [EProductId.Teton, EProductId.TetonBonus].some(e => e === id)

export const isDenali = (id: EProductId) =>
  [EProductId.Denali, EProductId.DenaliBonus].some(e => e === id)

export const isRiderEvolve = (storeData?: any) =>
  storeData?.rider_config?.rider_package?.rider_codes?.some((code: any) => code.code === 'evolve') || false
