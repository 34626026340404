import Moment from 'moment'
import React from 'react'

const data = [
  {
    startStamp: '2021-11-17 12:01:00-07',
    endStamp: '2021-11-18 11:59:00-07',
    messageTitle: 'Upcoming Adjusted Phone Line Hours',
    message:
      'We will be undergoing a system upgrade and our phone lines will be shut down on Friday, 11/19, from 6:00 AM to 9:00 AM Mountain Time.',
  },
  {
    startStamp: '2021-11-19 12:01:00-07',
    endStamp: '2021-11-19 09:01:00-7',
    messageTitle: 'Upcoming Adjusted Phone Line Hours',
    message:
      'We are undergoing a system upgrade and our phone lines will be shut down today from 6:00 AM to 9:00 AM Mountain Time.',
  },
]

interface ISystemNotification {
  startStamp: string
  endStamp: string
  messageTitle: string
  message: string
}

const isInDateRange = (start: string, end: string): boolean => {
  let today = Moment()
  return today.isBetween(Moment(start), Moment(end))
}

export const SystemNotifications: React.FC<any> = props => {
  const notes: ISystemNotification[] = data.filter((n: ISystemNotification) =>
    isInDateRange(n.startStamp, n.endStamp)
  )

  return notes.length > 0 ? (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'rgb(0, 114, 192)',
        padding: '10px',
      }}
    >
      {notes.map(n => (
        <div>
          <h5>{n.messageTitle}</h5>
          {n.message}
        </div>
      ))}
    </div>
  ) : null
}
