import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import tetonLogo from '../../../assets/images/agent/products/teton.png'
import tetonBonusLogo from '../../../assets/images/agent/products/teton_bonus.png'
import secureSavingsLogo from '../../../components/agent-components/Images/SS-placeholder.jpg'
import secureSavingsEliteLogo from '../../../components/agent-components/Images/SSE-placeholder.jpg'
import denaliBonusLogo from '../../../components/agent-components/Images/denali-bonus-evolve.png'
import denaliLogo from '../../../components/agent-components/Images/denali-evolve.png'
import vegaLogo from '../../../components/agent-components/Images/vega-placeholder.jpg'
import vegaBonusLogo from '../../../components/agent-components/Images/vegabonus-placeholder.jpg'

export default function Products() {
  return (
    <div className="mt-3">
      <Row>
        <Col md="12">
          <Button
            sx={{ marginRight: 2 }}
            className="float-right ml-2"
            onClick={() =>
              window.open(
                'https://media.SILACins.com/annuity/State Availability Grid.pdf',
                '_blank'
              )
            }
          >
            Product Availability Grid
          </Button>
          <Button
            className="float-right"
            onClick={() =>
              window.open(
                'https://media.SILACins.com/annuity/Rate Sheet.pdf',
                '_blank'
              )
            }
          >
            Annuity Rate Sheet
          </Button>
        </Col>
      </Row>

      <div className="mt-4">
        <h3 className="text-muted">Fixed Index Annuities</h3>
        <hr></hr>

        <Row>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card style={{ height: '100%' }}>
              <Link
               to={`/agent/products/denali-series`}
               style={{ height: '100%' }}
              >
                <CardImg
                  top
                  width="100%"
                  height="100%"
                  src={denaliLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card style={{ height: '100%' }}>
              <Link
               to={`/agent/products/denali-bonus-series`}
               style={{ height: '100%' }}
              >
                <CardImg
                  top
                  width="100%"
                  height="100%"
                  src={denaliBonusLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card style={{ height: '100%' }}>
              <Link
                to={`/agent/products/teton-series`}
                style={{ height: '100%' }}
              >
                <CardImg
                  top
                  width="100%"
                  height="100%"
                  src={tetonLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card style={{ height: '100%' }}>
              <Link
                to={`/agent/products/teton-bonus-series`}
                style={{ height: '100%' }}
              >
                <CardImg
                  top
                  width="100%"
                  height="100%"
                  src={tetonBonusLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>

          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card>
              <Link to={`/agent/products/vega-series`}>
                <CardImg top width="100%" src={vegaLogo} alt="Card image cap" />
              </Link>
            </Card>
          </Col>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card>
              <Link to={`/agent/products/vega-bonus-series`}>
                <CardImg
                  top
                  width="100%"
                  src={vegaBonusLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <h3 className="text-muted">Multi Year Guaranteed Annuity</h3>
        <hr></hr>

        <Row>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card>
              <Link to={`/agent/products/secure-savings`}>
                <CardImg
                  top
                  width="100%"
                  src={secureSavingsLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
          <Col className="mb-2" lg={2} md={4} sm={6}>
            <Card style={{ textShadow: '2px 2px' }}>
              <Link to={`/agent/products/secure-savings-elite`}>
                <CardImg
                  top
                  width="100%"
                  src={secureSavingsEliteLogo}
                  alt="Card image cap"
                />
              </Link>
            </Card>
          </Col>
        </Row>

        <br />
        <br />
        <br />
      </div>
    </div>
  )
}
