import React from 'react'
import { Spinner, Table } from 'reactstrap'

import AgentCommunicationListItem from './AgentCommunicationListItem'

const AgentCommunicationList = ({ communications, loading }) => (
  <div className="col-md-12">
    {!!loading ? (
      <div>
        <h6 className="card-subtitle mb-2 text-muted">
          <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
          {'  '}Loading Communication Data...
        </h6>
      </div>
    ) : communications.length === 0 ? (
      <div>
        <p>No communication information available</p>
      </div>
    ) : (
      <Table bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Subject</th>
            {/* <th>Description</th> */}
            <th>Action Date</th>
          </tr>
        </thead>
        <tbody>
          {communications.map(communication => (
            <AgentCommunicationListItem
              key={communication.id}
              type={communication.type}
              subject={communication.subject}
              link={communication.link}
              // description={communication.description}
              actionDate={communication.actionDate}
            />
          ))}
        </tbody>
      </Table>
    )}
  </div>
)

export default AgentCommunicationList
