import * as axios from 'axios'
import * as React from 'react'

import { IStore } from './store'
import { TAction, IAction, IActionType, IRequest } from './types'

/* async dispatch handler */
type ThunkAction<T> = (dispatch: React.Dispatch<T>) => T
type AsyncDispatch<T> = React.Dispatch<T | ThunkAction<T>>

const asyncWrapper = <T>(dispatch: React.Dispatch<T>): AsyncDispatch<T> => {
  return (action: T | ThunkAction<T>) => {
    return action instanceof Function ? action(dispatch) : dispatch(action) // have not verified standard dispatch works for standard actions
  }
}

export const asyncDispatch = <T>(
  dispatch: React.Dispatch<TAction<T>>,
  action: TAction<T>
) => {
  asyncWrapper<TAction<T>>(dispatch)(
    (
      dispatch: (
        value: IActionType & T
      ) => (IActionType & T) | PromiseLike<IActionType & T>
    ) => action().then(dispatch)
  )
}

/* request handler */
const baseURL = (window as any)._env_.REACT_APP_API_URL
const badRequestCode = 418 // I'm a teapot - response code used internally to convey a request error

const get = <Response>(url: string): Promise<{ data: Response }> => {
  return new Promise((resolve, reject) => {
    axios.default
      .get(url)
      .then((response: axios.AxiosResponse) => {
        return resolve(response)
      })
      .catch(error => {
        if (error.response) {
          error.response.data = null
          return resolve(error.response)
        } else if (error.request) {
          error.response = new Response(null, {
            status: badRequestCode,
            statusText: error,
          })
          return resolve(error.response)
        } else {
          return reject(error)
        }
      })
  })
}

const put = <Response>(url: string, body: any): Promise<{ data: Response }> => {
  let config: Object | undefined = undefined
  if (body instanceof FormData) {
    config = { headers: { 'content-type': 'multipart/form-data' } }
  }

  return new Promise((resolve, reject) => {
    axios.default
      .put(url, body, config)
      .then((response: axios.AxiosResponse) => {
        return resolve(response)
      })
      .catch(error => {
        if (error.response) {
          error.response.data = null
          return resolve(error.response)
        } else if (error.request) {
          error.response = new Response(null, {
            status: badRequestCode,
            statusText: error,
          })
          return resolve(error.response)
        } else {
          return reject(error)
        }
      })
  })
}

const post = <Response>(
  url: string,
  body: any
): Promise<{ data: Response }> => {
  let config: Object | undefined = undefined
  if (body instanceof FormData) {
    config = { headers: { 'content-type': 'multipart/form-data' } }
  }

  return new Promise((resolve, reject) => {
    axios.default
      .post(url, body, config)
      .then((response: axios.AxiosResponse) => {
        return resolve(response)
      })
      .catch(error => {
        if (error.response) {
          return resolve(error.response)
        } else if (error.request) {
          error.response = new Response(null, {
            status: badRequestCode,
            statusText: error,
          })
          return resolve(error.response)
        } else {
          return reject(error)
        }
      })
  })
}

const patch = <Response>(
  url: string,
  body: any
): Promise<{ data: Response }> => {
  let config: Object | undefined = undefined
  if (body instanceof FormData) {
    config = { headers: { 'content-type': 'multipart/form-data' } }
  }

  return new Promise((resolve, reject) => {
    axios.default
      .patch(url, body, config)
      .then((response: axios.AxiosResponse) => {
        return resolve(response)
      })
      .catch(error => {
        if (error.response) {
          error.response.data = null
          return resolve(error.response)
        } else if (error.request) {
          error.response = new Response(null, {
            status: badRequestCode,
            statusText: error,
          })
          return resolve(error.response)
        } else {
          return reject(error)
        }
      })
  })
}

const del = <Response>(url: string): Promise<{ data: Response }> => {
  return new Promise((resolve, reject) => {
    axios.default
      .delete(url)
      .then((response: axios.AxiosResponse) => {
        return resolve(response)
      })
      .catch(error => {
        if (error.response) {
          error.response.data = null
          return resolve(error.response)
        } else if (error.request) {
          error.response = new Response(null, {
            status: badRequestCode,
            statusText: error,
          })
          return resolve(error.response)
        } else {
          return reject(error)
        }
      })
  })
}

export const request = Object.assign(
  async <T extends any = any>(args: IRequest): Promise<T> => {
    const url = baseURL
      .concat(args.uri.startsWith('/') ? args.uri : '/'.concat(args.uri))
      .replace(/(\r\n|\r|\n| {2}|%20%20)/gm, '')

    return (await (['post', 'patch', 'put'].includes(args.method)
      ? request[args.method](url, args.body)
      : request[args.method](url, null))) as T
  },
  {
    get,
    put,
    post,
    patch,
    delete: del,
  }
)

/* reducers handler */
export const createReducer =
  (handlers: { [key: string]: any }): any =>
  (store: IStore, action: IAction): IStore => {
    return handlers[action.type] ? handlers[action.type](store, action) : store
  }
