import { FC, useEffect } from 'react'
import { useStore } from 'react-redux'

import { EAction } from '../../../_common/_store/reducers/app-reducers'
import NoResults from './NoResults'
import PolicyRow from './PolicyRow'

// renders the search table contents
const PolicyList: FC<{ data?: any; records?: number }> = props => {
  const { data, records } = props
  const { dispatch } = useStore()

  useEffect(() => {
    dispatch({
      type: EAction.Reset,
    })
  }, [dispatch])

  if (typeof data !== 'undefined' && 'detail' in data) {
    const policyList = data.detail.policies.map((policy: any, i: number) => {
      return <PolicyRow key={i} details={policy} />
    })
    return records === 0 ? <NoResults text="No Results Found" /> : policyList
  } else {
    return <NoResults text="" />
  }
}

export default PolicyList
