import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import numeral from 'numeral'

import { formatDate } from '../../_common/utils'
import { formatPercentage } from '../../_helpers/number'
import './PolicyEventsList.css'
import { IProductConfigChangePolicyEvents } from './types'

export default function ProductConfigPolicyEventTable(
  row: IProductConfigChangePolicyEvents
) {
  const previousWithdrawalCharges: number[] = JSON.parse(
    row.previous_withdrawal_charges
  )
  const newWithdrawalCharges: number[] = JSON.parse(
    row.new_withdrawal_charges
  )
  const formattedPreviousWithdrawalCharges = previousWithdrawalCharges.map(
    num => `${(num * 100).toFixed(2)}%, `
  )
  const formattedNewWithdrawalCharges = newWithdrawalCharges.map(
    num => `${(num * 100).toFixed(2)}%, `
  )

  const isBonus: boolean = row.product.toLowerCase().includes('bonus')

  const previousBonusRecovery: number[] = JSON.parse(
    row.previous_bonus_recovery === 'None' ? '[]' : row.previous_bonus_recovery
  )
  const newBonusRecovery: number[] = JSON.parse(
    row.bonus_recovery_adjustment
  )
  const formattedPreviousBonusRecovery = previousBonusRecovery.map(
    num => `${(num * 100).toFixed(2)}%, `
  )
  const formattedNewBonusRecovery = newBonusRecovery.map(
    num => `${(num * 100).toFixed(2)}%, `
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow key={`${row.id}-title`}>
              <TableCell component="th" scope="row">
                {row.product}
              </TableCell>
              <TableCell align="right">Previous Config Values</TableCell>
              <TableCell align="right">
                Config Values as of {formatDate(row.date)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={`${row.id}-withdrawal-charges`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Withdrawal Charges
              </TableCell>
              <TableCell align="right">
                {formattedPreviousWithdrawalCharges}
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(formattedPreviousWithdrawalCharges !== formattedNewWithdrawalCharges && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}>
                {formattedNewWithdrawalCharges}
              </TableCell>
            </TableRow>
            {isBonus && (
              <TableRow
                key={`${row.id}-bonus-recovery`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Bonus Recovery
                </TableCell>
                <TableCell align="right">{formattedPreviousBonusRecovery.length === 0 ? 'None' : formattedPreviousBonusRecovery}</TableCell>
                <TableCell align="right"
                sx={{
                  ...(formattedPreviousBonusRecovery !== formattedNewBonusRecovery && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
                >{formattedNewBonusRecovery}</TableCell>
              </TableRow>
            )}
            <TableRow
              key={`${row.id}-free-withdrawal-max-percentage`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Free Withdrawal Max Percentage
              </TableCell>
              <TableCell align="right">
                {formatPercentage(row.previous_free_withdrawal_max_percentage)}
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(row.previous_free_withdrawal_max_percentage !== row.new_free_withdrawal_max_percentage && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
              >
                {formatPercentage(row.new_free_withdrawal_max_percentage)}
              </TableCell>
            </TableRow>
            <TableRow
              key={`${row.id}-free-withdrawal-waiting-period`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Free Withdrawal Waiting Period
              </TableCell>
              <TableCell align="right">
                {row.previous_free_withdrawal_waiting_period} Months
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(row.previous_free_withdrawal_waiting_period !== row.new_free_withdrawal_waiting_period && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
              >
                {row.new_free_withdrawal_waiting_period} Months
              </TableCell>
            </TableRow>
            <TableRow
              key={`${row.id}-cumulative-withdrawal-max-percentage`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Cumulative Withdrawal Max Percentage
              </TableCell>
              <TableCell align="right">
                {formatPercentage(
                  row.previous_cumulative_withdrawal_max_percentage
                )}
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(row.previous_cumulative_withdrawal_max_percentage !== row.new_cumulative_withdrawal_max_percentage && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
              >
                {formatPercentage(row.new_cumulative_withdrawal_max_percentage)}
              </TableCell>
            </TableRow>
            <TableRow
              key={`${row.id}-account-value`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Account Value
              </TableCell>
              <TableCell align="right">
                {numeral(row.previous_account_value).format('$0,0.00')}
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(row.previous_account_value !== row.new_account_value && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
              >
                {numeral(row.new_account_value).format('$0,0.00')}
              </TableCell>
            </TableRow>
            <TableRow
              key={`${row.id}-cash-surrender-value`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Cash Surrender Value*
              </TableCell>
              <TableCell align="right">
                {numeral(row.previous_cash_surrender_value).format('$0,0.00')}
              </TableCell>
              <TableCell align="right"
                sx={{
                  ...(row.previous_cash_surrender_value !== row.new_cash_surrender_value && {
                    backgroundColor: "var(--bs-warning-bg-subtle)"
                  })
                }}
              >
                {numeral(row.new_cash_surrender_value).format('$0,0.00')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="cash-surrender-clause">
        *Cash Surrender Value may be different due to changes in how the
        Withdrawal Charges, Bonus Recovery, and MVA are calculated.
      </div>
    </>
  )
}
