enum EAction {
  GetActionNotes = 'Get_Action_Notes',
  PostContractingRecruit = 'Post_Contracting_Recruit',
  GetDownlineInvites = 'Get_Downline_Invites',
  GetProductConfigChangePolicyEvents = 'Get_Product_Config_Change_Policy_Events',
  GetWithdrawalAdjustmentPolicyEvents = 'Get_Withdrawal_Adjustment_Policy_Events',
  GetPolicyEvents = 'Get_Policy_Events',
}

export default EAction
