import numeral from 'numeral'
import { Line } from 'react-chartjs-2'

import { useChartData } from '../../../../_hooks'

const Benefit = ({ data }) => {
  const datasets = useChartData(data)
  if (!data) return null
  return (
    <Line
      data={{
        labels: data.labels,
        datasets,
      }}
      options={{
        legend: {
          display: true,
          position: 'bottom',
        },
        title: {
          display: false,
          text: '',
        },
        scales: {
          yAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  return numeral(value).format('$0,0')
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  // eslint-disable-next-line
                  if (value == 'Issue') {
                    return value
                    // eslint-disable-next-line
                  } else if (value == 1) {
                    return value + ' yr'
                  } else {
                    return value + ' yrs'
                  }
                },
              },
            },
          ],
        },
      }}
    />
  )
}

export default Benefit
