import {
  CardActionArea,
  CardContent,
  CardMedia,
  Card,
  Grid,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Loader from '../../../_common/_components/Loader/Loader'
import { ESeverity } from '../../../_enums'
import { EIndexSeriesType } from '../../../_enums/EIndexSeriesType'
import { useToaster } from '../../../_hooks'
import { getAvailableIndices } from '../../../_services/sales-tools/indices'
import { INDICES } from '../../../constants'
import './Indices.css'
import { TradeMarkDisplay } from '../../../_common/_components/TradeMarkDisplay'

export interface IIdice {
  title: string
  image: string
  link: string
  series: EIndexSeriesType
}
const Indice: React.FC<IIdice> = ({ title, image, link }) => {
  return (
    <Grid item xl={3} lg={4} md={6} sm={12}>
      <a target="_blank" about={title} href={link} rel="noreferrer">
        <Card sx={{ height: '100%' }} className="indices__item">
          <CardActionArea
            sx={{
              padding: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <CardMedia component="img" image={image} alt={title} />
            <CardContent>
              <Typography
                marginTop={2}
                textAlign="center"
                variant="h6"
                component="h3"
              >
                <TradeMarkDisplay tradeMark={title} />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </a>
    </Grid>
  )
}

const Indices: React.FC = () => {
  const [availableIndices, setAvailableIndices] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { toast } = useToaster()

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getAvailableIndices()
        setAvailableIndices(response)
      } catch (e) {
        toast(ESeverity.Error, 'Could not load available indices')
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h1 className="indices__title">
        <span>Indices</span>
      </h1>
      {isLoading && <Loader title="Loading Indices" />}
      <Grid container spacing={4}>
        {INDICES.filter(x => availableIndices.some(y => y === x.series)).map(
          item => (
            <Indice {...item} key={item.title} />
          )
        )}
      </Grid>
    </>
  )
}

export default Indices
