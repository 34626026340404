import { AgencyNewsResponse } from '../models/communications/agencyNew'
import { request } from './axiosService'

const RESOURCE = '/communications'

const communicationsService = {
  agencynews: {
    getAll: (limit = 4): Promise<{data: AgencyNewsResponse}> => {
      const limitQuery = limit ? `?limit=${limit}` : ''
      return request({
        method: 'get',
        uri: `${RESOURCE}/agencynews/${limitQuery}`,
      })
    },
    getAgencyNewContentById: (id: string): Promise<{data: string}> => {
      return request({
        method: 'get',
        uri: `${RESOURCE}/agencynews/${id}/content/`,
      })
    },
  },
}

export default communicationsService