import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { EMembershipType, EUserGroup } from '../../_enums'
import authService from '../../_services/authService'
import { ThemeProvider } from '../../themes'
import { Layout } from '../layout'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const hasClientPath = location.pathname.includes('/client')

  const userGroup =
    authService.isAgency() && !hasClientPath
      ? EUserGroup.ANNUITY_AGENT
      : EUserGroup.ANNUITY_CLIENT

  const agentMembership = !hasClientPath && authService.getAgentMembership()
  const themeType = agentMembership
    ? agentMembership.name === EMembershipType.EMBER
      ? EMembershipType.EMBER
      : agentMembership.name === EMembershipType.INNER_CIRCLE
      ? EMembershipType.INNER_CIRCLE
      : EMembershipType.DEFAULT
    : EMembershipType.DEFAULT

  return (
    <Route
      {...rest}
      onEnter={authService.resetLogoutTimer()}
      onChange={authService.resetLogoutTimer()}
      render={props =>
        authService.isAuthenticated({ ...rest }) ? (
          <ThemeProvider themeType={themeType}>
            <Layout userGroup={userGroup}>
              <Component {...props} />
            </Layout>
          </ThemeProvider>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
