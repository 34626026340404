import { Field } from 'formik'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { InputGroup } from 'reactstrap'

import StyledErrorMessage from './StyledErrorMessage'

const PhoneField = props => {
  const { disabled, name } = props

  return (
    <Fragment>
      <InputGroup>
        <Field name={name} className="form-control" disabled={disabled} />
      </InputGroup>
      <StyledErrorMessage name={name} />
    </Fragment>
  )
}

PhoneField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

PhoneField.defaultProps = {
  disabled: false,
}

export default PhoneField
