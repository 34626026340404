import { Box } from '@mui/material'
import React, { Component } from 'react'

import './ClientHome.css'
import Policy from './PolicySearch'
import clientLogo from './jumbotron.jpg'

export class ClientHome extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Box
            className="jumbo"
            sx={{
              padding: '4rem 2rem',
              marginBottom: '2rem',
              backgroundColor: '#e9ecef',
              backgroundImage: `url(${clientLogo})`,
            }}
          >
            <div className="textBackgroundDiv">
              <div className="innerBox">
                <h1 className="display-4 textBackground">
                  Welcome to Your Secure Account
                </h1>
              </div>
            </div>
          </Box>

          <Policy />
        </div>
      </div>
    )
  }
}

export default ClientHome
