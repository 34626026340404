import { IRider } from '../../types/salesTools'
import { request } from '../axiosService'

export const getRiders = async (productId: number): Promise<IRider[]> => {
  const response = await request({
    method: 'get',
    uri: `/illustration/plan_config/${productId}/riders`,
  })
  return response.data
}
