import { Grid, Hidden, Skeleton } from '@mui/material'
import { useState, useEffect } from 'react'

import { getCurrentAllocations } from '../../../../_services/policyService'
import { useStore } from '../../../_hooks/use-store'
import { EAction } from '../../../_store/reducers/app-reducers'
import CreditStrategy from './CreditStrategy'

function Allocation() {
  const {
    store: { selectedPolicy, currentAllocations },
    dispatch,
  } = useStore()

  const allocations = selectedPolicy.data.crediting_strategies
  const policyNumber = selectedPolicy.data.application.policy_number
  const config = selectedPolicy.config

  const [selectedType, setSelectedType] = useState('selected')
  const [includeCurrentAllocation, setIncludeCurrentAllocation] =
    useState(false)

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getCurrentAllocations(policyNumber)
        if (data) {
          setIncludeCurrentAllocation(true)
        }
        dispatch({ type: EAction.SET_CURRENT_ALLOCATIONS, payload: data })
      } catch (e) {
        dispatch({ type: EAction.SET_CURRENT_ALLOCATIONS, payload: [] })
      }
    }
    if (policyNumber) loadData()
  }, [policyNumber, dispatch])

  return (
    <>
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item sm={3}></Grid>
        </Hidden>
        {currentAllocations && (
          <Grid item xs={12} lg={6} sm={12} className="mt-4">
            <div className="form-group">
              <select
                className="form-control"
                id="sel1"
                data-testid="allocations_selector"
                value={selectedType}
                onChange={e => {
                  setSelectedType(e.target.value)
                }}
              >
                {includeCurrentAllocation && (
                  <option value="current">Current Effective Allocation*</option>
                )}
                <option value="selected">Selected Allocation*</option>
              </select>
            </div>
          </Grid>
        )}
        <Hidden smDown>
          <Grid item sm={3}></Grid>
        </Hidden>
        {currentAllocations ? (
          <Grid item md={12} className="mt-4">
            {includeCurrentAllocation && (
              <div hidden={selectedType !== 'current'}>
                <CreditStrategy
                  allocations={currentAllocations!}
                  config={config}
                />
                <div className="text-muted">
                  *Effective allocations that take into account additional
                  premiums and fixed interest
                </div>
              </div>
            )}

            <div hidden={selectedType === 'current'}>
              <CreditStrategy allocations={allocations} config={config} />
              <div className="text-muted">
                *Allocations the policyholder elected at the time of purchase or
                upon reallocation
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item md={12}>
            <Grid container>
              <Grid item md={9}>
                <Skeleton height="35em" width="90%" />
              </Grid>
              <Grid item md={3}>
                <Skeleton height="35em" width="90%" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Allocation
