import _ from 'lodash'

import { DynamicInput } from '../../Forms/FormikInputs/'

export const getMaxDate = (inputType, dateToCompare = null) => {
  if (inputType !== 'date') {
    return undefined
  }
  let maxDate = dateToCompare === null ? new Date() : dateToCompare
  maxDate.setDate(maxDate.getDate() - 1)
  return maxDate.toISOString().split('T')[0]
}

const ReportFormInputs = ({ params }) => {
  // order the list of inputs
  const orderedParams = _.orderBy({ ...params }, ['order'])

  const inputList = orderedParams.map((input, i) => {
    // destructure the inputs keys
    const { default_value, input_name, input_type, label } = input

    // map param object keys for DynamicInput component
    const inputComponent = {
      select: 'select',
      text: 'text',
      date: 'date',
      list: 'select',
      'multi select list': 'checkGroup',
      selectMulti: 'checkGroup',
    }

    // if there are select option, build an array for the options to pass to the DynamicInput component
    const selectOptions = (type, list) => {
      let result = []

      if (['select', 'checkGroup'].indexOf(type) > -1 && list !== '') {
        let o = list.replace(/, */g, ',').split(',')
        result = o.map((r, i) => ({ label: r, value: r }))
      }

      // we don't want the empty otion on a checkbox group
      if (inputComponent[input_type] !== 'checkGroup') {
        result.unshift({ label: '', value: '' })
      }

      return result
    }

    // map the incoming api json key names to names accepted by our Formik Inputs
    const component = inputComponent[input_type]
    const newProps = {
      name: input_name,
      label: label,
      options: selectOptions(component, default_value),
      inputType: component,
      multiple: component === 'checkGroup',
      required: false, // until i get the Yup required working dynamicaly
      // This is to only allow users to pick dates prior to today because all other days are not relevant date options. If new dates are added in the future to other reports, this may need to be revisited.
      max: getMaxDate(component),
    }

    return <DynamicInput {...newProps} key={i} />
  })

  return inputList
}

export default ReportFormInputs
