import {
  BasicField,
  DateField,
  SelectField,
  CheckGroup,
  SelectOptions,
} from './'

export const DynamicInput = props => {
  let { inputType, name, options } = props

  const componentMap = {
    select: SelectField,
    text: BasicField,
    date: DateField,
    checkGroup: CheckGroup, // change this to component after i create it
  }

  // workaround until i, can get this not to through a nasty impossible to debug error when name isnt set
  name = name || ' '

  // Dynamically select the input component type
  const DynamicComponent = componentMap[inputType]

  return (
    <DynamicComponent
      {...props}
      name={name}
      options={options}
      noTouchMessage={true}
    >
      <SelectOptions options={options} />
    </DynamicComponent>
  )
}
