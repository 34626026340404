import { Box, Button, Grid } from '@mui/material'
import { FieldArray, FormikProps, FormikProvider } from 'formik'
import { useEffect, useMemo } from 'react'

import { EFundsType } from '../../../../../_enums'
import {
  EProductFamilies,
  getProductById,
  getProductFamilyByProductId,
} from '../../../../../_enums/EProduct'
import {
  EWithdrawalCodeType,
  getWithdrawalTypeByCode,
} from '../../../../../_enums/EWithdrawalType'
import { useIllustrationContext } from '../../IllustrationContext'
import LifetimeFields from './LifetimeFields'
import WithdrawalFields from './WithdrawalFields'
import { IFormFields } from './Withdrawals'
import { IWithdrawalType } from './types'
import {
  getTetonWithdrawalTypes,
  sanitizeWithdrawals,
  getWithdrawalTypesByProductId,
  getOldestAndYoungest,
  addWithdrawalType,
} from './withdrawalsUtils'

type Props = {
  formik: FormikProps<IFormFields>
  maxPercentage: number
}

function WithdrawalForm({ formik, maxPercentage }: Props) {
  const { store } = useIllustrationContext()
  const { ownerAge, jointOwnerAge, typeOfFunds, selectedConfig } = store
  const { product } = selectedConfig.plan
  const { withdrawals } = formik.values
  const isQualified = typeOfFunds !== EFundsType.NonQualified
  const { oldest, youngest } = getOldestAndYoungest(ownerAge, jointOwnerAge)
  const hasWithdrawalSelected = withdrawals[0].type !== EWithdrawalCodeType.None
  const hasLifetimeSelected = withdrawals.some(
    (w: any) => getWithdrawalTypeByCode(w.type).isLifetime
  )
  const withdrawalTypes = useMemo(
    () =>
      getProductFamilyByProductId(product.id) === EProductFamilies.Teton
        ? getTetonWithdrawalTypes({
            code: selectedConfig.plan.code,
            isQualified,
            stateCode: selectedConfig.states[0].code,
            riderConfigId: store.riderConfigId,
          })
        : getWithdrawalTypesByProductId(
            getProductById(product.id).id,
            isQualified,
            store.riders,
            store.riderConfigId
          ),
    [product.id, isQualified]
  )

  useEffect(() => {
    if (
      hasWithdrawalSelected &&
      hasLifetimeSelected &&
      withdrawals.every(w => w.type !== EWithdrawalCodeType.Accelerated)
    ) {
      formik.setFieldTouched('jointOwnerAge')
    }
  }, [formik.values])

  useEffect(() => {
    sanitizeWithdrawals(formik.values.withdrawals)
    formik.handleChange('withdrawals')
  }, [formik.values.withdrawals])

  const getFilteredWithdrawalsTypes = (withdrawalIndex: number) => {
    const availableWithdrawalTypes: IWithdrawalType[] = []
    const currentSelection = withdrawals[withdrawalIndex]
      ? getWithdrawalTypeByCode(withdrawals[withdrawalIndex].type)
      : undefined
    const hasNormalWithdrawalTypeAfterCurrentSelection = currentSelection
      ? withdrawals.some(
          (withdrawal, index) =>
            (withdrawal.type === EWithdrawalCodeType.Amount ||
              withdrawal.type === EWithdrawalCodeType.PercentOfAv) &&
            index > withdrawalIndex
        )
      : false
    const lifeTimeWithdrawalIndex = withdrawals.findIndex(
      withdrawal => getWithdrawalTypeByCode(withdrawal.type).isLifetime
    )
    const withdrawalsSelectedMap = withdrawals
      .filter(
        x =>
          x.type !== EWithdrawalCodeType.Amount &&
          x.type !== EWithdrawalCodeType.PercentOfAv
      )
      .reduce((aggr, current) => {
        aggr[current.type] = true
        return aggr
      }, {})

    withdrawalTypes.forEach(withdrawalType => {
      const hasBeenSelected = withdrawalsSelectedMap[withdrawalType.code]
      const isCurrentSelection = withdrawalType.code === currentSelection?.code
      const isNoneOption = EWithdrawalCodeType.None === withdrawalType.code
      const isRMDOption = EWithdrawalCodeType.RMD === withdrawalType.code
      const hasLifetimeSelected = lifeTimeWithdrawalIndex !== -1

      if (hasBeenSelected && !isCurrentSelection) return
      if (isNoneOption && !(withdrawals.length === 1 && !withdrawalIndex))
        return

      if (hasLifetimeSelected) {
        if (!currentSelection?.isLifetime && withdrawalType.isLifetime) return
        if (lifeTimeWithdrawalIndex < withdrawalIndex && !isRMDOption) return
      }

      availableWithdrawalTypes.push({
        ...withdrawalType,
        disabled:
          hasNormalWithdrawalTypeAfterCurrentSelection &&
          withdrawalType.isLifetime,
      })
    })

    return availableWithdrawalTypes
  }

  const nextWithdrawalType = getFilteredWithdrawalsTypes(withdrawals.length)[0]

  return (
    <Box marginBottom={15}>
      <FormikProvider value={formik}>
        <FieldArray name="withdrawals">
          {arrayHelpers => (
            <Grid container spacing={4}>
              {withdrawals.map((wd, i) => (
                <Grid key={wd.id} container item spacing={2}>
                  <WithdrawalFields
                    formik={formik}
                    withdrawal={wd}
                    name={`withdrawals[${i}]`}
                    withdrawalTypes={getFilteredWithdrawalsTypes(i)}
                    index={i}
                    maxPercentage={maxPercentage}
                    oldest={oldest}
                    youngest={youngest}
                  />
                  <Grid
                    item
                    md={2}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {i === 0 ? null : (
                      <Button
                        color="error"
                        onClick={() => arrayHelpers.remove(i)}
                        type="button"
                      >
                        Remove
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
              {hasWithdrawalSelected && Boolean(nextWithdrawalType) && (
                <Grid item xs={12}>
                  <Button
                    type="button"
                    sx={{ mr: 1 }}
                    onClick={() =>
                      arrayHelpers.push(
                        addWithdrawalType((nextWithdrawalType as any).code)
                      )
                    }
                  >
                    Add Withdrawal
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </FieldArray>
      </FormikProvider>
      <LifetimeFields
        formik={formik}
        hasLifetimeSelected={hasLifetimeSelected}
        hasWithdrawalSelected={hasWithdrawalSelected}
        withdrawals={withdrawals}
      />
    </Box>
  )
}

export default WithdrawalForm
