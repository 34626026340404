import React, { useEffect } from 'react'
import { Spinner, Table } from 'reactstrap'

import { useStore } from '../../../_common/_hooks/use-store'
import { EAction } from '../../../_common/_store/reducers/app-reducers'
import ListItem from './ListItem'

function List({ policies, requestStatus }) {
  const { dispatch } = useStore()

  useEffect(() => {
    dispatch({
      type: EAction.Reset,
    })
  }, [dispatch])

  return (
    <>
      {requestStatus === 'done' ? (
        policies.length === 0 ? (
          <div>
            <h3>No account information available</h3>
          </div>
        ) : (
          <>
            <Table striped style={{ marginBottom: '100px' }}>
              <thead>
                <tr>
                  <th>Policy</th>
                  <th>Product</th>
                  <th>Years</th>
                  <th>Owner/Annuitant</th>
                  <th>Issue Date</th>
                  <th>
                    <span className="float-right">Account Value</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {policies.map(policy => (
                  <ListItem
                    key={policy.id}
                    policyNum={policy.policyNum}
                    product={policy.product}
                    years={policy.years}
                    accountVal={policy.accountVal}
                    owner={policy.owner}
                    issueDate={policy.issueDate}
                    annuitant={policy.annuitant}
                  />
                ))}
              </tbody>
            </Table>
          </>
        )
      ) : (
        <div>
          <h6 className="card-subtitle mb-2 text-muted">
            <Spinner
              color="primary"
              style={{ width: '2rem', height: '2rem' }}
            />{' '}
            Loading Policy Data...
          </h6>
        </div>
      )}
    </>
  )
}

export default List
