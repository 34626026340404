import { Chip } from '@mui/material'

import Table, { EDataType } from '../../../_common/_components/Table'
import { Agent } from '../../../_common/types'

export interface IAgents {
  agents: Agent[]
}

export function Agents({ agents }: IAgents) {
  return (
    <Table
      columns={[
        {
          field: 'agent_code',
          displayName: 'Agent Number',
        },
        {
          field: 'name',
          displayName: 'Name',
        },
        {
          field: 'type',
          displayName: 'Type',
          options: {
            render: val => (
              <Chip
                label={val}
                color={val === 'Primary' ? 'primary' : 'secondary'}
              />
            ),
          },
        },
        {
          field: 'percentage',
          displayName: 'Percentage',
          options: {
            dataType: EDataType.percentage,
          },
        },
      ]}
      rows={agents}
      dataKey="uuid"
    />
  )
}

export default Agents
