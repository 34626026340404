import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { FormikProps } from 'formik'

import { IRider } from '../../../../../types/salesTools'
import { IFormFields } from './ProductSelection'

interface IRiderDropdownProps {
  formik: FormikProps<IFormFields>
  riders: IRider[]
}

export default function RiderDropdown({ formik, riders }: IRiderDropdownProps) {
  return (
    <FormControl
      fullWidth
      error={
        Boolean(formik.submitCount) && Boolean(formik.errors.riderConfigId)
      }
    >
      <InputLabel id="rider">Rider</InputLabel>
      <Select
        labelId="rider"
        name="riderConfigId"
        value={formik.values.riderConfigId}
        label="Rider"
        onChange={formik.handleChange}
        disabled={!formik.values.issueState || !formik.values.plan}
        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
      >
        <MenuItem value={0}>None</MenuItem>
        {riders.map(rider => (
          <MenuItem key={rider.id} value={rider.rider_config_id}>
            {rider.rider_name}
          </MenuItem>
        ))}
      </Select>
      {Boolean(formik.submitCount) && (
        <FormHelperText>{formik.errors.riderConfigId}</FormHelperText>
      )}
    </FormControl>
  )
}
