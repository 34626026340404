import { Alert, Button } from '@mui/material'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { useEffect } from 'react'

import { getDocuments } from '../../../../_services/policyService'
import Table, { EDataType } from '../../../_components/Table'
import { useStore } from '../../../_hooks/use-store'
import { EAction } from '../../../_store/reducers/app-reducers'
import { IDocument } from './types'

function PolicyDocuments() {
  const {
    store: { documents, selectedPolicy },
    dispatch,
  } = useStore()

  const policyNumber = selectedPolicy.data.application.policy_number

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = (await getDocuments(policyNumber)).map(
          ({ type, ...rest }) => ({
            ...rest,
            type: type?.name,
          })
        )
        dispatch({ type: EAction.GetDocuments, payload: data })
      } catch (e) {
        dispatch({ type: EAction.GetDocuments, payload: [] })
      }
    }
    if (policyNumber) loadData()
  }, [policyNumber, dispatch])

  if (documents && !documents.length) {
    return (
      <Alert color="warning">
        There are currently no documents at this time.
      </Alert>
    )
  }

  const getBlob = (document: IDocument, callback) => {
    const url = `${window._env_.REACT_APP_API_URL}/annuity-policies/policies/${policyNumber}/documents/${document.id}/download/`

    axios
      .get(url, { responseType: 'blob' })
      .then(response => {
        const data = response.data
        if (!data) return

        callback(response.data)
      })
      .catch(() => {})
  }
  const handleDownload = (document: IDocument) => {
    getBlob(document, blob => {
      const file = new Blob([blob], { type: 'application/pdf' })
      const fileNameAndExt = `${document.name}.pdf`

      if (!!window.navigator && !!(window.navigator as any).msSaveOrOpenBlob) {
        ;(window.navigator as any).msSaveOrOpenBlob(file, fileNameAndExt)
        return
      }
      saveAs(file, fileNameAndExt)
    })
  }
  const handleView = (document: IDocument) => {
    getBlob(document, blob => {
      try {
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
        setTimeout(() => URL.revokeObjectURL(url), 1000 * 60 * 60)
      } catch (err) {
        console.log(err)
      }
    })
  }
  return (
    <Table
      columns={[
        {
          field: 'name',
          displayName: 'Name',
        },
        {
          field: 'type',
          displayName: 'Type',
        },
        {
          field: 'date_created',
          displayName: 'Upload Date',
          options: {
            dataType: EDataType.date,
          },
        },
        {
          field: '',
          displayName: '',
          options: {
            align: 'right',
            render: (_, row) => {
              return (
                <>
                  <Button
                    onClick={() => handleView(row)}
                    style={{ marginRight: '1rem' }}
                  >
                    View
                  </Button>
                  <Button onClick={() => handleDownload(row)}>Download</Button>
                </>
              )
            },
          },
        },
      ]}
      rows={documents!}
      dataKey="id"
    />
  )
}

export default PolicyDocuments
