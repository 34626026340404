import { InputAdornment, TextField, Grid } from '@mui/material'
import React, { useEffect, useCallback, useMemo } from 'react'

import { range } from '../../../../../_common/utils'
import { EWithdrawalCodeType } from '../../../../../_enums'
import { getWithdrawalTypeByCode } from '../../../../../_enums/EWithdrawalType'
import { formatPercentage, moneyFormat } from '../../../../../_helpers/money'
import { MAX_EXPECTED_AGE } from '../../../../../constants'
import { useIllustrationContext } from '../../IllustrationContext'
import WithdrawalTypeSelect from './WithdrawalTypeSelect'
import WithdrawalYearSelect from './WithdrawalYearSelect'
import {
  getWaitingPeriodByWithdrawalType,
  getWithdrawalWaitingPeriod,
} from './withdrawalsUtils'

function WithdrawalFields({
  withdrawal,
  name,
  withdrawalTypes,
  formik,
  index,
  maxPercentage,
  youngest,
  oldest,
}) {
  const {
    values,
    setFieldTouched,
    handleChange,
    setFieldValue,
    submitCount,
    errors,
  } = formik
  const withdrawalType = withdrawal.type
  const withdrawals = values.withdrawals
  const { store } = useIllustrationContext()
  const { selectedConfig, riders } = store

  const _yearOptions = useMemo(() => {
    const prevMinValue = index ? Number(withdrawals[index - 1].end) + 1 : 0
    const currentWithdrawal = withdrawals[index]
    const withdrawalType = getWithdrawalTypeByCode(currentWithdrawal.type)

    if (withdrawalType.code === EWithdrawalCodeType.RMD) {
      const minAge = withdrawalType.minAge - oldest
      return range(
        minAge > prevMinValue ? minAge : prevMinValue,
        MAX_EXPECTED_AGE - youngest
      )
    } else {
      const rider_max =
        riders !== undefined && riders.length > 0
          ? Math.max.apply(
              Math,
              riders.map(function (o) {
                return o.lifetime_withdrawal_waiting_period !== undefined
                  ? o.lifetime_withdrawal_waiting_period
                  : -1
              })
            )
          : undefined
      const rider_lifetime_override = rider_max > -1 ? rider_max : undefined
      const withdrawalWaitingPeriods = getWithdrawalWaitingPeriod(
        selectedConfig.data,
        rider_lifetime_override
      )
      let waitingPeriod = getWaitingPeriodByWithdrawalType(
        currentWithdrawal.type,
        withdrawalWaitingPeriods
      )
      if (withdrawalType.isLifetime) {
        const minAge = withdrawalType.minAge - youngest
        waitingPeriod = waitingPeriod > minAge ? waitingPeriod : minAge
        return range(
          waitingPeriod > prevMinValue ? waitingPeriod : prevMinValue,
          MAX_EXPECTED_AGE - youngest
        )
      } else {
        return range(
          waitingPeriod > prevMinValue ? waitingPeriod : prevMinValue,
          MAX_EXPECTED_AGE - oldest
        )
      }
    }
  }, [withdrawals])

  useEffect(() => {
    Object.keys(values.withdrawals[index]).forEach(key => {
      if (key !== 'id') {
        setFieldTouched(`withdrawals[${index}][${key}]`)
      }
    })
  }, [])

  useEffect(() => {
    if (!_yearOptions.length) {
      withdrawal.start = ''
      withdrawal.end = ''
    } else if (
      withdrawalType !== EWithdrawalCodeType.None &&
      Number(withdrawal.start) < _yearOptions[0]
    ) {
      withdrawal.start = _yearOptions[0]
    }
  }, [_yearOptions.length, withdrawalType])

  const handleAmountChange = useCallback(
    event => {
      const formatter =
        withdrawalType === EWithdrawalCodeType.Amount
          ? moneyFormat
          : formatPercentage
      setFieldValue(`${name}.amount`, formatter(event.target.value, event.type))
    },
    [withdrawalType, setFieldValue, name]
  )

  const amountTextFieldInputProps =
    withdrawalType === EWithdrawalCodeType.Amount
      ? { startAdornment: <InputAdornment position="start">$</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">%</InputAdornment> }

  const amountTextFieldHelpText =
    submitCount && errors.withdrawals?.[index]?.amount
      ? errors.withdrawals?.[index]?.amount
      : withdrawalType === EWithdrawalCodeType.Amount &&
        `Max amount will be ${maxPercentage}% of account value.`

  const showAmountField =
    withdrawalType === EWithdrawalCodeType.Amount ||
    withdrawalType === EWithdrawalCodeType.PercentOfAv

  const showWithdrawalYearSelect =
    withdrawalType !== EWithdrawalCodeType.None &&
    (withdrawalType !== EWithdrawalCodeType.Accelerated ||
      withdrawalType !== EWithdrawalCodeType.None)

  return (
    <>
      <Grid item md={4} sm={12}>
        <WithdrawalTypeSelect
          handleChange={handleChange}
          name={`${name}.type`}
          withdrawalType={withdrawal.type}
          withdrawalTypes={withdrawalTypes}
        />
      </Grid>

      <Grid item md={2} sm={12}>
        {showAmountField && (
          <TextField
            fullWidth
            value={withdrawal.amount}
            label="Amount"
            id={`${name}.amount`}
            name={`${name}.amount`}
            onChange={handleAmountChange}
            helperText={amountTextFieldHelpText}
            error={submitCount && errors.withdrawals?.[index]?.amount}
            InputProps={amountTextFieldInputProps}
          />
        )}
      </Grid>

      <Grid item md={2} sm={12}>
        {showWithdrawalYearSelect && (
          <WithdrawalYearSelect
            handleChange={handleChange}
            hasError={submitCount && errors.withdrawals?.[index]?.start}
            helperText={submitCount && errors.withdrawals?.[index]?.start}
            name={`${name}.start`}
            title="Withdrawal Start"
            year={withdrawal.start}
            yearOptions={_yearOptions}
          />
        )}
      </Grid>

      <Grid item md={2} sm={12}>
        {showWithdrawalYearSelect && (
          <WithdrawalYearSelect
            handleChange={handleChange}
            hasError={submitCount && errors.withdrawals?.[index]?.end}
            helperText={submitCount && errors.withdrawals?.[index]?.end}
            name={`${name}.end`}
            title="Withdrawal End"
            year={withdrawal.end}
            yearOptions={_yearOptions}
            yearStart={withdrawal.start}
          />
        )}
      </Grid>
    </>
  )
}

export default WithdrawalFields
