import moment from 'moment'

import { EBreakpoint } from '../_enums/EBreakpoint'
import { IPerson } from './_components/types'

export const formatName = (person: IPerson): string => {
  if (!person || !person.first_name) return ''

  return (
    person.first_name +
    ` ${!person.middle_name ? '' : person.middle_name}` +
    ` ${person.last_name ? person.last_name : ''}` +
    `${person.suffix ? ', ' + person.suffix : ''}`
  )
}

export const formatDate = (
  dataDate: Date | string | undefined | moment.Moment
): string => {
  return typeof dataDate === 'undefined' || !dataDate
    ? ''
    : moment(dataDate).format('L')
}

type JoinArgFunc = () => string
type JoinArgElement = JoinArgFunc | string
type JoinReduceCallback = (
  t: string,
  s: JoinArgElement,
  spacer: string
) => string
const joinReduceCallback: JoinReduceCallback = (t, s, spacer) => {
  return t + spacer + (typeof s === 'string' ? s : s())
}
export const join = (args: JoinArgElement[], spacer = ''): string =>
  args.reduce<string>((t, s) => joinReduceCallback(t, s, spacer), '')

export const range = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(0)
    .map((_, idx) => (start + idx).toString())

export function isSmallScreen(breakpoint: EBreakpoint | null) {
  return breakpoint === EBreakpoint.SM || breakpoint === EBreakpoint.XS
}
