import axios from 'axios'
import { Formik } from 'formik'
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Alert, Button, Col } from 'reactstrap'
import * as Yup from 'yup'

import Upload from './Upload'
import UploadForm from './UploadForm'
import './upload.css'

function sanatizeFileName(fileName) {
  return fileName
}

const fieldValidation = Yup.object().shape({
  formType: Yup.string(),
  policyNumber: Yup.string().when('formType', {
    is: val => {
      return val !== 'NA'
    },
    then: Yup.string().required('Policy Number is required.'),
  }),
})

const defaultResponseMessage = {
  code: undefined,
  message: '',
}

const defaultStatus = {
  statusTitle: '',
  statusIcon: '',
  statusMessage: '',
  status: 'inactive', // 'nactive, success, fail
  iconColor: '',
}

const defaultUploadState = {
  tryCount: 0,
  files: [],
  percentComplete: 0,
}

const initialFieldValues = { formType: 'NA', policyNumber: '' }

const maxRetries = 3

export default function Uploader() {
  const [status, setStatus] = useState(defaultStatus)
  const [showForm, setShowForm] = useState(true)
  const [resMessage, setResMessage] = useState(defaultResponseMessage)
  const [alertType, setAlertType] = useState('')
  const [uploadFieldValues, setUploadFieldValues] = useState(initialFieldValues)
  const [uploadState, setUploadState] = useState(defaultUploadState)

  useEffect(() => {
    if (status.status === 'fail') {
      setTimeout(() => {
        setStatus(defaultStatus)
      }, 2000)
    }
  }, [status])

  const getErrorMessage = useCallback(error => {
    if (isAxiosNetworkError(error)) {
      return {
        code: `${error}`,
        message: `We attempted to upload your file ${maxRetries} times but it failed. We are suspecting you are having internet connection issues. Please try again.`,
      }
    }

    const code =
      error.response && error.response.status
        ? error.response.status
        : undefined
    return {
      code,
      message: `Failed to upload file, please try again later`,
    }
  }, [])

  useEffect(() => {
    if (uploadState.tryCount <= 0) {
      return
    }

    const { policyNumber } = uploadFieldValues
    const url = `${window._env_.REACT_APP_API_URL}/agents/files`
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: handleUploadProgress,
    }
    const formData = new FormData()

    uploadState.files.forEach((file, index) => {
      // trim the file name length and remove all non-alphanumeric chars
      let fileName = sanatizeFileName(file.name)

      if (policyNumber !== '') {
        fileName = `${policyNumber}_${fileName}`
      }

      formData.append(`file${index}`, file, fileName)
    })

    setShowForm(false)
    axios
      .post(url, formData, config)
      .then(response => {
        setAlertType('success')
        setResMessage(s => ({ ...s, message: response.data }))
        setStatus({
          statusMessage: 'File Saved',
          status: 'success',
          statusTitle: 'Success',
          statusIcon: 'check-circle',
          iconColor: '#fff4ce',
        })
        setUploadState(defaultUploadState)
      })
      .catch(error => {
        if (uploadState.tryCount < maxRetries) {
          const httpError =
            error.response &&
            ![400, 401, 403, 500].includes(error.response.status)
          const axiosNetworkError = isAxiosNetworkError(error)

          if (httpError || axiosNetworkError) {
            setTimeout(() => {
              setUploadState(s => ({
                ...s,
                tryCount: s.tryCount + 1,
                percentComplete: 0,
              }))
            }, 1000)
            return
          }
        }

        setAlertType('danger')
        setResMessage(getErrorMessage(error))
        setStatus({
          statusMessage: 'File did not save.',
          status: 'fail',
          statusTitle: 'Alert',
          statusIcon: 'exclamation-triangle',
          iconColor: '#db9e48',
        })
        setUploadState(defaultUploadState)
      })
  }, [
    uploadState.tryCount,
    uploadState.files,
    getErrorMessage,
    uploadFieldValues,
  ])

  const getUploadStatus = () => {
    switch (uploadState.tryCount) {
      case 1:
        return 'Uploading files...'
      case 2:
        return 'Retrying upload...'
      default:
        return 'Last upload attempt...'
    }
  }

  // handles the actual uploading of the file
  const handleOnDrop = acceptedFiles => {
    const { formType, policyNumber } = uploadFieldValues
    // only upload if file is in the acceptedFiles list
    if (Array.isArray(acceptedFiles) && acceptedFiles.length !== 1) {
      return false
    }

    if (formType !== 'NA' && !policyNumber) {
      setStatus({
        statusMessage: 'Policy Number is Required.',
        status: 'fail',
        statusTitle: 'Alert',
        statusIcon: 'exclamation-triangle',
        iconColor: '#db9e48',
      })
      return false
    }
    setUploadState(s => ({ ...s, tryCount: 1, files: acceptedFiles }))
    setTimeout(() => {
      setStatus(defaultStatus)
    }, 2000)
  }

  const handleUploadProgress = e => {
    const percentComplete = e.total ? (e.loaded * 100) / e.total : 0
    setUploadState(s => ({ ...s, percentComplete }))
  }

  const isAxiosNetworkError = error =>
    error.isAxiosError && error.message === 'Network Error'

  const resetShowForm = () => {
    setShowForm(true)
    setResMessage(defaultResponseMessage)
    setAlertType('')
    setStatus(defaultStatus)
    setUploadFieldValues(initialFieldValues)
    setUploadState(defaultUploadState)
  }

  if (showForm) {
    return (
      <Fragment>
        <Formik
          initialValues={initialFieldValues}
          validateOnBlur
          validateOnChange
          validationSchema={fieldValidation}
        >
          {formikProps => (
            <UploadForm
              setUploadFieldValues={setUploadFieldValues}
              {...formikProps}
            />
          )}
        </Formik>
        <Upload handleOnDrop={handleOnDrop} {...status} />
      </Fragment>
    )
  }
  if (uploadState.tryCount) {
    return (
      <Fragment>
        <Col lg="4" md="5" xs="6" className="mx-auto mb-3">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${uploadState.percentComplete}%` }}
              aria-valuenow={uploadState.percentComplete}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <br />
          <div className="text-center">
            <div
              className="spinner-border ml-auto text-primary"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '12px' }}
            ></div>
            <strong>{getUploadStatus()}</strong>
          </div>
        </Col>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Col lg="4" md="5" xs="6" className="mx-auto mb-3">
        <Alert color={alertType} fade={false}>
          {alertType === 'success' ? (
            <Fragment>
              <p>
                Your file has been uploaded. Please save this confirmation for
                your reference:
              </p>
              {resMessage.message && (
                <h5 className="text-center">{resMessage.message}</h5>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {resMessage.code && <p>{resMessage.code}</p>}
              {resMessage.message && <p>{resMessage.message}</p>}
            </Fragment>
          )}
        </Alert>

        <Button
          className="float-right"
          color="outline-secondary"
          onClick={() => resetShowForm()}
        >
          Upload another file
        </Button>
      </Col>
    </Fragment>
  )
}
