import { Alert } from '@mui/material'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getRelatedPolicies } from '../../../../_services/policyService'
import Table, { EDataType } from '../../../_components/Table'
import { IPerson } from '../../../_components/types'
import { useStore } from '../../../_hooks/use-store'
import { EAction } from '../../../_store/reducers/app-reducers'
import { formatName } from '../../../utils'

export const getOwnerAnnuitant = (owner: IPerson, annuitant: IPerson) =>
  formatName(owner) === 'null null' ? formatName(annuitant) : formatName(owner)

function RelatedPolicies() {
  const {
    store: { relatedPolicies, selectedPolicy },
    dispatch,
  } = useStore()

  const policyNumber = selectedPolicy.data.application.policy_number

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = (await getRelatedPolicies(policyNumber)).map(
          ({
            application: { product, plan, issue_date, policy_number },
            owner,
            annuitant,
            ...rest
          }) => ({
            product,
            plan,
            issue_date,
            policy_number,
            owner_annuitant: getOwnerAnnuitant(owner as any, annuitant as any),
            ...rest,
          })
        )
        dispatch({ type: EAction.GetRelatedPolicies, payload: data })
      } catch (e) {
        dispatch({ type: EAction.GetRelatedPolicies, payload: [] })
      }
    }
    if (policyNumber) loadData()
  }, [policyNumber, dispatch])

  if (relatedPolicies && !relatedPolicies.length) {
    return (
      <Alert color="warning">
        There are currently no related policies at this time.
      </Alert>
    )
  }

  return (
    <Table
      columns={[
        {
          field: 'policy_number',
          displayName: 'Policy',
          options: {
            render: val => (
              <Link to={`/client/policy/details/${val}`}>{val}</Link>
            ),
          },
        },
        {
          field: 'product',
          displayName: 'Product',
        },
        {
          field: 'plan',
          displayName: 'Years',
        },
        {
          field: 'owner_annuitant',
          displayName: 'Owner/Annuitant',
        },
        {
          field: 'issue_date',
          displayName: 'Issue Date',
          options: {
            dataType: EDataType.date,
          },
        },
      ]}
      rows={relatedPolicies!}
      dataKey="policy_number"
    />
  )
}

export default RelatedPolicies
