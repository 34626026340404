import React from 'react'

export const AddressDisplay: React.FC<any> = address => {
  return (
    <p>
      {address.address.address1} <br /> {address.address.city},{' '}
      {address.address.state} {address.address.zip}
    </p>
  )
}
