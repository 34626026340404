/** @format */
import { connect, ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Alert } from 'reactstrap'

export const ErrorAlert = ({ field, ...props }) => (
  <Alert color="danger" {...field} {...props} />
)

export const WarningAlert = ({ field, ...props }) => (
  <Alert color="warning" {...field} {...props} />
)

export const StyledErrorMessage = ({ name, ...props }) => (
  <ErrorMessage name={name} component={ErrorAlert} {...props} />
)

StyledErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
}

// export const NoTouchEM = ({ name }) => (
//   <Field
//     name={name}
//     render={({ form }) => {
//       const error = getIn(form.errors, name)
//       return typeof error !== 'undefined' ? error : null
//     }}
//   />
// )

// NoTouchEM.propTypes = {
//   name: PropTypes.string.isRequired
// }

const NoTouchEM = props => {
  const { formik, name } = props
  const error = getIn(formik.errors, name)
  // const submitted = formik.submitCount > 0
  return typeof error !== 'undefined' ? (
    <div
      style={{
        width: '100%',
        'margin-top': '.25rem',
        'font-size': '80%',
        color: '#dc3545',
        'padding-left': '2rem',
        'font-weight': 'bold',
      }}
    >
      {error}
    </div>
  ) : null
}

//<Alert color="danger"></Alert>

NoTouchEM.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object, // formik props coming from connect HOC
}

NoTouchEM.defaultProps = {
  formik: {},
}

export const NoTouchErrorMessage = connect(NoTouchEM)

export default StyledErrorMessage
