import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import moment from 'moment'
import { FC } from 'react'
import { Link } from 'react-router-dom'

type PolicyRowProps = {
  details: {
    policy_number: string
    agent_code: string
    product: string
    status: string
    status_description: string
    tax_qualification_status: string
    received_date: string
    first_name: string
    last_name: string
  }
}
// renders each listing row
const PolicyRow: FC<PolicyRowProps> = props => {
  const { details } = props
  const source = details

  const policyNumber = source.policy_number
  const agentCode = source.agent_code
  const productName = source.product
  const status = source.status
  const status_text = source.status_description
  const tax_qualification_status = source.tax_qualification_status
  const receivedDate = source.received_date

  let firstName = source.first_name
  let lastName = source.last_name

  const url = '/agent/my-business/detail/' + policyNumber + '/' + status

  return (
    <tr>
      <td>{policyNumber}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{agentCode}</td>
      <td>{productName}</td>
      <td>{tax_qualification_status}</td>
      <td>
        <span>{status_text}</span>
      </td>
      <td>{moment(receivedDate).format('L')}</td>
      <td>
        <Link to={url}>
          <Button>
            View
            <FontAwesomeIcon
              icon={'arrow-alt-circle-right'}
              size="1x"
              style={{
                margin: '0 0px 2px 5px',
                zoom: '1.25',
                verticalAlign: 'middle',
              }}
            />
          </Button>
        </Link>
      </td>
    </tr>
  )
}

export default PolicyRow
