import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import { formatDate } from '../../_common/utils'
import './PolicyEventsList.css'
import ProductConfigPolicyEventTable from './ProductConfigPolicyEventTable'
import { IProductConfigChangePolicyEvents } from './types'

type ProductConfigPolicyEventsListProps = {
  productConfigPolicyEventsList: IProductConfigChangePolicyEvents[]
}

function ProductConfigPolicyEventsList({
  productConfigPolicyEventsList,
}: ProductConfigPolicyEventsListProps) {
  return (
    <div>
      {productConfigPolicyEventsList.map(event => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1-header"
          >
            <Typography variant="h5">{event.title}</Typography>
            <Typography variant="subtitle1">
              {formatDate(event.date)}
            </Typography>
            <Typography variant="body1">
              <strong>Reason: </strong>
              {event.reason}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className="notes">
              <strong>Notes: </strong>
              {event.notes}
            </Typography>
            <Typography variant="h6" className="withdrawal-adjustment-details">
              Config Change Details:
            </Typography>
            <ProductConfigPolicyEventTable {...event} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default ProductConfigPolicyEventsList
