import { Bar } from 'react-chartjs-2'

const Withdrawals = ({ data }) => {
  if (!data) return null
  return (
    <Bar
      data={data}
      options={{
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: '',
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return ` $${tooltipItem.yLabel}`
            },
          },
        },
      }}
    />
  )
}

export default Withdrawals
