import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'

import { EBreakpoint } from '../../_enums/EBreakpoint'
import { useBreakpoint } from '../../_hooks'
import authService from '../../_services/authService'
import { AutoFillAwareTextField } from '../../components'
import { EnviromentVariablesAlerts } from '../../components/app-enviroment'
import { SystemNotifications } from '../../components/layout/SystemNotifications'
import './Login.css'
import silacLogo from './SILAC-vector.png'

const LOGGED_OUT_MESSAGE = 'You are Logged Out'

const validationSchema = Yup.object().shape({
  username: Yup.string().required().label('Username'),
  password: Yup.string().required().label('Password'),
})

type Props = {
  dispatch: any
  showAlert: boolean
  alertMsg: string
  loggedIn: boolean
  loggingIn: boolean
}

function Login({ loggedIn, showAlert, alertMsg, loggingIn, dispatch }: Props) {
  const [showPassword, setShowPassword] = useState(false)
  const breakpoint = useBreakpoint()
  const handleSubmit = async ({ username, password }) => {
    dispatch(authService.signIn({ username, password }))
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  if (loggedIn) return <Redirect to={authService.redirectByUserGroups()} />

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="div"
        sx={{
          paddingTop: breakpoint === EBreakpoint.XS ? 2 : 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SystemNotifications />
        <Typography component="h1" variant="h5" textAlign="center">
          Secure Login
        </Typography>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <img
            className="profile-img img-fluid"
            src={silacLogo}
            alt="SILAC Logo"
          />
          <EnviromentVariablesAlerts />
          {showAlert && (
            <Alert
              sx={{ mt: 1 }}
              severity={LOGGED_OUT_MESSAGE === alertMsg ? 'info' : 'error'}
            >
              {alertMsg}
            </Alert>
          )}
          <AutoFillAwareTextField
            sx={{ marginTop: 4 }}
            fullWidth
            margin="normal"
            value={formik.values.username}
            onChange={formik.handleChange}
            label="Username"
            id="username"
            name="username"
            helperText={Boolean(formik.submitCount) && formik.errors.username}
            error={
              Boolean(formik.submitCount) && Boolean(formik.errors.username)
            }
          />
          <AutoFillAwareTextField
            fullWidth
            margin="normal"
            value={formik.values.password}
            onChange={formik.handleChange}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            id="password"
            name="password"
            helperText={Boolean(formik.submitCount) && formik.errors.password}
            error={
              Boolean(formik.submitCount) && Boolean(formik.errors.password)
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="togglePasswordVisibility"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={loggingIn}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
        </Box>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
        <Disclosure sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  )
}

function Disclosure(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      SILAC is licensed as SILAC Life Insurance Company in the state of
      California
    </Typography>
  )
}

// Redux state mapping
const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    showAlert: state.authentication.showAlert,
    alertMsg: state.authentication.alertMsg,
    loggingIn: state.authentication.loggingIn,
  }
}

const connectedLogin = connect(mapStateToProps)(Login)
export { connectedLogin as Login }
export { Login as IsolatedLogin }
