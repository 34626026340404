import { Container, Drawer } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useState } from 'react'

import { isSmallScreen } from '../../_common/utils'
import { EMembershipType, EUserGroup } from '../../_enums'
import { useBreakpoint } from '../../_hooks'
import authService from '../../_services/authService'
import { IMembership } from '../../models/agent'
import { useThemeContext } from '../../themes'
import Confetti from '../Confetti'
import AgentProfileOptions from '../agent-components/layout/ProfileOptions'
import ClientProfileOptions from '../client-components/layout/ProfileOptions'
import ClientSidebar from '../client-components/layout/Sidebar'
import AgentSidebar from './AgentSidebar'
import Header from './Header'
import './Sidebar.css'

/* this is for the client sidebard */

type Props = {
  children: React.ReactNode
  userGroup: EUserGroup.ANNUITY_CLIENT | EUserGroup.ANNUITY_AGENT
}

export default function Layout({ userGroup, children }: Props) {
  const breakpoint = useBreakpoint()
  const themeContext = useThemeContext()

  const [menuOpen, setMenuOpen] = useState(false)

  const agentMembership: IMembership = authService.getAgentMembership()

  return (
    <>
      {agentMembership &&
        themeContext.themeType !== EMembershipType.DEFAULT &&
        !agentMembership.lastLogin && <Confetti />}
      <Box minHeight={620} minWidth={620}>
        <Header
          setMenuOpen={setMenuOpen}
          userGroup={userGroup}
          profileOptions={
            userGroup === EUserGroup.ANNUITY_AGENT ? (
              <AgentProfileOptions />
            ) : (
              <ClientProfileOptions />
            )
          }
        />

        {isSmallScreen(breakpoint) ? (
          <Drawer
            anchor="left"
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            {userGroup === EUserGroup.ANNUITY_AGENT ? (
              <AgentSidebar setMenuOpen={setMenuOpen} />
            ) : (
              <ClientSidebar setMenuOpen={setMenuOpen} />
            )}
          </Drawer>
        ) : userGroup === EUserGroup.ANNUITY_AGENT ? (
          <AgentSidebar />
        ) : (
          <ClientSidebar />
        )}

        <Box p={2} className="sidebar-offset">
          <Container maxWidth="xl" sx={{ marginTop: 1 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </>
  )
}
