import * as React from 'react'
import { Alert } from 'reactstrap'

import * as actions from '../../_actions/ActionNotes.actions'
import { useStore } from '../../_common/_hooks/use-store'
import { asyncDispatch } from '../../_contexts/index'
import { IStore, getStore } from '../../_contexts/store'
import { TStore } from '../../_contexts/types'
import reducers from '../../_reducers/ActionNotes.reducers'
import ActionNotesList from './ActionNotesList'
import { IActionNote, IActionNotesStore } from './types'

function ActionNotes() {
  const { useEffect, useReducer } = React
  const {
    store: { selectedPolicy },
  } = useStore()

  const policyNumber = selectedPolicy.data.application.policy_number

  const [state, dispatch] = useReducer<TStore>(
    reducers,
    getStore<IActionNotesStore>() as IStore
  )

  const getActionNotes = (policyNumber: string) => {
    asyncDispatch(dispatch, actions.getActionNotes(policyNumber))
  }

  const {
    action_notes: { action_notes: actionNotes },
  } = state

  useEffect(() => {
    if (policyNumber) getActionNotes(policyNumber)
  }, [policyNumber])

  if (!actionNotes) return <Alert color="info">Loading...</Alert>
  if (!actionNotes.length) {
    return (
      <Alert color="warning">
        There are currently no actions at this time.
      </Alert>
    )
  }

  return (
    <table className="table table-hover">
      <tbody>
        <ActionNotesList actionNotes={actionNotes as Array<IActionNote>} />
      </tbody>
    </table>
  )
}

export default ActionNotes
