import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { communicationsService } from '../_services'

const MarketingCampaing: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState('')
  const params: { id: string } = useParams()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        const response =
          await communicationsService.agencynews.getAgencyNewContentById(
            params.id
          )
        setContent(response.data)
      } catch (e: any) {
        if (e?.status === 404) history.push('/not-found')
        else history.push('/login')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  if (isLoading)
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )

  return <Box dangerouslySetInnerHTML={{ __html: content }} />
}

export default MarketingCampaing
