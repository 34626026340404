import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import homeBanner from '../components/agent-components/Images/welcome-header.png'
import { Theme } from '../types'

export const defaultTheme: Theme = {
  navbarBackground: 'linear-gradient(180deg,#0072c0 15%,#00204a)',
  headerTitle: (
    <Link to="/" className="navbar-brand headerBrand">
      SILAC Annuity Portal
    </Link>
  ),
  homeBanner: (
    <Box
      className="jumbo"
      sx={{
        padding: '4rem 2rem',
        marginBottom: '2rem',
        backgroundColor: '#e9ecef',
        backgroundImage: `url(${homeBanner})`,
      }}
    >
      <div className="textBackgroundDiv">
        <div className="innerBox">
          <h1 className="display-4 textBackground">
            Welcome to the Agent Portal
          </h1>
        </div>
      </div>
    </Box>
  ),
  palette: {
    primary: {
      main: '#0072c0',
      light: '#59a0f3',
      dark: '#00478f',
    },
    secondary: {
      main: 'rgb(126, 184, 243)',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'initial',
          letterSpacing: 'initial',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
}
