import axios from 'axios'
import { saveAs } from 'file-saver'
import { useCallback, useState, useEffect, useRef } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import ModalSpinner from '../../layout/ModalSpinner'
import ReportForm from './ReportForm'
import ReportQueue from './ReportQueue'
import authService from '../../../_services/authService' // Import authService

const DynamicReports = ({
  reportCreatorHeaderText,
  reportListHeaderText,
  requiresPin = false,
}) => {
  let timerRef = useRef(null)
  const timerInterval = 10000
  const [reports, setReports] = useState([])
  const [busyWait, setBusyWait] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [requestedReports, setRequestedReports] = useState([])

  const getReportList = useCallback(() => {
    const requiresProtectedReports = requiresPin ? '_protected' : ''
    let url = `${window._env_.REACT_APP_API_URL}/agents/reporting${requiresProtectedReports}`
    let pin = localStorage.getItem('commission_pin') || ''
    let agentNumber = authService.getUserName() || '' // Use authService to get agentNumber
    axios.post(url, { pin: pin, agent_number: agentNumber }).then(response => {
      setReports([...response.data])
    })
  }, [requiresPin])

  const getRequestedReports = useCallback(() => {
    const requiresProtectedReports = requiresPin ? '_protected' : ''
    let url = `${window._env_.REACT_APP_API_URL}/agents/reporting/downloadable_list${requiresProtectedReports}`
    let pin = localStorage.getItem('commission_pin') || ''
    let agentNumber = authService.getUserName() || '' // Use authService to get agentNumber
    axios.post(url, { pin: pin, agent_number: agentNumber }).then(response => {
      setBusyWait(false)
      setRequestedReports([...response.data])
    })
  }, [requiresPin])

  const getSpecificReport = (reportName, payload) => {
    setBusyWait(true)
    let url = `${window._env_.REACT_APP_API_URL}/agents/reporting/${reportName}`
    let pin = localStorage.getItem('commission_pin') || ''
    let agentNumber = authService.getUserName() || '' // Use authService to get agentNumber
    axios
      .post(url, {
        ...payload,
        requires_pin: requiresPin,
        pin: pin,
        agent_number: agentNumber,
      })
      .then(() => {
        getRequestedReports()
      })
      .catch(error => {
        console.log('ERROR: ', error)
      })
  }

  const handleReportChange = e => {
    setSelectedIndex(e.target.value)
  }

  const handleReportSubmit = formValues => {
    const config = reports[selectedIndex]
    const payload = buildReportPostPayload(config.params, formValues)
    getSpecificReport(config.name, payload)
  }

  const buildReportPostPayload = (origParams, values) => {
    let payload = { params: [] }
    payload.params = origParams.map((input, i) => {
      return {
        input_name: input.input_name,
        input_type: input.input_type,
        input_value: values[input.input_name] || '',
      }
    })
    return payload
  }

  const onFileDownload = (fileName, fileId, fileType) => {
    const fileNameAndExt = fileName + fileType
    const url = `${window._env_.REACT_APP_API_URL}/agents/reporting/download${requiresPin ? '_protected' : ''}/${fileId}`
    let pin = localStorage.getItem('commission_pin') || ''
    let agentNumber = authService.getUserName() || '' // Use authService to get agentNumber
    axios
      .post(
        url,
        { pin: pin, agent_number: agentNumber },
        { responseType: 'blob' }
      )
      .then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            fileNameAndExt
          )
          return
        }
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        saveAs(file, fileNameAndExt)
      })
      .catch(error => {
        console.log('ERROR: ', error)
      })
  }

  useEffect(() => {
    getReportList()
    getRequestedReports()
    timerRef.current = setInterval(() => getRequestedReports(), timerInterval)
    return () => {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [getReportList, getRequestedReports])

  return (
    <ModalSpinner active={busyWait}>
      <Row>
        <Col md="6">
          <br />
          <Card>
            <CardHeader>{reportCreatorHeaderText}</CardHeader>
            <CardBody>
              <select
                id="report"
                className="form-control"
                placeholder="Select Report"
                onChange={handleReportChange}
                onBlur={handleReportChange}
              >
                <option />
                {reports.map((report, id) => (
                  <option key={report.id} value={id}>
                    {report.name}
                  </option>
                ))}
              </select>
              <ReportForm
                reportConfigId={selectedIndex}
                reportConfig={reports[selectedIndex]}
                handleReportSubmit={handleReportSubmit}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <br />
          <Card>
            <CardHeader>{reportListHeaderText}</CardHeader>
            <CardBody>
              <ReportQueue
                requestedReports={requestedReports}
                onFileDownload={onFileDownload}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ModalSpinner>
  )
}

export default DynamicReports
