import React, { Component } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import PolicyDetail from './PolicyDetail'
import PolicySearch from './PolicySearch'

class AgentMyBusiness extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Switch>
            <Route exact path="/agent/my-business" component={PolicySearch} />
            <Route
              path="/agent/my-business/detail/:policy/:status"
              component={PolicyDetail}
            />
            <Redirect to="/agent/my-business" />
          </Switch>
        </div>
      </div>
    )
  }
}

export default AgentMyBusiness
