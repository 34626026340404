import { Alert, Box } from '@mui/material'
import { useEffect } from 'react'

import { useStore } from '../../_common/_hooks/use-store'
import { GetEnvironment } from '../../_common/_store/actions/policy-actions'

export default function SingleEnviromentVariablesAlert() {
  const { store, dispatch } = useStore()

  useEffect(() => {
    ;(async function () {
      dispatch?.(await GetEnvironment())
    })()
  }, [dispatch])

  const version = store?.environment?.version

  if (
    !version ||
    (version.hasOwnProperty('should_show_environment_banner') &&
      !version.should_show_environment_banner)
  )
    return null

  if (version?.error) return <Alert severity="error">{version?.error}</Alert>

  return (
    <Alert severity="warning">
      <Box display="flex" alignItems="center">
        Database:{' '}
        <b style={{ marginRight: 8 }}>{version?.db_name?.toUpperCase()}</b>
        Environment: <b style={{ marginRight: 8 }}>{version?.server_env}</b>
        Feature Flags: <b>{version?.feature_flags.join(', ')}</b>
      </Box>
    </Alert>
  )
}
