import { useEffect, useReducer } from 'react'
import { Alert } from 'reactstrap'

import * as actions from '../../_actions/PolicyEvents.actions'
import { useStore } from '../../_common/_hooks/use-store'
import { asyncDispatch } from '../../_contexts'
import { getStore, IStore } from '../../_contexts/store'
import { TStore } from '../../_contexts/types'
import reducers from '../../_reducers/PolicyEvents.reducers'
import ProductConfigPolicyEventsList from './ProductConfigPolicyEventsList'
import WithdrawalAdjustmentPolicyEventsList from './WithdrawalAdjustmentPolicyEventsList'
import { IPolicyEventsStore } from './types'

function Events() {
  const {
    store: { selectedPolicy },
  } = useStore()

  const policyNumber = selectedPolicy.data.application.policy_number

  const [state, dispatch] = useReducer<TStore>(
    reducers,
    getStore<IPolicyEventsStore>() as IStore
  )

  const getPolicyEvents = (policyNumber: string) => {
    asyncDispatch(dispatch, actions.getPolicyEvents(policyNumber))
  }

  const {
    policy_events: { policy_events: policyEvents },
  } = state

  useEffect(() => {
    if (policyNumber) getPolicyEvents(policyNumber)
  }, [policyNumber])

  if (!policyEvents) return <Alert color="info">Loading...</Alert>
  if (
    !policyEvents.productConfigChangePolicyEvents.length &&
    !policyEvents.withdrawalAdjustmentPolicyEvents.length
  ) {
    return (
      <Alert color="warning">There are currently no events at this time.</Alert>
    )
  }
  return (
    <>
      <ProductConfigPolicyEventsList
        productConfigPolicyEventsList={
          policyEvents.productConfigChangePolicyEvents
        }
      />
      <WithdrawalAdjustmentPolicyEventsList
        withdrawalAdjustmentPolicyEventsList={
          policyEvents.withdrawalAdjustmentPolicyEvents
        }
      />
    </>
  )
}

export default Events
