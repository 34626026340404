import React from 'react'

import PremiumRow from './PremiumRow'

const PremiumRows = props => {
  const { data } = props
  const interestRowsList = data.map((section, i) => {
    return <PremiumRow key={i} data={section} />
  })
  return interestRowsList
}

export default PremiumRows
