/** @format */
import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Label, FormGroup } from 'reactstrap'

import { StyledErrorMessage, NoTouchErrorMessage } from './StyledErrorMessage'

export const BasicField = props => {
  const { disabled, label, name, required, noTouchMessage, onBlur } = props

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Field
        name={name}
        className="form-control"
        disabled={disabled}
        required={required}
        onBlur={onBlur}
      />
      {noTouchMessage ? (
        <NoTouchErrorMessage name={name} />
      ) : (
        <StyledErrorMessage name={name} />
      )}
    </FormGroup>
  )
}

BasicField.propTypes = {
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  noTouchMessage: PropTypes.bool,
}

BasicField.defaultProps = {
  disabled: false,
  readonly: false,
  required: false,
  noTouchMessage: false,
}

export default BasicField
