import { Button } from '@mui/material'
import axios from 'axios'
import React, { Component } from 'react'

export default class FirelightLogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      SAMLResponse: '',
      RelayState: '',
      url: `${window._env_.REACT_APP_FIRELIGHT_SSO_URL}`,
    }
  }

  handleLogin() {
    const url = `${window._env_.REACT_APP_API_URL}/agents/firelight_sso/`

    axios
      .get(url)
      .then(response => {
        const { data } = response
        this.setState(prevState => ({
          ...prevState,
          ...data,
        }))
      })
      .catch(error => {
        console.log(error.response.data)
      })
  }

  componentDidUpdate = () => {
    if (this.state.SAMLResponse !== '' && this.state.RelayState !== '') {
      document.getElementById('firelightlogin').submit()
    }
  }

  render() {
    const { props, state } = this
    const { SAMLResponse, RelayState, url } = state

    return (
      <>
        <Button
          {...props}
          onClick={() => {
            return this.handleLogin()
          }}
        >
          {props.children}
        </Button>
        <form
          id="firelightlogin"
          target="_firelight"
          method="post"
          action={url}
        >
          <input name="SAMLResponse" type="hidden" value={SAMLResponse} />
          <input name="RelayState" type="hidden" value={RelayState} />
        </form>
      </>
    )
  }
}
