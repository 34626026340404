import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'

export default class MaterialsFilters extends PureComponent {
  static propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filterValues: PropTypes.shape({
      productFilter: PropTypes.string,
      stateFilter: PropTypes.string,
    }),
    productList: PropTypes.arrayOf(PropTypes.object),
    stateList: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    filterValues: {
      productFilter: 'Any',
      stateFilter: 'Any',
    },
  }

  /* componentDidUpdate(prevProps) {
    if (prevProps.products.length === 0 && this.props.products.length !== 0) {
      console.log(this.props.products)
    }
  } */

  buildOptions = list =>
    list.map(item => {
      const code = typeof item.id !== 'undefined' ? item.id : item.code
      return (
        <option key={item.code} value={code}>
          {item.name}
        </option>
      )
    })

  handleSelectChange = e => {
    const id = e.target.id,
      value = e.target.value,
      productID = e.target.prodID || null
    // console.log('filter', id) //TODO remove
    // console.log('value', value) //TODO remove
    this.props.onFilterChange(id, value, productID)
  }

  render() {
    const { filterValues, productList, stateList } = this.props

    return (
      <>
        <Form>
          <FormGroup>
            <Label htmlFor="productFilter">Select Product:</Label>
            <Input
              id="productFilter"
              name="productFilter"
              type="select"
              onChange={this.handleSelectChange}
              value={filterValues.productFilter}
            >
              <option value="Any">Any</option>
              {this.buildOptions(productList)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="stateFilter">Select State:</Label>
            <Input
              id="stateFilter"
              name="stateFilter"
              type="select"
              onChange={this.handleSelectChange}
              value={filterValues.stateFilter}
            >
              <option value="Any">Any</option>
              {this.buildOptions(stateList)}
            </Input>
          </FormGroup>
        </Form>
      </>
    )
  }
}
