import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatMTDatetime } from '../../_helpers/date'
import { IActionNote } from './types'

type RelatedPolicyListProps = {
  actionNotes: Array<IActionNote>
}

function ActionNotesList({ actionNotes }: RelatedPolicyListProps) {
  return (
    <>
      {actionNotes
        .filter(x => !x.is_confidential)
        .map((actionNote, i) => (
          <ActionNotesRow key={i} actionNote={actionNote} />
        ))}
    </>
  )
}

type RelatedPolicyRowProps = {
  actionNote: IActionNote
}

function ActionNotesRow({ actionNote }: RelatedPolicyRowProps) {
  const notifyMethod = actionNote.notification_method.toLowerCase()
  const icon = (method => {
    switch (method) {
      case 'email':
        return faAt
      case 'mail':
        return faEnvelope
      case 'telephone':
        return faPhone
      default:
        return faPhone
    }
  })(notifyMethod)

  return (
    <tr data-testid={actionNote.id}>
      <td>
        <div className="note-container">
          <div>
            {formatMTDatetime(actionNote.action_date)}
          </div>
          <div className="note-sub-container">
            <div>{actionNote.reporter}</div>
            <div className="note-method">
              <FontAwesomeIcon
                icon={icon}
                color="#7eb8f3"
                size="1x"
                title={notifyMethod}
              />
            </div>
          </div>
        </div>
        <div className="note-note">{actionNote.notes}</div>
      </td>
    </tr>
  )
}

export default ActionNotesList
