import { Button } from '@mui/material'
import { Alert, ListGroup, ListGroupItem } from 'reactstrap'

const ReportQueue = ({ requestedReports, onFileDownload }) => {
  // escape functionality if now form input params
  if (!requestedReports || requestedReports.length === 0)
    return (
      <Alert color="warning">
        No report request has been made within the last 12 hours.
      </Alert>
    )

  return (
    <ListGroup>
      {requestedReports.map(report => (
        <ListGroupItem
          className="list-group-item d-flex justify-content-between align-items-center"
          key={report.index}
        >
          {report.file_name}
          <span>{report.status}</span>
          {report.status === 'Ready' ? (
            <Button
              className="float-right"
              onClick={() => {
                onFileDownload(report.file_name, report.id, report.format)
              }}
            >
              Download
            </Button>
          ) : (
            <Button className="float-right" disabled>
              Download
            </Button>
          )}
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}

export default ReportQueue
