import { TableCell, TableRow } from '@mui/material'
import _ from 'lodash'
import { Alert } from 'reactstrap'

import Table, { EDataType } from '../../../../_common/_components/Table'
import { useStore } from '../../../../_common/_hooks/use-store'
import { formatMoney } from '../../../../_helpers/number'

export const getReceivedTotal = premiums =>
  premiums
    .map(rec => {
      return rec.received_amount
    })
    .reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b)
    }, 0)

export const getExpectedTotal = premiums =>
  premiums
    .map(rec => {
      return rec.expected_amount
    })
    .reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b)
    }, 0)

export function Premiums() {
  const { store } = useStore()
  const data = store?.selectedPolicy?.data?.premiums

  const premiums = _.orderBy({ ...data }, ['received_date'], ['desc'])
  const receivedTotal = getReceivedTotal(premiums)
  const expectedTotal = getExpectedTotal(premiums)

  if (!premiums?.length) {
    return (
      <Alert color="warning">
        There are currently no premiums at this time.
      </Alert>
    )
  }

  return (
    <Table
      columns={[
        {
          field: 'received_date',
          displayName: 'Date',
          options: {
            dataType: EDataType.date,
          },
        },
        {
          field: 'premium_from',
          displayName: 'Payer',
        },
        {
          field: 'type',
          displayName: 'Type',
        },
        {
          field: 'received_amount',
          displayName: 'Received Amt',
          options: {
            dataType: EDataType.money,
          },
        },
        {
          field: 'expected_amount',
          displayName: 'Expected Amt',
          options: {
            dataType: EDataType.money,
          },
        },
      ]}
      rows={premiums}
      dataKey="id"
      footer={
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell align="right">
            <b>Totals:</b>
          </TableCell>
          <TableCell align="right">{formatMoney(receivedTotal)}</TableCell>
          <TableCell align="right">{formatMoney(expectedTotal)}</TableCell>
        </TableRow>
      }
    />
  )
}

export default Premiums
