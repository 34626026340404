import { InfoRounded } from '@mui/icons-material'
import { Box, Grid, Tooltip } from '@mui/material'
import numeral from 'numeral'

function CashSurrenderValueDetails({ accountValues, onViewDetails }) {
  const rows = [
    {
      key: 'wc',
      title: 'Withdrawal Charge',
      value: accountValues?.withdrawal_charge,
      toolTipText:
        'The amount deducted from the account value for withdrawals exceeding the free withdrawal amount.',
    },
    {
      key: 'b',
      title: 'Bonuses',
      value: accountValues?.bonus,
      toolTipText:
        'Immediate interest credit applied to your policy. It equals premiums multiplied by the premium bonus rate.',
    },
    {
      key: 'br',
      title: 'Bonus Recovery',
      value: accountValues?.bonus_recovery,
      toolTipText:
        'The amount of premium bonus deducted from the account value for withdrawals exceeding the free withdrawal amount. This only applies when Withdrawal Charges apply.',
    },
    {
      key: 'mva',
      title: 'MVA',
      value: accountValues?.mva,
      toolTipText:
        'An increase or decrease in withdrawal amounts based on the movement of interest rates.  This only applies when Withdrawal Charges apply.',
    },
    {
      key: 'mgv',
      title: 'MGV Adjustment',
      value: accountValues?.mgv_adjustment,
      toolTipText:
        'An increase to your cash surrender value if the Minimum Guaranteed Value exceeds the cash value.',
    },
  ]

  return (
    <>
      <Grid container spacing={2}>
        {rows.map(({ key, title, value, toolTipText }) => (
          <Grid item container xs={12} key={key}>
            <Grid item xs={5} className="data-label">
              {title}
            </Grid>
            <hr />
            <Grid item xs={1}>
              <Tooltip placement="top" arrow title={toolTipText}>
                <Box marginLeft={1}>
                  <InfoRounded fontSize="small" />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={6} className="data-value">
              {numeral(Number(parseFloat(value).toFixed(2))).format('$0,0.00')}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default CashSurrenderValueDetails
