import numeral from 'numeral'

// formats the value inside the input to our money format
export const moneyFormat = (value, event) => {
  // strip out commas and split at decimal
  value = value ? value : 0
  const s = value
    .toString()
    .replace(/[^0-9.]/g, '')
    .split('.')

  // limit whole number to 13 digits
  const t = s[0].substring(0, 13)

  // put commas in whole number
  const i = numeral(t).format('0,00')

  // limit decimal to two places (if it exists)
  const d = typeof s[1] === 'undefined' ? '' : `.${s[1].substring(0, 2)}`

  // put the whole number and decimal back together
  value = i + d

  // if a blur or init event, enforce two digits behind decimal
  value =
    ['blur', 'init'].indexOf(event) > -1
      ? numeral(Number(value.replace(/[^0-9.]/g, '')).toFixed(2)).format(
          '0,0.00'
        )
      : value

  return value
}

export const formatPercentage = (val, event) => {
  // strip out any non numeric
  let v = val.toString().replace(/\D/g, '')

  // remove more than three digits
  v = v.replace(/^(\d{3}).*/, '$1')

  // cant be more than 100
  v = Number(v, 10) > 100 ? v.replace(/^(\d{2}).*/, '$1') : v

  // if empty string default to zero
  v = v === '' ? 0 : v

  // removes leading zeros
  v = Number(v, 10).toString()

  v =
    ['blur', 'init'].indexOf(event) > -1
      ? numeral(Number(v.replace(/[^0-9.]/g, '')).toFixed(2)).format('0,0.00')
      : v

  return v
}

export const stripCommas = value => {
  let cleanValue = !value
    ? '0'
    : value
        .toString()
        .replace(/[^0-9.]/g, '')
        .replace(/(^\d+\.\d\d)(\d+)$/, '$1')
  cleanValue = Number(cleanValue).toFixed(2)
  return cleanValue
}
