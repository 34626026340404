import numeral from 'numeral'

export const formatMoney = (data: any): string =>
  numeral(data).format('$0,0.00')

export const formatMoneyRounded = (data: any): string =>
  numeral(data).format('$0,0')

export const formatPercentage = (data: any): string =>
  numeral(data).format('0.00%')
