import * as d3 from 'd3'
import React, { useEffect, useRef } from 'react'

//Added the 10 colors from schemeCategory10 + a few more since we now need more than 10 colors
const colorSchemes = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#fb9a99',
  '#fdbf6f',
  '#cab2d6',
]

export const chartColors = colorSchemes

const PieChart = props => {
  const ref = useRef(null)
  const createPie = d3
    .pie()
    .value(d => d.value)
    .sort(null)
  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius)
  const outerArc = d3
    .arc()
    .innerRadius(props.outerRadius * 0.8)
    .outerRadius(props.outerRadius * 1)

  const colors = d3.scaleOrdinal(colorSchemes)
  const format = d3.format(',.0%')

  useEffect(() => {
    const data = createPie(props.data)

    const group = d3.select(ref.current)
    const groupWithData = group.selectAll('g.arc').data(data)

    // Its opacity is set to 0: we don't see it by default.
    var tooltip = d3.select('#tooltip').style('opacity', 0)

    groupWithData.exit().remove()

    const groupWithUpdate = groupWithData
      .enter()
      .append('g')
      .attr('class', 'arc')

    const path = groupWithUpdate
      .append('path')
      .merge(groupWithData.select('path.arc'))

    path
      .attr('class', 'arc')
      .attr('d', createArc)
      .attr('fill', (d, i) => colors(i))
      .on('mouseover', function (d, i) {
        tooltip.style('opacity', 1).style('background-color', colors(i))
        d3.select(this)
          .style('cursor', 'pointer')
          .style('opacity', function (d) {
            return this._current === d ? 1 : 0.8
          })
      })
      .on('mousemove', function (d) {
        const div = d3.select('#piechart').node()
        tooltip
          .html(format(d.value))
          .style('left', d3.mouse(div)[0] + 'px')
          .style('top', d3.mouse(div)[1] + 'px')
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .style('cursor', 'none')
          .style('opacity', function (d) {
            return this._current === d ? 0.8 : 1
          })
        tooltip.style('opacity', 0)
      })

    // This threshold will be used when the pie slices are so small, it makes it difficult to read the text.
    const threshold = 0.03

    const text = group
      .selectAll('g.arc')
      .append('text')
      .merge(groupWithData.select('text'))

    text
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr(
        'transform',
        (d, i) =>
          `translate(${
            d.value <= threshold ? outerArc.centroid(d) : createArc.centroid(d)
          })`
      )
      .style('fill', 'white')
      .style('font-size', '1.2em')
      .text(d => format(d.value))
  }, [props.data, colors, createArc, createPie, format, outerArc])

  return (
    <div id="piechart">
      <div id="tooltip" className="piechart-tooltip" />
      <svg
        preserveAspectRatio="xMinYMin meet"
        viewBox={`0 0 ${props.width} ${props.height}`}
        width="100%"
        height="100%"
      >
        <g
          ref={ref}
          transform={`translate(${props.outerRadius} ${props.outerRadius})`}
        />
      </svg>
    </div>
  )
}

export default PieChart
