import { Box } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'
import React, { Component } from 'react'
import {
  Card,
  CardBody,
  Alert,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Button,
} from 'reactstrap'

import authService from '../../_services/authService'
import './AgentMyProfile.css'

class AgentMyProfile extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
      fields: {
        currentPin: null,
        oldPassword: null,
        newPassword: null,
        newPin: null,
        confirmPassword: null,
        confirmPin: null,
      },
      errors: {
        passwordMessage: false,
        pinMessage: false,
        messageColor: 'secondary',
        hasPinMessage: false,
      },
      hasPin: true,
    }
  }

  componentDidMount() {
    this.existingPin()
  }

  existingPin = () => {
    const userName = authService.getUserName()
    axios
      .get(`${window._env_.REACT_APP_API_URL}/agents/${userName}/profile`)
      .then(response => {
        this.setState({ hasPin: response.data.has_commission_pin })
      })
      .catch(reason => {
        console.log(reason.response)
        this.setState({
          errors: {
            hasPinMessage: reason.response.data,
          },
        })
      })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  submitNewPassword = e => {
    e.preventDefault()
    this.setState({
      errors: {},
    })

    let fields = this.state.fields
    if (!fields.oldPassword || !fields.newPassword || !fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'All fields are required.',
        },
      })
    } else if (fields.newPassword !== fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'New password and confirm password do not match',
        },
      })
    } else {
      axios
        .put(`${window._env_.REACT_APP_API_URL}/core/changepassword`, {
          new_password: this.state.fields.newPassword,
          old_password: this.state.fields.oldPassword,
          refresh: authService.getRawRefreshToken(),
        })
        .then(response => {                         
          if(response.status === 500) {            
            this.setState({
              errors: {
                passwordMessage: 'An unexpected error ocurred trying to reset the password',
              },
            })
          }
          else {            
            this.setState({
              errors: {
                passwordMessage: response.data,
              },
            })
          }          
        })
        .catch(reason => {                    
          if(reason.response.status === 500) {
            this.setState({
              errors: {
                passwordMessage: 'An unexpected error ocurred trying to change the password'
              },
            })            
          }
          else {            
            this.setState({
              errors: {
                passwordMessage: reason.response.data
              },
            })
          }          
        })
    }
  }

  submitNewPin = e => {
    e.preventDefault()
    this.setState({
      errors: {
        pinMessage: false,
      },
    })
    const { fields, hasPin } = this.state

    if (!fields.newPin || !fields.confirmPin) {
      this.setState({
        errors: {
          pinMessage: 'Please Enter a Pin.',
        },
      })
    } else if (fields.newPin !== fields.confirmPin) {
      this.setState({
        errors: {
          pinMessage: 'Pins do not match',
        },
      })
    } else {
      const userName = authService.getUserName()
      const config = {
        url: `${window._env_.REACT_APP_API_URL}/agents/${userName}/profile/commission_pin`,
        data: {
          commission_pin: fields.newPin,
        },
      }
      if (hasPin) {
        config.method = 'put'
        config.data.current_commission_pin = fields.currentPin
      } else {
        config.method = 'post'
      }

      axios(config)
        .then(() => {
          this.setState({
            errors: {
              pinMessage: 'Pin Updated Successfully.',
              messageColor: 'success',
            },
          })
        })
        .catch(reason => {
          this.setState({
            errors: {
              pinMessage: reason.response.data,
              messageColor: 'danger',
            },
          })
        })
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames('profile-tabs', {
                      active: this.state.activeTab === '1',
                    })}
                    onClick={() => {
                      this.toggle('1')
                    }}
                  >
                    Create/Change Pin
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames('profile-tabs', {
                      active: this.state.activeTab === '2',
                    })}
                    onClick={() => {
                      this.toggle('2')
                    }}
                  >
                    Change Password
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="6">
                      <br />
                      <Form onSubmit={this.submitNewPin} autoComplete="off">
                        {this.state.hasPin ? (
                          <FormGroup>
                            <Label htmlFor="pin">Current Pin</Label>
                            <Input
                              type="text"
                              name="pin"
                              id="pin"
                              onChange={this.handleChange.bind(
                                this,
                                'currentPin'
                              )}
                              placeholder="Enter current pin"
                            />
                          </FormGroup>
                        ) : (
                          ''
                        )}

                        <FormGroup>
                          <Label htmlFor="newpin">New Pin</Label>
                          <Input
                            type="text"
                            name="newpin"
                            id="newpin"
                            onChange={this.handleChange.bind(this, 'newPin')}
                            placeholder="Enter new pin"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="confirmPin">Confirm Pin</Label>
                          <Input
                            type="text"
                            name="confirmPin"
                            id="confirmPin"
                            onChange={this.handleChange.bind(
                              this,
                              'confirmPin'
                            )}
                            placeholder="Verify new pin number"
                          />
                        </FormGroup>
                        <Alert
                          color={this.state.errors.messageColor}
                          isOpen={this.state.errors.pinMessage !== false}
                        >
                          {this.state.errors.pinMessage}
                        </Alert>
                        <Button>Submit</Button>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="6">
                      <br />
                      <Form
                        onSubmit={this.submitNewPassword}
                        autoComplete="off"
                      >
                        <FormGroup>
                          <Label htmlFor="password">Old Password</Label>
                          <Input
                            type="password"
                            name="oldPassword"
                            id="password"
                            onChange={this.handleChange.bind(
                              this,
                              'oldPassword'
                            )}
                            placeholder="Enter old password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="password">New Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handleChange.bind(
                              this,
                              'newPassword'
                            )}
                            placeholder="Enter new password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="verifyPassword">
                            Confirm Password
                          </Label>
                          <Input
                            type="password"
                            name="verifyPassword"
                            id="verifyPassword"
                            onChange={this.handleChange.bind(
                              this,
                              'confirmPassword'
                            )}
                            placeholder="Enter confirm password"
                          />
                        </FormGroup>
                        <Alert
                          color="info"
                          isOpen={Boolean(this.state.errors.passwordMessage)}
                        >
                          <ul className="mb-0">
                            {Array.isArray(
                              this.state.errors.passwordMessage
                            ) ? (
                              this.state.errors.passwordMessage.map(message => (
                                <li>{message}</li>
                              ))
                            ) : (
                              <li>{this.state.errors.passwordMessage}</li>
                            )}
                          </ul>
                        </Alert>
                        <Box paddingTop={2}>
                          <Button>Submit</Button>
                        </Box>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default AgentMyProfile
