import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Grid } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardTitle, CardText, CardHeader, CardBody } from 'reactstrap'

import { communicationsService } from '../../_services'
import announcementService from '../../_services/announcementService'
import authService from '../../_services/authService'
import { store } from '../../_store/store'
import { useThemeContext } from '../../themes'
import FadeModal from '../layout/FadeModal'
import { legacyAgentMessage } from './'
import './AgentHome.css'
import AgentHomeNewsList from './AgentHomeNewsList'

const NEW_RATE_CHANGE_VALUE = 'newRateAnnouncement'

const AgentHome = props => {
  const isLegacyAgent = !!authService.getIsLegacyAgent()
  const primaryAgentNumber = authService.getPrimaryAgentNumber()
  const { theme } = useThemeContext()

  const [data, setData] = useState({
    activeIndex: 0,
    newsItems: '',
    announcement: {
      open: true,
    },
    legacyAgent: {
      open: isLegacyAgent,
    },
  })

  const [dashboard, setDashboard] = useState({
    added: 0,
    approved: 0,
    attention: 0,
  })
  const [newRateChangeAnnouncement, setNewRateChangeAnnouncement] =
    useState(false)

  const { announcement } = data

  useEffect(() => {
    const getNews = () => {
      let username = authService.getUserName()

      communicationsService.agencynews.getAll().then(response => {
        const newsItems = []
        const results = response.data.results

        for (let result of results) {
          const newsItem = {}

          newsItem.id = result.id
          newsItem.actionDate = result.action_date
          newsItem.type = result.type
          newsItem.subject = result.subject
          newsItem.link =
            result.link ?? `/agent/marketing-campaign/${result.id}`
          newsItem.isInternal = !result.link
          newsItem.description = result.description
          newsItems.push(newsItem)
        }

        setData(d => ({ ...d, newsItems: newsItems }))
      })

      axios
        .get(`${window._env_.REACT_APP_API_URL}/intake/dashboard/${username}`)
        .then(response => {
          const results = response.data
          const prevDash = dashboard
          const result = { ...prevDash, ...results }
          setDashboard(result)
        })
        .catch(console.log)
      showNewRateChangeAnnouncement()
    }

    getNews()
    // NOTE: this 'useEffect' function is only called on mount.
    // if this changes in the future, remove the eslint disable below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const showAnnouncement = (key = 'announcement') => {
      if (allowAnnouncement(key) && !data[key].open) {
        setData(d => ({ ...d, [key]: { open: true } }))
      }
    }

    showAnnouncement()
  }, [announcement, data])

  const allowAnnouncement = (key = 'announcement') => {
    const viewed =
      !!store.getState().announcements[key] &&
      store.getState().announcements[key].viewed
    const acknowledged = announcementService.isAcknowledged(key)
    return !(viewed || acknowledged)
  }

  const showNewRateChangeAnnouncement = () => {
    const today = new Date()
    const hasSeenToday = new Date(sessionStorage.getItem(NEW_RATE_CHANGE_VALUE))

    // only show this once a day until we reach the end date
    if (hasSeenToday.getDate() !== today.getDate()) {
      sessionStorage.setItem(NEW_RATE_CHANGE_VALUE, today)
      const beginningDate = new Date('5/2/2022')
      const endDate = new Date('5/13/2022')
      if (today >= beginningDate && today <= endDate) {
        setNewRateChangeAnnouncement(true)
      }
    }
  }

  const closeAnnouncement = (confirmed = false, key = 'announcement') => {
    announcementService.viewed(true, key)

    const acknowledged = typeof confirmed === 'boolean' ? confirmed : false

    if (acknowledged) {
      announcementService.acknowledged(true, key)
    }

    setData({
      ...data,
      [key]: { ...data[key], ...{ open: false } },
    })
  }

  const closeNewRateChangeAnnouncement = () => {
    setNewRateChangeAnnouncement(false)
  }

  const getNewRateAnnoucementJsxMessage = () => {
    return (
      <>
        <Link
          to={{
            pathname:
              'https://media.silacins.com/annuity/Annuity%20Rate%20Sheet%20-%20Rev%2005.02.22.pdf',
          }}
          target="blank"
        >
          Click here
        </Link>{' '}
        to view the new rate sheet with details on premium bonus, benefit bonus
        and rate changes.
      </>
    )
  }

  return (
    <div>
      {allowAnnouncement() && (
        <FadeModal
          open={data.announcement.open}
          title="Current System Issues"
          message="We are currently experiencing technical difficulties causing some portal functionality to be unavailable at this time. We are working toward a solution and will update when available."
          handleClose={closeAnnouncement}
        />
      )}
      <FadeModal
        open={!!newRateChangeAnnouncement}
        title="Product improvements effective May 4th!"
        jsxMessage={getNewRateAnnoucementJsxMessage()}
        handleClose={closeNewRateChangeAnnouncement}
      />

      {allowAnnouncement('legacyAgent') && (
        <FadeModal
          open={data.legacyAgent.open}
          title="Legacy Agent Number"
          message={legacyAgentMessage(primaryAgentNumber)}
          handleClose={() => closeAnnouncement(false, 'legacyAgent')}
        />
      )}

      <Box>
        <Box>{theme.homeBanner}</Box>

        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader>Recently Issued</CardHeader>
              <CardBody className="textCenter">
                <CardTitle className="dashboardCardtitle">
                  {dashboard?.approved}
                </CardTitle>
                <CardText className="muted-text">Last 30 Days</CardText>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader>Recently Submitted</CardHeader>
              <CardBody className="textCenter">
                <CardTitle className="dashboardCardtitle">
                  {dashboard?.added}
                </CardTitle>
                <CardText className="muted-text">Last 30 Days</CardText>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader>Outstanding Requirements</CardHeader>
              <CardBody className="textCenter">
                <CardTitle className="dashboardCardtitle">
                  {dashboard?.attention}
                </CardTitle>
                <CardText className="muted-text">Last 30 Days</CardText>
              </CardBody>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={4} marginTop={4}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader>Recent Home Office News</CardHeader>
              <CardBody>
                <AgentHomeNewsList newsItems={data.newsItems} />
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader>Sales & Marketing Tools</CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'file'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <Button
                          onClick={() =>
                            window.open(
                              'https://media.SILACins.com/annuity/Rate Sheet.pdf',
                              '_blank'
                            )
                          }
                        >
                          Annuity Rate Sheet
                        </Button>
                      </CardBody>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'file'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <Button
                          sx={{ marginRight: 2 }}
                          onClick={() =>
                            window.open(
                              'https://media.SILACins.com/annuity/State Availability Grid.pdf',
                              '_blank'
                            )
                          }
                        >
                          Product Availability Grid
                        </Button>
                      </CardBody>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'chart-line'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <Link
                          className="mr-1"
                          to={`/agent/sales-tools/illustration`}
                        >
                          <Button>Run an Illustration</Button>
                        </Link>
                      </CardBody>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'calculator'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <Link
                          className="mr-1"
                          to={`/agent/sales-tools/secure-savings-calculator`}
                        >
                          <Button>Secure Savings Calculator</Button>
                        </Link>
                      </CardBody>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'calculator'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <Link
                          className="mr-1"
                          to={`/agent/sales-tools/renewals-calculator`}
                        >
                          <Button>Renewals Calculator</Button>
                        </Link>
                      </CardBody>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <CardBody className="textCenter">
                        <FontAwesomeIcon
                          icon={'external-link-alt'}
                          color="#D3D3D3"
                          size="6x"
                        />
                        <br />
                        <br />
                        <a
                          className="mr-1"
                          href="https://www.silacins.com/silac"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button>SILAC Landing Page</Button>
                        </a>
                      </CardBody>
                    </Card>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default AgentHome
