import { request } from '../_contexts'
import { TAction } from '../_contexts/types'
import { EAction } from '../_enums'
import {
  IPolicyEventValue,
  IProductConfigChangePolicyEvents,
  IWithdrawalAdjustmentPolicyEvents,
} from '../components/Events/types'

export interface IGetProductConfigChangePolicyEvents {
  payload: {
    data?: IProductConfigChangePolicyEvents[]
  }
}

export interface IGetWithdrawalAdjustmentPolicyEvents {
  payload: {
    data?: IWithdrawalAdjustmentPolicyEvents[]
  }
}

export interface IGetPolicyEventsAction {
  payload: {
    data?: IPolicyEventValue
  }
}
const getProductConfigChangePolicyEvents = (
  policy_number: string
): TAction<IGetProductConfigChangePolicyEvents> => {
  return async () => {
    const { data } = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policy_number}/policy-events/product-config-change/`,
    })

    return {
      type: EAction.GetProductConfigChangePolicyEvents,
      payload: {
        data,
      },
    }
  }
}

const getWithdrawalAdjustmentPolicyEvents = (
  policy_number: string
): TAction<IGetWithdrawalAdjustmentPolicyEvents> => {
  return async () => {
    const { data } = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policy_number}/policy-events/withdrawal-config-change/`,
    })

    return {
      type: EAction.GetWithdrawalAdjustmentPolicyEvents,
      payload: {
        data,
      },
    }
  }
}

export const getPolicyEvents = (
  policy_number: string
): TAction<IGetPolicyEventsAction> => {
  return async () => {
    const productConfigChangeEventResults =
      await getProductConfigChangePolicyEvents(policy_number)()
    const withdrawalAdjustmentEventResults =
      await getWithdrawalAdjustmentPolicyEvents(policy_number)()

    const combinedEvents: IPolicyEventValue = {
      productConfigChangePolicyEvents:
        productConfigChangeEventResults.payload.data ?? [],
      withdrawalAdjustmentPolicyEvents:
        withdrawalAdjustmentEventResults.payload.data ?? [],
    }

    return {
      type: EAction.GetPolicyEvents,
      payload: {
        data: combinedEvents,
      },
    }
  }
}
