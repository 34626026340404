import { Component } from 'react'
import { Redirect } from 'react-router-dom'

import './SalesMaterials.css'
import { SalesMaterials } from './index.jsx'

const imoList = [
  { imoName: 'Ron Rawlings', imoHash: '7273A1D249A818ACEED46826CEB8EA7E' },
]

export class SalesMaterialsExternal extends Component {
  hideLayout = ({ params }) => {
    return !(params.showBranding && params.showBranding === '1')
  }

  isIMO = ({ params }) => {
    const hashExists = imoList.find(i => i.imoHash === params.imoHash)
    return hashExists ? true : false
  }

  render() {
    let { match } = this.props

    if (this.isIMO(match)) {
      return (
        <SalesMaterials hideLayout={this.hideLayout(match)} match={match} />
      )
    } else {
      return <Redirect to="/forbidden">Not Authorized</Redirect>
    }
  }
}

export default SalesMaterialsExternal
