import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

import { formatMoney } from '../../../../../_helpers/number'

type Props = {
  ownerName: string
  age: number
  product: string
  premium: number
  taxQualificationStatus: string
  state: string
}

const Summary = (props: Props) => {
  return (
    <Paper>
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h6" component="h2" fontWeight={600}>
              Summary
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Owner Name: </b>
              {props.ownerName}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Product/Term: </b>
              {props.product}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Tax Qualification Status: </b>
              {props.taxQualificationStatus}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Age: </b>
              {props.age}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Issued Premium: </b>
              {formatMoney(props.premium)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              <b>Issued State: </b>
              {props.state}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Summary
