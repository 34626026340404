import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const {
  REACT_APP_SENTRY_ENABLED: enabledStr,
  REACT_APP_SENTRY_DEBUG: debugStr,
  REACT_APP_SENTRY_SAMPLE_RATE: sampleRateStr,
  REACT_APP_SENTRY_DSN: dsn,
  REACT_APP_SENTRY_ENV: environment,
  REACT_APP_VERSION: version,
} = window._env_

const enabled =
  'true'.localeCompare(enabledStr, undefined, { sensitivity: 'accent' }) === 0
if (enabled && dsn) {
  const debug =
    'true'.localeCompare(debugStr, undefined, { sensitivity: 'accent' }) === 0

  Sentry.init({
    dsn,
    debug,
    release: `portal@${version || 'unknown'}`,
    environment: environment || 'local',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(sampleRateStr || '0.01'),
  })
}
