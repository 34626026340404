import { Grid, Paper } from '@mui/material'

import { useStore } from '../../../../_common/_hooks/use-store'
import { formatName } from '../../../../_common/utils'

export function Agent() {
  const {
    store: {
      selectedPolicy: { primary_agent },
    },
  } = useStore()

  const { email, telephone, address1, city, state, zip } = primary_agent

  return (
    <Grid sm={12} container justifyContent="space-around">
      <Grid sm={12} md={6} item>
        <Paper elevation={3}>
          <Grid p={'30px'} container spacing={3}>
            <Grid item xs={12} md={5} className="data-label">
              Agent Name
            </Grid>
            <Grid item xs={12} md={7}>
              {formatName(primary_agent)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Email
            </Grid>
            <Grid item xs={12} md={7}>
              {email || '-'}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Phone Number
            </Grid>
            <Grid item xs={12} md={7}>
              {telephone || '-'}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Address
            </Grid>
            <Grid item xs={12} md={7}>
              <p>
                {address1 || '-'}
                <br />
                {`${city} ${state} ${zip}`}
              </p>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Agent
