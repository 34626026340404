const initialState = {}

export function agentInfo(state = initialState, action) {
  switch (action.type) {
    case 'SET_AGENT_INFO':
      return {
        ...state,
        agentInfo: action.payload,
      }
    default:
      return state
  }
}
