import React, { useContext } from 'react'

import { EAction, reducer } from './reducers'
import {
  InitialIllustrationStore,
  NextButtonState,
} from './store'
import { IllustrationStore } from '../../../types/salesTools'

export interface IIllustrationContext {
  store: IllustrationStore
  dispatch: any
  setNextButtonState: (
    key: 'Ready' | 'Loading' | 'Invalid' | 'Disabled'
  ) => void
  setStepsValidator: (
    key:
      | 'isAllocationsValid'
      | 'isIssueInformationValid'
      | 'isWithdrawalsValid',
    isValid: boolean
  ) => void
  stepsValidator: (
    step: 'Allocations' | 'Withdrawals' | 'Illustrations',
    action: (index: number) => void
  ) => false | void
}

export const IllustrationContext = React.createContext<IIllustrationContext>(
  {} as IIllustrationContext
)

export const IllustrationProvider = ({ children }) => {
  const [store, dispatch] = React.useReducer(reducer, InitialIllustrationStore)
  
  const setNextButtonState = (
    key: 'Ready' | 'Loading' | 'Invalid' | 'Disabled'
  ) => {
    dispatch({
      type: EAction.SetNextButtonState,
      payload: { ...NextButtonState[key] },
    })
  }

  const setStepsValidator = (
    key:
      | 'isAllocationsValid'
      | 'isIssueInformationValid'
      | 'isWithdrawalsValid',
    isValid: boolean
  ) => {
    dispatch({
      type: EAction.SetStepValidity,
      payload: { [key]: isValid },
    })
  }

  const stepsValidator = (
    step: 'Allocations' | 'Withdrawals' | 'Illustrations',
    action: (index: number) => void
  ) => {
    switch (step) {
      case 'Allocations':
        return !store.validSteps.isIssueInformationValid && action(1)
      case 'Withdrawals':
        return (
          !(
            store.validSteps.isIssueInformationValid &&
            store.validSteps.isAllocationsValid
          ) && action(2)
        )
      case 'Illustrations':
        return (
          !(
            store.validSteps.isIssueInformationValid &&
            store.validSteps.isWithdrawalsValid &&
            store.validSteps.isAllocationsValid
          ) && action(3)
        )
    }
  }

  return (
    <IllustrationContext.Provider
      value={{
        store,
        dispatch,
        setNextButtonState,
        setStepsValidator,
        stepsValidator,
      }}
    >
      {children}
    </IllustrationContext.Provider>
  )
}

export const useIllustrationContext = () => {
  const values = useContext(IllustrationContext)
  if (!values) throw Error('Should use within a Illustration provider')

  return {
    ...values,
  }
}
