import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'

import { EWithdrawalCodeType } from '../../../../../_enums'

interface WithdrawalTypeSelectProps {
  name: string
  withdrawalType: EWithdrawalCodeType
  withdrawalTypes: Array<{ code: EWithdrawalCodeType; name: string }>
  handleChange: (event: SelectChangeEvent<EWithdrawalCodeType>) => void
}

const WithdrawalTypeSelect: React.FC<WithdrawalTypeSelectProps> = ({
  name,
  withdrawalType,
  withdrawalTypes,
  handleChange,
}) => (
  <FormControl fullWidth>
    <InputLabel id={name}>Type of Withdrawal</InputLabel>
    <Select
      label="Type of Withdrawal"
      labelId={name}
      name={name}
      value={withdrawalType}
      onChange={handleChange}
      MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
    >
      {withdrawalTypes.map(type => (
        <MenuItem key={type.code} value={type.code}>
          {type.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default React.memo(WithdrawalTypeSelect)
