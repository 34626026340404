export enum EProductFamilies {
  Denali = 'denali',
  SecureSavings = 'securesavings',
  Teton = 'teton',
  Vega = 'vega',
}

export enum EProductId {
  SecureSavings = 1,
  SecureSavingsElite = 2,
  Teton = 3,
  TetonBonus = 4,
  Denali = 5,
  DenaliBonus = 6,
  Vega = 7,
  VegaBonus = 8,
}
export class EProduct {
  static readonly denali = new EProduct(
    EProductId.Denali,
    'DENALI              ',
    'Denali',
    EProductFamilies.Denali
  )
  static readonly denali_bonus = new EProduct(
    EProductId.DenaliBonus,
    'DENALIB             ',
    'Denali Bonus',
    EProductFamilies.Denali
  )
  static readonly secure_savings = new EProduct(
    EProductId.SecureSavings,
    'MYGA_SS             ',
    'Secure Savings',
    EProductFamilies.SecureSavings
  )
  static readonly secure_savings_elite = new EProduct(
    EProductId.SecureSavingsElite,
    'MYGA_SSE            ',
    'Secure Savings Elite',
    EProductFamilies.SecureSavings
  )
  static readonly teton = new EProduct(
    EProductId.Teton,
    'TETON               ',
    'Teton',
    EProductFamilies.Teton
  )
  static readonly teton_bonus = new EProduct(
    EProductId.TetonBonus,
    'TETONB              ',
    'Teton Bonus',
    EProductFamilies.Teton
  )
  static readonly vega = new EProduct(
    EProductId.Vega,
    'VEGA                ',
    'Vega',
    EProductFamilies.Vega
  )
  static readonly vega_bonus = new EProduct(
    EProductId.VegaBonus,
    'VEGAB               ',
    'Vega Bonus',
    EProductFamilies.Vega
  )

  private constructor(
    public readonly id: number,
    public readonly code: string,
    public readonly name: string,
    public readonly product_family: string
  ) {}

  toString() {
    return this.name
  }
}

export function getProductById(id: EProductId) {
  switch (id) {
    case EProductId.Denali:
      return EProduct.denali
    case EProductId.DenaliBonus:
      return EProduct.denali_bonus
    case EProductId.SecureSavings:
      return EProduct.secure_savings
    case EProductId.SecureSavingsElite:
      return EProduct.secure_savings_elite
    case EProductId.Teton:
      return EProduct.teton
    case EProductId.TetonBonus:
      return EProduct.teton_bonus
    case EProductId.Vega:
      return EProduct.vega
    case EProductId.VegaBonus:
      return EProduct.vega_bonus
    default:
      throw Error(`Unknown product with id: ${id}`)
  }
}

export function getProductFamilyByProductId(id: EProductId) {
  const product = getProductById(id)
  return product.product_family
}
