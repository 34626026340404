import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import numeral from 'numeral'
import { ReactNode } from 'react'

import { formatDate } from '../../_helpers/date'
import { formatMoney, formatMoneyRounded } from '../../_helpers/number'

export enum EDataType {
  text,
  number,
  percentage,
  formatPercentage,
  date,
  money,
  moneyRounded,
}

interface IColumnOptions {
  dataType?: EDataType
  render?: (val: string, row: any) => ReactNode
  align?: string
}

interface IColumn {
  displayName: string
  field: string
  options?: IColumnOptions
}

type Props = {
  columns: IColumn[]
  rows: any[]
  dataKey?: string
  footer?: ReactNode
  title?: string
}

const SKELETON_ROW_COUNT = 5

function BasicTable({ title, columns, rows, dataKey, footer = null }: Props) {
  const getValue = (row: any, col: IColumn) => {
    if (col.options?.render) return col.options.render(row[col.field], row)
    if (row[col.field] === 'n/a') return 'N/A'
    if (col.options?.dataType === EDataType.date)
      return !row[col.field] || row[col.field] === '-'
        ? '-'
        : formatDate(row[col.field])
    if (col.options?.dataType === EDataType.money)
      return formatMoney(row[col.field])
    if (col.options?.dataType === EDataType.moneyRounded)
      return formatMoneyRounded(row[col.field])
    if (col.options?.dataType === EDataType.formatPercentage)
      return numeral(row[col.field]).format('0.00%')

    const percentageText =
      col.options?.dataType === EDataType.percentage ? '%' : ''
    return `${row[col.field]}${percentageText}`
  }

  const getAlign = (column: IColumn) => {
    if (column.options?.align)
      return column.options.align as
        | 'left'
        | 'center'
        | 'right'
        | 'justify'
        | 'inherit'
        | undefined
    return column.options?.dataType === EDataType.number ||
      column.options?.dataType === EDataType.percentage ||
      column.options?.dataType === EDataType.formatPercentage ||
      column.options?.dataType === EDataType.money ||
      column.options?.dataType === EDataType.moneyRounded
      ? 'right'
      : 'left'
  }

  return (
    <>
      <TableContainer component={Paper}>
        {title && (
          <Toolbar sx={{ marginBottom: 2 }}>
            <Typography variant="h6" component="div" className="table-title">
              {title}
            </Typography>
          </Toolbar>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="basic table">
          <TableHead>
            <TableRow>
              {columns.map(col => (
                <TableCell align={getAlign(col)} key={col.field}>
                  <b>{col.displayName}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows?.length > 0 ? (
            <TableBody key="realbody">
              {rows.map((row, i) => (
                <TableRow
                  data-testid={
                    dataKey ? (rows[i][dataKey] ? rows[i][dataKey] : i) : i
                  }
                  key={dataKey ? (rows[i][dataKey] ? rows[i][dataKey] : i) : i}
                >
                  {columns.map(col => (
                    <TableCell
                      align={getAlign(col)}
                      key={`${i}-${col.field}`}
                      data-testid={`${i}-${col.field}`}
                      component="th"
                      scope="row"
                    >
                      {getValue(row, col)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {footer}
            </TableBody>
          ) : (
            <TableBody key="fakebody">
              {[...Array(SKELETON_ROW_COUNT)].map((_, i) => (
                <TableRow data-testid={i} key={i}>
                  {columns.map(col => (
                    <TableCell
                      align={getAlign(col)}
                      key={`${i}-${col.field}`}
                      component="th"
                      scope="row"
                    >
                      <Skeleton height="3em" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

export default BasicTable
