import { TextField, TextFieldProps } from '@mui/material'
import { useCallback, useState } from 'react'

const AutoFillAwareTextField = ({
  onChange,
  inputProps,
  InputLabelProps,
  ...rest
}: TextFieldProps) => {
  const [fieldHasValue, setFieldHasValue] = useState(false)
  const makeAnimationStartHandler = stateSetter => e => {    
    const hasValue = e.target.value !== ''
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(hasValue)
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(hasValue)
    }
  }

  const _onChange = useCallback(
    e => {
      onChange?.(e)
      setFieldHasValue(e.target.value !== '')
    },
    [onChange]
  )

  return (
    <TextField
      inputProps={{
        onAnimationStart: makeAnimationStartHandler(setFieldHasValue),
        ...inputProps,
      }}
      InputLabelProps={{
        shrink: fieldHasValue,
        ...InputLabelProps,
      }}
      onChange={_onChange}
      {...rest}
    />
  )
}
export default AutoFillAwareTextField
