import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

import authService from '../../_services/authService'
import '../../pages/Login/Login.css'
import silacLogo from './SILAC-vector.png'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      //loggedIn: authService.isAuthenticated(),
      loggedIn: false,
      email: '',
      showAlert: false,
      alertMsg: '',
      submitted: false,
      loggingIn: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onHandleLastNameChange = this.onHandleLastNameChange.bind(this)
  }

  onHandleLastNameChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value, showAlert: false, submitted: false })
  }

  handleSubmit() {
    const { email } = this.state
    this.setState({ submitted: true })

    if (email) {
      this.props.dispatch(authService.getResetEmail(this.state))
    }
  }

  render = () => {
    const { email, submitted } = this.state

    const { showAlert, alertMsg, loggedIn } = this.props

    // Redirect to default page if logged in
    if (loggedIn) {
      return <Redirect to={authService.redirectByUserGroups()} />
    }

    return (
      <Container fluid className="full-height bg-light">
        <Row className="h-100 justify-content-center full-height align-items-center bg-light">
          <Col xs="10" lg="3" className="p-0">
            <h1 className="text-center login-title">Request Password Reset</h1>

            <Card>
              <div className="account-wall">
                <img
                  className="profile-img img-fluid"
                  src={silacLogo}
                  alt="SILAC Logo"
                  width="400px"
                />
                <div className="form-signin">
                  <ShowRequired
                    message={alertMsg}
                    display={showAlert}
                    className="alert alert-info"
                  />
                  <h6>
                    Please enter your email address and click the 'Send Reset
                    Email' Button.
                  </h6>
                  <h6 className="mb-3">
                    Check your inbox for instructions to reset your password.
                  </h6>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      onChange={this.onHandleLastNameChange}
                    />
                  </div>

                  <ShowRequired
                    message="Email is required"
                    display={submitted && !email}
                    className="help-block alert alert-danger"
                  />

                  <br />
                  <button
                    className="btn btn-lg btn-primary btn-block"
                    onClick={() => {
                      return this.handleSubmit()
                    }}
                  >
                    Send Reset Email
                  </button>
                  <br />
                </div>
              </div>
            </Card>
            <Link to="/login" className="text-center new-account">
              Cancel
            </Link>
            <span className="clearfix" />
          </Col>
        </Row>
      </Container>
    )
  }
}

// Redux state mapping
const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    showAlert: state.authentication.showAlert,
    alertMsg: state.authentication.alertMsg,
    submitted: state.authentication.submitted,
    loggingIn: state.authentication.loggingIn,
  }
}

const connectedForgotPassword = connect(mapStateToProps)(ForgotPassword)
export { connectedForgotPassword as ForgotPassword }

// displayed divs for required fields or alerts (probably a bootstrap function to replace this but i didnt have time to look)
class ShowRequired extends Component {
  render() {
    if (this.props.display === true) {
      return <div className={this.props.className}>{this.props.message}</div>
    } else {
      return null
    }
  }
}
