import * as React from 'react'

import * as actions from '../_actions/AgentContracting.actions'
import reducers from '../_reducers/AgentContracting.reducers'
import {
  IAgentContractingStore,
  IAgentContractingContext,
} from '../components/agent-components/agent-contracting/types'
import { IContractingRecruit } from '../components/agent-components/agent-contracting/types'
import { asyncDispatch } from './index'
import { IStore, getStore } from './store'
import { TStore } from './types'

export const AgentContractingContext = React.createContext(
  {} as IAgentContractingContext
)

export const AgentContractingProvider = (props: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = React.useReducer<TStore>(
    reducers,
    getStore<IAgentContractingStore>() as IStore
  )

  const postContractingRecruit = (
    agentNumber: string,
    recruit: IContractingRecruit
  ) => {
    asyncDispatch(
      dispatch,
      actions.postContractingRecruit(agentNumber, recruit)
    )
  }

  const getDownlineInvites = (agentNumber: string) => {
    asyncDispatch(dispatch, actions.getDownlineInvites(agentNumber))
  }

  return (
    <AgentContractingContext.Provider
      value={{
        state,
        postContractingRecruit,
        getDownlineInvites,
      }}
    >
      {props.children}
    </AgentContractingContext.Provider>
  )
}

export default AgentContractingProvider
