import * as d3 from 'd3'
import React, { Component } from 'react'

import AccountValueModal from './AccountValueModal'

class PremiumD3Chart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accountValues: this.props.accountValues,
    }
  }

  componentDidMount() {
    var data = [
      { name: 'Premiums', value: this.state.accountValues.premiums },
      { name: 'Bonus', value: this.state.accountValues.bonus },

      {
        name: 'Interest Received',
        value: this.state.accountValues.interest,
      },
      { name: 'Withdrawals', value: this.state.accountValues.debits * -1 },
    ]

    var text = parseFloat(this.state.accountValues.total)

    var width = 350
    var height = 350
    var thickness = 40

    var radius = Math.min(width, height) / 2
    var color = d3.scaleOrdinal(d3.schemeCategory10)

    var svg = d3
      .select('#canvas')
      .append('svg')
      .attr('class', 'pie premium-pie')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 350 350')

    var g = svg
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

    var arc = d3
      .arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius)

    var pie = d3
      .pie()
      .value(function (d) {
        return d.value
      })
      .sort(null)

    var formatDecimalComma = d3.format(',.2f'),
      formatMoney = function (d) {
        return '$' + formatDecimalComma(d)
      }

    var centerText = g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .style('font-size', '2em')
      .text(function () {
        return formatMoney(text)
      })

    // this is used to build out the chart in 'Account Value'
    // eslint-disable-next-line
    var path = g
      .selectAll('path')
      .data(pie(data))
      .enter()
      .append('g')
      .on('mouseover', function (d) {
        let g = d3
          .select(this)
          .style('cursor', 'pointer')
          .style('fill', 'black')
          .append('g')
          .attr('class', 'text-group')

        centerText.style('visibility', 'hidden')

        g.append('text')
          .attr('class', 'name-text')
          .text(`${d.data.name}`)
          .attr('text-anchor', 'middle')
          .style('font-size', '1.5em')
          .attr('dy', '-0.3em')

        g.append('text')
          .attr('class', 'value-text')
          .text(function () {
            return formatMoney(`${d.data.value}`)
          })
          .attr('text-anchor', 'middle')
          .style('font-size', '1.5em')
          .attr('dy', '0.8em')
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .style('cursor', 'none')
          .style('fill', color(this._current))
          .select('.text-group')
          .remove()

        centerText.style('visibility', 'visible')
      })
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      .on('mouseover', function (d) {
        d3.select(this)
          .style('cursor', 'pointer')
          .style('opacity', function (d) {
            // eslint-disable-next-line
            return this._current == d ? 1 : 0.8
          })
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .style('cursor', 'none')
          .style('opacity', function (d) {
            // eslint-disable-next-line
            return this._current == d ? 0.8 : 1
          })
      })
      .each(function (d, i) {
        this._current = i
      })
  }

  render() {
    return (
      <React.Fragment>
        <div id="canvas" style={{ textAlign: 'center' }} />
        <AccountValueModal />
      </React.Fragment>
    )
  }
}

export default PremiumD3Chart
