export enum EAllocationType {
  FI = 'Fixed Interest',
  MAS = 'S&P 500 Monthly Average with Spread',
  'NASDAQ-AP2PS' = 'Nasdaq Generations 5 Point-to-Point with Spread',
  AP2PC = 'S&P 500 Point-to-Point with Cap',
  'ATLAS-AP2PPR' = 'Barclays Atlas 5 Point-to-Point with Participation Rate',
  AP2PPR = 'S&P 500 Point-to-Point with Participation Rate',
  'ATLAS-AP2PS' = 'Barclays Atlas 5 Point-to-Point with Spread',
  MP2PC = 'S&P 500 Monthly Point-to-Point with Cap',
  'RVP-AP2PPR' = 'S&P 500 RavenPack AI Point-to-Point with Participation Rate',
  MAC = 'S&P 500 Monthly Average with Cap',
  'RVP-AP2PS' = 'S&P 500 RavenPack AI Point-to-Point with Spread',
  MAPR = 'S&P 500 Monthly Average with Participation',
  'NASDAQ-AP2PPR' = 'Nasdaq Generations 5 Point-to-Point with Participation Rate',
  'SPDS-AP2PPR' = 'S&P 500 Duo Swift Point-to-Point with Participation Rate',
}
