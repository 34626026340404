import { Button } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { Component } from 'react'
import MaskedInput from 'react-text-mask'
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
} from 'reactstrap'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import emailMask from 'text-mask-addons/dist/emailMask'

import PremiumTable from './PremiumTable'
import './RenewalsCalculator.css'

class RenewalsCalculator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      agentName: '',
      applicantName: '',
      applicantAge: 60,
      applicantIssueState: '',
      agentPhone: '',
      agentEmail: '',
      premium: 100000,
      product: 'ss',
      guaranteePeriod: 2,
      rmd: false,
      interest: false,
      av: false,
      modal: false,

      alertMessage: '',
      showAlert: false,
      disablePrint: false,
      states: [],
      products: [],
      renewalPeriods: [],
      riders: [],
    }

    // bind events
    this.handleApplicantNameChange = this.handleApplicantNameChange.bind(this)
    this.handleAgentNameChange = this.handleAgentNameChange.bind(this)
    this.handleAgeChange = this.handleAgeChange.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleAgentNameChange = this.handleAgentNameChange.bind(this)
    this.handleAgentPhoneChange = this.handleAgentPhoneChange.bind(this)
    this.handleAgentEmailChange = this.handleAgentEmailChange.bind(this)
    this.handlePremiumChange = this.handlePremiumChange.bind(this)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handlePeriodChange = this.handlePeriodChange.bind(this)
    this.handleRiderChange = this.handleRiderChange.bind(this)
    this.modalToggle = this.modalToggle.bind(this)
    this.printPDF = this.printPDF.bind(this)
  }

  componentDidMount() {
    this.queryAPI()
  }

  queryAPI() {
    let url = `${window._env_.REACT_APP_API_URL}/sales-tools/interest_calculator/`

    axios.get(url).then(response => {
      const results = response.data

      this.setState(prevState => ({
        ...prevState,
        states: results.states,
        products: results.products,
        renewalPeriods: results.renewal_periods,
        riders: results.riders,
      }))
    })
  }

  // event handlers
  handleApplicantNameChange(e) {
    this.setState({ ...this.state, applicantName: e.target.value })
  }

  handleAgentNameChange(e) {
    this.setState({ ...this.state, agentName: e.target.value })
  }

  handleAgeChange(e) {
    if (e.target.value.match(/[^0-9]/g)) {
      return false
    }

    const prevState = { ...this.state }
    let curVal = isNaN(parseInt(e.target.value)) ? '' : e.target.value

    // get the min and max for the product
    const alerts = this.checkAgeRangeForProduct(
      curVal,
      prevState.product,
      prevState.products
    )

    this.setState({
      prevState,
      applicantAge: curVal,
      showAlert: alerts.showAlert,
      disablePrint: alerts.showAlert,
      alertMessage: alerts.alertMessage,
    })
  }

  checkAgeRangeForProduct(age, prevProduct, products) {
    let result = { showAlert: false, alertMessage: '', disablePrint: false }

    // get the min and max for the product
    let curProd = products.filter((prod, i) => {
      return prod.value === prevProduct
    })[0]

    if (age > curProd.age.max) {
      result.showAlert = true
      result.disablePrint = true
      result.alertMessage =
        'Maximum age for ' + curProd.name + ' is ' + curProd.age.max
    } else if (age < curProd.age.min) {
      result.showAlert = true
      result.disablePrint = true
      result.alertMessage =
        'Minimum age for ' + curProd.name + ' is ' + curProd.age.min
    }
    return result
  }

  handleStateChange(e) {
    const prevState = { ...this.state }
    let newProduct = prevState.product
    let newRmd = prevState.rmd
    let newInterest = prevState.interest
    let newAv = prevState.av
    let newGuaranteePeriod = prevState.guaranteePeriod

    // get u.s. states properties
    let selectedStateProperties = prevState.states.filter(state => {
      return state.name === e.target.value
    })
    selectedStateProperties = selectedStateProperties[0]

    // reset product and riders values if not an sse state
    const { sse } = selectedStateProperties

    if (prevState.product === 'sse' && !sse) {
      // will have to fix this conditional, selectedStateProperties.sse could be true
      newProduct = 'ss'
      newRmd = false
      newInterest = false
      newAv = false
    }

    // reset value of Guaranteed Period if period not available in state
    let productInState = false
    if (
      newProduct in selectedStateProperties &&
      typeof selectedStateProperties[newProduct] === 'object' &&
      'renewal_periods' in selectedStateProperties[newProduct]
    ) {
      productInState =
        selectedStateProperties[newProduct]['renewal_periods'].indexOf(
          prevState.guaranteePeriod
        ) > -1
      newGuaranteePeriod = productInState
        ? newGuaranteePeriod
        : selectedStateProperties[newProduct].renewal_periods[0]
    }

    // get the min and max for the product
    const alerts = this.checkAgeRangeForProduct(
      prevState.applicantAge,
      newProduct,
      prevState.products
    )

    this.setState({
      prevState,
      applicantIssueState: e.target.value,
      product: newProduct,
      showAlert: alerts.showAlert,
      disablePrint: alerts.disablePrint,
      alertMessage: alerts.alertMessage,
      rmd: newRmd,
      interest: newInterest,
      av: newAv,
      guaranteePeriod: newGuaranteePeriod,
    })
  }

  handleAgentPhoneChange(e) {
    this.setState({ ...this.state, agentPhone: e.target.value })
  }

  handleAgentEmailChange(e) {
    this.setState({ ...this.state, agentEmail: e.target.value })
  }

  handlePremiumChange(e) {
    const prevState = { ...this.state }
    let curVal = e.target.value

    curVal = curVal.replace(/[$,]/g, '')

    if (curVal.match(/[^0-9.]/g)) {
      return false
    }

    curVal = parseFloat(curVal)

    const alerts = this.checkAgeRangeForProduct(
      prevState.applicantAge,
      prevState.product,
      prevState.products
    )

    if (curVal > 500000) {
      alerts.showAlert = true
      alerts.disablePrint = false
      alerts.alertMessage =
        'Premiums greater than $500,000.00 require home office approval.'
    } else if (curVal < 10000) {
      alerts.showAlert = true
      alerts.disablePrint = true
      alerts.alertMessage = 'The minimum premium is $10,000.00'
    }

    this.setState({
      prevState,
      premium: curVal,
      showAlert: alerts.showAlert,
      disablePrint: alerts.disablePrint,
      alertMessage: alerts.alertMessage,
    })
  }

  handleProductChange(e) {
    const prevState = { ...this.state }
    let newRmd = prevState.rmd
    let newInterest = prevState.interest
    let newAv = prevState.av
    let newPeriod = prevState.guaranteePeriod

    // reset product and riders values if not an sse state
    if (e.target.value === 'ss') {
      newRmd = false
      newInterest = false
      newAv = false
    }

    // check to see if guarantee period exists for current product ( period in product )
    let selectedStateProperties = prevState.states.filter(state => {
      return state.name === prevState.applicantIssueState
    })
    selectedStateProperties = selectedStateProperties[0]

    if (
      e.target.value in selectedStateProperties &&
      typeof selectedStateProperties[e.target.value] === 'object' &&
      'renewal_periods' in selectedStateProperties[e.target.value]
    ) {
      if (
        selectedStateProperties[e.target.value]['renewal_periods'].indexOf(
          prevState.guaranteePeriod
        ) === -1
      ) {
        // get the lowest value available in array
        const renewalPeriods =
          selectedStateProperties[e.target.value]['renewal_periods']
        newPeriod = Math.min(...renewalPeriods)
      }
    }

    // get the min and max for the product
    const alerts = this.checkAgeRangeForProduct(
      prevState.applicantAge,
      e.target.value,
      prevState.products
    )

    this.setState({
      ...prevState,
      product: e.target.value,
      guaranteePeriod: newPeriod,
      showAlert: alerts.showAlert,
      disablePrint: alerts.disablePrint,
      alertMessage: alerts.alertMessage,
      rmd: newRmd,
      interest: newInterest,
      av: newAv,
    })
  }

  handlePeriodChange(e) {
    const prevState = { ...this.state }
    let newRmd = prevState.rmd
    let newInterest = prevState.interest
    let newAv = prevState.av
    let currVal = e.target.value

    if (currVal === '5') {
      newRmd = false
      newInterest = false
      newAv = false
    }

    this.setState({
      prevState,
      guaranteePeriod: currVal,
      rmd: newRmd,
      interest: newInterest,
      av: newAv,
    })
  }

  handleRiderChange(e) {
    const { id, checked } = e.target
    this.setState({ ...this.state, [id]: checked })
  }

  modalToggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  printPDF() {
    let url = `${window._env_.REACT_APP_API_URL}/illustration/annuity/securesavings?print`
    this.setState({ loading: true })

    const premiumOutputData = buildPremiumOutputData({ ...this.state })
    const product = this.state.products.find(
      k => k.value === this.state.product
    )
    const plan = this.state.renewalPeriods.find(
      k => k.value === this.state.guaranteePeriod.toString()
    )

    let data = {
      illustrationDate: moment().local().format('L'),
      agent: {
        name: this.state.agentName,
        telephone: this.state.agentPhone,
        email: this.state.agentEmail,
      },
      applicant: {
        name: this.state.applicantName,
        age: this.state.applicantAge,
        state: this.state.applicantIssueState,
      },
      product: product.name,
      premium: this.state.premium,
      plan: plan.name,
      riders: [],
      table: premiumOutputData,
    }

    if (this.state.rmd) {
      data.riders.push('RMD')
    }
    if (this.state.interest) {
      data.riders.push('Interest')
    }
    if (this.state.av) {
      data.riders.push('5% of AV')
    }

    axios
      .post(url, data, { responseType: 'blob' })
      .then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], { type: 'application/pdf' }),
            'filename.pdf'
          )
          return
        }

        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(error => {
        console.log('ERROR: ', error)
      })
  }

  render() {
    console.log('=========>', this.state)

    const {
      applicantName,
      applicantAge,
      applicantIssueState,
      agentName,
      agentPhone,
      agentEmail,
      premium,
      product,
      guaranteePeriod,
      rmd,
      interest,
      av,
      states,
      products,
      renewalPeriods,
      showAlert,
      disablePrint,
      alertMessage,
    } = this.state

    const premiumOutputData = buildPremiumOutputData({ ...this.state })

    return (
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <span>Premium Renewal Calculator</span>
              <span>
                <Button
                  onClick={this.printPDF}
                  disabled={
                    this.state.applicantIssueState === '' || disablePrint
                  }
                >
                  Print Preview
                </Button>
              </span>
            </CardHeader>
            <CardBody>
              <Row style={{ marginBottom: '10px' }}>
                <Col md="4">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border">
                      Applicant Information
                    </legend>
                    <br />
                    <Form>
                      <FormGroup>
                        <Label htmlFor="applicantName">Name</Label>
                        <Input
                          type="text"
                          name="applicantName"
                          id="applicantName"
                          value={applicantName}
                          onChange={this.handleApplicantNameChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="applicantAge">Issue Age</Label>
                        <Input
                          type="text"
                          name="applicantAge"
                          id="applicantAge"
                          value={applicantAge}
                          onChange={this.handleAgeChange}
                        />
                      </FormGroup>
                      <ApplicantIssueStateSelect
                        states={states}
                        selected={applicantIssueState}
                        handler={e => {
                          return this.handleStateChange(e)
                        }}
                      />
                    </Form>
                  </fieldset>
                </Col>
                <Col md="4">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border">
                      Agent Information
                    </legend>
                    <br />
                    <Form>
                      <FormGroup>
                        <Label htmlFor="agentName">Agent Name</Label>
                        <Input
                          type="text"
                          name="agentName"
                          id="agentName"
                          value={agentName}
                          onChange={this.handleAgentNameChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="agentPhone">Agent Phone</Label>
                        <MaskedInput
                          type="text"
                          name="agentPhone"
                          id="agentPhone"
                          value={agentPhone}
                          className="form-control"
                          mask={[
                            '(',
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide="true"
                          onChange={this.handleAgentPhoneChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="agentEmail">Agent Email</Label>
                        <MaskedInput
                          type="text"
                          name="agentEmail"
                          id="agentEmail"
                          value={agentEmail}
                          className="form-control"
                          mask={emailMask}
                          guide="true"
                          onChange={this.handleAgentEmailChange}
                        />
                      </FormGroup>
                    </Form>
                  </fieldset>
                </Col>
                <Col md="4">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border">
                      Secure Savings Plan Options
                    </legend>
                    <br />
                    <Form>
                      <FormGroup>
                        <Label htmlFor="premium">Premium</Label>
                        <MaskedInput
                          type="text"
                          name="premium"
                          id="premium"
                          value={parseFloat(premium).toFixed(2)}
                          mask={numberMask}
                          maxLength={15}
                          className="form-control"
                          onChange={this.handlePremiumChange}
                        />
                      </FormGroup>
                      <ProductSelect
                        products={products}
                        selected={products}
                        states={states}
                        selectedState={applicantIssueState}
                        handler={e => {
                          return this.handleProductChange(e)
                        }}
                      />
                      <GuaranteePeriodSelect
                        renewalPeriods={renewalPeriods}
                        selected={guaranteePeriod}
                        states={states}
                        selectedState={applicantIssueState}
                        product={product}
                        handler={e => {
                          return this.handlePeriodChange(e)
                        }}
                      />
                      <RidersForm
                        product={product}
                        guaranteePeriod={guaranteePeriod}
                        riders={[{ rmd }, { interest }, { av }]}
                        handler={e => {
                          return this.handleRiderChange(e)
                        }}
                      />
                    </Form>
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <DisplayGreenAlert
                    states={states}
                    selectedState={applicantIssueState}
                    product={product}
                  />
                  <DisplayRedAlert display={showAlert} message={alertMessage} />
                </Col>
              </Row>
              <PremiumTableWrapper
                display={disablePrint}
                data={premiumOutputData}
              />
              <Row>
                <Col md="12">
                  <ol className="text-muted">
                    <li>
                      During the 30 days after the end of the guarantee period,
                      the contract may be surrendered with no market value
                      adjustment or surrender charges. However, surrender of the
                      contract at any time before the 30 day window will result
                      in a market value adjustment and surrender charge.
                    </li>
                    <li>
                      During the initial guarantee period, the interest rate is
                      guaranteed. Thereafter, the interest rate can drop to the
                      minimum guaranteed interest rate.
                    </li>
                    <li>
                      This calculator does not reflect any withdrawals or
                      applicable state premium taxes.
                    </li>
                    <li>
                      This document does not provide tax advice. Please consult
                      your tax advisor.
                    </li>
                    <li>
                      Taxable amounts withdrawn prior to age 59 1/2 may be
                      subject to a 10% penalty in addition to ordinary income
                      tax.
                    </li>
                    <li>
                      Interest rates may change at any point. Please contact
                      SILAC for current rates.
                    </li>
                    <li>
                      Unless an optional liquidity rider is elected, no free
                      withdrawal provisions are included with the Secure Savings
                      Elite.
                    </li>
                    <li>
                      Secure Savings Elite death benefit is equal to Cash
                      Surrender Value unless Spousal Continuation is elected.
                    </li>
                  </ol>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const ApplicantIssueStateSelect = props => {
  var { states, handler } = props

  const options = states.map(state => {
    return (
      <option value={state.name} key={state.name}>
        {state.name}
      </option>
    )
  })

  return (
    <FormGroup>
      <Label htmlFor="applicantIssueState">Issue State</Label>
      <Input
        type="select"
        name="applicantIssueState"
        id="applicantIssueState"
        onChange={handler}
      >
        {options}
      </Input>
    </FormGroup>
  )
}

const ProductSelect = props => {
  var { products, handler, states, selectedState } = props
  let selectedStateProperties = states.filter(state => {
    return state.name === selectedState
  })
  selectedStateProperties = selectedStateProperties[0]
  const options = products.map(product => {
    return product.value in selectedStateProperties &&
      selectedStateProperties[product.value] &&
      product.renewal_rates &&
      Object.keys(product.renewal_rates).length > 0 ? (
      <option value={product.value} key={product.value}>
        {product.name}
      </option>
    ) : (
      ''
    )
  })

  return (
    <FormGroup>
      <Label htmlFor="product">Product</Label>
      <Input type="select" name="aproduct" id="product" onChange={handler}>
        {options}
      </Input>
    </FormGroup>
  )
}

const GuaranteePeriodSelect = props => {
  var { renewalPeriods, handler, states, selectedState, product } = props
  let selectedStateProperties = states.filter(state => {
    return state.name === selectedState
  })
  selectedStateProperties = selectedStateProperties[0]

  const options = renewalPeriods.map(period => {
    let productInState = false
    if (
      product in selectedStateProperties &&
      typeof selectedStateProperties[product] === 'object' &&
      'renewal_periods' in selectedStateProperties[product]
    ) {
      productInState =
        selectedStateProperties[product]['renewal_periods'].indexOf(
          period.value
        ) > -1
    }

    return productInState ? (
      <option value={period.value} key={period.value}>
        {period.name}
      </option>
    ) : (
      ''
    )
  })

  return (
    <FormGroup>
      <Label htmlFor="guaranteePeriod">Guarantee Period</Label>
      <Input
        type="select"
        name="guaranteePeriod"
        id="guaranteePeriod"
        onChange={handler}
      >
        {options}
      </Input>
    </FormGroup>
  )
}

const RidersForm = props => {
  const { riders, handler, product } = props
  const { rmd, interest, av } = riders
  const hasRiders = product === 'sse'

  if (!hasRiders) {
    return ''
  }

  return (
    <div>
      <FormGroup>Optional Riders</FormGroup>
      <FormGroup>
        <div className="custom-control custom-checkbox">
          <Input
            className="custom-control-input"
            type="checkbox"
            checked={rmd}
            name="rmd"
            id="rmd"
            onChange={handler}
          />
          <Label className="custom-control-label" htmlFor="rmd">
            RMD
          </Label>
        </div>
        <div className="custom-control custom-checkbox">
          <Input
            className="custom-control-input"
            type="checkbox"
            checked={interest}
            name="interest"
            id="interest"
            onChange={handler}
          />
          <Label className="custom-control-label" htmlFor="interest">
            Interest
          </Label>
        </div>
        <div className="custom-control custom-checkbox">
          <Input
            className="custom-control-input"
            type="checkbox"
            checked={av}
            name="av"
            id="av"
            onChange={handler}
          />
          <Label className="custom-control-label" htmlFor="av">
            5% of AV
          </Label>
        </div>
      </FormGroup>
    </div>
  )
}

const DisplayGreenAlert = props => {
  const { selectedState, product } = props
  if (selectedState === '') {
    return ''
  }

  const label = product === 'ss' ? 'Secure Savings' : 'Secure Savings Elite'

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="alert alert-success text-center">
          <b>{label} is available for this state.</b>
        </div>
      </div>
    </div>
  )
}

const DisplayRedAlert = props => {
  const { display, message } = props
  if (!display) {
    return ''
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="alert alert-danger text-center">
          <b>{message}</b>
        </div>
      </div>
    </div>
  )
}

const PremiumTableWrapper = props => {
  let { data, display } = props

  if (data.length === 0 || display) {
    return ''
  }

  return (
    <Card style={{ margin: '30px 0 40px 0' }}>
      <CardBody style={{ margin: '-1px 15px 0 15px' }}>
        <PremiumTable data={data} tableSize="sm" border="no" />
      </CardBody>
    </Card>
  )
}

const buildPremiumOutputData = state => {
  let result = Array(parseInt(state.guaranteePeriod)).fill(null)
  let rate = getInterestRate(
    state.products,
    state.product,
    state.states,
    state.applicantIssueState,
    state.renewalPeriods,
    state.guaranteePeriod,
    state.rmd,
    state.interest,
    state.av,
    state.riders
  )
  let year = 1
  let age = state.applicantAge
  let startValue = state.premium
  let credit = startValue * rate
  let endValue = startValue + credit

  // dont parse if no state chosen
  if (state.applicantIssueState === '') {
    return []
  }

  result = result.map(i => {
    let resultObj = { year, age, startValue, rate, credit, endValue }
    year++
    age++
    startValue = endValue
    credit = startValue * rate
    endValue = startValue + credit

    return resultObj
  })

  return result
}

const getInterestRate = (
  products,
  product,
  states,
  state,
  renewal_periods,
  period,
  rmd,
  interest,
  av,
  riders
) => {
  const prodProps = products.filter(prod => {
    return product === prod.value
  })[0]
  const prodRate = prodProps
    ? period in prodProps.renewal_rates
      ? prodProps.renewal_rates[period]
      : null
    : null
  const stateProps = states.filter(st => {
    return st.name === state
  })[0]

  const stateAdjRate =
    (stateProps &&
    product in stateProps &&
    typeof stateProps[product] === 'object' &&
    'renewal_rates' in stateProps[product] &&
    period in stateProps[product]['renewal_rates']
      ? stateProps[product]['renewal_rates'][period]
      : prodRate) / 100

  const selectedRiders = { rmd, av, interest }
  const riderRate = riders
    .map(rider => {
      return selectedRiders[rider.name] === true ? rider.value / 100 : 0
    })
    .reduce((total, amount) => total + amount, 0)
  const finalRate = stateAdjRate - riderRate

  return finalRate
}

const numberMask = createNumberMask({
  prefix: '$',
  includeThousandsSeparator: true,
  allowDecimal: true,
  requireDecimal: true,
  decimalLimit: 2,
})

export default RenewalsCalculator
