import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material'
import numeral from 'numeral'

import { formatDate } from '../../_common/utils'
import './PolicyEventsList.css'
import { IWithdrawalAdjustmentPolicyEvents } from './types'

type WithdrawalAdjustmentPolicyEventsListProps = {
  withdrawalAdjustmentPolicyEventsList: IWithdrawalAdjustmentPolicyEvents[]
}

function WithdrawalAdjustmentPolicyEventsList({
  withdrawalAdjustmentPolicyEventsList,
}: WithdrawalAdjustmentPolicyEventsListProps) {
  return (
    <div>
      {withdrawalAdjustmentPolicyEventsList.map(event => (     
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1-header"
          >
            <Typography variant="h5">{event.title}</Typography>
            <Typography variant="subtitle1">
              {formatDate(event.date)}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Reason: </strong>
              {event.reason}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className="notes">
              <strong>Notes: </strong>
              {event.notes}
            </Typography>
            <Typography variant="h6" className="withdrawal-adjustment-details">
              Withdrawal Adjustment Details:
            </Typography>
            <Typography variant="body1" className="action-date">
              <strong>Withdrawal Action Date: </strong>
              {formatDate(event.withdrawal_action_date)}
            </Typography>            
            {(event.previous_withdrawal_charge != 0 || event.withdrawal_charge_adjustment != 0) && (
              <>
                <Typography variant="body1" className="details-top-section">
                  <strong>Previous Withdrawal Charges: </strong>
                  <div>
                    {numeral(event.previous_withdrawal_charge).format('$0,0.00')}
                  </div>
                </Typography>
                <Typography variant="body1" className="details-top-section">
                  <strong>Withdrawal Charge Adjustment: </strong>
                  <div>
                    {numeral(event.withdrawal_charge_adjustment).format('$0,0.00')}
                  </div>
                </Typography>
              </>
            )}
            {(event.previous_surrender_charges != 0 || event.surrender_charges_adjustment != 0) && (
              <>
                <Typography variant="body1" className="details-top-section">
                  <strong>Previous Surrender Charges: </strong>
                  <div>
                    {numeral(event.previous_surrender_charges).format('$0,0.00')}
                  </div>
                </Typography>
                <Typography variant="body1" className="details-top-section">
                  <strong>Surrender Charge Adjustment: </strong>
                  <div>
                    {numeral(event.surrender_charges_adjustment).format('$0,0.00')}
                  </div>
                </Typography>
              </>
            )}
            <Typography variant="body1" className="details-top-section">
              <strong>Previous Bonus Recovery: </strong>
              <div>
                {numeral(event.previous_bonus_recovery).format('$0,0.00')}
              </div>
            </Typography>
            <Typography variant="body1" className="details-top-section">
              <strong>Bonus Recovery Adjustment: </strong>
              <div>
                {numeral(event.bonus_recovery_adjustment).format('$0,0.00')}
              </div>
            </Typography>
            <Typography variant="body1" className="details-top-section">
              <strong>Previous MVA Calculation: </strong>
              <div>
                {numeral(event.previous_mva_calculation).format('$0,0.00')}
              </div>
            </Typography>
            <Typography
              variant="body1"
              className="details-top-section mva-adjustment"
            >
              <strong>MVA Adjustment: </strong>
              <div>{numeral(event.mva_adjustment).format('$0,0.00')}</div>
            </Typography>
            <Divider />
            <Typography
              variant="body1"
              className="details-bottom-section new-total-charges-amount"
            >
              <strong>New Total Charges Amount: </strong>
              <div>
                <span className="symbol"> = </span>
                {numeral(event.new_total_charges_amount).format('$0,0.00')}
              </div>
            </Typography>
            <Typography variant="body1" className="details-bottom-section">
              <strong>Total Charges Adjustment</strong>
              <div>
                <span className="symbol"> = </span>
                {numeral(event.total_charges_adjustment_amount).format(
                  '$0,0.00'
                )}
              </div>
            </Typography>
            <Typography variant="body1" className="details-bottom-section">
              <strong>Interest Adjustment: </strong>
              <div>
                <span className="symbol"> + </span>
                {numeral(event.interest_adjustment_amount).format('$0,0.00')}
              </div>
            </Typography>
            <Typography variant="body1" className="details-bottom-section">
              <strong>Total Adjustment Amount: </strong>
              <div>
                <span className="symbol"> = </span>
                {numeral(event.total_adjustment_amount).format('$0,0.00')}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default WithdrawalAdjustmentPolicyEventsList
