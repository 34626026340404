import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import ReportFormInputs from './ReportFormInputs'

const ReportForm = ({ reportConfigId, reportConfig, handleReportSubmit }) => {
  // escape functionality if now form input params
  if (!reportConfig || !reportConfig.params || reportConfig.params.length === 0)
    return null

  const { params } = reportConfig

  const parseOutValues = (val, params) => {
    // since Formik strips out empty inputs, we need to make sure all the keys are in place
    let baseVal = {}

    for (let i in params) {
      baseVal[params[i].input_name] = ''
    }

    //convert any values that need to be changed for the api here
    return { ...baseVal, ...val }
  }

  return (
    <>
      <hr />
      <Formik
        initialValues={{}}
        validationSchema={ReportValidationSchema(params)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          const newValues = parseOutValues(values, params)
          return handleReportSubmit(newValues)
        }}
        render={({ values, submitForm, errors, validateForm }) => (
          <Form>
            <ReportFormInputs params={params} />
            <Button type="submit" className="float-right">
              Run Report
            </Button>
          </Form>
        )}
      />
    </>
  )
}

export default ReportForm

const ReportValidationSchema = params => {
  let validationObj = {}

  for (let i in params) {
    if (params[i].input_type === 'multi select list') {
      validationObj[params[i].input_name] = Yup.object(() => {
        let prodArr = params[i].default_value.replace(/, */g, ',').split(',')
        let prodObj = {}
        for (i in prodArr) {
          prodObj[prodArr[i]] = Yup.boolean()
        }
        return prodObj
      }).test('ProductTest', 'You must select at least one Product', obj => {
        obj = obj || {}
        return (
          Object.keys(obj).filter(prod => {
            return obj[prod] === true
          }).length > 0
        )
      })
    }

    if (params[i].input_type !== 'multi select list') {
      validationObj[params[i].input_name] =
        Yup.string().required('This is required!')
    }
  }

  return Yup.object().shape(validationObj)
}
