import { Table } from 'reactstrap'

import {
  formatMoneyRounded,
  formatPercentage,
} from '../../../../_helpers/number'

const NonGuaranteedPolicyValuesComparison = ({
  data,
  dataTotal,
  isVega,
  hasIncomeValue,
  headerStyles,
}) => {
  if (!data) return null

  return (
    <Table className="table table-hover table-sm">
      <thead
        style={{
          textAlign: 'center',
          color: 'white',
          ...headerStyles,
        }}
      >
        <tr>
          <th>Policy Year</th>
          {/*<th>S&P Gain</th>*/}
          <th>Interest Credit Rate</th>
          <th>Account Value</th>
          {isVega && <th>Benefit Value</th>}
          {hasIncomeValue && <th>Income Value</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((comparison, i) => (
          <NonGuaranteedPolicyValuesComparisonRow
            key={i}
            data={comparison}
            isVega={isVega}
            hasIncomeValue={hasIncomeValue}
            isFirstYear={i === 0}
          />
        ))}
      </tbody>
      <tfoot style={{ textAlign: 'center' }}>
        <tr>
          <th scope="col">Annual Effective Rate</th>
          {/*<th scope="col">*/}
          {/*  {numeral(dataTotal.index_change).format(*/}
          {/*    '0.00%'*/}
          {/*  )}*/}
          {/*</th>*/}
          <th scope="col">{formatPercentage(dataTotal.icr)}</th>
          <th scope="col">{formatPercentage(dataTotal.account_value)}</th>
          {isVega && (
            <th scope="col">{formatPercentage(dataTotal.benefit_value)}</th>
          )}
          {hasIncomeValue && (
            <th scope="col">{formatPercentage(dataTotal.income_value)}</th>
          )}
        </tr>
      </tfoot>
    </Table>
  )
}

const NonGuaranteedPolicyValuesComparisonRow = ({
  data,
  isVega,
  hasIncomeValue,
  isFirstYear,
}) => {
  return (
    <tr style={{ textAlign: 'center' }}>
      <td>{data.years}</td>
      {/*<td>{numeral(data.index_change).format('0.00%')}</td>*/}
      <td>
        {isFirstYear && Number(data.icr) === 0
          ? 'n/a'
          : formatPercentage(data.icr)}
      </td>
      <td>{formatMoneyRounded(data.account_value)}</td>
      {isVega && <td>{formatMoneyRounded(data.benefit_value)}</td>}
      {hasIncomeValue && <td>{formatMoneyRounded(data.income_value)}</td>}
    </tr>
  )
}

export default NonGuaranteedPolicyValuesComparison
