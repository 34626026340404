import numeral from 'numeral'
import React from 'react'

const PremiumRow = props => {
  let { data } = props
  let { year, age, startValue, rate, credit, endValue } = data

  return (
    <tr>
      <td>{year}</td>
      <td>{age}</td>
      <td>{numeral(startValue).format('$0,0.00')}</td>
      <td>{numeral(rate).format('%0.00')}</td>
      <td>{numeral(credit).format('$0,0.00')}</td>
      <td>{numeral(endValue).format('$0,0.00')}</td>
    </tr>
  )
}

export default PremiumRow
