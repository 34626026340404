import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import logo from '../assets/images/InnerCircle-expanded4portal72dpi.png'
import homeBanner from '../assets/images/ic_banner.png'
import { Theme } from '../types'
import { defaultTheme } from './default'

export const innerCircleTheme: Theme = {
  ...defaultTheme,
  navbarBackground: '#000000',
  headerTitle: (
    <Link to="/" className="headerLogo">
      <img src={logo} alt="logo" />
    </Link>
  ),
  homeBanner: (
    <Box id="banner" marginBottom={4}>
      <img style={{ width: '100%' }} src={homeBanner} alt="welcome banner" />
    </Box>
  ),
  palette: {
    primary: {
      main: '#f9b74b',
      light: '#ffe97b',
      dark: '#c28716',
    },
    secondary: {
      main: '#000000',
      light: '#2c2c2c',
      dark: '#000000',
    },
  },
}
