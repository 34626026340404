/* eslint-disable no-script-url */
import numeral from 'numeral'
import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Table } from 'reactstrap'

import { ESeverity } from '../../../../_enums'
import { useToaster } from '../../../../_hooks'
import { getAccountValueDetails } from '../../../../_services/policyService'
import { useStore } from '../../../_hooks/use-store'
import { IAccountValueDetails } from './types'

const AccountValueModal = () => {
  const { toast } = useToaster()

  const {
    store: { selectedPolicy },
  } = useStore()

  const policyNumber = selectedPolicy.data.application.policy_number
  const [accountValueDetails, setAccountValueDetails] =
    useState<null | IAccountValueDetails>(null)

  const refreshPolicyAccountValueDetails = async (policyNumber: string) => {
    try {
      const data = await getAccountValueDetails(policyNumber)
      setAccountValueDetails(data)
    } catch (e) {
      toast(
        ESeverity.Error,
        'Could not retrieve account value details from server.'
      )
    }
  }

  useEffect(() => {
    if (policyNumber) refreshPolicyAccountValueDetails(policyNumber)
  }, [policyNumber])

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const values = [
    {
      header: 'Initial Premium',
      value:
        accountValueDetails?.initial_premiums ?? 0 > 0
          ? accountValueDetails?.initial_premiums
          : accountValueDetails?.premiums,
      mathValue: '',
    },
    {
      header: 'Premium Bonus',
      value: accountValueDetails?.bonus,
      mathValue: '+',
    },
    {
      header: 'Additional Premium',
      value: accountValueDetails?.additional_premiums,
      mathValue: '+',
    },
    {
      header: 'Additional Premium Bonus',
      value: accountValueDetails?.additional_bonus,
      mathValue: '+',
    },
    {
      header: 'Total Interest Credited',
      value: accountValueDetails?.interest,
      mathValue: '+',
    },
    {
      header: 'Withdrawals',
      value: Math.abs(accountValueDetails?.withdrawals || 0),
      mathValue: '-',
    },
    {
      header: 'Withdrawal Adjustments',
      value: accountValueDetails?.adjustments,
      mathValue: '+',
    },
    {
      header: 'Account Value',
      value: accountValueDetails?.total,
      mathValue: '=',
    },
  ]

  return (
    <div>
      <a href="Javascript:void(0)" className="float-right" onClick={toggle}>
        view calculations
      </a>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Account Value Details</ModalHeader>
        <ModalBody>
          <Table borderless size="sm">
            <tbody>
              {values
                .filter(({ header, value, mathValue }) => !!value)
                .map(({ header, value, mathValue }, i) => (
                  <tr key={i}>
                    <th scope="row">{mathValue}</th>
                    <td>{numeral(value).format('$0,0.00')}</td>
                    <td>{header}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AccountValueModal
