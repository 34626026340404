import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, Paper, useTheme } from '@mui/material'
import axios from 'axios'
import React, { useMemo, useState, useEffect } from 'react'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'

import { EUserGroup } from '../../_enums'
import EMembershipType from '../../_enums/EMembershipType'
import authService from '../../_services/authService'
import * as config from '../../_services/service.config'
import { store } from '../../_store/store'
import { THEMES, useThemeContext } from '../../themes/ThemeProvider'
import './agentSidebar.css'

function isActivePath(isActive: boolean, link: string, url: string) {
  if (link === '') {
    return url === '/agent'
  }
  return isActive
}

function getSidebarOptions(
  isLegacyAgent: boolean,
  shouldHideContracting: boolean
) {
  return [
    { link: '', icon: 'tachometer-alt', text: 'Home' },
    { link: '/my-business', icon: 'list', text: 'My Business' },
    {
      link: '/commissions',
      icon: 'money-bill-alt',
      text: 'Commissions',
    },
    {
      link: '/reports',
      icon: 'chart-line',
      text: 'Reports',
      hidden: isLegacyAgent,
    },
    { link: '/products', icon: 'archive', text: 'Products' },
    { link: '/sales-tools', icon: 'tools', text: 'Sales Tools' },
    {
      link: '/contracting',
      icon: 'user-check',
      text: 'Contracting',
      hidden: isLegacyAgent || shouldHideContracting,
    },
    {
      link: '/communication',
      icon: 'satellite',
      text: 'Communication',
    },
    { link: '/support', icon: 'phone', text: 'Support' },
  ]
}

export default function AgentSidebar({
  setMenuOpen,
}: {
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const theme = useTheme()
  const isLegacyAgent = authService.getIsLegacyAgent()
  const { themeType } = useThemeContext()
  const { url } = useRouteMatch()
  const [agentInfo, setAgentInfo] = useState(
    store.getState().agentInfo.agentInfo
  )

  const getAgentInfo = () => {
    const agentNumber = authService.getUserName()
    const url = `${config.baseURL}/agents/${agentNumber}`
    axios
      .get(url)
      .then(response => {
        store.dispatch({
          type: 'SET_AGENT_INFO',
          payload: { ...response.data },
        })
        setAgentInfo(response.data)
      })
      .catch(error => {
        store.dispatch({
          type: 'SET_AGENT_INFO',
          payload: undefined,
        })
        setAgentInfo(undefined)
        console.log(error)
      })
  }

  const hasContracting = authService.userHasGroup(EUserGroup.AgentContracting)
  const isNotLOALevel = agentInfo && agentInfo.level !== 'LOA'

  useEffect(() => {
    if (hasContracting && agentInfo == undefined) {
      getAgentInfo()
    }
    const unsubscribe = store.subscribe(() => {
      setAgentInfo(store.getState().agentInfo.agentInfo)
    })
    return () => {
      unsubscribe()
    }
  }, [agentInfo, hasContracting])

  const sidebarOptions = useMemo(
    () =>
      getSidebarOptions(
        isLegacyAgent,
        !isNotLOALevel || !hasContracting
      ).filter(x => !x.hidden),
    [isLegacyAgent, isNotLOALevel]
  )

  const isDefaultTheme = themeType === EMembershipType.DEFAULT

  return (
    <>
      <Paper
        sx={{
          background: `${THEMES[themeType].navbarBackground} !important`,
        }}
        className="sidebar"
        elevation={2}
        component="nav"
      >
        <Box width="100%" height="100%">
          <Box className="layout">
            <Box
              flexGrow="1"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              margin={2}
              marginBottom={0}
            >
              <Grid container flexDirection="column" rowSpacing={1}>
                {sidebarOptions.map(({ icon, link, text }) => (
                  <Grid key={link} item>
                    <NavLink
                      onClick={() => setMenuOpen?.(old => !old)}
                      to={`/agent${link}`}
                      style={isActive => ({
                        color: 'white',
                        ...(isActivePath(isActive, link, url)
                          ? {
                              color: 'black',
                              backgroundColor: isDefaultTheme
                                ? 'white'
                                : theme.palette.primary.main,
                            }
                          : null),
                      })}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        height={48}
                        paddingLeft={4}
                        paddingRight={4}
                        width="100%"
                        className="link"
                      >
                        <FontAwesomeIcon icon={icon! as IconProp} /> {text}
                      </Box>
                    </NavLink>
                  </Grid>
                ))}
                <Grid item>
                  <Link
                    onClick={() => setMenuOpen?.(old => !old)}
                    to={{
                      pathname: 'http://www.SILACins.com/CCPA-PrivacyPolicy',
                    }}
                    target="_blank"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      height={48}
                      paddingLeft={4}
                      paddingRight={4}
                      width="100%"
                      className="link"
                      sx={{ color: 'white !important' }}
                    >
                      <FontAwesomeIcon icon="gavel" /> Privacy Policy
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <SidebarFooter />
          </Box>
        </Box>
      </Paper>
      <Box className="content-offset" />
    </>
  )
}

/* TODO: move user here */
function SidebarFooter() {
  // const getFullName = (): string => {
  //   const userName = authService.getFullName()
  //   return userName && userName.toString().replace(' ', '') !== ''
  //     ? userName
  //     : 'Options'
  // }

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      marginBottom={4}
      flexDirection="column"
      rowGap={2}
    >
      {/* <Link to="/agent/profile" className="profile-link">
        <Button variant="outlined" className="profile-footer-option">
          {getFullName()}
        </Button>
      </Link>
      <Link to="/logout" className="profile-link">
        <Button variant="outlined" className="profile-footer-option">
          Logout
        </Button>
      </Link> */}

      <Box className="small" color="white" textAlign="center" marginTop={2}>
        ©{new Date().getFullYear()} SILAC Insurance Company
      </Box>
    </Box>
  )
}
