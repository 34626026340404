export function modalPopUp(state = {}, action = {}) {
  switch (action.type) {
    case 'INIT_MODAL':
      return { ...action.payload, modalIsOpen: false }
    case 'OPEN_MODAL':
      return { ...action.payload, modalIsOpen: true }
    case 'CLOSE_MODAL':
      return { ...action.payload, modalIsOpen: false }
    default:
      return state
  }
}
