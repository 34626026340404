import { Grid, Paper } from '@mui/material'
import moment from 'moment'

import { useStore } from '../../../../_common/_hooks/use-store'
import { formatDate, formatName } from '../../../../_common/utils'

function PolicyDetails() {
  const { store } = useStore()
  const { selectedPolicy } = store

  return (
    <Grid sm={12} container justifyContent="space-around">
      <Grid sm={12} md={6} item>
        <Paper elevation={3}>
          <Grid p={'30px'} container spacing={3}>
            <Grid item xs={12} md={5} className="data-label">
              Status
            </Grid>
            <Grid item xs={12} md={7}>
              {setStatustext(selectedPolicy.data?.application?.status)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Sub Status
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.data?.application?.substatus}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Product Type
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.product?.type +
                ': ' +
                selectedPolicy.product?.name}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Policy Years
            </Grid>
            <Grid item xs={12} md={7}>
              {!isNaN(parseInt(selectedPolicy.data?.application?.plan))
                ? selectedPolicy.data?.application?.plan + ' Years'
                : ''}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Owner Name
            </Grid>
            <Grid item xs={12} md={7}>
              {formatName(selectedPolicy.data?.owner)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Joint Owner Name
            </Grid>
            <Grid item xs={12} md={7}>
              {formatName(selectedPolicy.data?.joint_owner)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Annuitant Name
            </Grid>
            <Grid item xs={12} md={7}>
              {formatName(selectedPolicy.data?.annuitant)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Issue State
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.data?.application?.state}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Issue Date
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.data?.application?.issue_date ===
                'Invalid date' ||
              selectedPolicy.data?.application?.issue_date === '' ? (
                <>&nbsp;</>
              ) : (
                selectedPolicy.data?.application?.issue_date
              )}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Last Updated
            </Grid>
            <Grid item xs={12} md={7}>
              {formatDate(selectedPolicy.data?.application?.updated_at)}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Days in Current Status
            </Grid>
            <Grid item xs={12} md={7}>
              {moment().diff(
                moment(selectedPolicy.data?.application?.updated_at),
                'days'
              )}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Application Date
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.data?.application?.application_date}
            </Grid>
            <Grid item xs={12} md={5} className="data-label">
              Application Received Date
            </Grid>
            <Grid item xs={12} md={7}>
              {selectedPolicy.data?.application?.received_date}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export const setStatustext = status => {
  let text = ''

  switch (status) {
    case 'ASO':
      text = 'Issued'
      break
    case 'DLR':
      text = 'Issued and Mailed'
      break
    case 'ISS':
      text = 'Issued and Mailed'
      break
    case 'MLD':
      text = 'Issued and Mailed'
      break
    case 'MLR':
      text = 'Issued and Mailed'
      break
    case 'REC':
      text = 'Issued and Mailed'
      break
    case 'NTO':
      text = status
      break
    case 'DEL':
      text = status
      break
    case 'NGF':
      text = 'Forms Not in Good Order'
      break
    case 'NEW':
      text = 'New App'
      break
    case 'PTF':
      text = 'Pending Transfer or Funds'
      break
    case 'RDN':
      text = 'Received Denied'
      break
    case 'PAR':
      text = 'Pending Agent Review'
      break
    case 'NGS':
      text = 'Suitability Not in Good Order'
      break
    default:
      text = status
  }
  return text
}

export default PolicyDetails
