import { Button, Link } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { Table } from 'reactstrap'

import './SalesMaterials.css'

export default class MaterialsTable extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        group: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        formId: PropTypes.number.isRequired,
      })
    ),
  }

  static defaultProps = {
    title: '',
    materials: [
      {
        name: '',
        group: '',
        formId: -1,
        description: '',
      },
    ],
  }

  renderRows = rows =>
    (rows || []).map(row => {
      return row.formId < 0 ? null : (
        <tr key={row.formId}>
          <td colSpan="3">{row.name}</td>
          <td colSpan="3">{row.description}</td>
          <td colSpan="3">{row.group}</td>
          <td colSpan="3">
            <Link href={row.link} target="_blank" rel="noopener noreferrer">
              <Button>Download</Button>
            </Link>
          </td>
        </tr>
      )
    })

  render() {
    return (
      <Fragment>
        <Table>
          <thead>
            <tr>
              <th>{this.props.title}</th>
            </tr>
            <tr>
              <th colSpan="3">Name</th>
              <th colSpan="3">Description</th>
              <th colSpan="3">Group</th>
              <th colSpan="3"></th>
            </tr>
          </thead>
          <tbody>{this.renderRows(this.props.materials)}</tbody>
        </Table>
      </Fragment>
    )
  }
}
