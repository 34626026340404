/** @format */
import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Label, FormGroup } from 'reactstrap'

import { StyledErrorMessage, NoTouchErrorMessage } from './StyledErrorMessage'

export const SelectField = props => {
  const {
    children,
    disabled,
    label,
    name,
    multiple,
    required,
    noTouchMessage,
    onBlur,
  } = props

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Field
        component="select"
        name={name}
        className="form-control"
        disabled={disabled}
        multiple={multiple}
        required={required}
        onBlur={onBlur}
      >
        {children}
      </Field>
      {noTouchMessage ? (
        <NoTouchErrorMessage name={name} />
      ) : (
        <StyledErrorMessage name={name} />
      )}
    </FormGroup>
  )
}

SelectField.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  noTouchMessage: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

SelectField.defaultProps = {
  disabled: false,
  multiple: false,
  required: false,
  noTouchMessage: false,
}

export const RequiredSelectField = props => {
  const { children, disabled, label, name, multiple, required } = props

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Field
        component="select"
        name={name}
        className="form-control"
        disabled={disabled}
        multiple={multiple}
        required={required}
      >
        {children}
      </Field>
      <NoTouchErrorMessage name={name} />
    </FormGroup>
  )
}

RequiredSelectField.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

RequiredSelectField.defaultProps = {
  disabled: false,
  multiple: false,
  required: false,
}

export default SelectField
