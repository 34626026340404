import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import authService from '../../../_services/authService'
import Page from '../../layout/Page'
import AgentContractList from './AgentContractList'

const AgentContracting = () => {
  return (
    <Page title="Contracting">
      <Row>
        <Col md="12">
          <Link to="/agent/contracting/recruit">
            <Button className="float-right">Recruit New Downline Agent</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12">
          <Card>
            <CardHeader>
              <b>Downline Recruitment Status</b>
            </CardHeader>
            <CardBody>
              <AgentContractList
                //loading={this.state.loading}
                agentNumber={authService.getUserName()}
              />
            </CardBody>
          </Card>
        </div>
      </Row>
    </Page>
  )
}

export default AgentContracting
