import { combineReducers } from 'redux'

import { agentInfo } from './agentInfoReducer'
import { announcements } from './announcementReducer'
import { authTokens } from './authTokensReducer'
import { authentication } from './authenticationReducer'
import { modalPopUp } from './modalPopUpReducer'

const rootReducer = combineReducers({
  authentication,
  authTokens,
  modalPopUp,

  agentInfo,
  announcements,
})

export default rootReducer
