import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'

export default function Upload(props) {
  const maxSize = 20971520 // 1048576 = 1 meg in bytes

  // handles the actual uploading of the file
  const onDrop = acceptedFiles => props.handleOnDrop(acceptedFiles)

  const {
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept:
      'application/pdf, application/x-pdf, image/tiff, image/jpeg, image/pjpeg',
    onDrop,
    minSize: 0,
    maxSize: 20971520,
    multiple: false,
  })

  const uploadStyle = (
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileTooLarge
  ) => {
    let status = ''

    if (isDragAccept) {
      status = ' accept'
    }
    if (isDragReject) {
      status = ' reject'
    }
    if (isFileTooLarge) {
      status = ' reject'
    }

    return 'uploadArea' + status
  }

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize

  return (
    <section className="uploadContainer">
      <div
        {...getRootProps({
          className: uploadStyle(
            isDragActive,
            isDragAccept,
            isDragReject,
            isFileTooLarge
          ),
        })}
      >
        <input {...getInputProps()} />
        <div className="title">Upload Application File</div>
        <div className="subtext">
          Drag file here or click to select a single file
        </div>
        <div className="superscript">
          (Only PDF, TIFF, JPEG files accepted. Max file size 20MB)
        </div>
        <div className={'alert ' + props.status}>
          <div className="uploadMsgContainer">
            <div>
              <div className="uploadIcon"></div>
              <div className="uploadMsg">
                <div>
                  {props.statusIcon === '' ? (
                    <Fragment />
                  ) : (
                    <FontAwesomeIcon
                      icon={props.statusIcon}
                      size="3x"
                      color={props.iconColor}
                    />
                  )}
                </div>
                <div>
                  <strong className="title">{props.statusTitle}</strong>
                  <div className="content">{props.statusMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
