import React from 'react'

// import { Link } from 'react-router-dom';

const AgentContractingListItem = ({
  id,
  inviter_agent_number,
  first_name,
  last_name,
  email,
  level,
  invitation_date,
  invitation_status,
  vertafore_packet_id,
  onboarded_agent_number,
  completed_date,
}) => (
  <tr>
    <td>{inviter_agent_number}</td>
    <td>
      {first_name} {last_name}
    </td>
    <td>{email}</td>
    <td>{level}</td>
    <td>{invitation_date}</td>
    <td>{completed_date}</td>
    <td>{invitation_status}</td>
  </tr>
)

export default AgentContractingListItem
