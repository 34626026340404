import { Grid, SelectChangeEvent } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState, ReactNode } from 'react'
import * as Yup from 'yup'

import ESeverity from '../../../../../_enums/ESeverity'
import { isTeton, isDenali } from '../../../../../_helpers/policy'
import { useDidMountEffect, useToaster } from '../../../../../_hooks'
import { getRiders } from '../../../../../_services/sales-tools/illustration'
import stepImage from '../../../../../assets/images/sales-tools/delivery-box.png'
import { IRider } from '../../../../../types/salesTools'
import { useIllustrationContext } from '../../IllustrationContext'
import { EAction } from '../../reducers'
import StepImage from '../StepImage'
import RiderDropdown from './RiderDropdown'

export interface IFormFields {
  issueState: string
  agent: string
  plan: string
  riderConfigId: string
}

const validationSchema = Yup.object().shape({
  issueState: Yup.string().required().label('Issue State'),
  agent: Yup.string().required().label('Agent Name'),
  plan: Yup.string().required().label('Plan'),
  riderConfigId: Yup.string()
    .label('Rider')
    .when('plan', {
      is: val => val && isTeton(Number(val.split('_')[1])),
      then: Yup.string().required(),
    }),
})

type Props = {
  actions: (isLoading: boolean) => ReactNode
  handleNext: () => void
}

export function ProductSelection({ actions, handleNext }: Props) {
  const { store, dispatch } = useIllustrationContext()
  const { toast } = useToaster()

  const [riders, setRiders] = useState<IRider[] | undefined>(store.riders)
  const [configs, setConfigs] = useState<any[]>([])
  const [states, setStates] = useState<any[]>([])
  const [productList, setProductList] = useState<any[]>([])

  const [selectedConfig, setSelectedConfig] = useState<any>(
    store.selectedConfig.id ? store.selectedConfig : undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnSubmit = (values: IFormFields) => {
    if (formik.isValid) {
      dispatch({
        type: EAction.SetProductSelection,
        payload: {
          agent: values.agent,
          email: null,
          gender: null,
          plan: values.plan,
          riderConfigId: values.riderConfigId && Number(values.riderConfigId),
          issueState: values.issueState,
          selectedConfig,
          riders,
        },
      })
      handleNext()
    }
  }

  const formik = useFormik({
    initialValues: {
      agent: store.agent,
      issueState: store.issueState,
      plan: store.plan,
      riderConfigId: store.riderConfigId?.toString() || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  })

  useEffect(() => {
    const url = `${window._env_.REACT_APP_API_URL}/illustration/available?limit=5000`

    axios
      .get(url)
      .then(response => {
        const { results } = response.data
        setConfigs(results)
        const availableStates = results.reduce((stateList, config) => {
          if (stateList.length === 0) {
            stateList.push({
              name: config.state_name,
              code: config.state_code,
              id: config.state_id,
            })
          } else {
            if (
              Array.isArray(stateList) &&
              stateList.filter(state => state.id === config.state_id).length ===
                0
            ) {
              stateList.push({
                name: config.state_name,
                code: config.state_code,
                id: config.state_id,
              })
            }
          }
          return stateList.sort((a, b) => a.id - b.id)
        }, [])
        setStates(availableStates)

        if (formik.values.issueState)
          setProductList(
            results.filter(
              config => config.state_code === formik.values.issueState
            )
          )
      })
      .catch(() => {
        toast(ESeverity.Error, 'Could not retrieve data from server')
      })
    // NOTE: this 'useEffect' function is only called on mount.
    // if this changes in the future, remove the eslint disable below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDidMountEffect(() => {
    setRiders(undefined)
    formik.setFieldValue('riderConfigId', '0')
    if (
      !selectedConfig ||
      !(
        isTeton(selectedConfig.plan.product.id) ||
        isDenali(selectedConfig.plan.product.id)
      )
    )
      return
    setIsLoading(true)
    ;(async () => {
      const data = await getRiders(selectedConfig.id)
      setRiders(data)
      setIsLoading(false)
    })()
  }, [selectedConfig?.id, selectedConfig?.plan.product.id])

  const handleIssueStateChange = (e: SelectChangeEvent<any>) => {
    setProductList(
      configs.filter(config => config.state_code === e.target.value)
    )
    formik.setFieldValue('plan', '')
    formik.handleChange(e)
    setSelectedConfig(undefined)
  }

  const handlePlanChange = (e: SelectChangeEvent<any>) => {
    dispatch({
      type: EAction.Reset,
    })

    const plan = e.target.value

    formik.setValues({
      ...formik.values,
      plan,
      riderConfigId: '',
    })

    if (!plan) return

    setIsLoading(true)
    getConfigByID(onPlanSelect(plan, formik.values.issueState), plan)
  }

  const onPlanSelect = (plan, issueState) => {
    if (configs.length === 0 || plan === '' || issueState === '') return

    const [planID, productID] = plan.split('_')

    const _selectedReducedConfig = configs.find(config => {
      return (
        Number(planID) === config.plan_id &&
        Number(productID) === config.product_id &&
        issueState === config.state_code
      )
    })

    return _selectedReducedConfig.id
  }

  const getConfigByID = (configID: string, plan: string) => {
    const url = `${window._env_.REACT_APP_API_URL}/annuity-config/${configID}`
    axios
      .get(url)
      .then(response => {
        setSelectedConfig(response.data)
      })
      .catch(_ => toast(ESeverity.Error, 'Could not retrieve data from server'))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid container rowSpacing={4} item xs={12} md={6}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={formik.values.agent}
              onChange={formik.handleChange}
              label="Agent Name"
              id="agent"
              name="agent"
              helperText={Boolean(formik.submitCount) && formik.errors.agent}
              error={
                Boolean(formik.submitCount) && Boolean(formik.errors.agent)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={
                Boolean(formik.submitCount) && Boolean(formik.errors.issueState)
              }
            >
              <InputLabel id="issueState">Issue State</InputLabel>
              <Select
                labelId="issueState"
                name="issueState"
                value={formik.values.issueState}
                label="Issue State"
                onChange={handleIssueStateChange}
                disabled={!configs}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
              >
                <MenuItem value="">Select...</MenuItem>
                {states.map(state => (
                  <MenuItem key={state.code} value={state.code}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(formik.submitCount) && (
                <FormHelperText>{formik.errors.issueState}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.submitCount) && Boolean(formik.errors.plan)}
            >
              <InputLabel id="plan">Product</InputLabel>
              <Select
                labelId="plan"
                name="plan"
                value={formik.values.plan}
                label="Product"
                onChange={handlePlanChange}
                disabled={!formik.values.issueState || !productList.length}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
              >
                <MenuItem value="">Select...</MenuItem>
                {productList?.map(product => (
                  <MenuItem
                    key={product.id}
                    value={`${product.plan_id}_${product.product_id}`}
                  >
                    {`${product.product_name} ${product.plan_name}`}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(formik.submitCount) && (
                <FormHelperText>{formik.errors.plan}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {Boolean(riders?.length) && (
              <RiderDropdown formik={formik} riders={riders!} />
            )}
          </Grid>
        </Grid>
      </Grid>
      {actions(isLoading)}
      <StepImage image={stepImage} title="product selection" />
    </form>
  )
}
