import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'

import PieChart, {
  chartColors,
} from '../../../../components/client-components/PolicyDetails/PieChart'
import Loader from '../../../_components/Loader/Loader'

// Grab crediting strategy info from WindAPI
const queryStrategies = setStratState => {
  const url = `${window._env_.REACT_APP_API_URL}/annuity-strategies/?limit=100`

  return axios.get(url).then(response => setStratState(response.data.results))
}

function CreditStrategy(props: any) {
  const { allocations, config } = props
  const [strategies, setStrategies] = useState([])
  const [chartData, setChartData] = useState([])

  // hit the api after mount
  useEffect(() => {
    queryStrategies(setStrategies)
    // console.log('chartColors', chartColors)
  }, [])

  useEffect(() => {
    if (strategies.length !== 0) {
      setChartData(
        (strategies as Array<any>).reduce((strats, currentStrat) => {
          const stratArr: Array<any> = [...strats]
          if (
            allocations[currentStrat.code] &&
            allocations[currentStrat.code] > 0
          ) {
            const rate =
              Number.parseFloat(
                config?.crediting.strategies[currentStrat.code] || 0
              ) * 100

            stratArr.push({
              id: currentStrat.id,
              label: currentStrat.name,
              rate: rate ? rate.toFixed(2).toString() + '%' : 'N/A',
              value: allocations[currentStrat.code] / 100,
            })
          }
          return stratArr
        }, [])
      )
    }
  }, [strategies, allocations, config?.crediting.strategies])

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={12} lg={3}>
        <div className="mt-5 single-value-container">
          <PieChart
            data={chartData}
            width={350}
            height={350}
            innerRadius={0}
            outerRadius={175}
          />
        </div>
      </Grid>

      <Grid item md={4} sm={12} lg={9}>
        <br />
        <br />

        <table width="100%">
          <thead>
            <tr>
              <th>
                <h2>Crediting Strategy</h2>
              </th>
              <th>
                <h2>Allocation &#37;</h2>
              </th>
            </tr>
          </thead>
          <tbody>
            {chartData.length !== 0 ? (
              chartData.map((strat: any, idx) => (
                <tr key={idx}>
                  <td className="data-label">
                    <h5>
                      <FontAwesomeIcon
                        icon="square"
                        size="1x"
                        color={chartColors[idx]}
                      />
                      &nbsp;
                      {strat.label}{' '}
                      <span className="muted-text">({strat.rate})</span>
                    </h5>
                  </td>
                  <td>
                    <h5>{Math.round(strat.value * 10000) / 100} &#37;</h5>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <Loader title="Loading..." />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Grid>
      {/* <hr></hr>
      <div><pre>{JSON.stringify(props, null, 2)}</pre></div> */}
    </Grid>
  )
}

export default CreditStrategy
