import { Box, Paper } from '@mui/material'

import { THEMES, useThemeContext } from '../../../themes'
import SidebarItems from '../../layout/SidebarItems'

const Sidebar = ({
  setMenuOpen,
}: {
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const { themeType } = useThemeContext()
  const data = [
    { linkTarget: '/client', icon: 'list', text: 'My Policies' },
    { linkTarget: '/client/self-service', icon: 'file', text: 'Service Forms' },
    { linkTarget: '/client/support', icon: 'phone', text: 'Contact Us' },
  ]
  return (
    <>
      <Paper
        sx={{
          background: `${THEMES[themeType].navbarBackground} !important`,
        }}
        className="sidebar"
        elevation={2}
        component="nav"
      >
        <Box width="100%" height="100%">
          <Box className="layout">
            <Box
              flexGrow="1"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              margin={2}
              marginBottom={0}
            >
              <SidebarItems items={data} setMenuOpen={setMenuOpen} />
            </Box>
            <SidebarFooter />
          </Box>
        </Box>
      </Paper>
      <Box className="content-offset" />
    </>
  )
}

/* TODO: move user here */
function SidebarFooter() {
  // const getFullName = (): string => {
  //   const userName = authService.getFullName()
  //   return userName && userName.toString().replace(' ', '') !== ''
  //     ? userName
  //     : 'Options'
  // }

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      marginBottom={4}
      flexDirection="column"
      rowGap={2}
    >
      {/* <Link to="/agent/profile" className="profile-link">
        <Button variant="outlined" className="profile-footer-option">
          {getFullName()}
        </Button>
      </Link>
      <Link to="/logout" className="profile-link">
        <Button variant="outlined" className="profile-footer-option">
          Logout
        </Button>
      </Link> */}

      <Box className="small" color="white" textAlign="center" marginTop={2}>
        ©{new Date().getFullYear()} SILAC Insurance Company
      </Box>
    </Box>
  )
}

export default Sidebar
