import { IllustrationStore } from '../../../types/salesTools'
import {  InitialIllustrationStore } from './store'

export enum EAction {
  SetNextButtonState = 'SET_NEXT_BUTTON_STATE',
  UpdateStore = 'UPDATE_STORE',
  SetProductSelection = 'SET_PRODUCT_SELECTION',
  SetStepValidity = 'SET_STEP_VALIDITY',
  Reset = 'RESET',
}

export interface IActionType {
  type: EAction
  payload: any
}

export const reducer = (
  state: IllustrationStore,
  action: IActionType
): IllustrationStore => {
  switch (action.type) {
    case EAction.Reset:
      return {
        ...InitialIllustrationStore,
        ownerName: state.ownerName,
        ownerAge: state.ownerAge,
        jointOwnerName: state.jointOwnerName,
        initialPremium: state.initialPremium,
        additionalPremiumYear1: state.additionalPremiumYear1,
        typeOfFunds: state.typeOfFunds,
      }
    case EAction.SetProductSelection:
      return {
        ...state,
        ...action.payload,
      }
    case EAction.UpdateStore:
      return {
        ...state,
        ...action.payload,
      }

    case EAction.SetNextButtonState:
      return {
        ...state,
        nextButtonState: action.payload,
      }

    case EAction.SetStepValidity:
      return {
        ...state,
        validSteps: { ...state.validSteps, ...action.payload },
      }
    default:
      return { ...state }
  }
}
