import { Button } from '@mui/material'
import axios from 'axios'
import classnames from 'classnames'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'

import authService from '../../../_services/authService'
import DynamicReports from '../agent-reports-dynamic/DynamicReports'
import './AgentCommissions.css'
import AgentCommissionChart from './AgentCommissionsChart'
import AgentCommissionTable from './AgentCommissionsTable'

const ERROR_MSG = 'Something went wrong'
export default class AgentCommission extends Component {
  constructor(props) {
    super(props)

    this.state = {
      commissions: '',
      hierarchy: false,
      pin: '',
      displayTable: false,
      commissionsListForChart: [],
      displayChart: false,
      activeTab: '1',
      agent_number: '', // Store agent_number in state
      endDate: '',
      startDate: '',
      products: '',
      fileType: '',
      requestedReports: [],
      requestNumber: 0,
      generating: false,
      visible: true,
      requestCount: 0,
    }

    this.onHierarchyChange = this.onHierarchyChange.bind(this)
    this.handlePinSubmit = this.handlePinSubmit.bind(this)
    this.handlePinChange = this.handlePinChange.bind(this)

    this.toggle = this.toggle.bind(this)

    this.onDismiss = this.onDismiss.bind(this)
  }

  onDismiss() {
    this.setState({ visible: false })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  handlePinSubmit(e) {
    localStorage.setItem('commission_pin', this.state.pin)
    const agentNumber = authService.getUserName()
    this.setState({ agent_number: agentNumber }, () => {
      this.queryAPI() // Call after state update is complete
    })
    e.preventDefault() // prevent page refresh
  }

  handlePinChange(e) {
    let curVal = e.target.value
    //do not allow the keystroke if non numerical of exceeds 5 digits
    if (curVal.match(/([^0-9])|([0-9]{5})/g)) {
      return false
    }

    this.setState(prevState => ({ ...prevState, pin: curVal }))
  }

  onHierarchyChange(e) {
    let changeVal = e.target.value
    if (this.state.hierarchy !== changeVal) {
      this.setState(prevState => ({ hierarchy: !prevState.hierarchy }))
    }
  }

  queryAPI() {
    const { agent_number } = this.state // Get agent_number from state
    let url = `${window._env_.REACT_APP_API_URL}/agents/commission/${agent_number}`
    let payload = {
      commission_pin: this.state.pin,
      hierarchy: this.state.hierarchy,
    }

    axios
      .post(url, payload)
      .then(response => {
        const commissions = []
        const results = response.data.table

        for (let result of results) {
          const commission = {}

          commission.id = result.id
          commission.actionDate = result.action_date
          commission.agentNumber = result.agent_number
          commission.commissionPaid = result.amount
          commission.description = result.description
          commission.policyNumber = result.policy.policy_number

          commissions.push(commission)
        }

        this.setState(() => ({ commissions: commissions, displayTable: true }))

        // Build Commissions Chart List
        const commissionsChartData = response.data.chart
        const commissionAgg = {}
        for (let commission in commissionsChartData) {
          let commissionDate = new Date(
            commissionsChartData[commission].action_date
          )
          let commissionMonth = commissionDate.getMonth() + 1

          if (commissionAgg[commissionMonth] === undefined)
            commissionAgg[commissionMonth] = parseInt(
              commissionsChartData[commission].data_sum
            )
          else
            commissionAgg[commissionMonth] += parseInt(
              commissionsChartData[commission].data_sum
            )
        }

        const commissionsList = []
        for (let i = 1; i <= 12; i++) {
          if (commissionAgg[i] === undefined) commissionsList.push(0)
          else commissionsList.push(commissionAgg[i])
        }

        this.setState(() => ({
          commissionsListForChart: commissionsList,
          displayChart: true,
        }))
      })
      .catch(reason => {
        this.setState(() => ({
          displayTable: false,
          errors: {
            pinMessage:
              reason.response.data?.detail || reason.response.data || ERROR_MSG,
          },
        }))
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hierarchy !== this.state.hierarchy) {
      this.queryAPI()
    }
  }

  render() {
    const {
      errors,
      pin,
      commissions,
      displayTable,
      commissionsListForChart,
      displayChart,
    } = this.state

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Commissions
            </li>
          </ol>
        </nav>
        <Row>
          <Col md="12">
            <Link replace={false} to="/agent/my-profile">
              <Button className="float-right">Create/Change Pin</Button>
            </Link>
          </Col>
        </Row>
        <hr />
        {!displayChart ? (
          <Row className="d-block">
            <div className="col-md-12" style={{ position: 'relative' }}>
              <div style={{ margin: '1em auto', width: '300px' }}>
                <Card>
                  <CardHeader>Commissions Pin</CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handlePinSubmit}>
                      <Row>
                        <Col md="12">
                          <Input
                            type="password"
                            value={pin}
                            onChange={this.handlePinChange}
                            name="topicBox"
                            id="topicBox"
                            placeholder="Enter Commissions Pin"
                          />
                        </Col>
                      </Row>
                      <br />
                      {errors ? (
                        <span className="float-left">
                          <Alert color="danger">{errors.pinMessage}</Alert>
                        </span>
                      ) : null}

                      <Button
                        type="submit"
                        className="float-right"
                        onSubmit={this.handlePinSubmit}
                      >
                        Submit
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        ) : (
          <Row className="d-block">
            <div>
              <Nav tabs>
                {false && (
                  <NavItem>
                    <NavLink
                      className={classnames('commission-tabs', {
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1')
                      }}
                    >
                      Commissions
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={classnames('commission-tabs', {
                      active: this.state.activeTab === '1',
                    })}
                    onClick={() => {
                      this.toggle('1')
                    }}
                  >
                    Statements
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {false && (
                  <TabPane tabId="1" disabled={true}>
                    <Row>
                      <Col md="12">
                        <br />
                        <AgentCommissionChart
                          commissionsList={commissionsListForChart}
                          display={displayChart}
                        />{' '}
                        <br />
                        <AgentCommissionTable
                          commissions={commissions}
                          display={displayTable}
                          onHierarchyChange={this.onHierarchyChange}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                <TabPane tabId="1">
                  <DynamicReports
                    requiresPin={true}
                    reportCreatorHeaderText={'Statement Request'}
                    reportListHeaderText={'Statement Queue'}
                  />
                </TabPane>
              </TabContent>
            </div>
          </Row>
        )}
      </div>
    )
  }
}
