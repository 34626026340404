import { Box, Hidden } from '@mui/material'

type Props = {
  image: any
  title: string
}

export default function StepImage({ image, title }: Props) {
  return (
    <Hidden mdDown>
      <Box
        sx={{
          pt: 2,
          position: 'absolute',
          right: 25,
          bottom: 25,
        }}
      >
        <img height={100} src={image} alt={title} />
      </Box>
    </Hidden>
  )
}
