import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Typography } from '@mui/material'
import React from 'react'

const NotFound: React.FC = () => {
  return (
    <Box
      marginTop={'20vh'}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <WarningAmberIcon fontSize="large" />
      <Typography marginLeft={2} component="h1" variant="h5">
        404 Not Found
      </Typography>
    </Box>
  )
}

export default NotFound
