/* eslint-disable no-script-url */
import React from 'react'
import { useEffect, useState } from 'react'
import { useStore } from '../../../../_hooks/use-store'
import { PaperDisplay } from '../../../../_components/PaperDisplay'
import numeral from 'numeral'
import { EModal } from '../EModal'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import IncomeValueDetails from './IncomeValueDetails'
import { IIncomeValueDetails } from '../types'
import { getIncomeValueDetails } from '../../../../../_services/policyService'

const IncomeValueCard = () => {

  const {
    store: { selectedPolicy },
  } = useStore()

  const [openModals, setOpenModals] = useState(EModal.None)

  const [incomeValueDetails, setIncomeValueDetails] = useState<null | IIncomeValueDetails>(null)

  const policyNumber = selectedPolicy.data.application.policy_number

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getIncomeValueDetails(policyNumber)
        setIncomeValueDetails(data)
      } catch (e) { }
    }
    loadData()
  }, [policyNumber])

  const toggleIncomeValueDetailsModal = () => {
    setOpenModals(
      openModals === EModal.IncomeValueDetails
        ? EModal.None
        : EModal.IncomeValueDetails
    )
  }

  return (
    <>
      <PaperDisplay
        displayId="IncomveValue"
        toolTipPlacement="right"
        displayTitle="Income Value"
        displayValue={numeral(
          incomeValueDetails?.total ?? 0
        ).format('$0,0.00')}
        // toolTipText="Total interest credited for the current policy year."
        footerAction={
          {
            actionText: 'View Details',
            action: toggleIncomeValueDetailsModal,
          }
        }
        isLoading={!Boolean(incomeValueDetails || false)}
      />

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openModals === EModal.IncomeValueDetails}
        title="Income Value Details"
        onClose={toggleIncomeValueDetailsModal}
        style={{ padding: '10px' }}
      >
        <div className="dialog-title">
          <DialogTitle className="dialog-title">Income Value Details</DialogTitle>
          <IconButton>
            <Close onClick={toggleIncomeValueDetailsModal} />
          </IconButton>
        </div>
        <IncomeValueDetails data={incomeValueDetails} />
      </Dialog>
    </>
  )
}

export default IncomeValueCard
