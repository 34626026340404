import axios from 'axios'

import { store } from '../_store/store'
import announcementService from './announcementService'
import { initService } from './initService'

const authService = {
    isAuthenticated(prop) {
        let { allowed } = { ...prop }
        allowed = Array.isArray(allowed) ? allowed : []
        let { userInfo } = this.getRefreshToken()

        // if a refresh token exists, then test against groups and exp date
        if (userInfo) {
            var { groups } = userInfo

            if (!Array.isArray(groups) || groups.length === 0) {
                return false
            }

            this.checkAxiosAuthHeaderAndRefreshIfNull()

            // change the groups from an array of object to just the group names
            groups = groups.map((group, i) => {
                return group.name
            })

            // compare the group array with some array of allowed groups for the component, return false if no matches true if matches
            let intersection = allowed.filter(x => -1 !== groups.indexOf(x))
            return intersection.length > 0
        }

        // should be return false if no refresh token
        return false
    },

    signIn(state) {
        announcementService.viewed(false)
        announcementService.viewed(false, 'legacyAgent')
        store.dispatch({ type: 'SET_AGENT_INFO', payload: undefined })
        return dispatch => {
            // redux store requsting login state
            dispatch({ type: 'USERS_LOGIN_REQUEST', payload: state })

            // configs to make auth request
            let connect = {
                url: `${window._env_.REACT_APP_API_URL}/login/token/`,
                body: JSON.stringify({
                    username: state.username,
                    password: state.password,
                }),
                headers: { headers: { 'Content-Type': 'application/json' } },
            }

            // make the request
            axios
                .post(connect.url, connect.body, connect.headers)
                .then(response => {
                    // set the local storage
                    localStorage.setItem('access', response.data.access)
                    localStorage.setItem('refresh', response.data.refresh)

                    // set to store and axios
                    authService.setTokensInStore(
                        response.data.access,
                        response.data.refresh
                    )

                    // determine if groups exist, dispatch success if they do and trigger fail if they dont.
                    let { userInfo } = this.getRefreshToken()

                    if (
                        !userInfo ||
                        !Array.isArray(userInfo.groups) ||
                        userInfo.groups.length === 0
                    ) {
                        // redux store failure login state
                        dispatch({ type: 'USERS_LOGIN_FAILURE', payload: state })
                    } else {
                        // redux store success login state
                        dispatch({ type: 'USERS_LOGIN_SUCCESS', payload: state })
                    }
                })
                .catch(error => {
                    // redux store failure login state
                    dispatch({ type: 'USERS_LOGIN_FAILURE', payload: state })
                })
        }
    },

    signOut(state) {
        return dispatch => {
            dispatch({ type: 'USERS_LOGOUT', payload: state })

            // Delete Tokens from local storage
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')

            // Detele tokens from store
            dispatch({
                type: 'DELETE_TOKENS',
                payload: { refreshToken: '', accessToken: '' },
            })

            // stop the logout timers
            authService.stopLogoutPopupTimer()
            authService.stopLogoutTimer()
        }
    },

    // Refreshes the access token when failed request and then rerun api call with updated access token
    refreshAuthLogic(err) {
        const config = {
            requestBody: { refresh: authService.getRawRefreshToken() },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }

        return err =>
            axios
                .post(
                    `${window._env_.REACT_APP_API_URL}/login/token/refresh/`,
                    config.requestBody,
                    config.headers
                )
                .then(res => {
                    const refreshToken =
                        res.data.refresh || localStorage.getItem('refresh')
                    // update the store
                    store.dispatch({
                        type: 'REFRESH_ACCESS_TOKEN',
                        payload: {
                            refreshToken: refreshToken,
                            accessToken: res.data.access,
                        },
                    })

                    // update local storage
                    localStorage.setItem('access', res.data.access)
                    localStorage.setItem('refresh', refreshToken)

                    // set axios header with new token
                    axios.defaults.headers.common['Authorization'] =
                        'JWT ' + res.data.access

                    return Promise.resolve()
                })
    },

    checkAxiosAuthHeaderAndRefreshIfNull() {
        initService.createAuthRefreshInterceptor()
        initService.resetTokensOnBrowserRefresh()
    },

    // set the tokens in the store and axios header
    setTokensInStore(acc, ref) {
        // set to store
        store.dispatch({
            type: 'SET_TOKENS',
            payload: { refreshToken: ref, accessToken: acc },
        })

        // set default axios token header
        axios.defaults.headers.common['Authorization'] = 'JWT ' + acc
    },

    // auto logout and redirect based on
    autoLogout() {
        const refreshTokenData = this.getRefreshToken()
        const accessTokenData = this.getAccessToken()
        var currentTime = Math.round(new Date().getTime() / 1000)

        // compare token times and refresh token with current time, if access token or current time excede refresh tokens expiration then log out
        if (
            refreshTokenData.exp < accessTokenData.exp - 300 ||
            refreshTokenData.exp < currentTime
        ) {
            authService.logOut()
            return null
        }
    },

    // removes logout tokens AND redirects, mostly used for being called from non react components
    logOut() {
        store.dispatch(authService.signOut({}))
        window.location.replace('/logout')
    },

    // starts and restarts the lougout timer process
    resetLogoutTimer() {
        authService.stopLogoutPopupTimer()

        window.queLogoutPopup = setTimeout(() => {
            store.dispatch({
                type: 'OPEN_MODAL',
                payload: {},
            })

            authService.stopLogoutPopupTimer()

            window.logoutCountdown = setTimeout(
                authService.logOut,
                `${window._env_.REACT_APP_LOGOUT_TIMER}`
            )
        }, `${window._env_.REACT_APP_LOGOUT_POPUP_TIMER}`)
    },

    stopLogoutPopupTimer() {
        clearTimeout(window.queLogoutPopup)
    },

    stopLogoutTimer() {
        clearTimeout(window.logoutCountdown)
    },

    logoutPopupConfirmation() {
        store.dispatch({ type: 'CLOSE_MODAL', payload: {} })
        authService.stopLogoutTimer()
        authService.resetLogoutTimer()
    },

    // Parses the JWT token into a JSON object
    parseJwt(token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace('-', '+').replace('_', '/')
        return JSON.parse(window.atob(base64))
    },

    // Gets the encrypted token from local storage
    getRawToken(tokenType) {
        return localStorage.getItem(tokenType)
    },

    // Gets the encrypted refresh token
    getRawRefreshToken() {
        return authService.getRawToken('refresh')
    },

    // Gets the encrypted access token
    getRawAccessToken() {
        return authService.getRawToken('access')
    },

    // Gets the encrypted refresh JSON
    getRefreshToken() {
        let token = authService.getRawRefreshToken()
        return token ? authService.parseJwt(token) : false
    },

    // Gets the encrypted access JSON
    getAccessToken() {
        let token = authService.getRawAccessToken()
        return token ? authService.parseJwt(token) : false
    },

    // Gets the username() {
    getUserName() {
        let tokenData = this.getRefreshToken()
        if (!tokenData.userInfo) {
            return false
        }
        return tokenData.userInfo.username
    },

    // Gets the full name of user {
    getFullName() {
        let tokenData = this.getRefreshToken()
        return tokenData.userInfo && tokenData.userInfo.full_name
            ? tokenData.userInfo.full_name
            : false
    },

    getIsLegacyAgent() {
        let tokenData = this.getRefreshToken()
        return tokenData.userInfo && tokenData.userInfo.isLegacyAgent
            ? tokenData.userInfo.isLegacyAgent
            : false
    },

    getPrimaryAgentNumber() {
        let tokenData = this.getRefreshToken()
        return tokenData.userInfo && tokenData.userInfo.primaryAgentNumber
            ? tokenData.userInfo.primaryAgentNumber
            : null
    },

    // Get a user group
    userHasGroup(groupName) {
        let token = this.getRefreshToken()
        if (!token.userInfo) return false
        let userGroup = token.userInfo.groups.find(group => {
            return group.name === groupName
        })
        return userGroup !== undefined
    },

    // handles auto redirect on login
    redirectByUserGroups() {
        var destination = ''
        let token = this.getRefreshToken()
        if (!token.userInfo) return destination
        let groups = token.userInfo.groups.map((group, i) => {
            return group.name
        })

        if (groups.indexOf('annuity-home-office') > -1) {
            destination = '/agent'
        } else if (groups.indexOf('annuity-client') > -1) {
            destination = '/client'
        } else if (groups.indexOf('annuity-agent') > -1) {
            destination = '/agent'
        }

        return destination
    },

    getUserGroups() {
        const token = this.getRefreshToken()
        if (!token.userInfo) return []
        return token.userInfo.groups.map((group, i) => group.name)
    },

    isAgency() {
        const userGroups = this.getUserGroups()
        return userGroups.some(
            x => x === 'annuity-agent' || x === 'annuity-home-office'
        )
    },

    isClient() {
        const userGroups = this.getUserGroups()
        return userGroups.some(x => x === 'annuity-client')
    },

    isAgent() {
        let token = this.getRefreshToken()
        if (!token.userInfo) return false
        let groups = token.userInfo.groups.map((group, i) => {
            return group.name
        })

        return groups.indexOf('annuity-agent') > -1
    },

    // handles request to get a password email reset sent
    getResetEmail(state) {
        return dispatch => {
            // redux store requsting login state

            // configs to make auth request
            let connect = {
                url: `${window._env_.REACT_APP_API_URL}/login/password_reset/`,
                body: JSON.stringify({ email: state.email }),
                headers: {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'none',
                    },
                },
            }

            // make the request
            axios
                .post(connect.url, connect.body, connect.headers)
                .then(response => {
                    if (!response.data) {
                    } else {
                        dispatch({ type: 'USERS_PASSWORD_RESET_REQUEST', payload: state })
                    }
                })
                .catch(error => {
                    // redux store failure login state
                    dispatch({ type: 'USERS_LOGIN_FAILURE', payload: state })
                })
        }
    },

    getAgentMembership() {
        const token = this.getRefreshToken()
        if (!token.userInfo) return null
        return token.userInfo.agentMembership
    },
}

export default authService
