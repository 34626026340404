import { request } from '../_contexts'
import { TAction, INotification } from '../_contexts/types'
import { EAction, ESeverity } from '../_enums'
import { IContractingRecruit } from '../components/agent-components/agent-contracting/types'

export interface IPostContractingRecruitAction {
  payload: object
  notification: INotification
}

export interface IGetDownlineInvitesAction {
  payload: {
    data?: IContractingRecruit[]
    count: Number
    num_pages: Number
    page_number: Number
  }
  notification: INotification
}

export const postContractingRecruit = (
  agentNumber: string,
  recruit: IContractingRecruit
): TAction<IPostContractingRecruitAction> => {
  return async () => {
    const { status, data } = await request({
      method: 'post',
      uri: `/agents/${agentNumber}/recruiting`,
      body: recruit,
    })

    return {
      type: EAction.PostContractingRecruit,
      payload: {},
      notification:
        200 <= status && status < 300
          ? {
              status: ESeverity.Success,
              message: 'Invitation sent successfully',
            }
          : {
              status: ESeverity.Error,
              message: `Failed to send invitation: ${data}`,
            },
    }
  }
}

export const getDownlineInvites = (
  agentNumber: string,
  take: number = 10,
  page_number: number = 1,
  order_by: string = ''
): TAction<IGetDownlineInvitesAction> => {
  return async () => {
    const { data, status } = await request({
      method: 'get',
      uri: `/agents/${agentNumber}/downline_invites?take=${take}&page_number=${page_number}${
        order_by !== '' ? `&order_by=${order_by}` : ''
      }`,
      body: { take: take, page_number: page_number, order_by: order_by },
    })

    return {
      type: EAction.GetDownlineInvites,
      payload: {
        data: data?.data,
        count: data?.count,
        num_pages: data?.num_pages,
        page_number: data?.page_number,
      },
      notification:
        200 <= status && status < 300
          ? {
              status: ESeverity.Success,
              message: 'Data Retrieved successfully',
            }
          : {
              status: ESeverity.Error,
              message: 'Failed to retrieve data',
            },
    }
  }
}
