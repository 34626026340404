import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input, FormGroup } from 'reactstrap'

import { StyledErrorMessage } from './StyledErrorMessage'

const StyledCheckbox = ({ field, label, ...props }) => {
  const { name, value, ...restField } = field
  return (
    <Input
      type="checkbox"
      id={name}
      label={label}
      checked={value}
      {...restField}
      {...props}
    />
  )
}

StyledCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string.isRequired,
}

export const Checkbox = props => {
  const { disabled, readonly, label, name, validationMessage } = props

  return (
    <FormGroup check>
      <Field
        name={name}
        component={StyledCheckbox}
        disabled={disabled}
        readOnly={readonly}
        label={label}
      />
      {validationMessage ? <StyledErrorMessage name={name} /> : null}
    </FormGroup>
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  validationMessage: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  disabled: false,
  readonly: false,
  validationMessage: true,
}

export default Checkbox
