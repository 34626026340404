if (process.env.REACT_APP_ADD_ENVS_TO_CODE) {
  window._env_ = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_APP_LOGOUT_POPUP_TIMER: process.env.REACT_APP_LOGOUT_POPUP_TIMER,
    REACT_APP_LOGOUT_TIMER: process.env.REACT_APP_LOGOUT_TIMER,
    REACT_APP_FIRELIGHT_SSO_URL: process.env.REACT_APP_FIRELIGHT_SSO_URL,
    REACT_APP_SENTRY_SAMPLE_RATE: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
    REACT_APP_SENTRY_ENABLED: process.env.REACT_APP_SENTRY_ENABLED,
    REACT_APP_SENTRY_DEBUG: process.env.REACT_APP_SENTRY_DEBUG,
    REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
    REACT_APP_VERSION: process.env.REACT_APP_VERSION,
    REACT_APP_CHAT_UUID: process.env.REACT_APP_CHAT_UUID,
    REACT_APP_CHAT_TENANT: process.env.REACT_APP_CHAT_TENANT,
    REACT_APP_CHAT_CHANNEL: process.env.REACT_APP_CHAT_CHANNEL,
    REACT_APP_CHAT_DOMAIN: process.env.REACT_APP_CHAT_DOMAIN,
    REACT_APP_CHAT_PATH: process.env.REACT_APP_CHAT_PATH,
  }
}
