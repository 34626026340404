import moment from 'moment'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { Table } from 'reactstrap'

export const yearsFilter = (year, issueDate, value) =>
  (year === issueDate && year <= value.action_date) ||
  (year !== issueDate && year < value.action_date)

function InterestBreakdown({ policyValues, isMyga }) {
  const defaultTitle = isMyga ? 'Total Interest' : 'Before Issuance'
  const [selectedYear, setSelectedYear] = useState(null)
  const [interest, setInterest] = useState(null)

  useEffect(() => {
    function selectYear(years, value) {
      const issueDate = years[years.length - 1]
      const filteredYears = years.filter(year =>
        yearsFilter(year, issueDate, value)
      )
      const year = filteredYears.length > 0 ? filteredYears[0] : defaultTitle
      return year
    }

    const allocations = policyValues.filter(
      value => value.type === 'A' && Number.parseFloat(value.amount) > 0.0
    )
    const allocationsByYear = allocations.reduce((map, value) => {
      if (!(value.action_date in map)) {
        map[value.action_date] = {}
      }
      if (!(value.strategy_code in map[value.action_date])) {
        map[value.action_date][value.strategy_code] = {
          name: value.strategy_name,
          allocated: 0.0,
          interestEarned: 0.0,
        }
      }
      map[value.action_date][value.strategy_code].allocated +=
        Number.parseFloat(value.amount)
      return map
    }, {})

    const years = Object.keys(allocationsByYear).sort((a, b) =>
      a < b ? 1 : -1
    )
    setSelectedYear(years[years.length - 1])
    allocationsByYear[defaultTitle] = {
      FI: { name: 'Fixed Interest', allocated: 0.0, interestEarned: 0.0 },
    }
    if (!selectedYear) setSelectedYear(defaultTitle)

    const premiums = policyValues.filter(value => value.type === 'P')
    premiums.forEach(value => {
      const year = selectYear(years, value)
      if (!(value.strategy_code in allocationsByYear[year])) {
        allocationsByYear[year][value.strategy_code] = {
          name: value.strategy_name,
          allocated: 0.0,
          interestEarned: 0.0,
        }
      }
      allocationsByYear[year][value.strategy_code].allocated +=
        Number.parseFloat(value.amount)
    })

    const credits = policyValues.filter(value => value.type === 'C')
    credits.forEach(value => {
      const year = selectYear(years, value)
      if (value.strategy_code in allocationsByYear[year]) {
        allocationsByYear[year][value.strategy_code].interestEarned +=
          Number.parseFloat(value.amount)
      }
    })
    setInterest(allocationsByYear)
    // NOTE: this 'useEffect' function is only called on mount.
    // if this changes in the future, remove the eslint disable below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '20px' }}>
      {Boolean(interest) && (
        <>
          <select
            className="form-control"
            id="yearSelector"
            value={selectedYear}
            onChange={e => {
              setSelectedYear(e.target.value)
            }}
          >
            {Object.keys(interest).map(year => (
              <option key={year} value={year}>
                {!isNaN(Date.parse(year))
                  ? `Year starting ${moment(year).format('L')}`
                  : year}
              </option>
            ))}
          </select>
          <br />
          <Table borderless size="sm">
            <thead>
              <tr>
                <th>Strategy</th>
                <th>Amount Allocated</th>
                <th>Interest Earned</th>
                <th>Percent Yield</th>
              </tr>
            </thead>
            <tbody>
              {!!interest[selectedYear] &&
                Object.entries(interest[selectedYear]).map(
                  ([key, { name, allocated, interestEarned }], index) => (
                    <tr key={key}>
                      <td>{name}</td>
                      <td>{numeral(allocated).format('$0,0.00')}</td>
                      <td>{numeral(interestEarned).format('$0,0.00')}</td>
                      <td>
                        {numeral(
                          allocated > 0 ? interestEarned / allocated : 0.0
                        ).format('0,0.00%')}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}

export default InterestBreakdown
