import React from 'react'
import { ListGroup } from 'reactstrap'

import AgentHomeNewsItem from './AgentHomeNewsItem'

const AgentHomeNewsList = ({ newsItems }) => (
  <>
    {newsItems.length === 0 ? (
      <div>
        <p>No account information available</p>
      </div>
    ) : (
      <ListGroup>
        {newsItems.map(newsItem => {
          return <AgentHomeNewsItem key={newsItem.id} {...newsItem} />
        })}
      </ListGroup>
    )}
  </>
)

export default AgentHomeNewsList
