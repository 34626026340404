import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Box, Pagination, useTheme } from '@mui/material'
import axios from 'axios'
import esb from 'elastic-builder'
import moment from 'moment'
import { FC, useCallback, useEffect, useReducer, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedTextInput from 'react-text-mask'

import { legacyAgentMessage } from '..'
import announcementService from '../../../_services/announcementService'
import authService from '../../../_services/authService'
import { store } from '../../../_store/store'
import FadeModal from '../../layout/FadeModal'
import ModalSpinner from '../../layout/ModalSpinner'
import ColumnHeader from './ColumnHeader'
import PagesDisplayedMsg from './PagesDisplayedMsg'
import PolicyList from './PolicyList'

const PolicySearch: FC<any> = props => {
  const theme = useTheme()
  const reducer = (state, payload) => ({ ...state, ...payload })
  const isLegacyAgent = authService.getIsLegacyAgent()
  const primaryAgentNumber = authService.getPrimaryAgentNumber()

  const [data, setData] = useState({
    policyData: {},
    perPage: 10,
    offset: 0,
    activePage: 1,
    totalRecords: 0,
    sortBy: 'application.received_date',
    sortDirection: 'desc',
    filterBy: '',
    loaded: false,
    legacyAgent: {
      open: isLegacyAgent,
    },
  })
  const [startDates, setStartDates] = useReducer(reducer, {
    startDate: new Date('1/1/2010'),
    lastGoodStartDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  })
  const [endDates, setEndDates] = useReducer(reducer, {
    endDate: new Date(),
    lastGoodEndDate: new Date(),
  })
  const [selectedAgreementId, setSelectedAgreementId] = useState(null)

  const {
    activePage,
    filterBy,
    offset,
    perPage,
    sortBy,
    sortDirection,
    loaded,
    policyData,
    totalRecords,
  } = data

  const [agentInfo, setAgentInfo] = useState(
    store.getState().agentInfo
      ? store.getState().agentInfo.agentInfo
      : undefined
  )

  useEffect(() => {
    const unsubscribeAI = store.subscribe(() => {
      const storeAgentInfo = store.getState().agentInfo
        ? store.getState().agentInfo.agentInfo
        : undefined
      setAgentInfo(storeAgentInfo)
    })

    return () => {
      unsubscribeAI()
    }
  }, [])

  useEffect(() => {
    if (!startDates.startDate) {
      setStartDates({ startDate: startDates.lastGoodStartDate })
    }
    if (!endDates.endDate) {
      setEndDates({ endDate: endDates.lastGoodEndDate })
    }
  }, [
    startDates.startDate,
    endDates.endDate,
    endDates.lastGoodEndDate,
    startDates.lastGoodStartDate,
  ])

  const buildElasticSearchJson = useCallback(() => {
    const body = esb.requestBodySearch()
    const boolquery = esb.boolQuery()

    // filter criteria
    if (filterBy.length > 0) {
      boolquery.should([
        esb
          .queryStringQuery(`*${filterBy}*`)
          .fields([
            'application.policy_number',
            'annuitant.first_name',
            'annuitant.last_name',
          ]),
        esb
          .nestedQuery()
          .path('agents')
          .query(
            esb
              .boolQuery()
              .must([
                esb.matchPhrasePrefixQuery('agents.agent_code', `${filterBy}`),
                esb.termQuery('agents.type', 'Primary'),
              ])
          ),
      ])
    }

    // filter out statusses
    let statusFilter = ['DEL']

    if (statusFilter.length > 0) {
      boolquery.mustNot(esb.termsQuery('application.status', statusFilter))
    }

    // date range
    boolquery.must([
      // ! NOTE: .timzone method caused issue where elasticsearch returned the wrong date
      esb
        .rangeQuery('application.received_date')
        .lte(parseDate(endDates.endDate))
        .gte(parseDate(startDates.startDate)),
    ])

    body.query(esb.constantScoreQuery(boolquery))

    // sorting
    let sorts = [esb.sort(sortBy, sortDirection)]

    sorts.push(esb.sort('application.created_at', 'desc'))

    // sort, results per page, offset start
    body.sorts(sorts).size(perPage).from(offset)

    return body.toJSON()
  }, [
    endDates.endDate,
    filterBy,
    offset,
    perPage,
    sortBy,
    sortDirection,
    startDates.startDate,
  ])

  const queryAPI = useCallback(
    cancelSource => {
      if (
        !isValidDate(startDates.startDate) ||
        !isValidDate(endDates.endDate)
      ) {
        return
      }

      const elasticSearchPayload = buildElasticSearchJson()
      let userName = authService.getUserName()
      const selectedAgreement = selectedAgreementId
        ? `?agreement=${selectedAgreementId}`
        : ''
      const config = {
        url: `${window._env_.REACT_APP_API_URL}/intake/search/${userName}${selectedAgreement}`,
        data: elasticSearchPayload,
      }

      setData(d => ({ ...d, loaded: false }))
      axios
        .post(config.url, config.data, { cancelToken: cancelSource.token })
        .then(response => {
          let totalRecordsVar = 0
          if (typeof response.data.detail.total !== 'undefined') {
            totalRecordsVar = response.data.detail.total
          }

          setData(d => ({
            ...d,
            loaded: true,
            policyData: response.data,
            totalRecords: totalRecordsVar,
          }))
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            return
          }
          setData(d => ({ ...d, loaded: true }))
          console.log(error)
        })
    },
    [
      buildElasticSearchJson,
      endDates.endDate,
      selectedAgreementId,
      startDates.startDate,
    ]
  )

  useEffect(() => {
    const saveToStorage = () => {
      window.sessionStorage.setItem('filterBy', filterBy)
      window.sessionStorage.setItem('perPage', String(perPage))
    }

    const cancelSource = axios.CancelToken.source()
    queryAPI(cancelSource)
    saveToStorage()
    return () => cancelSource.cancel('useEffect cleaned up')
  }, [
    activePage,
    filterBy,
    offset,
    perPage,
    sortBy,
    sortDirection,
    startDates.startDate,
    endDates.endDate,
    selectedAgreementId,
    queryAPI,
  ])

  const parseDate = date => {
    const parseDay = date.getDate(),
      parseMonth = date.getMonth(),
      parseYear = date.getFullYear()
    // ! NOTE: Date.UTC needed to retrieve the correct date from elasticsearch

    return new Date(
      Date.UTC(parseYear, parseMonth, parseDay, 0, 0, 0, 0)
    ).toISOString()
  }

  const isValidDate = (date: Date): boolean =>
    !!date && typeof date === 'object' && !isNaN(date.getTime())

  const allowAnnouncement = () => {
    const viewed =
      !!store.getState().announcements['legacyAgent'] &&
      store.getState().announcements['legacyAgent'].viewed
    const acknowledged = announcementService.isAcknowledged('legacyAgent')
    return !(viewed || acknowledged)
  }

  const showAnnouncement = () => {
    announcementService.viewed(false, 'legacyAgent')
    setData({
      ...data,
      legacyAgent: { ...data.legacyAgent, ...{ open: true } },
    })
  }

  const closeAnnouncement = (confirmed = false) => {
    announcementService.viewed(true, 'legacyAgent')

    const acknowledged = typeof confirmed === 'boolean' ? confirmed : false

    if (acknowledged) {
      announcementService.acknowledged(true, 'legacyAgent')
    }

    setData({
      ...data,
      legacyAgent: { ...data.legacyAgent, ...{ open: false } },
    })
  }

  // resets the state when per page selectbox option changed
  const handleRowsPerPageChange = e => {
    let perPage = e.target.value,
      offset = 0,
      activePage = 1
    setData({ ...data, perPage, offset, activePage })
  }

  const handlePaginationPageChange = (_, pageNumber) => {
    setData({
      ...data,
      offset: (pageNumber - 1) * perPage,
      activePage: pageNumber,
    })
  }

  const handleFilterChange = e => {
    e.preventDefault()
    let filter = e.target.value

    if (e.keyCode !== 13 && e.type === 'keyup') {
      return null
    }

    setData({ ...data, filterBy: filter.trim(), offset: 0, activePage: 1 })
  }

  const handleSortClick = field => {
    let newSortDirection =
      field === sortBy ? (sortDirection === 'desc' ? 'asc' : 'desc') : 'desc'
    setData({ ...data, sortBy: field, sortDirection: newSortDirection })
  }

  const handleStartDateChange = newStartDate => {
    const lastDate = isValidDate(newStartDate)
      ? newStartDate
      : startDates.lastGoodStartDate
    setStartDates({ startDate: newStartDate, lastGoodStartDate: lastDate })
  }

  const handleStartDateBlur = () => {
    const newDate = isValidDate(startDates.startDate)
      ? startDates.startDate
      : startDates.lastGoodStartDate
    setStartDates(newDate)
  }

  const handleEndDateChange = newEndDate => {
    const lastDate = isValidDate(newEndDate)
      ? newEndDate
      : endDates.lastGoodEndDate
    setEndDates({ endDate: newEndDate, lastGoodEndDate: lastDate })
  }

  const handleEndDateBlur = () => {
    const newDate = isValidDate(endDates.endDate)
      ? endDates.endDate
      : endDates.lastGoodEndDate
    setEndDates({ endDate: newDate })
  }

  const handleAgreementChange = selectedAgreementOption => {
    const agreementId =
      selectedAgreementOption.target.value === '0'
        ? null
        : selectedAgreementOption.target.value
    setSelectedAgreementId(agreementId)
  }

  return (
    <div className="mr-3">
      {allowAnnouncement() && (
        <FadeModal
          open={data.legacyAgent.open}
          title="Legacy Agent Number"
          jsxMessage={legacyAgentMessage(primaryAgentNumber)}
          handleClose={() => closeAnnouncement(false)}
        />
      )}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            My Business / Policy Search
          </li>
        </ol>
      </nav>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div
                className="form-group mr-2"
                style={{ display: 'inline-block' }}
              >
                <input
                  className="form-control"
                  autoComplete="off"
                  id="policySearchFilter"
                  placeholder="Search"
                  onBlur={handleFilterChange}
                  onKeyUp={handleFilterChange}
                  style={{ width: '300px' }}
                />
              </div>
              <div
                className="form-group mr-2"
                style={{ display: 'inline-block', paddingRight: '0px' }}
              >
                <select
                  className="form-control ng-untouched ng-pristine ng-valid"
                  onChange={handleRowsPerPageChange}
                  id="limitSelect"
                  style={{ width: '133px' }}
                >
                  <option className="ng-tns-c2-1" value="10">
                    10 per Page
                  </option>
                  <option className="ng-tns-c2-1" value="15">
                    15 per Page
                  </option>
                  <option className="ng-tns-c2-1" value="25">
                    25 per Page
                  </option>
                  <option className="ng-tns-c2-1" value="50">
                    50 per Page
                  </option>
                </select>
              </div>
              <div
                className="form-group datePickerGroup"
                style={{ display: 'inline-block' }}
              >
                <div className="date-start">
                  <DatePicker
                    id="startDate"
                    selected={startDates.startDate}
                    startDate={startDates.startDate}
                    onChange={date => handleStartDateChange(date)}
                    onBlur={() => {
                      return handleStartDateBlur()
                    }}
                    shouldCloseOnSelect={true}
                    className="form-control"
                    size="10"
                    maxlength="10"
                    strictParsing
                    customInput={
                      <MaskedTextInput
                        type="text"
                        mask={[
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    }
                  />
                  <label htmlFor="startDate">
                    <FontAwesomeIcon
                      icon={'calendar'}
                      color={theme.palette.secondary.main}
                      size="1x"
                    />
                  </label>
                </div>
                <FontAwesomeIcon
                  icon={'long-arrow-alt-right'}
                  color={theme.palette.secondary.main}
                  size="1x"
                  style={{ margin: '0 5px 0 5px', zoom: '1.5' }}
                />
                <div className="date-end">
                  <DatePicker
                    id="endDate"
                    selected={endDates.endDate}
                    onChange={date => handleEndDateChange(date)}
                    onBlur={() => {
                      return handleEndDateBlur()
                    }}
                    endDate={endDates.endDate}
                    minDate={startDates.startDate}
                    className="form-control"
                    customInput={
                      <MaskedTextInput
                        type="text"
                        mask={[
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    }
                  />
                  <label htmlFor="endDate">
                    <FontAwesomeIcon
                      icon={'calendar'}
                      color={theme.palette.secondary.main}
                      size="1x"
                    />
                  </label>
                </div>
              </div>
              {false && (
                <div
                  className="form-group mr2"
                  style={{ display: 'inline-block' }}
                >
                  <select
                    id="agreementSelector"
                    className="form-control"
                    onChange={handleAgreementChange}
                  >
                    <option key="activeagreement" value="0">
                      All Agreements
                    </option>
                    {agentInfo?.agreements.map(ag => (
                      <option key={ag.id} value={ag.id}>
                        {`${
                          ag.startDate ? moment(ag.startDate).format('L') : ''
                        } - 
                        ${
                          ag.endDate
                            ? moment(ag.endDate).format('L')
                            : 'current'
                        }
                        ${
                          ag.legacyAgentNumber
                            ? ' | ' + ag.legacyAgentNumber.toString()
                            : ''
                        }
                      `}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {isLegacyAgent ? (
                <div className="form-group" style={{ display: 'inline-block' }}>
                  <Alert
                    severity="error"
                    variant="outlined"
                    onClick={showAnnouncement}
                    style={{ border: 'none' }}
                  >
                    {primaryAgentNumber}
                  </Alert>
                </div>
              ) : null}
            </div>
          </div>
          <div className="table-responsive table-hover ng-tns-c2-1">
            <ModalSpinner active={!loaded}>
              <table className="table">
                <thead className="ng-tns-c2-1">
                  <tr>
                    <ColumnHeader
                      displayName="Policy #"
                      field="application.policy_number"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="First Name"
                      field="annuitant.first_name"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Last Name"
                      field="annuitant.last_name"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Agent #"
                      field="agents.agent_code"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Product"
                      field="application.product"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Tax Status"
                      field="application.tax_qualification_status"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Status"
                      field="application.status"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <ColumnHeader
                      displayName="Date Received"
                      field="application.received_date"
                      properties={props}
                      handleSortClick={handleSortClick}
                    />
                    <th className="ng-tns-c2-1"> </th>
                  </tr>
                </thead>
                <tbody className="ng-tns-c2-1">
                  <PolicyList data={policyData} records={totalRecords} />
                </tbody>
              </table>
            </ModalSpinner>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <PagesDisplayedMsg
            perPage={perPage}
            offset={offset}
            totalRecords={totalRecords}
          />
        </div>
        {loaded && (
          <Box
            className="col"
            marginTop={2}
            sx={{
              ul: {
                justifyContent: 'flex-end',
              },
            }}
          >
            <Pagination
              shape="rounded"
              variant="outlined"
              color="primary"
              size="large"
              page={activePage}
              count={Math.ceil(totalRecords / perPage)}
              onChange={handlePaginationPageChange}
            />
          </Box>
        )}
      </div>
    </div>
  )
}

PolicySearch.displayName = 'PolicySearch'

export default PolicySearch
