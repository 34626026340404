import { IGetActionNotesAction } from '../_actions/ActionNotes.actions'
import { createReducer } from '../_contexts'
import { TReducer } from '../_contexts/types'
import EAction from '../_enums/EAction'

const handleGetActionNotes: TReducer<IGetActionNotesAction> = (
  store,
  { payload }
) => {
  return {
    ...store,
    common: {
      ...store.common,
      notification: undefined,
    },
    action_notes: {
      ...store.action_notes,
      action_notes: payload.data || [],
    },
  }
}

export default createReducer({
  [EAction.GetActionNotes]: handleGetActionNotes,
})
