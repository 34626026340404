import { useMemo } from 'react'

export default function useChartData(data) {
  const datasets = useMemo(() => {
    return data?.datasets.map(x => ({
      label: x.label,
      data: x.data,
      borderColor: x.borderColor,
      backgroundColor: x.backgroundColor,
    }))
  }, [data])

  return datasets
}
