/* eslint-disable no-script-url */
import React from 'react'
import { IIncomeValueDetails } from '../types'
import {
  Grid,
  Typography
} from '@mui/material'

import numeral from 'numeral'

type Props = {
  data?: IIncomeValueDetails | null
}

const convertToPositive = (value: number | undefined) => {
  if (value === undefined) {
    return 0
  }
  return value < 0 ? value * -1 : value
}

const IncomeValueDetails = ({ data }: Props) => {

  return (
    <Grid container justifyContent="center" mb={2}>
      <Grid item xs={10}>
        <Grid container rowSpacing={1.5}>
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "lightgrey" }} />
          {/*row*/}
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.premiums)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Premiums</Grid>
          {/*row*/}
          <Grid item xs={1}>+</Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.premium_bonus)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Premium Bonus</Grid>
          {/*row*/}
          <Grid item xs={1}>+</Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.interim_fixed_interest)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Interim Fixed Interest</Grid>
          {/*row*/}
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "lightgrey" }} />
          {/*row*/}
          <Grid item xs={1}> </Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.initial_income_value)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Initial Income Value</Grid>
          {/*row*/}
          <Grid item xs={1}>+</Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.income_credit)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Income Credit</Grid>
          {/*row*/}
          <Grid item xs={1}>-</Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.withdrawals)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Withdrawals*</Grid>
          {/*row*/}
          <Grid item xs={1}>+</Grid>
          <Grid item xs={3}>
            {numeral(convertToPositive(data?.withdrawal_adjustments)).format('$0,0.00')}
          </Grid>
          <Grid item xs={8}>Withdrawal Adjustments*</Grid>
          {/*row*/}
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "lightgrey" }} />
          {/*row*/}
          <Grid item xs={12} textAlign="right">
            Income Value = {numeral(convertToPositive(data?.total)).format('$0,0.00')}
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Typography variant={"caption"}>*Each Withdrawal will decrease the Income Value pro-rata</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  )
}

export default IncomeValueDetails
