export enum EFundsType {
  Qualified = 'qualified',
  NonQualified = 'nonQualified',
}

export class FundTypes {
  static readonly Qualified = new FundTypes('Qualified', EFundsType.Qualified)
  static readonly NonQualified = new FundTypes(
    'Non Qualified',
    EFundsType.NonQualified
  )

  private constructor(
    public readonly name: string,
    public readonly code: string
  ) {}

  toString() {
    return this.name
  }
}
