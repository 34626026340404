import {
  CardActionArea,
  CardContent,
  CardMedia,
  Card,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import illustrationSoftware from '../../assets/images/sales-tools/illustration-software.png'
import indices from '../../assets/images/sales-tools/indices.png'
import renewalsCalculator from '../../assets/images/sales-tools/renewals-calculator.png'
import secureSavingsCalculator from '../../assets/images/sales-tools/secure-savings-calculator.png'
import './SalesTools.css'

interface IItem {
  title: string
  image: string
  link: string
}

export const ITEMS: IItem[] = [
  {
    title: 'Illustration Software',
    link: '/agent/sales-tools/illustration',
    image: illustrationSoftware,
  },
  {
    title: 'Indices',
    link: '/agent/sales-tools/indices',
    image: indices,
  },
  {
    title: 'Secure Savings Calculator',
    link: '/agent/sales-tools/secure-savings-calculator',
    image: secureSavingsCalculator,
  },
  {
    title: 'Renewals Calculator',
    link: '/agent/sales-tools/renewals-calculator',
    image: renewalsCalculator,
  },
]

const Item: React.FC<IItem> = ({ title, image, link }) => {
  return (
    <Grid item xl={3} lg={4} md={6} sm={12}>
      <Link about={title} to={link}>
        <Card sx={{ height: '100%' }} className="sales-tools__item">
          <CardActionArea
            sx={{
              padding: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <CardMedia component="img" image={image} alt={title} />
            <CardContent>
              <Typography
                marginTop={2}
                textAlign="center"
                variant="h6"
                component="h3"
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  )
}

const SalesTools: React.FC = () => {
  return (
    <>
      <h1 className="sales-tools__title">
        <span>Sales</span> Tools
      </h1>
      <Grid container spacing={4}>
        {ITEMS.map(item => (
          <Item {...item} key={item.title} />
        ))}
      </Grid>
    </>
  )
}

export default SalesTools
