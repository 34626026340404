import { Box } from '@mui/material'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

const NonGuaranteedAgregatePolicyValuesComparison = ({ data }) => {
  const _data = useMemo(() => {
    if (!data) return null
    const res = { ...data }
    res.labels = res.labels.map(x => x.replace('Point-to-Point', 'P2P'))
    return res
  }, [data])

  if (!_data) return null
  return (
    <Box width="100%">
      <Bar
        options={{
          indexAxis: 'y',
          legend: { display: false },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return ` ${tooltipItem.xLabel}%`
              },
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, values) {
                    return value + '%'
                  },
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        height={600}
        width={400}
        data={_data}
      />
    </Box>
  )
}

export default NonGuaranteedAgregatePolicyValuesComparison
