import LockIcon from '@mui/icons-material/Lock'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Forbidden: React.FC = () => {
  return (
    <>
      <Box
        marginTop={'20vh'}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LockIcon fontSize="large" />
        <Typography marginLeft={2} component="h1" variant="h5">
          401 Forbidden
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography marginLeft={2} variant="subtitle1">
          It seems like you don't have permissions to view this page. Please
          check and try again.
        </Typography>
      </Box>
    </>
  )
}

export default Forbidden
