import axios from 'axios'

import { store } from '../_store/store'
import authService from './authService'

// contains services to be run when the application is initialized
export default function init() {
  var f
  for (f in initService) {
    initService[f]()
  }
}

export const initService = {
  setDefaultAxiosConfigs() {},
  // Sets and interceptor for axios ( that contains the Access Token Refresh)
  createAuthRefreshInterceptor() {
    const id = axios.interceptors.response.use(
      // interceptor for good statuses
      res => {
        // need to check the refresh token expiration
        authService.autoLogout()

        return res
      },

      // interceptor for error statuses
      error => {
        // Check for Refresh Tokens expiration date, if after exp date just log out
        authService.autoLogout()

        // Reject promise if the error status is not in options.ports or defaults.ports
        const statusCodes = [403] // 401, 403
        if (
          !error.response ||
          (error.response.status &&
            statusCodes.indexOf(+error.response.status) === -1)
        ) {
          return Promise.reject(error)
        }

        // Remove the interceptor to prevent a loop
        // in case token refresh also causes the 401
        axios.interceptors.response.eject(id)

        // Token refresh logic
        const refreshTokenCall = authService.refreshAuthLogic()
        const refreshCall = refreshTokenCall(error)

        // Create interceptor that will bind all the others requests
        // until refreshTokenCall is resolved
        const requestQueueInterceptorId = axios.interceptors.request.use(
          request =>
            refreshCall.then(() => {
              return request
            })
        )

        // When response code is 401 (Unauthorized), try to refresh the token.
        return refreshCall
          .then(() => {
            axios.interceptors.request.eject(requestQueueInterceptorId)

            // set the original requests headers with the new auth tokens as it cannot use the newly set axios global header yet
            const currentToken = store.getState().authTokens.accessToken
            error.response.config.headers.Authorization = 'JWT ' + currentToken
            error.response.headers.Authorization = 'JWT ' + currentToken

            return axios(error.response.config)
          })
          .catch(error => {
            axios.interceptors.request.eject(requestQueueInterceptorId)
            return Promise.reject(error)
          })
          .finally(() => this.createAuthRefreshInterceptor())
      }
    )

    return axios
  },

  // reset axios authorization header and redux state when brower refreshed
  resetTokensOnBrowserRefresh() {
    let accessToken = authService.getRawAccessToken()
    let refreshToken = authService.getRawRefreshToken()

    if (
      'null' !== authService.getRawAccessToken() &&
      'null' !== authService.getRawRefreshToken()
    ) {
      authService.setTokensInStore(accessToken, refreshToken)
    }
  },
}
