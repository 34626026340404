import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { createContext, useContext, useState } from 'react'

import { EMembershipType } from '../_enums'
import { Theme } from '../types'
import { defaultTheme } from './default'
import { emberTheme } from './ember'
import { innerCircleTheme } from './innerCircle'

export const THEMES: {
  [EMembershipType.INNER_CIRCLE]: Theme
  [EMembershipType.EMBER]: Theme
  [EMembershipType.DEFAULT]: Theme
} = {
  [EMembershipType.INNER_CIRCLE]: innerCircleTheme,
  [EMembershipType.EMBER]: emberTheme,
  [EMembershipType.DEFAULT]: defaultTheme,
}

interface IThemeProviderValue {
  themeType: EMembershipType
  theme: Theme
  updatEMembershipType: (themeType: EMembershipType) => void
}

const ThemeContext = createContext<IThemeProviderValue | null>(null)

export function ThemeProvider({
  themeType = EMembershipType.DEFAULT,
  children,
}) {
  const [_themeType, setThemeType] = useState(themeType)
  const theme = createTheme(THEMES[_themeType])

  function updatEMembershipType(themeType: EMembershipType) {
    setThemeType(themeType)
  }

  const value = {
    themeType: _themeType,
    theme: THEMES[_themeType],
    updatEMembershipType,
  }

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  const context = useContext(ThemeContext)
  if (!context)
    throw new Error(
      'useThemeContext should be used inside a ThemeContext provider'
    )
  return context
}
