import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Spinner } from 'reactstrap'

const ModalSpinner = ({ active, children }) => {
  const spinnerId = 'spinner'
  const modalWrapperId = 'modal-wrapper'
  const modalBackgroundId = 'modal-background'

  const wrapper = document.getElementById(modalWrapperId)
  const clientWidth = wrapper ? wrapper.clientWidth : 0
  const clientHeight = wrapper ? wrapper.clientHeight : 0

  const reducer = (state, payload) => ({ ...state, ...payload })
  // spinner position
  const [spinnerPosition, setSpinnerPosition] = useReducer(reducer, {
    top: 0,
    left: 0,
  })

  // background size
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  // initial background position and spinner position offset
  const [offsets, setOffsets] = useState({
    X: 0,
    Y: 0,
    spinnerX: 0,
    spinnerY: 0,
  })

  // window scroll position
  const [scrolling, setScrolling] = useState({
    X: 0,
    Y: 0,
  })

  // utility function that returns the client rect for the specified element, otherwise null
  const getBoundingClientRect = elementId => {
    const element = document.getElementById(elementId)
    return element ? element.getBoundingClientRect() : null
  }

  // persist the initial background position and spinner position offset
  const initOffsets = useCallback(() => {
    const spinner = getBoundingClientRect(spinnerId)
    const background = getBoundingClientRect(modalBackgroundId)
    if (background && spinner) {
      setOffsets(o => ({
        ...o,
        X: background.left - window.scrollX,
        Y: background.top - window.scrollY,
        spinnerX: spinner.width / 2,
        spinnerY: spinner.height / 2,
      }))
    }
  }, [])

  // window resizing handler
  const handleResizing = useCallback(
    e => {
      e.preventDefault()
      setSize(s => ({
        ...s,
        width: clientWidth,
        height: clientHeight,
      }))
    },
    [clientHeight, clientWidth]
  )

  // window scrolling handler
  const handleScrolling = useCallback(e => {
    e.preventDefault()
    setScrolling(s => ({
      ...s,
      X: window.scrollX,
      Y: window.scrollY,
    }))
  }, [])

  // horizontal scrolling handler
  const handleScrollX = useCallback(() => {
    const background = getBoundingClientRect(modalBackgroundId)
    if (background) {
      let X =
        background.left > 0
          ? (window.innerWidth - background.left) / 2 // scrolling - start
          : window.innerWidth / 2 - background.left // scrolling - middle

      if (background.width < window.innerWidth) {
        X = background.width / 2 // NOT scrolling
      } else if (
        window.scrollX + window.innerWidth >
        background.width + offsets.X
      ) {
        X = background.width - window.innerWidth / 2 // scrolling - end
      }

      setSpinnerPosition({ left: X - offsets.spinnerX })
    }
  }, [offsets.X, offsets.spinnerX])

  // vertical scrolling handler
  const handleScrollY = useCallback(() => {
    const background = getBoundingClientRect(modalBackgroundId)
    if (background) {
      let Y =
        background.top > 0
          ? (window.innerHeight - background.top) / 2 // scrolling - start
          : window.innerHeight / 2 - background.top // scrolling - middle

      if (background.height < window.innerHeight) {
        Y = background.height / 2 // NOT scrolling
      } else if (
        window.scrollY + window.innerHeight >
        background.height + offsets.Y
      ) {
        Y = background.height - window.innerHeight / 2 // scrolling - end
      }

      setSpinnerPosition({ top: Y - offsets.spinnerY })
    }
  }, [offsets.Y, offsets.spinnerY])

  useEffect(() => {
    window.addEventListener('resize', handleResizing)
    window.addEventListener('scroll', handleScrolling)
    return () => {
      window.removeEventListener('resize', handleResizing)
      window.removeEventListener('scroll', handleScrolling)
    }
  }, [handleResizing, handleScrolling])

  useEffect(() => {
    if (wrapper) {
      initOffsets()
    }
  }, [wrapper, initOffsets])

  useEffect(() => {
    if (wrapper) {
      handleResizing({
        preventDefault: () => null,
      })
    }
  }, [clientWidth, clientHeight, handleResizing, wrapper])

  useEffect(() => {
    if (active) {
      handleScrollX()
    }
  }, [active, size.width, scrolling.X, handleScrollX])

  useEffect(() => {
    if (active) {
      handleScrollY()
    }
  }, [active, size.height, scrolling.Y, handleScrollY])

  return (
    <div id={modalWrapperId}>
      {active && (
        <div
          id={modalBackgroundId}
          style={{
            zIndex: 999,
            display: 'flex',
            width: size.width,
            height: size.height,
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            id="modal-spinner"
            style={{
              zIndex: 998,
              top: spinnerPosition.top,
              left: spinnerPosition.left,
              display: 'block',
              position: 'relative',
              backgroundColor: 'transparent',
            }}
          >
            <Spinner id={spinnerId} color="primary" />
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default ModalSpinner
