import { Box, Container, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Loader from '../../../_common/_components/Loader/Loader'
import PolicyDetailsTitle from '../../../_common/_components/PolicyDetailsTitle'
import { useStore } from '../../../_common/_hooks/use-store'
import AccountValues from '../../../_common/_shared/PolicyDetails/AccountValues'
import Allocation from '../../../_common/_shared/PolicyDetails/Allocations'
import BeneficiaryList from '../../../_common/_shared/PolicyDetails/Beneficiaries'
import Documents from '../../../_common/_shared/PolicyDetails/Documents'
import Owner from '../../../_common/_shared/PolicyDetails/Owner'
import RelatedPolicies from '../../../_common/_shared/PolicyDetails/RelatedPolicies'
import { GetPolicy } from '../../../_common/_store/actions/policy-actions'
import { EAction } from '../../../_common/_store/reducers/app-reducers'
import { isMyga } from '../../../_helpers/policy'
import { getPolicyFromIntake } from '../../../_services/policyService'
import { RiderConfig } from '../../../types/salesTools/config'
import ActionNotes from '../../ActionNotes'
import TabPanel from '../../TabPanel'
import { RiderDetails } from '../../policyDetailsTabs'
import Agents from '../Agents'
import PolicyDetails from './PolicyDetails'
import Premiums from './Premiums'
import Events from '../../Events'

export enum EActiveTab {
  AccountValues = '1',
  PolicyDetail = '2',
  Agents = '3',
  Premiums = '4',
  Actions = '5',
  RelatedPolicies = '6',
  PolicyDocuments = '7',
  OwnerInformation = '8',
  BeneficiaryInformation = '9',
  Allocations = '10',
  RiderDetails = '11',
  Events = '12,',
}

interface IPolicyDetail {
  //status: string
  //hasParams: boolean
  //hasResult: boolean
  //policyData: any
  //documents: any[]
  activeTab: EActiveTab
  //loaded: boolean
}

function PolicyDetail(props: any) {
  const history = useHistory()
  const queryParams = props.match.params ? props.match.params : {}
  const { store, dispatch } = useStore()
  const [localState, setLocalState] = React.useState<IPolicyDetail>({
    activeTab: EActiveTab.AccountValues,
  })

  const riderConfig = store?.selectedPolicy?.rider_config as RiderConfig

  const toggle = (event, tab) => {
    setLocalState({ ...localState, activeTab: tab })
  }

  useEffect(() => {
    const queryAPI = async () => {
      try {
        const data = await GetPolicy(queryParams.policy)
        dispatch(data)
      } catch (e) {
        try {
          const response = await getPolicyFromIntake(queryParams.policy)
          dispatch({
            type: EAction.GetPolicy,
            payload: { selectedPolicy: response },
          })
        } catch (e) {
          const status = (e as any)?.status || (e as any)?.response?.status
          //TODO backend should return correct status
          //if (status === 401) history.push('/forbidden')
          if (status === 404) history.push('/not-found')
          else history.push('/forbidden')
        }
      }
    }

    queryAPI()
    // NOTE: the disable warning below is for the 'toast' hook.
    // if this changes in the future, remove the eslint disable below
    // to ensure that it is the only dependency left out of the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryParams.policy])

  function a11yProps(tab) {
    return {
      id: `simple-tab-${tab}`,
      'aria-controls': `simple-tabpanel-${tab}`,
      value: tab,
    }
  }

  const _isMyga = isMyga(store.selectedPolicy)

  if (!store.selectedPolicy) return <Loader title="Loading Policy Details" />

  return (
    <>
      <PolicyDetailsTitle
        title={`Policy Details: ${store.selectedPolicy?.data?.application?.policy_number}`}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={localState.activeTab}
          onChange={toggle}
          aria-label="nav tabs example"
          indicatorColor="primary"
          centered
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            data-cy="accountValuesTab"
            label="Account Values"
            {...a11yProps(EActiveTab.AccountValues)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="policyDetailsTab"
            label="Policy Details"
            {...a11yProps(EActiveTab.PolicyDetail)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          {riderConfig &&
            riderConfig?.rider_benefits &&
            Boolean(Object.keys(riderConfig?.rider_benefits).length) && (
              <Tab
                data-cy="ridersDetailsTab"
                label="Rider Details"
                {...a11yProps(EActiveTab.RiderDetails)}
                sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
              />
            )}
          <Tab
            data-cy="ownerTab"
            label="Owner"
            {...a11yProps(EActiveTab.OwnerInformation)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="beneficiariesTab"
            label="Beneficiaries"
            {...a11yProps(EActiveTab.BeneficiaryInformation)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="documentsTab"
            label="Documents"
            {...a11yProps(EActiveTab.PolicyDocuments)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="agentsTab"
            label="Agents"
            {...a11yProps(EActiveTab.Agents)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="premiumsTab"
            label="Premiums"
            {...a11yProps(EActiveTab.Premiums)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          {!_isMyga && (
            <Tab
              data-cy="allocationsTab"
              label="Allocations"
              {...a11yProps(EActiveTab.Allocations)}
              sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
            />
          )}
          <Tab
            data-cy="actionsTab"
            label="Actions"
            {...a11yProps(EActiveTab.Actions)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="eventsTab"
            label="Events"
            {...a11yProps(EActiveTab.Events)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
          <Tab
            data-cy="relatedPoliciesTab"
            label="Related Policies"
            {...a11yProps(EActiveTab.RelatedPolicies)}
            sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
          />
        </Tabs>
      </Box>
      <Box sx={{ marginTop: '2em' }}>
        <Box
          sx={{ minHeight: '750px', paddingTop: '1em', paddingBottom: '2em' }}
        >
          <Container sx={{ marginTop: '30px' }}>
            <TabPanel
              tab={EActiveTab.AccountValues}
              activeTab={localState.activeTab}
            >
              <AccountValues />
            </TabPanel>
            <TabPanel
              tab={EActiveTab.PolicyDetail}
              activeTab={localState.activeTab}
            >
              <PolicyDetails />
            </TabPanel>
            {riderConfig && Boolean(Object.keys(riderConfig).length) && (
              <TabPanel
                tab={EActiveTab.RiderDetails}
                activeTab={localState.activeTab}
              >
                <RiderDetails riderConfig={riderConfig} isClient={false} />
              </TabPanel>
            )}
            <TabPanel
              tab={EActiveTab.OwnerInformation}
              activeTab={localState.activeTab}
            >
              <Owner />
            </TabPanel>
            <TabPanel
              tab={EActiveTab.BeneficiaryInformation}
              activeTab={localState.activeTab}
            >
              <BeneficiaryList />
            </TabPanel>
            <TabPanel tab={EActiveTab.Agents} activeTab={localState.activeTab}>
              <Agents agents={store.selectedPolicy?.data?.agents} />
            </TabPanel>
            <TabPanel
              tab={EActiveTab.Premiums}
              activeTab={localState.activeTab}
            >
              <Premiums />
            </TabPanel>
            {!_isMyga && (
              <TabPanel
                tab={EActiveTab.Allocations}
                activeTab={localState.activeTab}
              >
                <Allocation />
              </TabPanel>
            )}
            <TabPanel tab={EActiveTab.Actions} activeTab={localState.activeTab}>
              <ActionNotes />
            </TabPanel>
            <TabPanel tab={EActiveTab.Events} activeTab={localState.activeTab}>
              <Events />
            </TabPanel>
            <TabPanel
              tab={EActiveTab.RelatedPolicies}
              activeTab={localState.activeTab}
            >
              <RelatedPolicies />
            </TabPanel>
            <TabPanel
              tab={EActiveTab.PolicyDocuments}
              activeTab={localState.activeTab}
            >
              <Documents />
            </TabPanel>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default PolicyDetail
