import PaletteIcon from '@mui/icons-material/Palette'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

import { useStore } from '../../_common/_hooks/use-store'
import { EMembershipType } from '../../_enums'
import { useThemeContext } from '../../themes'

export default function DeveloperHeaderOptions() {
  const [paletteAnchorEl, setPaletteAnchorEl] = useState<null | HTMLElement>(
    null
  )
  const { store } = useStore()
  const { themeType, updatEMembershipType } = useThemeContext()
  const version = store?.environment?.version

  if (
    !version ||
    (version.hasOwnProperty('should_show_environment_banner') &&
      !version.should_show_environment_banner)
  )
    return null

  function handleClose(themeType?: EMembershipType) {
    setPaletteAnchorEl(null)
    themeType && updatEMembershipType(themeType)
  }

  return (
    <>
      <IconButton
        sx={{ marginRight: 2 }}
        onClick={ev => setPaletteAnchorEl(ev.currentTarget)}
      >
        <PaletteIcon sx={{ color: 'white' }} />
      </IconButton>
      <Menu
        aria-labelledby="palette menu"
        anchorEl={paletteAnchorEl}
        open={Boolean(paletteAnchorEl)}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          disabled={themeType === EMembershipType.DEFAULT}
          onClick={() => handleClose(EMembershipType.DEFAULT)}
        >
          Default
        </MenuItem>
        <MenuItem
          disabled={themeType === EMembershipType.INNER_CIRCLE}
          onClick={() => handleClose(EMembershipType.INNER_CIRCLE)}
        >
          Inner Circle
        </MenuItem>
        <MenuItem
          disabled={themeType === EMembershipType.EMBER}
          onClick={() => handleClose(EMembershipType.EMBER)}
        >
          Ember
        </MenuItem>
      </Menu>
    </>
  )
}
