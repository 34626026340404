import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { InputGroup, InputGroupText } from 'reactstrap'

const styles = {
  prime: {
    backgroundColor: '#343A40',
    borderColor: '#343A40',
  },
  alt: {
    backgroundColor: '#2196f3',
    borderColor: '#2196f3',
  },
  money: {
    backgroundColor: '#679c58',
    borderColor: '#679c58',
  },
}

const IconAddon = props => {
  const { addonType, icon, stylePreference } = props
  const iconStyle = styles[stylePreference]
    ? styles[stylePreference]
    : styles.prime
  const position = addonType === 'append' ? 'append' : 'prepend'

  return (
    <InputGroup addonType={position}>
      <InputGroupText style={iconStyle}>
        <FontAwesomeIcon icon={icon} size="1x" color="#fff" />
      </InputGroupText>
    </InputGroup>
  )
}

IconAddon.propTypes = {
  addonType: PropTypes.oneOf(['append', 'prepend']).isRequired,
  icon: PropTypes.string.isRequired,
  stylePreference: PropTypes.oneOf(['prime', 'alt', 'money']).isRequired,
}

export default IconAddon
