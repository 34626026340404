import { initialPolicyContext } from '../AppContext'
import { IStore } from '../types/store'

export enum EAction {
  GetPolicy = 'GET_POLICY',
  UpdatePolicy = 'UPDATE_POLICY',
  GetDocuments = 'GET_DOCUMENTS',
  GetRelatedPolicies = 'GET_RELATED_POLICIES',
  GetEnvironment = 'GET_ENVIRONMENT',
  Reset = 'RESET',
  SET_CURRENT_ALLOCATIONS = 'SET_CURRENT_ALLOCATIONS',
}

export interface IActionType {
  type: EAction
  payload: any
}

export const appReducer = (state: IStore, action: IActionType): IStore => {
  switch (action.type) {
    case EAction.GetEnvironment:
      return {
        ...state,
        environment: {
          version: action.payload,
        },
      }
    case EAction.GetPolicy:
      return {
        ...state,
        ...action.payload,
      }
    case EAction.GetDocuments:
      return {
        ...state,
        documents: action.payload,
      }
    case EAction.GetRelatedPolicies:
      return {
        ...state,
        relatedPolicies: action.payload,
      }
    case EAction.Reset:
      return {
        ...initialPolicyContext,
        environment: state.environment,
      }
    case EAction.SET_CURRENT_ALLOCATIONS:
      return {
        ...state,
        currentAllocations: action.payload,
      }
    default:
      return { ...state }
  }
}
