import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Row, Col } from 'reactstrap'

class ClientSelfService extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <h2
              style={{
                color: '#0072C0',
                fontFamily: 'Arial',
                marginTop: '40px',
              }}
            >
              Self Service
            </h2>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                <Link
                  to="/client/self-service"
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/client-portal/change-beneficiary-form.pdf',
                      '_blank'
                    )
                  }
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FontAwesomeIcon
                            className="backgroundIcon"
                            icon={'users'}
                            size="5x"
                          />
                        </Col>
                        <Col md="10">
                          <Row>
                            <Col md="12">
                              <h3 className="card-title">
                                Change of Beneficiary Form
                              </h3>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Download Change of Beneficiary Form
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                <Link
                  to="/client/self-service"
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/client-portal/annuity-death-claim-form.pdf',
                      '_blank'
                    )
                  }
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FontAwesomeIcon
                            className="backgroundIcon"
                            icon={'clinic-medical'}
                            size="5x"
                          />
                        </Col>
                        <Col md="10">
                          <Row>
                            <Col md="12">
                              <h3 className="card-title">
                                Deceased Owner Claim Form
                              </h3>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Download Deceased Owner Claim Form
                                (Beneficiaries)
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                <Link
                  to="/client/self-service"
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/client-portal/annuitant-ownership-service-form.pdf',
                      '_blank'
                    )
                  }
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FontAwesomeIcon
                            className="backgroundIcon"
                            icon={'file'}
                            size="5x"
                          />
                        </Col>
                        <Col md="10">
                          <Row>
                            <Col md="12">
                              <h3 className="card-title">
                                Change Name/Contact Form
                              </h3>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Download Change Name/Contact Form
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                <Link
                  to="/client/self-service"
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/client-portal/full-withdrawal-request-form.pdf',
                      '_blank'
                    )
                  }
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FontAwesomeIcon
                            className="backgroundIcon"
                            icon={'money-bill'}
                            size="5x"
                          />
                        </Col>
                        <Col md="10">
                          <Row>
                            <Col md="12">
                              <h3 className="card-title">Withdrawal Form</h3>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Download Withdrawal Form
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                <Link
                  to="/client/self-service"
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/client-portal/systematic-withdrawal-request.pdf',
                      '_blank'
                    )
                  }
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FontAwesomeIcon
                            className="backgroundIcon"
                            icon={faFileInvoiceDollar}
                            size="5x"
                          />
                        </Col>
                        <Col md="10">
                          <Row>
                            <Col md="12">
                              <h3 className="card-title">
                                Systematic Withdrawal Form
                              </h3>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Download Systematic Withdrawal Form
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ClientSelfService
