import { Grid, Paper } from '@mui/material'

import { AddressDisplay } from '../../../_components'
import { IPerson } from '../../../_components/types'
import { formatDate, formatName } from '../../../utils'

export interface IPersonDisplay {
  personLabel:
    | 'Owner'
    | 'Annuitant'
    | 'Joint Owner'
    | 'Beneficiary'
    | 'Joint Annuitant'
  personData: IPerson
  annuitant?: string
}

function PersonDisplay({ personLabel, personData }: IPersonDisplay) {
  //use nullish assignment to get rid of nulls
  if (personData !== null) {
    personData.first_name ??= ''
    personData.last_name ??= ''
    personData.birth_date ??= ''
    personData.gender ??= ''
  }

  // is valid if the object is not null and not all the fields are empty or null
  const isValidPerson =
    personData !== null &&
    personLabel !== null &&
    !(
      !personData.first_name &&
      !personData.last_name &&
      !personData.birth_date &&
      !personData.gender
    )

  return isValidPerson ? (
    <Grid sm={12} md={6} item>
      <Paper elevation={3}>
        <Grid
          p={'30px'}
          container
          spacing={3}
          sx={{ paddingTop: '0 !important' }}
        >
          <Grid item sm={12}>
            <h5 className="text-muted">{`${personLabel} - ${formatName(
              personData
            )}`}</h5>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
            <hr />
          </Grid>
          <Grid item xs={12} md={5} className="data-label">
            Date of Birth
          </Grid>
          <Grid item xs={12} md={7}>
            {formatDate(personData.birth_date)}
          </Grid>
          <Grid item xs={12} md={5} className="data-label">
            Gender
          </Grid>
          <Grid item xs={12} md={7}>
            {personData.gender}
          </Grid>
          <Grid item xs={12} md={5} className="data-label">
            Address
          </Grid>
          <Grid item xs={12} md={7}>
            <AddressDisplay address={personData} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ) : (
    <></>
  )
}

export default PersonDisplay
