import { CancelRounded } from '@mui/icons-material'
import { OptionsObject, SnackbarKey, VariantType, useSnackbar } from 'notistack'

import { ESeverity } from '../_enums'

interface IToaster {
  toast: ((severity: ESeverity, message: string) => void) & {
    info: (message: string) => void
    success: (message: string) => void
    warning: (message: string) => void
    error: (message: string) => void
  }
}

export const useToaster = (): IToaster => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const toColor = (
    variant?: VariantType
  ): 'warning' | 'success' | 'info' | 'error' =>
    variant && variant !== 'default' ? variant : 'info'

  const toast = (message: string, options: OptionsObject) => {
    enqueueSnackbar(message.trim(), {
      ...options,
      variant: options.variant,
      action: (key: SnackbarKey) => (
        <div
          style={{ verticalAlign: 'center' }}
          onClick={() => closeSnackbar(key)}
        >
          <CancelRounded style={{ fontSize: 16 }} />
        </div>
      ),
    })
  }
  const info = (message: string) => {
    toast(message, { variant: ESeverity.Info })
  }
  const success = (message: string) => {
    toast(message, { variant: ESeverity.Success })
  }
  const warning = (message: string) => {
    toast(message, { variant: ESeverity.Warning })
  }
  const error = (message: string) => {
    toast(message, { variant: ESeverity.Error })
  }

  const messages = {
    [ESeverity.Info]: info,
    [ESeverity.Error]: error,
    [ESeverity.Warning]: warning,
    [ESeverity.Success]: success,
  }

  return {
    toast: Object.assign(
      (severity: ESeverity, message: string) => {
        return messages[severity](message)
      },
      {
        info,
        error,
        warning,
        success,
      }
    ),
  }
}
