/** @format */
import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { Checkbox } from './'
import { StyledErrorMessage, NoTouchErrorMessage } from './StyledErrorMessage'

export const CheckGroup = props => {
  const { disabled, label, name, required, options, noTouchMessage } = props

  if (options && options.length > 0) {
    let result = (
      <React.Fragment>
        <label>{label}</label>
        <FieldArray
          name={name}
          render={() => {
            return options.map((o, i) => {
              const { label, value } = o

              return (
                <Checkbox
                  {...props}
                  key={value}
                  name={`${name}.${value}`}
                  disabled={disabled}
                  required={required}
                  label={label}
                  value={value}
                  validationMessage={false}
                />
              )
            })
          }}
        />
        {noTouchMessage ? (
          <NoTouchErrorMessage name={name} />
        ) : (
          <StyledErrorMessage name={name} />
        )}
        <br />
      </React.Fragment>
    )
    return result
  }

  return null
}

CheckGroup.propTypes = {
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  noTouchMessage: PropTypes.bool,
}

CheckGroup.defaultProps = {
  disabled: false,
  readonly: false,
  required: false,
  noTouchMessage: false,
}

export default CheckGroup
