import { Box } from '@mui/material'
import numeral from 'numeral'
import { useEffect, useMemo } from 'react'
import { Line } from 'react-chartjs-2'

import { useChartData } from '../../../../_hooks'

const NonGuaranteedPolicyValues = ({ data }) => {
  const datasets = useChartData(data)
  if (!data) return null
  return (
    <Box width="100%">
      <Line
        data={{
          labels: data.labels,
          datasets,
        }}
        options={{
          legend: {
            display: true,
            position: 'bottom',
          },
          title: {
            display: false,
            text: '',
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, values) {
                    return numeral(value).format('$0,0')
                  },
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  callback: function (value, index, values) {
                    // eslint-disable-next-line
                    if (value == 'Issue') {
                      return value
                      // eslint-disable-next-line
                    } else if (value == 1) {
                      return value + ' yr'
                    } else {
                      return value + ' yrs'
                    }
                  },
                },
              },
            ],
          },
        }}
      />
    </Box>
  )
}

export default NonGuaranteedPolicyValues
