import { v4 as uuidv4 } from 'uuid'

import {
  EProductFamilies,
  EProductId,
  getProductFamilyByProductId,
} from '../../../../../_enums/EProduct'
import {
  EWithdrawalCodeType,
  EWithdrawalType,
} from '../../../../../_enums/EWithdrawalType'
import { store } from '../../../../../_store/store'
import { IRider } from '../../../../../types/salesTools'
import { IWithdrawalWaitingPeriods } from './types'

export const getOldestAndYoungest = (
  ownerAge?: number,
  jointOwnerAge?: number
) => ({
  oldest: Math.max(...([ownerAge ?? 0, jointOwnerAge].filter(Boolean) as any)),
  youngest: Math.min(
    ...([ownerAge ?? 0, jointOwnerAge].filter(Boolean) as any)
  ),
})

export function addWithdrawalType(type: EWithdrawalCodeType) {
  return {
    id: uuidv4(),
    type: type,
    amount: 0,
    start: '0',
    end: '0',
  }
}

export function getWithdrawalWaitingPeriod(
  data: any,
  rider_lifetime_override?: number
): IWithdrawalWaitingPeriods {
  const withdrawalWaitingPeriod = data.withdrawal_waiting_period ?? {}
  if (data.withdrawal.free)
    withdrawalWaitingPeriod.standard_waiting_period =
      data.withdrawal.free.waiting_period
  if (rider_lifetime_override !== undefined) {
    withdrawalWaitingPeriod.lifetime_waiting_period = rider_lifetime_override
    withdrawalWaitingPeriod.level_waiting_period = rider_lifetime_override
    withdrawalWaitingPeriod.increasing_waiting_period = rider_lifetime_override
  } else if (data.withdrawal.lifetime)
    withdrawalWaitingPeriod.lifetime_waiting_period =
      data.withdrawal.lifetime.waiting

  return withdrawalWaitingPeriod
}

export function getWithdrawalTypesByProductId(
  productId: EProductId,
  isQualified: boolean,
  riders?: IRider[],
  riderConfigId?: number
) {
  let withdrawalTypes = [
    EWithdrawalType.None,
    EWithdrawalType.PercentOfAv,
    EWithdrawalType.Amount,
  ]
  if (isQualified) withdrawalTypes.push(EWithdrawalType.RMD)

  const productFamily = getProductFamilyByProductId(productId)

  switch (productFamily) {
    case EProductFamilies.Vega:
      return [
        ...withdrawalTypes,
        EWithdrawalType.Level,
        EWithdrawalType.Increasing,
        EWithdrawalType.Accelerated,
      ]
    case EProductFamilies.Denali:
      if (
        riders !== undefined &&
        riders.length > 0 &&
        riderConfigId !== undefined &&
        riderConfigId > 0
      ) {
        riders.forEach(rider => {
          if (rider.rider_config_id === riderConfigId)
            withdrawalTypes.push(...getWithdrawalTypesByRider(rider))
        })
        return withdrawalTypes
      }
      return [...withdrawalTypes, EWithdrawalType.Life]
    default:
      return withdrawalTypes
  }
}

export function getWithdrawalTypesByRider(rider: IRider) {
  switch (rider.rider_code) {
    case 'evolve':
      return [EWithdrawalType.Level, EWithdrawalType.Increasing]
    default:
      return []
  }
}

function isCalifornia(stateCode: string) {
  return stateCode === 'CA'
}

function is10Plan(code: string) {
  return code === '10'
}

export function getTetonWithdrawalTypes(options: {
  stateCode: string
  code: string
  riderConfigId?: number
  isQualified: boolean
}) {
  return [
    EWithdrawalType.None,
    EWithdrawalType.PercentOfAv,
    EWithdrawalType.Amount,
    ...(!isCalifornia(options.stateCode) || is10Plan(options.code)
      ? [EWithdrawalType.MaximumCumulative]
      : []),

    ...(options.isQualified ? [EWithdrawalType.RMD] : []),
  ]
}

const getWaitingPeriodInYears = (withdrawalPeriod?: number) =>
  Math.ceil((withdrawalPeriod ?? 0) / 12) + 1

export function getWaitingPeriodByWithdrawalType(
  withdrawal_type: EWithdrawalCodeType,
  withdrawalWaitingPeriods: IWithdrawalWaitingPeriods
) {
  switch (withdrawal_type) {
    case EWithdrawalCodeType.Level:
      return getWaitingPeriodInYears(
        withdrawalWaitingPeriods.level_waiting_period
      )
    case EWithdrawalCodeType.Increasing:
      return getWaitingPeriodInYears(
        withdrawalWaitingPeriods.increasing_waiting_period
      )
    case EWithdrawalCodeType.Accelerated:
      return getWaitingPeriodInYears(
        withdrawalWaitingPeriods.accelerated_waiting_period
      )
    case EWithdrawalCodeType.Lifetime:
      return getWaitingPeriodInYears(
        withdrawalWaitingPeriods.lifetime_waiting_period
      )
    default:
      return getWaitingPeriodInYears(
        withdrawalWaitingPeriods.standard_waiting_period
      )
  }
}

export function sanitizeWithdrawals(withdrawals: any[]) {
  for (let i = 0; i < withdrawals.length; i++) {
    if (Number(withdrawals[i].start) > Number(withdrawals[i].end))
      withdrawals[i].end = withdrawals[i].start
    if (!i) continue
    if (
      withdrawals[i].start &&
      Number(withdrawals[i - 1].end) >= Number(withdrawals[i].start)
    ) {
      withdrawals[i].start = (Number(withdrawals[i - 1].end) + 1).toString()
      if (Number(withdrawals[i].start) > Number(withdrawals[i].end))
        withdrawals[i].end = withdrawals[i].start
    }
  }
}
