import React, { Fragment } from 'react'
// import { useFormikContext } from 'formik'
import { Col } from 'reactstrap'

import { BasicField, SelectField } from '../Forms/FormikInputs'

export default function UploadForm({
  setUploadFieldValues,
  setFieldTouched,
  values,
}) {
  const handleValues = values => {
    setUploadFieldValues({ ...values })
  }

  return (
    <Fragment>
      <Col lg="4" md="5" xs="6" className="mx-auto mb-3">
        <SelectField
          name="formType"
          label="Form Type"
          onBlur={() => {
            setFieldTouched('formType')
            handleValues(values)
          }}
        >
          <option value="NA">New Application</option>
          <option value="AC">Application Corrections</option>
          <option value="TF">Transfer Form</option>
          <option value="DR">Delivery Receipt</option>
          <option value="SF">Service Form</option>
        </SelectField>
        {values.formType !== 'NA' ? (
          <BasicField
            name="policyNumber"
            label="Policy Number"
            onBlur={() => {
              setFieldTouched('policyNumber')
              handleValues(values)
            }}
          />
        ) : (
          <Fragment />
        )}
      </Col>
    </Fragment>
  )
}
