import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import {
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'

import { EMembershipType, EUserGroup } from '../../_enums'
import authService from '../../_services/authService'
import { useThemeContext } from '../../themes'
import { SingleEnviromentVariablesAlert } from '../app-enviroment'
import DeveloperHeaderOptions from './DeveloperHeaderOptions'
import './Header.css'

export default function Header({
  profileOptions,
  setMenuOpen,
  userGroup,
}: {
  profileOptions: JSX.Element
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  userGroup: EUserGroup
}) {
  const { themeType, theme } = useThemeContext()

  const userName = authService.getFullName()
  const profileName =
    userName && userName.toString().replace(' ', '') !== ''
      ? userName
      : 'Options'

  const isDefaultTheme = themeType === EMembershipType.DEFAULT
  const background = isDefaultTheme
    ? theme.palette?.primary?.['main']
    : theme.palette?.secondary?.['main']

  return (
    <div id="header">
      <Navbar
        className="fixed-top"
        style={{
          background,
        }}
      >
        <Box display="flex">
          <NavbarToggler
            onClick={() => setMenuOpen(old => !old)}
            className="headerMenuBtn"
            style={{ marginRight: 8 }}
          >
            <FontAwesomeIcon icon="bars" />
          </NavbarToggler>

          {theme.headerTitle}
        </Box>

        <SingleEnviromentVariablesAlert />

        <Box display="flex">
          {userGroup === EUserGroup.ANNUITY_AGENT && <DeveloperHeaderOptions />}

          {userName && (
            <Nav className="ml-auto headerOptions" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <div className="d-none d-md-block">
                    {profileName} <FontAwesomeIcon icon={'caret-down'} />
                  </div>
                  <div className="d-md-none">
                    <FontAwesomeIcon
                      icon="user"
                      style={{ fontSize: '1.25rem' }}
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="headerOptionMenu">
                  {profileOptions}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          )}
        </Box>
      </Navbar>
      <div id="bodyOffset" />
    </div>
  )
}
