import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import Loader from '../../../_common/_components/Loader/Loader'
import PolicyDetailsTitle from '../../../_common/_components/PolicyDetailsTitle'
import { useStore } from '../../../_common/_hooks/use-store'
import AccountValues from '../../../_common/_shared/PolicyDetails/AccountValues'
import Allocation from '../../../_common/_shared/PolicyDetails/Allocations'
import BeneficiaryList from '../../../_common/_shared/PolicyDetails/Beneficiaries'
import PolicyDocuments from '../../../_common/_shared/PolicyDetails/Documents'
import Owner from '../../../_common/_shared/PolicyDetails/Owner'
import RelatedPolicies from '../../../_common/_shared/PolicyDetails/RelatedPolicies'
import { GetPolicy } from '../../../_common/_store/actions/policy-actions'
import { isMyga } from '../../../_helpers/policy'
import { RiderConfig } from '../../../types/salesTools/config'
import TabPanel from '../../TabPanel'
import { RiderDetails } from '../../policyDetailsTabs'
import Agent from './Agent'
import './PolicyDetails.css'

const enum EActiveTab {
  AccountValues = '1',
  OwnerInformation = '2',
  Beneficiary = '3',
  AgentInformation = '4',
  Allocations = '5',
  RelatedPolicies = '6',
  PolicyDocuments = '7',
  RiderDetails = '8',
}

function PolicyDetails(props: any) {
  const history = useHistory()
  const { store, dispatch } = useStore()
  const policyNumber: string = props.match.params.policyNum
  const [localState, setLocalState] = React.useState<{ activeTab: EActiveTab }>(
    { activeTab: EActiveTab.AccountValues }
  )

  const riderConfig = store?.selectedPolicy?.rider_config as RiderConfig

  const toggle = (event, tab) => {
    if (localState.activeTab !== tab) {
      setLocalState({ ...localState, activeTab: tab })
    }
  }

  useEffect(() => {
    const queryAPI = async () => {
      try {
        const data = await GetPolicy(policyNumber)
        dispatch(data)
      } catch (e) {
        const status = (e as any)?.status || (e as any)?.response?.status
        //TODO backend should return correct status
        //if (status === 401) history.push('/forbidden')
        if (status === 404) history.push('/not-found')
        else history.push('/forbidden')
      }
    }

    queryAPI()
    // NOTE: the disable warning below is for the 'toast' hook.
    // if this changes in the future, remove the eslint disable below
    // to ensure that it is the only dependency left out of the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, policyNumber])

  function a11yProps(tab) {
    return {
      id: `simple-tab-${tab}`,
      'aria-controls': `simple-tabpanel-${tab}`,
      value: tab,
    }
  }

  const _isMyga = isMyga(store.selectedPolicy)

  return (
    <div>
      <Row>
        <Col md="12">
          {!store.selectedPolicy ? (
            <Loader title="Loading contract details" />
          ) : (
            <>
              <PolicyDetailsTitle title={`Contract Details: ${policyNumber}`} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs
                  value={localState.activeTab}
                  onChange={toggle}
                  aria-label="nav tabs example"
                  indicatorColor="primary"
                  centered
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    data-cy="accountValuesTab"
                    label="Account Values"
                    {...a11yProps(EActiveTab.AccountValues)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                  {riderConfig &&
                    riderConfig?.rider_benefits &&
                    Boolean(
                      Object.keys(riderConfig?.rider_benefits).length
                    ) && (
                      <Tab
                        data-cy="riderDetailsTab"
                        label="Rider Details"
                        {...a11yProps(EActiveTab.RiderDetails)}
                        sx={{
                          marginRight: '1em',
                          fontWeight: 'bold',
                          minWidth: 0,
                        }}
                      />
                    )}

                  <Tab
                    data-cy="ownerTab"
                    label="Owner"
                    {...a11yProps(EActiveTab.OwnerInformation)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                  <Tab
                    data-cy="beneficiariesTab"
                    label="Beneficiaries"
                    {...a11yProps(EActiveTab.Beneficiary)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                  <Tab
                    data-cy="agentsTab"
                    label="Agents"
                    {...a11yProps(EActiveTab.AgentInformation)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                  <Tab
                    data-cy="documentsTab"
                    label="Documents"
                    {...a11yProps(EActiveTab.PolicyDocuments)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                  {!_isMyga && (
                    <Tab
                      data-cy="allocationsTab"
                      label="Allocations"
                      {...a11yProps(EActiveTab.Allocations)}
                      sx={{
                        marginRight: '1em',
                        fontWeight: 'bold',
                        minWidth: 0,
                      }}
                    />
                  )}
                  <Tab
                    data-cy="relatedPoliciesTab"
                    label="Related Policies"
                    {...a11yProps(EActiveTab.RelatedPolicies)}
                    sx={{ marginRight: '1em', fontWeight: 'bold', minWidth: 0 }}
                  />
                </Tabs>
              </Box>

              <Box sx={{ marginTop: '2em' }}>
                {/*TODO: set elavation to 3 so the paper shadow is visible as defined in mockups*/}
                <Paper elevation={0}>
                  <Box
                    sx={{
                      minHeight: '750px',
                      paddingTop: '1em',
                      paddingBottom: '2em',
                    }}
                  >
                    <Container sx={{ marginTop: '30px' }}>
                      <TabPanel
                        tab={EActiveTab.AccountValues}
                        activeTab={localState.activeTab}
                      >
                        <AccountValues />
                      </TabPanel>
                      {riderConfig && Boolean(Object.keys(riderConfig).length) && (
                        <TabPanel
                          tab={EActiveTab.RiderDetails}
                          activeTab={localState.activeTab}
                        >
                          <RiderDetails riderConfig={riderConfig} isClient={true} />
                        </TabPanel>
                      )}
                      <TabPanel
                        tab={EActiveTab.OwnerInformation}
                        activeTab={localState.activeTab}
                      >
                        <Owner />
                      </TabPanel>
                      <TabPanel
                        tab={EActiveTab.Beneficiary}
                        activeTab={localState.activeTab}
                      >
                        <BeneficiaryList />
                      </TabPanel>
                      <TabPanel
                        tab={EActiveTab.AgentInformation}
                        activeTab={localState.activeTab}
                      >
                        <Agent />
                      </TabPanel>
                      {!_isMyga && (
                        <TabPanel
                          tab={EActiveTab.Allocations}
                          activeTab={localState.activeTab}
                        >
                          <Allocation />
                        </TabPanel>
                      )}
                      <TabPanel
                        tab={EActiveTab.RelatedPolicies}
                        activeTab={localState.activeTab}
                      >
                        <RelatedPolicies />
                      </TabPanel>
                      <TabPanel
                        tab={EActiveTab.PolicyDocuments}
                        activeTab={localState.activeTab}
                      >
                        <PolicyDocuments />
                      </TabPanel>
                    </Container>
                  </Box>
                </Paper>
              </Box>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default PolicyDetails
