import { Field } from 'formik'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Input, InputGroup, Label, FormGroup } from 'reactstrap'

import IconeAddon from './IconAddon'
import { StyledErrorMessage, NoTouchErrorMessage } from './StyledErrorMessage'

const DateInput = ({ field, ...props }) => {
  return <Input type="date" {...field} {...props} />
}

DateInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
}

export const DateField = props => {
  const { name, label, required, noTouchMessage } = props
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <InputGroup>
        <Field
          {...props}
          name={name}
          component={DateInput}
          required={required}
          className="form-control"
        />
        <IconeAddon addonType="append" icon="calendar" stylePreference="alt" />
      </InputGroup>
      {noTouchMessage ? (
        <NoTouchErrorMessage name={name} />
      ) : (
        <StyledErrorMessage name={name} />
      )}
    </FormGroup>
  )
}

DateField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noTouchMessage: PropTypes.bool,
}

DateField.defaultProps = {
  disabled: false,
  required: false,
  noTouchMessage: false,
}

export const LabeledDate = props => {
  const { disabled, label, name, required } = props
  return (
    <Fragment>
      <Label for={name}>{label}</Label>
      <InputGroup>
        <IconeAddon addonType="prepend" icon="calendar" stylePreference="alt" />
        <Field
          name={name}
          component={DateInput}
          className="form-control"
          disabled={disabled}
          required={required}
        />
      </InputGroup>
      <StyledErrorMessage name={name} />
    </Fragment>
  )
}

LabeledDate.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

LabeledDate.defaultProps = {
  disabled: false,
  required: false,
}

export default DateField
