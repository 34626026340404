import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import logo from '../assets/images/Ember-expanded4portal.png'
import homeBanner from '../assets/images/ember_banner.png'
import { Theme } from '../types'
import { defaultTheme } from './default'

export const emberTheme: Theme = {
  ...defaultTheme,
  navbarBackground:
    'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0, 0,1) 50%, rgba(237,17,17,1) 100%)',
  headerTitle: (
    <Link to="/" className="headerLogo">
      <img src={logo} alt="logo" />
    </Link>
  ),
  homeBanner: (
    <Box id="banner" marginBottom={4}>
      <img style={{ width: '100%' }} src={homeBanner} alt="welcome banner" />
    </Box>
  ),
  palette: {
    primary: {
      main: '#ed1111',
      light: '#ff5a3e',
      dark: '#b10000',
    },
    secondary: {
      main: '#000000',
      light: '#2c2c2c',
      dark: '#000000',
    },
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      ...defaultTheme.components?.MuiButton,
      styleOverrides: {
        root: {
          ...(defaultTheme.components?.MuiButton?.styleOverrides?.root as any),
          background:
            'linear-gradient(180deg, rgba(81,6,6,1) 0%, rgba(115,8,8,1) 0%, rgba(237,17,17,1) 100%)',
        },
      },
    },
  },
}
