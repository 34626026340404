import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { FormikProps } from 'formik'
import React, { ChangeEvent, useRef } from 'react'

import {
  ELifetimeWithdrawal,
  EWithdrawalCodeType,
} from '../../../../../_enums'
import { ELifetimeWithdrawalType } from '../../../../../_enums/ELifetimeWithdrawalType'
import { IFormFields } from './Withdrawals'
import { Withdrawal } from '../../../../../types/salesTools'

interface Props {
  hasWithdrawalSelected: boolean
  hasLifetimeSelected: boolean
  withdrawals: Withdrawal[]
  formik: FormikProps<IFormFields>
}

const TYPES_LIFETIME_WITHDRAWALS = [
  {
    name: ELifetimeWithdrawal.Single.name,
    code: ELifetimeWithdrawal.Single.code,
  },
  {
    name: ELifetimeWithdrawal.Joint.name,
    code: ELifetimeWithdrawal.Joint.code,
  },
]

const LifetimeFields: React.FC<Props> = ({
  hasWithdrawalSelected,
  hasLifetimeSelected,
  withdrawals,
  formik,
}) => {
  const ref = useRef<any>(null)

  const handleJointOwnerAgeOnChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    formik.handleChange(e)
    formik.values.withdrawals.forEach(w => {
      if (
        w.type !== EWithdrawalCodeType.PercentOfAv &&
        w.type !== EWithdrawalCodeType.Amount
      ) {
        w.start = ''
        w.end = ''
      }
    })
    formik.handleChange('withdrawals')
  }

  const handleLifetimePayoutOnChange = (e: SelectChangeEvent<string>) => {
    if (
      e.target.value === ELifetimeWithdrawal.Single.code &&
      formik.errors.jointOwnerAge
    ) {
      setTimeout(() => ref.current?.focus(), 0)
      return
    }

    formik.values.withdrawals.forEach(w => {
      if (
        w.type !== EWithdrawalCodeType.PercentOfAv &&
        w.type !== EWithdrawalCodeType.Amount
      ) {
        w.start = ''
        w.end = ''
      }
    })

    formik.handleChange('withdrawals')
    formik.handleChange(e)
  }

  if (
    !(
      hasWithdrawalSelected &&
      hasLifetimeSelected &&
      withdrawals.every(w => w.type !== EWithdrawalCodeType.Accelerated)
    )
  )
    return null
  return (
    <Grid container spacing={4} marginTop={4}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item md={4} sm={12}>
          <FormControl
            fullWidth
            error={
              formik.touched.lifetime_payout &&
              Boolean(formik.errors.lifetime_payout)
            }
          >
            <InputLabel id="lifetime_payout">
              Type of Lifetime Withdrawal
            </InputLabel>
            <Select
              labelId="lifetime_payout"
              label="Type of Lifetime Withdrawal"
              name="lifetime_payout"
              value={formik.values.lifetime_payout}
              onChange={handleLifetimePayoutOnChange}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            >
              {TYPES_LIFETIME_WITHDRAWALS.map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.lifetime_payout && (
              <FormHelperText>{formik.errors.lifetime_payout}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={4} sm={12}>
          {formik.values.lifetime_payout === ELifetimeWithdrawalType.Joint && (
            <TextField
              inputRef={ref}
              fullWidth
              type="number"
              value={formik.values.jointOwnerAge}
              onChange={handleJointOwnerAgeOnChange}
              label="Joint Owner Age"
              id="jointOwnerAge"
              name="jointOwnerAge"
              helperText={
                formik.touched.jointOwnerAge && formik.errors.jointOwnerAge
              }
              error={
                formik.touched.jointOwnerAge &&
                Boolean(formik.errors.jointOwnerAge)
              }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LifetimeFields
