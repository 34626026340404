import { request } from '../_contexts'
import { TAction } from '../_contexts/types'
import { EAction } from '../_enums'
import { IActionNote } from '../components/ActionNotes/types'

export interface IGetActionNotesAction {
  payload: {
    data?: IActionNote[]
  }
}

export const getActionNotes = (
  policy_number: string
): TAction<IGetActionNotesAction> => {
  return async () => {
    const { data } = await request({
      method: 'get',
      uri: `/action-notes/?policy_number=${policy_number}`,
    })

    return {
      type: EAction.GetActionNotes,
      payload: {
        data,
      },
    }
  }
}
