import React from 'react'

// import { Link } from 'react-router-dom';

const AgentCommunicationListItem = ({
  id,
  link,
  type,
  subject,
  description,
  actionDate,
}) => (
  <tr>
    <td>{type}</td>
    <td>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {subject}
      </a>
    </td>
    {/* <td>{description}</td> */}
    <td>{actionDate}</td>
  </tr>
)

export default AgentCommunicationListItem
