import { LoadingButton } from '@mui/lab'
import { Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ReactNode, useState } from 'react'

import {
  Allocations,
  IllustrationProvider,
  IssueInformation,
  ProductSelection,
  Withdrawals,
  Illustration as IllustrationStep,
} from '../../../components/sales-tools/illustration'

const withStepContainer = (title: string, c: ReactNode) => (
  <Card sx={{ position: 'relative', padding: 4 }}>
    <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
    <Box marginTop={4}>{c}</Box>
  </Card>
)

function Illustration() {
  const [activeStep, setActiveStep] = useState(0)
  const completed = {}

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  const actions = (
    isLoading: boolean = false,
    options: { next?: ReactNode; back?: ReactNode } = { next: null, back: null }
  ) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: 4,
        position: 'absolute',
        right: 20,
        top: 0,
      }}
    >
      <LoadingButton
        variant="contained"
        disableElevation
        type="button"
        onClick={() => {
          handleBack()
        }}
        sx={{ mr: 1 }}
        disabled={activeStep === 0}
        loading={isLoading}
      >
        Previous
      </LoadingButton>
      {options.next ? (
        options.next
      ) : (
        <LoadingButton
          variant="contained"
          disableElevation
          type="submit"
          sx={{ mr: 1 }}
          disabled={Boolean(isLastStep())}
          loading={isLoading}
        >
          Next
        </LoadingButton>
      )}
    </Box>
  )

  const steps = [
    {
      label: 'Product Selection',
      c: withStepContainer(
        'Product Selection',
        <ProductSelection actions={actions} handleNext={handleNext} />
      ),
    },
    {
      label: 'Issue Information',
      c: withStepContainer(
        'Issue Information',
        <IssueInformation actions={actions} handleNext={handleNext} />
      ),
    },
    {
      label: 'Allocations',
      c: withStepContainer(
        'Allocation',
        <Allocations actions={actions} handleNext={handleNext} />
      ),
    },
    {
      label: 'Withdrawals',
      c: withStepContainer(
        'Withdrawal',
        <Withdrawals actions={actions} handleNext={handleNext} />
      ),
    },
    {
      label: 'Illustration',
      c: withStepContainer(
        'Illustration',
        <IllustrationStep actions={actions} handleNext={handleNext} />
      ),
    },
  ]

  return (
    <IllustrationProvider>
      <Container maxWidth="xl">
        <Typography variant="h3" component="h1" marginBottom={5}>
          <b>Illustration</b> Software
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(({ label }, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box marginTop={4}>{steps[activeStep].c}</Box>
      </Container>
    </IllustrationProvider>
  )
}

export default Illustration
