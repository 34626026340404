import React from 'react'

import { appReducer } from './reducers/app-reducers'
import { IStore } from './types/store'

export interface IAppContext {
  store: IStore
  dispatch: any
}

export const AppContext = React.createContext<IAppContext>({} as IAppContext)

export const initialPolicyContext: IStore = {
  selectedPolicy: undefined,
  documents: undefined,
  loaded: false,
  hasParams: false,
  relatedPolicies: undefined,
  currentAllocations: undefined,
  environment: undefined,
}

export const AppProvider = ({ children }) => {
  const [store, dispatch] = React.useReducer(appReducer, initialPolicyContext)

  return (
    <AppContext.Provider value={{ store, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
