import { useFormik } from 'formik'
import { ReactNode } from 'react'
import * as Yup from 'yup'

import { ELifetimeWithdrawal } from '../../../../../_enums/ELifetimeWithdrawalType'
import { EWithdrawalCodeType } from '../../../../../_enums/EWithdrawalType'
import { moneyFormat, stripCommas } from '../../../../../_helpers/money'
import { formatMoneyRounded } from '../../../../../_helpers/number'
import stepImage from '../../../../../assets/images/sales-tools/money-withdrawal.png'
import '../../../../../components/Forms/Inputs/inputs.css'
import { useIllustrationContext } from '../../IllustrationContext'
import { EAction } from '../../reducers'
import StepImage from '../StepImage'
import WithdrawalForm from './WithdrawalForm'
import { Withdrawal } from '../../../../../types/salesTools'

export function getValidationSchema({
  minAge,
  maxAge,
  maxPercentage,
  maxFreeWithdrawal,
  minFreeWithdrawal,
}: {
  minAge: number
  maxAge: number
  maxPercentage: number
  maxFreeWithdrawal: number
  minFreeWithdrawal: number
}) {
  return Yup.object().shape({
    withdrawals: Yup.array().of(
      getValidationSchemaWithdrawal(
        maxPercentage,
        maxFreeWithdrawal,
        minFreeWithdrawal
      )
    ),
    lifetime_payout: Yup.string().required(
      'Type of Lifetime Withdrawal is required.'
    ),
    jointOwnerAge: Yup.number()
      .notRequired()
      .nullable()
      .min(minAge)
      .max(maxAge)
      .label('Joint Owner Age')
      .when('lifetime_payout', {
        is: lifetime_payout =>
          lifetime_payout === ELifetimeWithdrawal.Joint.code,
        then: Yup.number().required('This field is required.'),
        otherwise: Yup.number(),
      }),
  })
}

function getValidationSchemaWithdrawal(
  maxPercentage: number,
  maxFreeWithdrawal: number,
  minFreeWithdrawal: number
) {
  return Yup.object().shape({
    type: Yup.string().required('Value required.'),
    amount: Yup.string()
      .when('type', {
        is: (val: string) => val === EWithdrawalCodeType.Amount,
        then: Yup.string()
          .required('Value required.')
          .test(
            'is-not-min',
            `Amount must be greater or equal to ${formatMoneyRounded(
              minFreeWithdrawal
            )}.`,
            value => Number(stripCommas(value)) >= minFreeWithdrawal
          ),
        // .test(
        //   'is-not-max',
        //   `Amount must be lower or equal to ${formatMoneyRounded(
        //     maxFreeWithdrawal
        //   )}.`,
        //   value => Number(stripCommas(value)) <= maxFreeWithdrawal
        // ),
        otherwise: Yup.string().notRequired().nullable(),
      })
      .when('type', {
        is: (val: string) => val === EWithdrawalCodeType.PercentOfAv,
        then: Yup.string()
          .required('Value required.')
          .test(
            'is-not-zero',
            'Amount must be greater than 0.',
            value => Number(stripCommas(value)) > 0
          )
          .test(
            'is-not-max',
            `Amount must be lower or equal to ${maxPercentage}%.`,
            value => Number(stripCommas(value)) <= maxPercentage
          ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    start: Yup.string().when('type', {
      is: (val: string) => val !== EWithdrawalCodeType.None,
      then: Yup.string().required('Value required.'),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    end: Yup.string().when('type', {
      is: (val: string) =>
        val !== EWithdrawalCodeType.None &&
        val !== EWithdrawalCodeType.Accelerated,
      then: Yup.string().required('Value required.'),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  })
}



export interface IFormFields {
  jointOwnerAge?: number
  withdrawals: Withdrawal[]
  lifetime_payout: string
}

type Props = {
  actions: (isLoading?: boolean) => ReactNode
  handleNext: () => void
}

export function Withdrawals({ actions, handleNext }: Props) {
  const { store, dispatch } = useIllustrationContext()
  const { jointOwnerAge, withdrawals, lifetime_payout } = store
  const config = store.selectedConfig!
  const maxPercentage = Number(
    (
      (store.riderConfigId
        ? Number(
            store.riders!.find(x => x.rider_config_id === store.riderConfigId)!
              .free_withdrawal_max_percent
          ) || config.data.withdrawal.free.max_percentage
        : config.data.withdrawal.free.max_percentage) * 100
    ).toFixed(1)
  )

  const handleOnSubmit = (values: IFormFields) => {
    if (formik.isValid) {
      const formattedWithdrawals = values.withdrawals.map(wd => ({
        ...wd,
        amount: stripCommas(wd.amount),
      }))

      dispatch({
        type: EAction.UpdateStore,
        payload: {
          ...values,
          withdrawals: formattedWithdrawals,
          jointOwnerAge:
            values.lifetime_payout === ELifetimeWithdrawal.Joint.code
              ? values.jointOwnerAge
              : 0,
        },
      })

      handleNext()
    }
  }

  const formik = useFormik({
    initialValues: {
      withdrawals: withdrawals.map(wd => ({
        ...wd,
        amount: moneyFormat(wd.amount),
      })),
      lifetime_payout: lifetime_payout,
      jointOwnerAge,
    },
    validationSchema: getValidationSchema({
      maxAge: config.data.issue.max_age,
      minAge: config.data.issue.min_age,
      maxFreeWithdrawal: 10000, // to define
      minFreeWithdrawal: Number(
        Number(config.data.withdrawal.min_amount).toFixed(1)
      ),
      maxPercentage,
    }),
    onSubmit: handleOnSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <WithdrawalForm formik={formik} maxPercentage={maxPercentage} />
      <StepImage image={stepImage} title="issue information" />
      {actions()}
    </form>
  )
}
