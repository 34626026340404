import React, { Component } from 'react'

class AgentMyAgents extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          Brandon is trying to get this de-scoped or fully understand what this
          section is
        </div>
      </div>
    )
  }
}

export default AgentMyAgents
