import { Box } from '@mui/material'
import PropTypes from 'prop-types'

function TabPanel(props) {
  const { children, tab, activeTab, ...other } = props

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${tab}`}
      aria-labelledby={`simple-tab-${tab}`}
      {...other}
    >
      {tab === activeTab && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  tab: PropTypes.any.isRequired,
  activeTab: PropTypes.any.isRequired,
}

export default TabPanel
