export function legacyAgentMessage(primaryAgentNumber) {
  return (
    <>
      <p>You have logged in with a legacy agent number.</p>
      <p>
        To view all business, commissions, and reports, please login using agent
        number <b>{primaryAgentNumber}</b>.
      </p>
    </>
  )
}
