import React, { Component } from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'

import silacLogo from './SILAC-vector.png'

class ClientSupport extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <h2
              style={{
                color: '#0072C0',
                fontFamily: 'Arial',
                marginTop: '40px',
              }}
            >
              Contact Us
            </h2>

            <br />

            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <br />

                    <div className="text-center">
                      <div className="card-body">
                        <img
                          className="profile-img img-fluid"
                          src={silacLogo}
                          width="400px"
                          alt="SILAC Logo"
                        />
                        <h5 className="card-title">SILAC Insurance Company</h5>
                        <p className="card-text">
                          299 South Main Street #1100 <br />
                          Salt Lake City, UT 84111
                        </p>
                        <div className="container">
                          <br />
                          <dl class="row">
                            <dt class="col-xl-12">Customer Service</dt>
                            <dd class="col-xl-12">
                              1 (800) 352-5150
                              <br />
                              Service@SILACins.com
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ClientSupport
