import { Close } from '@mui/icons-material'
import { Backdrop, Box, Button, Fade, IconButton, Modal } from '@mui/material'

interface IFadeModalProps {
  open: boolean
  title: string
  message?: string
  jsxMessage: any
  handleClose: (confirmed: boolean) => void
}

export const FadeModal = ({
  open,
  title,
  message,
  jsxMessage,
  handleClose,
}: IFadeModalProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={theme => ({
            backgroundColor: theme.palette.background.paper,
            border: '2px solid lightgray',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 1, 2),
            maxWidth: '40%',
          })}
        >
          <Box sx={{ float: 'left', fontSize: 16, fontWeight: 'bold' }}>
            {title || ''}
          </Box>
          <IconButton
            sx={{ float: 'right', marginRight: '2px' }}
            size="small"
            onClick={() => handleClose(false)}
          >
            <Close style={{ fontSize: 16 }} />
          </IconButton>
          <hr
            style={{
              height: '2px',
              borderWidth: 0,
              color: 'lightgray',
              marginTop: '30px',
              backgroundColor: 'lightgray',
            }}
          />
          <Box sx={{ margin: '30px', fontSize: 14, whiteSpace: 'pre-wrap' }}>
            {message || ''}
          </Box>
          {jsxMessage}
          <hr
            style={{
              height: '2px',
              borderWidth: 0,
              color: 'lightgray',
              marginTop: '30px',
              backgroundColor: 'lightgray',
            }}
          />
          <Button
            sx={{ float: 'right', marginRight: '10px' }}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => handleClose(true)}
          >
            Acknowledge
          </Button>
        </Box>
      </Fade>
    </Modal>
  )
}

export default FadeModal
