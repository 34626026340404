import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'
import * as Yup from 'yup'

import { AgentContractingContext } from '../../../_contexts/AgentContracting.context.tsx'
import ESeverity from '../../../_enums/ESeverity'
import { useToaster } from '../../../_hooks'
import authService from '../../../_services/authService'
import { store } from '../../../_store/store'
import Page from '../../layout/Page'

const RecruitAgent = () => {
  const { toast } = useToaster()

  const { state, postContractingRecruit } = useContext(AgentContractingContext)

  const {
    common: { notification },
  } = state

  const [formReset, setFormReset] = useState({
    init: false,
    reset: (form = false) => {},
  })

  const [agentInfo, setAgentInfo] = useState(
    store.getState().agentInfo
      ? store.getState().agentInfo.agentInfo
      : undefined
  )

  useEffect(() => {
    const unsubscribeAI = store.subscribe(() => {
      const storeAgentInfo = store.getState().agentInfo
        ? store.getState().agentInfo.agentInfo
        : undefined
      setAgentInfo(storeAgentInfo)
    })

    return () => {
      unsubscribeAI()
    }
  }, [])

  const initialData = {
    first_name: '',
    last_name: '',
    email: '',
    level: 'n/a',
  }

  const AgentLevels = {
    NMO1: 'National Marketing Office 1',
    NMO: 'National Marketing Office',
    FMO1: 'Field Marketing Office 1',
    FMO: 'Field Marketing Office',
    MGA2: 'Managing General Agent 2',
    MGA1: 'Managing General Agent 1',
    MGA: 'Managing General Agent',
    GA1: 'General Agent 1',
    GA: 'General Agent',
    PPGA1: 'Personal Producer General Agent 1',
    PPGA: 'Personal Producer General Agent',
    LOA: 'License Only Agent',
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(30, 'Max Length (30)')
      .required('Required')
      .matches(
        /^[a-zA-Z-]+$/,
        'Name can only contain alpha characters and dashes'
      ),
    last_name: Yup.string()
      .max(50, 'Max Length (50)')
      .required('Required')
      .matches(
        /^[a-zA-Z-]+$/,
        'Name can only contain alpha characters and dashes'
      ),
    email: Yup.string()
      .email('Invalid email')
      .max(254, 'Max Length (254)')
      .required('Required'),
    level: Yup.string().notOneOf(['n/a'], 'Make a selection'),
  })

  const getAgentLevels = (level = 'n/a') => {
    let leveled = false
    const options = Object.keys(AgentLevels)
      .filter(key => (key === level ? !(leveled = true) : leveled))
      .map((key, index) => (
        <option key={index} value={key}>
          {key}
        </option>
      ))
    options.splice(
      0,
      0,
      <option key={-1} value={'n/a'} disabled hidden>
        New Agent Level
      </option>
    )
    return options
  }

  const handleSubmit = (values, { errors, setSubmitting, resetForm }) => {
    if (!errors) {
      let agentNumber = authService.getUserName()
      postContractingRecruit(agentNumber, values)
    }
    if (!formReset.init) {
      setFormReset({
        init: true,
        reset: form => {
          setSubmitting(false)
          if (form) resetForm(initialData)
        },
      })
    }
  }

  useEffect(() => {
    if (notification) {
      toast(notification.status, notification.message)
      formReset.reset(notification.status === ESeverity.Success)
    }
    // NOTE: the disable warning below is for the 'toast' hook.
    // if this changes in the future, remove the eslint disable below
    // to ensure that it is the only dependency left out of the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <Page title="Contracting">
      <Row>
        <Col md="1" />
        <Col md="10">
          <Card>
            <CardHeader>
              <b>Recruit New Downline Agent</b>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={initialData}
                validateOnChange
                validateOnBlur
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  handleSubmit,
                  setValues,
                  errors,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="row-validations">
                      <Col md="3">
                        <Input
                          placeholder="First Name"
                          id="first_name"
                          type="text"
                          className="form-control"
                          spellCheck="false"
                          autoCapitalize="on"
                          value={values.first_name}
                          onChange={e => {
                            setValues({
                              ...values,
                              first_name: e.target.value,
                            })
                          }}
                        />
                        {errors.first_name && touched.first_name && (
                          <div className="error-field">{errors.first_name}</div>
                        )}
                      </Col>
                      <Col md="3">
                        <Input
                          placeholder="Last Name"
                          id="last_name"
                          type="text"
                          className="form-control"
                          spellCheck="false"
                          autoCapitalize="on"
                          value={values.last_name}
                          onChange={e => {
                            setValues({
                              ...values,
                              last_name: e.target.value,
                            })
                          }}
                        />
                        {errors.last_name && touched.last_name && (
                          <div className="error-field">{errors.last_name}</div>
                        )}
                      </Col>
                      <Col md="3">
                        <Input
                          placeholder="Recruiting Email"
                          id="email"
                          type="email"
                          className="form-control"
                          spellCheck="false"
                          autoComplete="on"
                          value={values.email}
                          onChange={e => {
                            setValues({
                              ...values,
                              email: e.target.value,
                            })
                          }}
                        />
                        {errors.email && touched.email && (
                          <div className="error-field">{errors.email}</div>
                        )}
                      </Col>
                      <Col md="3">
                        <Input
                          id="level"
                          type="select"
                          className="form-control"
                          spellCheck="false"
                          value={values.level}
                          onChange={e => {
                            setValues({
                              ...values,
                              level: e.target.value,
                            })
                          }}
                        >
                          {getAgentLevels(agentInfo ? agentInfo.level : '')}
                        </Input>
                        {errors.level && touched.level && (
                          <div className="error-field">{errors.level}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className="row-buttons">
                      <Col>
                        <Button
                          type="submit"
                          className="float-right float-bottom"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

export default RecruitAgent
