import { Button } from '@mui/material'
import classnames from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'

import silacLogo from '../layout/SILAC-vector.png'
import Uploader from '../uploader'
import './AgentSupport.css'

const AgentSupport = () => {
  const [activeTab, setActiveTab] = useState('1')

  return (
    <div className="row">
      <div className="col-md-12">
        <Row>
          <Col md="12">
            <Link to="/agent/products/sales-materials">
              <Button className="float-right">Sales Materials</Button>
            </Link>
          </Col>
        </Row>

        <br />

        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames('support-tabs', {
                    active: activeTab === '1',
                  })}
                  onClick={() => {
                    setActiveTab('1')
                  }}
                >
                  Contact Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames('support-tabs', {
                    active: activeTab === '2',
                  })}
                  onClick={() => {
                    setActiveTab('2')
                  }}
                >
                  Product Training
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames('support-tabs', {
                    active: activeTab === '3',
                  })}
                  onClick={() => {
                    setActiveTab('3')
                  }}
                >
                  Upload Files
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames('support-tabs', {
                    active: activeTab === '4',
                  })}
                  onClick={() => {
                    setActiveTab('4')
                  }}
                >
                  Webinars
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <br />

                    <div className="text-center">
                      <div className="card-body">
                        <img
                          className="profile-img img-fluid"
                          src={silacLogo}
                          width="400px"
                          alt="logo"
                        />
                        <h5 className="card-title">SILAC Insurance Company</h5>
                        <div className="container">
                          <br />
                          <dl className="row">
                            <dt className="col-xl-12">Mailing Address</dt>
                            <dd className="col-xl-12">
                              SILAC Insurance Company
                              <br />
                              P.O. Box 30245
                              <br />
                              Salt Lake City, UT 84130-0245
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">Overnight Address</dt>
                            <dd className="col-xl-12">
                              SILAC Insurance Company
                              <br />
                              299 South Main Street, #1100
                              <br />
                              Salt Lake City, UT 84111
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">Business Hours</dt>
                            <dd className="col-xl-12">
                              6:30 AM – 5:00 PM MST Monday-Friday
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">Agency Services</dt>
                            <dd className="col-xl-12">
                              Phone: (888) 352-5120
                              <br />
                              Fax: (801) 579-3781
                              <br />
                              <a href="mailto:Annuity.AgencyServices@SILACins.com">
                                Annuity.AgencyServices@SILACins.com
                              </a>
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">New Business</dt>
                            <dd className="col-xl-12">
                              Phone: (888) 352-5178
                              <br />
                              Fax (888) 352-5126
                              <br />
                              <a href="mailto:Annuity.New.Business@SILACins.com">
                                Annuity.New.Business@SILACins.com
                              </a>
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">FIA Service Center</dt>
                            <dd className="col-xl-12">
                              Phone: (888) 352-5122
                              <br />
                              Fax: (801) 812-8789
                              <br />
                              <a href="mailto:Annuity.POS@SILACins.com">
                                Annuity.POS@SILACins.com
                              </a>
                            </dd>
                          </dl>
                          <dl className="row">
                            <dt className="col-xl-12">MYGA Service Center</dt>
                            <dd className="col-xl-12">
                              Phone: (833) 889-0910
                              <br />
                              Fax: (336) 419-0279
                              <br />
                              <a href="Annuity.POS@SILACins.com">
                                Annuity.POS@SILACins.com
                              </a>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <br />

                    <Row>
                      <Col md={{ size: 3 }}>
                        <div className="card text-center">
                          <div className="card-header">Training</div>
                          <div className="card-body">
                            <h5 className="card-title">Vega Series</h5>
                            <p className="card-text">
                              A Galaxy of Opportunities
                            </p>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://www.silacins.com/agent/training',
                                  '_blank'
                                )
                              }
                            >
                              Click Here
                            </Button>{' '}
                          </div>
                        </div>
                      </Col>

                      <Col md={{ size: 3 }}>
                        <div className="card text-center">
                          <div className="card-header">Training</div>
                          <div className="card-body">
                            <h5 className="card-title">Denali Series</h5>
                            <p className="card-text">
                              Accumulation and Lifetime Income
                            </p>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://www.silacins.com/agent/training',
                                  '_blank'
                                )
                              }
                            >
                              Click Here
                            </Button>{' '}
                          </div>
                        </div>
                      </Col>

                      <Col md={{ size: 3 }}>
                        <div className="card text-center">
                          <div className="card-header">Training</div>
                          <div className="card-body">
                            <h5 className="card-title">Teton Series</h5>
                            <p className="card-text">
                              Experience, Innovation & Excellence
                            </p>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://www.silacins.com/agent/training',
                                  '_blank'
                                )
                              }
                            >
                              Click Here
                            </Button>
                          </div>
                        </div>
                      </Col>

                      <Col md={{ size: 3 }}>
                        <div className="card text-center">
                          <div className="card-header">Training</div>
                          <div className="card-body">
                            <h5 className="card-title">
                              Secure Savings Series
                            </h5>
                            <p className="card-text">
                              Safety &amp; Security with Options
                            </p>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://www.silacins.com/agent/training',
                                  '_blank'
                                )
                              }
                            >
                              Click Here
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <br />
                    <Uploader />
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <br />

                    <Row>
                      <Col md={{ size: 4, offset: 4 }}>
                        <div className="card text-center">
                          <div className="card-header">Webinars</div>
                          <div className="card-body">
                            <h5 className="card-title">SILAC Videos</h5>
                            <p className="card-text">
                              Follow this link to see all of SILAC's videos
                            </p>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://vimeo.com/showcase/6095559',
                                  '_blank'
                                )
                              }
                            >
                              Click Here
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default AgentSupport
