import { Alert, Chip } from '@mui/material'

import Table, { EDataType } from '../../../_components/Table'
import { useStore } from '../../../_hooks/use-store'

function Beneficiaries() {
  const { store } = useStore()
  const beneficiaries = store?.selectedPolicy?.data?.beneficiaries

  if (!beneficiaries?.length) {
    return (
      <Alert color="warning">
        There are currently no beneficiaries at this time.
      </Alert>
    )
  }

  return (
    <Table
      columns={[
        {
          field: 'name',
          displayName: 'Name',
        },
        {
          field: 'type',
          displayName: 'Type',
          options: {
            render: val => (
              <Chip
                label={val}
                color={val === 'Primary' ? 'primary' : 'secondary'}
              />
            ),
          },
        },
        {
          field: 'percentage',
          displayName: 'Percent',
          options: {
            dataType: EDataType.percentage,
          },
        },
      ]}
      rows={beneficiaries}
      dataKey="uuid"
    />
  )
}

export default Beneficiaries
