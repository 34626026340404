export function authTokens(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_TOKENS':
      return {
        ...action.payload,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
      }
    case 'DELETE_TOKENS':
      return {
        ...action.payload,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
      }
    case 'REFRESH_ACCESS_TOKEN':
      return {
        ...action.payload,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
      }
    default:
      return state
  }
}
