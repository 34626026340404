import { Box, Button } from '@mui/material'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import denaliBonusLogo from '../../../agent-components/Images/denali-bonus-evolve.png'
import evolveLogo from '../../../agent-components/Images/evolve-logo-black.png'
import FirelightLogin from '../../firelight'
import './ProductsDetailDenaliBonus.css'

class ProductDetailDenaliBonus extends Component {
  render() {
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Denali FIA Series
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-3">
            <Card>
              <CardImg src={denaliBonusLogo} />
            </Card>
          </div>
          <div className="col-md-9 productText">
            <Row>
              <Col md="12">
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/Denali Bonus Brochure.pdf',
                      '_blank'
                    )
                  }
                >
                  Brochure
                </Button>
                <Link className="mr-1" to={`/agent/sales-tools/illustration`}>
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Run an Illustration
                  </Button>
                </Link>
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://www.silacins.com/agent/training',
                      '_blank'
                    )
                  }
                >
                  Product Training
                </Button>
                <Link to="/agent/products/sales-materials/6">
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Sales Materials
                  </Button>
                </Link>
                <FirelightLogin sx={{ marginTop: 1, marginRight: 1 }}>
                  Start eApp
                </FirelightLogin>
              </Col>
            </Row>
            <hr />
            <br />
            <article>
              <p>
                Denali is North America's tallest mountain. It grows higher and
                faster than most mountains in the world and is still growing!
                Its location makes it more resistant to erosion.
                <br></br>
                <br></br>
                The Denali Bonus Series is our product suite where your client
                can get the best of both accumulation and lifetime income plus
                an upfront premium bonus. It's a modified single premium fixed
                indexed annuity series with a 7-, 10- and 14-year version so
                that you can find the term that best suits your client's needs.
                <br></br>
                <br></br>
                It automatically comes with one fixed interest and many-indexed
                crediting strategies. Annual free withdrawal, lifetime
                withdrawal, wellness withdrawal, home health care, nursing care,
                terminal illness and wealth transfer benefits are all
                automatically included with this product line for no charge.
                <br></br>
                <Box marginTop={4} marginBottom={2}>
                  <a
                    rel="noreferrer"
                    href="https://www.silacins.com/evolve"
                    target="_blank"
                  >
                    <img
                      width={250}
                      alt="evolve logo"
                      src={evolveLogo}
                    />
                  </a>
                </Box>
                Denali can now be enhanced with the optional Evolve Rider.  Evolve 
                provides an additional premium bonus, enhanced liquidity and an
                enhanced lifetime withdrawal benefit.  These benefits are available
                for an annual spread.
              </p>
            </article>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductDetailDenaliBonus
