export const SelectOptions = ({ options }) => {
  if (options && options.length > 0) {
    return options.map((o, i) => {
      const { label, value } = o

      return (
        <option key={`${label}_${i}`} value={value}>
          {label}
        </option>
      )
    })
  }

  return null
}
