import * as Yup from 'yup'

import { moneyFormat, stripCommas } from '../../../../_helpers/money'

const MIN_INITIAL_PREMIUM_VALUE = 10000
const MIN_ADITIONAL_PREMIUM_VALUE = 2000
const REQUIRED_MSG = 'This field is required.'

export const validationSchema = (min_age: number, max_age: number) =>
  Yup.object().shape({
    ownerName: Yup.string().required(REQUIRED_MSG),
    ownerAge: Yup.number()
      .min(min_age)
      .max(max_age)
      .required(REQUIRED_MSG)
      .label('Owner Age'),
    jointOwnerName: Yup.string().notRequired().nullable(),
    jointOwnerAge: Yup.number()
      .notRequired()
      .nullable()
      .min(min_age)
      .max(max_age)
      .label('Joint Owner Age')
      .when('jointOwnerName', {
        is: jointOwnerName => Boolean(jointOwnerName),
        then: Yup.number().required(REQUIRED_MSG),
        otherwise: Yup.number(),
      }),

    initialPremium: Yup.string()
      .required(REQUIRED_MSG)
      .test(
        'is-valid',
        `Amount must be greater than ${moneyFormat(
          MIN_INITIAL_PREMIUM_VALUE
        )}.`,
        value => {
          return Number(stripCommas(value)) >= MIN_INITIAL_PREMIUM_VALUE
        }
      ),
    additionalPremiumYear1: Yup.string()
      .notRequired()
      .nullable()
      .test(
        'is-valid',
        `Amount must be greater than ${moneyFormat(
          MIN_ADITIONAL_PREMIUM_VALUE
        )}.`,
        value => {
          return (
            !Number(stripCommas(value)) ||
            Number(stripCommas(value)) >= MIN_ADITIONAL_PREMIUM_VALUE
          )
        }
      ),
    typeOfFunds: Yup.string().required(REQUIRED_MSG),
  })
