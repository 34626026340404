import React, { PureComponent } from 'react'

// Renders message of what listings the client is seeing out of how many total results
export default class PagesDisplayedMsg extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.startPage !== this.props.startPage) {
      this.setState(prevState => ({
        ...prevState,
      }))
    }
  }

  render() {
    const { perPage, offset, totalRecords } = this.props
    let startPage = offset + 1
    let endPage = Math.min(parseInt(offset) + parseInt(perPage), totalRecords)

    if (startPage > 0 && endPage > 0 && totalRecords > 0) {
      return (
        <div className="ng-tns-c2-1" style={{ margin: '20px 0' }}>
          Displaying {startPage}-{endPage} of {totalRecords}
        </div>
      )
    }
    return ''
  }
}

/*  */
