import { Box, Pagination } from '@mui/material'
import moment from 'moment'
import { useEffect, useReducer, useState } from 'react'
import { Col, Row } from 'reactstrap'

import * as actions from '../../../_actions/AgentContracting.actions'
import { asyncDispatch } from '../../../_contexts/index'
import { IStore, getStore } from '../../../_contexts/store'
import { TStore } from '../../../_contexts/types'
import reducers from '../../../_reducers/AgentContracting.reducers'
import PagesDisplayedMsg from '../PolicySearch/PagesDisplayedMsg'
import ContractingRecruitItem from './AgentContractingListItem'
import { IAgentContractingStore } from './types'

const AgentContractList = ({ agentNumber }) => {
  const [state, dispatch] = useReducer<TStore>(
    reducers,
    getStore<IAgentContractingStore>() as IStore
  )

  const [pageState, setpageState] = useState({
    perPage: 10,
    offset: 0,
    activePage: 1,
    pageRangeDisplayed: 7,
  })

  const getDownlineInvites = (
    agentNumber: string,
    perPage: number,
    activePage: number
  ) => {
    asyncDispatch(
      dispatch,
      actions.getDownlineInvites(agentNumber, perPage, activePage)
    )
  }

  const handlePaginationPageChange = (_, pageNumber) => {
    setpageState({
      ...pageState,
      offset: (pageNumber - 1) * pageState.perPage,
      activePage: pageNumber,
    })
  }

  const {
    downline_invites: {
      downline_invites: contractingRecruits,
      count: totalRecords,
    },
  } = state

  useEffect(() => {
    if (agentNumber) {
      getDownlineInvites(agentNumber, pageState.perPage, pageState.activePage)
    }
  }, [agentNumber, pageState.perPage, pageState.activePage])

  return contractingRecruits ? (
    <Row>
      <Col classname="col-md-12">
        {contractingRecruits.length === 0 ? (
          <div>
            <p>There are currently no downline invites available</p>
          </div>
        ) : (
          <div>
            <table className="table table-hover">
              <thead>
                <th>Invite Sent By</th>
                <th>Invite Sent To</th>
                <th>Invitee Email</th>
                <th>Invitee Level</th>
                <th>Date Sent</th>
                <th>Date Completed</th>
                <th>Status</th>
              </thead>
              <tbody>
                {contractingRecruits.map((recruit, i) => (
                  <ContractingRecruitItem
                    key={i}
                    id={recruit.id}
                    inviter_agent_number={recruit.inviter_agent_number}
                    first_name={recruit.first_name}
                    last_name={recruit.last_name}
                    email={recruit.email}
                    level={recruit.level}
                    invitation_date={moment(recruit.invitation_date).format(
                      'M/D/yyyy'
                    )}
                    invitation_status={recruit.status_name}
                    vertafore_packet_id={recruit.vertafore_packet_id}
                    onboarded_agent_number={recruit.onboarded_agent_number}
                    completed_date={
                      !!recruit.completed_date
                        ? moment(recruit.completed_date).format('MM/D/yyyy')
                        : 'N/A'
                    }
                  />
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-3">
                <PagesDisplayedMsg
                  perPage={pageState.perPage}
                  offset={pageState.offset}
                  totalRecords={totalRecords}
                />
              </div>
              {Boolean(totalRecords) && (
                <Box
                  className="col"
                  marginTop={2}
                  sx={{
                    ul: {
                      justifyContent: 'flex-end',
                    },
                  }}
                >
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    color="primary"
                    size="large"
                    page={pageState.activePage}
                    count={Math.ceil(Number(totalRecords) / pageState.perPage)}
                    onChange={handlePaginationPageChange}
                  />
                </Box>
              )}
            </div>
          </div>
        )}
      </Col>
    </Row>
  ) : null
}

export default AgentContractList
