/* eslint-disable no-script-url */
import InfoRounded from '@mui/icons-material/InfoRounded'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

export interface IPaperDisplay {
  displayId: string
  displayValue: string | React.ReactNode
  displayTitle: string
  toolTipText?: string
  toolTipPlacement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
  footerAction?: { actionText: string; action: () => void }
  isLoading?: boolean
}

export function PaperDisplay({
  displayId,
  displayValue,
  displayTitle,
  toolTipText,
  toolTipPlacement,
  footerAction,
  isLoading = false,
}: IPaperDisplay) {
  return (
    <Paper
      elevation={3}
      className="single-value-container"
      data-cy={'PaperDisplay' + displayId}
    >
      <h5 className="m-b-20 text-muted">
        {displayTitle}
        {toolTipText && (
          <Tooltip
            arrow
            title={toolTipText}
            placement={toolTipPlacement ? toolTipPlacement : 'bottom'}
          >
            <InfoRounded htmlColor="#000000" fontSize="small" />
          </Tooltip>
        )}
      </h5>
      <h2>{displayValue}</h2>
      {footerAction && (
        <div className="paper-display-footer">
          <a
            href="Javascript:void(0)"
            onClick={footerAction.action}
            className={isLoading ? 'a-disabled' : ''}
          >
            {footerAction.actionText}
          </a>
        </div>
      )}
    </Paper>
  )
}
