import {
  IPostContractingRecruitAction,
  IGetDownlineInvitesAction,
} from '../_actions/AgentContracting.actions'
import { createReducer } from '../_contexts/index'
import { TReducer } from '../_contexts/types'
import EAction from '../_enums/EAction'

const handlePostContractingRecruit: TReducer<IPostContractingRecruitAction> = (
  store,
  { notification }
) => {
  return {
    ...store,
    common: {
      ...store.common,
      notification: notification,
    },
  }
}

const handleGetDownlineInvites: TReducer<IGetDownlineInvitesAction> = (
  store,
  { payload, notification }
) => {
  return {
    ...store,
    common: {
      ...store.common,
      notification: notification,
    },
    downline_invites: {
      ...store.downline_invites,
      downline_invites: payload.data || [],
      count: payload.count || 0,
      number_of_pages: payload.num_pages || 0,
      page_number: payload.page_number || 0,
    },
  }
}

export default createReducer({
  [EAction.PostContractingRecruit]: handlePostContractingRecruit,
  [EAction.GetDownlineInvites]: handleGetDownlineInvites,
})
