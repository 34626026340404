import { useEffect, useState } from 'react'

interface IChat {
  shouldLoadChat: boolean
  align: string
}

const CHAT_COMMON_JS = '/CHAT/common/js/chat.js'

const useChat = ({ align, shouldLoadChat }: IChat) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const buttonContainerId = `__8x8-chat-button-container-${window._env_.REACT_APP_CHAT_UUID}`

    if (!shouldLoadChat) {
      document.getElementById(buttonContainerId)?.remove()
      return
    }

    window.__8x8Chat = {
      uuid: window._env_.REACT_APP_CHAT_UUID,
      tenant: window._env_.REACT_APP_CHAT_TENANT,
      channel: window._env_.REACT_APP_CHAT_CHANNEL,
      domain: window._env_.REACT_APP_CHAT_DOMAIN,
      path: window._env_.REACT_APP_CHAT_PATH,
      buttonContainerId,
      align,
    }

    const loadChat = () => {
      const div = document.createElement('div')
      div.setAttribute('id', buttonContainerId)
      div.className = 'chat'
      document.body.appendChild(div)

      const se = document.createElement('script')
      se.type = 'text/javascript'
      se.async = true
      se.src = window.__8x8Chat.domain + window.__8x8Chat.path + CHAT_COMMON_JS

      const os = document.getElementsByTagName('script')[0]
      os.parentNode!.insertBefore(se, os)
      setIsLoaded(true)
    }

    loadChat()
  }, [shouldLoadChat, align])

  return isLoaded
}
export default useChat
