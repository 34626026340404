import './Loader.css'

function Loader({ title, subtitle = '' }) {
  return (
    <div className="loader-container">
      <div className="card">
        <h3>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
        <div className="loader">
          <div className="spin"></div>
          <div className="bounce"></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
