import { FC } from 'react'

// display when no results
type NoResultsProps = {
  text: string
}

const NoResults: FC<NoResultsProps> = props => {
  return (
    <tr>
      <td
        colSpan={8}
        className="text-center p-4"
        style={{ backgroundColor: '#fff' }}
      >
        <b />
        {props.text}
        <b />
      </td>
    </tr>
  )
}

export default NoResults
