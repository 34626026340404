// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSort,
  faCaretDown,
  faCaretUp,
  faInfoCircle,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faLongArrowAltRight,
  faBars,
  faCalendar,
  faPhone,
  faAddressCard,
  faTachometerAlt,
  faList,
  faMoneyBillAlt,
  faArchive,
  faTools,
  faUserAlt,
  faSatellite,
  faQuestionCircle,
  faFile,
  faCalculator,
  faChartLine,
  faMoneyBill,
  faUser,
  faUserCheck,
  faUsers,
  faClinicMedical,
  faFileSignature,
  faEnvelope,
  faAt,
  faChartPie,
  faPercent,
  faDollarSign,
  faCheckCircle,
  faExclamationTriangle,
  faSquare,
  faGavel,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import 'chart.js/auto'
import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import './App.css'
import { AppContext, initialPolicyContext } from './_common/_store/AppContext'
import { appReducer } from './_common/_store/reducers/app-reducers'
import EUserGroup from './_enums/EUserGroup'
import useChat from './_hooks/useChat'
import AgentContractingRoutes from './_routes/AgentContracting.routes'
import authService from './_services/authService'
import init from './_services/initService'
import { ScrollToTop } from './components'
import AgentHome from './components/agent-components/AgentHome'
import AgentMyAgents from './components/agent-components/AgentMyAgents'
import AgentMyBusiness from './components/agent-components/AgentMyBusiness'
import AgentMyProfile from './components/agent-components/AgentMyProfile'
import AgentSupport from './components/agent-components/AgentSupport'
import PrivateRoute from './components/agent-components/PrivateRoute'
import RenewalsCalculator from './components/agent-components/RenewalsCalculator'
import AgentSalesMaterials from './components/agent-components/SalesMaterials'
import AgentSalesMaterialsExternal from './components/agent-components/SalesMaterials/external.js'
import SecureSavingsCalculator from './components/agent-components/SecureSavingsCalculator'
import AgentCommissions from './components/agent-components/agent-commissions/AgentCommissions'
import AgentCommunication from './components/agent-components/agent-communications/AgentCommunication'
import ProductDetailDenaliBonus from './components/agent-components/agent-products/denali-bonus/ProductsDetailDenaliBonus'
import ProductDetailDenali from './components/agent-components/agent-products/denali/ProductsDetailDenali'
import SecureSavingsEliteDetail from './components/agent-components/agent-products/secure-savings-elite/SecureSavingsEliteDetail'
import SecureSavingsDetail from './components/agent-components/agent-products/secure-savings/SecureSavingsDetail'
import ProductDetailVegaBonus from './components/agent-components/agent-products/vega-bonus/ProductsDetailVegaBonus'
import ProductDetailVega from './components/agent-components/agent-products/vega/ProductsDetailVega'
import AgentReportsDynamic from './components/agent-components/agent-reports-dynamic'
import { ClientHome } from './components/client-components/ClientHome'
import ClientProfile from './components/client-components/ClientProfile/ClientProfile'
import ClientSelfService from './components/client-components/ClientSelfService/ClientSelfService'
import ClientSupport from './components/client-components/ClientSupport/ClientSupport'
import ClientPolicyDetails from './components/client-components/PolicyDetails'
import { ForgotPassword } from './components/layout/ForgotPassword'
import InnactiveModal from './components/layout/InnactiveModal'
import { Logout } from './components/layout/Logout'
import ResetPassword from './components/layout/ResetPassword'
import { MarketingCampaing } from './pages'
import Forbidden from './pages/Forbidden'
import { Login } from './pages/Login'
import NotFound from './pages/NotFound'
import SalesTools from './pages/SalesTools'
import Illustration from './pages/SalesTools/Illustration'
import Indices from './pages/SalesTools/Indices'
import Products, {
  TetonBonusSeriesPage,
  TetonSeriesPage,
} from './pages/agent/products'

library.add(
  faSort,
  faCaretDown,
  faCaretUp,
  faInfoCircle,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faLongArrowAltRight,
  faBars,
  faCalendar,
  faPhone,
  faAddressCard,
  faTachometerAlt,
  faList,
  faMoneyBillAlt,
  faArchive,
  faTools,
  faUserAlt,
  faSatellite,
  faQuestionCircle,
  faFile,
  faCalculator,
  faChartLine,
  faMoneyBill,
  faUser,
  faUserCheck,
  faUsers,
  faClinicMedical,
  faFileSignature,
  faEnvelope,
  faAt,
  faChartPie,
  faPercent,
  faDollarSign,
  faCheckCircle,
  faExclamationTriangle,
  faSquare,
  faGavel,
  faExternalLinkAlt
)

if (process.env.NODE_ENV !== 'test') {
  ReactGA.initialize('UA-5427275-19')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

function Chat({ store }) {
  const [isAgent, setIsAgent] = useState(authService.isAgent())
  const location = useLocation()

  useEffect(() => {
    setIsAgent(authService.isAgent())
  }, [location])

  useChat({
    align: 'right',
    shouldLoadChat: Boolean(
      store?.environment?.version.feature_flags.includes('portal-chat') &&
        isAgent
    ),
  })
  return null
}

function App() {
  // As tempted as you are the put the init() in a useEffect, don't. This is
  // your warning that there will be a race condition between this useEffect
  // and the useEffects within all components. To put it bluntly, you'll start
  // having 403-Forbidden results after a F5 refesh.

  const initService = useRef(true)

  if (initService.current) {
    initService.current = false
    init()
  }

  const [store, dispatch] = React.useReducer(appReducer, initialPolicyContext)

  return (
    <AppContext.Provider value={{ store, dispatch }}>
      <Router>
        <ScrollToTop />
        {/* this should be changed with the new structure*/}
        <Chat store={store} />
        <InnactiveModal />
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <PrivateRoute
            exact
            path="/agent"
            component={AgentHome}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products"
            component={Products}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products/denali-series"
            component={ProductDetailDenali}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products/denali-bonus-series"
            component={ProductDetailDenaliBonus}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products/teton-series"
            component={TetonSeriesPage}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products/teton-bonus-series"
            component={TetonBonusSeriesPage}
            allowed={['annuity-agent', 'annuity-home-office']}
          />

          <PrivateRoute
            exact
            path="/agent/products/vega-series"
            component={ProductDetailVega}
            allowed={['annuity-agent', 'annuity-home-office']}
          />

          <PrivateRoute
            exact
            path="/agent/products/vega-bonus-series"
            component={ProductDetailVegaBonus}
            allowed={['annuity-agent', 'annuity-home-office']}
          />

          <PrivateRoute
            exact
            path="/agent/products/secure-savings"
            component={SecureSavingsDetail}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/products/secure-savings-elite"
            component={SecureSavingsEliteDetail}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <Route
            exact
            path="/agent/products/salesmaterials/:product?/:showBranding?/:imoHash"
            component={AgentSalesMaterialsExternal}
          />
          <PrivateRoute
            path="/agent/products/sales-materials/:product?"
            component={AgentSalesMaterials}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            path="/agent/my-business"
            component={AgentMyBusiness}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/my-agents"
            component={AgentMyAgents}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/sales-tools"
            component={SalesTools}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/sales-tools/secure-savings-calculator"
            component={SecureSavingsCalculator}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/sales-tools/renewals-calculator"
            component={RenewalsCalculator}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/sales-tools/indices"
            component={Indices}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/sales-tools/illustration"
            component={Illustration}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/communication"
            component={AgentCommunication}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/commissions"
            component={AgentCommissions}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/reports"
            component={AgentReportsDynamic}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/my-profile"
            component={AgentMyProfile}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/support"
            component={AgentSupport}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            exact
            path="/agent/marketing-campaign/:id"
            component={MarketingCampaing}
            allowed={['annuity-agent', 'annuity-home-office']}
          />
          <PrivateRoute
            path="/agent/contracting"
            component={AgentContractingRoutes}
            allowed={[EUserGroup.AgentContracting]}
          />
          <PrivateRoute
            exact
            path="/client"
            component={ClientHome}
            allowed={['annuity-client']}
          />
          <PrivateRoute
            exact
            path="/client/self-service"
            component={ClientSelfService}
            allowed={['annuity-client']}
          />
          <PrivateRoute
            exact
            path="/client/profile"
            component={ClientProfile}
            allowed={['annuity-client']}
          />
          <PrivateRoute
            path="/client/policy/details/:policyNum"
            component={ClientPolicyDetails}
            allowed={['annuity-client']}
          />
          <PrivateRoute
            exact
            path="/client/support"
            component={ClientSupport}
            allowed={['annuity-client']}
          />

          <PrivateRoute
            exact
            path="/forbidden"
            component={Forbidden}
            allowed={['annuity-agent', 'annuity-home-office', 'annuity-client']}
          />

          <PrivateRoute
            exact
            path="/not-found"
            component={NotFound}
            allowed={['annuity-agent', 'annuity-home-office', 'annuity-client']}
          />

          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/" component={ResetPassword} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </AppContext.Provider>
  )
}

export default App
