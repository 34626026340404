const initialAnnouncement = {
  open: false,
  viewed: false,
  acknowledged: false,
  title: '',
  message: '',
}

const initialState = {
  announcements: { ...initialAnnouncement },
}

export function announcements(state = initialState, action) {
  switch (action.type) {
    case 'ANNOUNCEMENT_VIEWED':
      const existing = !state[action.payload.key]
        ? initialAnnouncement
        : state[action.payload.key]
      return {
        ...state,
        ...{
          [action.payload.key]: {
            ...existing,
            viewed: !!action.payload.viewed,
          },
        },
      }
    default:
      return state
  }
}
