import { Button } from '@mui/material'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import secureSavingsEliteLogo from '../../../agent-components/Images/SSE-placeholder.jpg'
import FirelightLogin from '../../firelight'
import './SecureSavingsEliteDetail.css'

class SecureSavingsEliteDetail extends Component {
  render() {
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Secure Savings Elite
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-3">
            <Card>
              <CardImg src={secureSavingsEliteLogo} />
            </Card>
          </div>
          <div className="col-md-9 productText">
            <Row>
              <Col md="12">
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/SS ELITE Brochure.pdf',
                      '_blank'
                    )
                  }
                >
                  Brochure
                </Button>
                <Link to={`/agent/sales-tools/secure-savings-calculator`}>
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Run a Calculation
                  </Button>
                </Link>
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://www.silacins.com/agent/training',
                      '_blank'
                    )
                  }
                >
                  Product Training
                </Button>
                <Link
                  to="/agent/products/sales-materials/2"
                  style={{ 'text-decoration': 'none' }}
                >
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Sales Materials
                  </Button>
                </Link>
                <FirelightLogin sx={{ marginTop: 1, marginRight: 1 }}>
                  Start eApp
                </FirelightLogin>
              </Col>
            </Row>
            <hr />
            <br />
            <article>
              <p>
                The Secure Savings Elite series provides the highest guaranteed
                fixed interest rates that we offer and allows your client to
                customize the product to best fit his or her needs. It’s a
                single premium Multi-Year Guaranteed Annuity series with a 2-,
                3- and 5-year rate guarantee period to choose from. Optional
                free withdrawal benefits are available on the 2-, 3- and 5-year
                versions for a slightly reduced interest rate. Spousal
                continuation at death is automatically included. If your client
                is looking for the highest guaranteed interest rate possible,
                then go Elite!
              </p>
            </article>
          </div>
        </div>
      </div>
    )
  }
}

export default SecureSavingsEliteDetail
