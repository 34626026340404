import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { Link } from 'react-router-dom'

function ListItem({
  policyNum,
  product,
  years,
  accountVal,
  owner,
  issueDate,
  annuitant,
}) {
  return (
    <tr>
      <td>
        <b>
          <Link to={`/client/policy/details/${policyNum}`}>{policyNum}</Link>
        </b>
      </td>
      <td>{product}</td>
      <td>{years}</td>
      {owner === 'null null' ? <td>{annuitant}</td> : <td>{owner}</td>}
      {issueDate === '-' ? (
        <td>-</td>
      ) : (
        <td>{moment(issueDate).format('L')}</td>
      )}
      <td>
        <span className="float-right">
          {numeral(accountVal).format('$0,0.00')}
        </span>
      </td>
    </tr>
  )
}

export default ListItem
