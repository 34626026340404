import { store } from '../_store/store'

const ANNOUNCEMENT_ON = false

const announcementService = {
  viewed(viewed = false, key = 'announcement') {
    const value =
      key === 'announcement' ? (ANNOUNCEMENT_ON ? viewed : true) : viewed
    store.dispatch({
      type: 'ANNOUNCEMENT_VIEWED',
      payload: { viewed: value, key },
    })
  },
  acknowledged(acknowledged = false, key = 'announcement') {
    if (acknowledged) {
      sessionStorage.setItem(key, 'acknowledged')
    } else {
      sessionStorage.removeItem(key)
    }
  },
  isAcknowledged(key = 'announcement') {
    if (key !== 'announcement') {
      return !!sessionStorage.getItem(key)
    }
    return ANNOUNCEMENT_ON ? !!sessionStorage.getItem(key) : true
  },
}

export default announcementService
