import { IGetPolicyEventsAction } from '../_actions/PolicyEvents.actions'
import { createReducer } from '../_contexts'
import { TReducer } from '../_contexts/types'
import { EAction } from '../_enums'

const handleGetPolicyEvents: TReducer<IGetPolicyEventsAction> = (
  store,
  { payload }
) => {
  return {
    ...store,
    common: {
      ...store.common,
      notification: undefined,
    },
    policy_events: {
      ...store.policy_events,
      policy_events: payload.data,
    },
  }
}

export default createReducer({
  [EAction.GetPolicyEvents]: handleGetPolicyEvents,
})
