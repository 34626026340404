import { Button } from '@mui/material'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import secureSavingsLogo from '../../../agent-components/Images/SS-placeholder.jpg'
import FirelightLogin from '../../firelight'
import './SecureSavingsDetail.css'

class SecureSavingsDetail extends Component {
  render() {
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Secure Savings
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-3">
            <Card>
              <CardImg src={secureSavingsLogo} />
            </Card>
          </div>
          <div className="col-md-9 productText">
            <Row>
              <Col md="12">
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://media.SILACins.com/annuity/Secure Savings Brochure.pdf',
                      '_blank'
                    )
                  }
                >
                  Brochure
                </Button>
                <Link to={`/agent/sales-tools/secure-savings-calculator`}>
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Run a Calculation
                  </Button>
                </Link>
                <Button
                  sx={{ marginTop: 1, marginRight: 1 }}
                  onClick={() =>
                    window.open(
                      'https://www.silacins.com/agent/training',
                      '_blank'
                    )
                  }
                >
                  Product Training
                </Button>
                <Link
                  to="/agent/products/sales-materials/1"
                  style={{ 'text-decoration': 'none' }}
                >
                  <Button sx={{ marginTop: 1, marginRight: 1 }}>
                    Sales Materials
                  </Button>
                </Link>
                <FirelightLogin sx={{ marginTop: 1, marginRight: 1 }}>
                  Start eApp
                </FirelightLogin>
              </Col>
            </Row>
            <hr />
            <br />
            <article>
              <p>
                The Secure Savings is our flagship MYGA series that provides the
                highest guaranteed fixed interest rates that we offer paired
                with built-in liquidity provisions. It's a single premium
                Multi-Year Guaranteed Annuity series with a 2-, 3- and 5-year
                rate guarantee period to choose from. Annual free withdrawals,
                full account value at death and spousal continuation are
                automatically included free of charge. If your client is looking
                for the highest guaranteed interest rate possible with built in
                liquidity, then Secure Savings is your solution.
              </p>
            </article>
          </div>
        </div>
      </div>
    )
  }
}

export default SecureSavingsDetail
