export enum EWithdrawalCodeType {
  None = 'none',
  PercentOfAv = 'percentofav',
  Amount = 'amount',
  Lifetime = 'lifetime',
  Level = 'level',
  Increasing = 'increasing',
  Accelerated = 'accelerated',
  RMD = 'rmd',
  MAXIMUM_CUMULATIVE = 'MAXIMUM_CUMULATIVE',
}

export class EWithdrawalType {
  static readonly None = new EWithdrawalType(EWithdrawalCodeType.None, 'None')
  static readonly PercentOfAv = new EWithdrawalType(
    EWithdrawalCodeType.PercentOfAv,
    'Percent of Account Value'
  )
  static readonly Amount = new EWithdrawalType(
    EWithdrawalCodeType.Amount,
    'Annual Withdrawal Amount'
  )
  static readonly Life = new EWithdrawalType(
    EWithdrawalCodeType.Lifetime,
    'Lifetime Withdrawal',
    true,
    50
  )
  static readonly Level = new EWithdrawalType(
    EWithdrawalCodeType.Level,
    'Level Lifetime Withdrawals',
    true,
    50
  )
  static readonly Increasing = new EWithdrawalType(
    EWithdrawalCodeType.Increasing,
    'Increasing Lifetime Withdrawals',
    true,
    50
  )
  static readonly Accelerated = new EWithdrawalType(
    EWithdrawalCodeType.Accelerated,
    'Accelerated Withdrawals',
    true,
    50
  )
  static readonly RMD = new EWithdrawalType(
    EWithdrawalCodeType.RMD,
    'RMD',
    false,
    73
  )
  static readonly MaximumCumulative = new EWithdrawalType(
    EWithdrawalCodeType.MAXIMUM_CUMULATIVE,
    'Free Withdrawal Available'
  )

  private constructor(
    public readonly code: string,
    public readonly name: string,
    public readonly isLifetime: boolean = false,
    public readonly minAge: number = 0
  ) {}

  toString() {
    return this.name
  }
}

export function getWithdrawalTypeByCode(code: string) {
  switch (code) {
    case EWithdrawalType.PercentOfAv.code:
      return EWithdrawalType.PercentOfAv
    case EWithdrawalType.Amount.code:
      return EWithdrawalType.Amount
    case EWithdrawalType.Life.code:
      return EWithdrawalType.Life
    case EWithdrawalType.Level.code:
      return EWithdrawalType.Level
    case EWithdrawalType.Increasing.code:
      return EWithdrawalType.Increasing
    case EWithdrawalType.Accelerated.code:
      return EWithdrawalType.Accelerated
    case EWithdrawalType.RMD.code:
      return EWithdrawalType.RMD
    case EWithdrawalType.MaximumCumulative.code:
      return EWithdrawalType.MaximumCumulative
    default:
      return EWithdrawalType.None
  }
}
