import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'

interface WithdrawalYearSelectProps {
  title: string
  name: string
  helperText: string
  hasError: boolean
  year: string
  yearOptions: string[]
  handleChange: (event: SelectChangeEvent<string>) => void
  yearStart?: string
}

const WithdrawalYearSelect: React.FC<WithdrawalYearSelectProps> = ({
  title,
  name,
  helperText,
  hasError,
  yearOptions,
  year,
  yearStart,
  handleChange,
}) => (
  <FormControl fullWidth error={hasError}>
    <InputLabel id={name}>{title}</InputLabel>
    <Select
      labelId={name}
      name={name}
      label={title}
      value={year}
      onChange={handleChange}
      MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
    >
      <MenuItem value="">Select...</MenuItem>
      {yearOptions.map(year => (
        <MenuItem
          key={year}
          value={year}
          hidden={Boolean(yearStart && Number(year) < Number(yearStart))}
        >
          Year {year}
        </MenuItem>
      ))}
    </Select>
    {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)

export default React.memo(WithdrawalYearSelect)
