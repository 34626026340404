import React, { Component } from 'react'

import DynamicReports from './DynamicReports'

export default class AgentReportsPage extends Component {
  render() {
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Reports
            </li>
          </ol>
        </nav>

        <DynamicReports
          reportCreatorHeaderText={'Available Reports'}
          reportListHeaderText={'Requested Report Queue'}
        />
      </div>
    )
  }
}
