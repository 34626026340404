import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Grid,
} from '@mui/material'
import { useFormik } from 'formik'
import { ReactNode } from 'react'

import { FundTypes } from '../../../../../_enums/EFundsType'
import { ELifetimeWithdrawal } from '../../../../../_enums/ELifetimeWithdrawalType'
import { EWithdrawalCodeType } from '../../../../../_enums/EWithdrawalType'
import { moneyFormat, stripCommas } from '../../../../../_helpers/money'
import stepImage from '../../../../../assets/images/sales-tools/search.png'
import { useIllustrationContext } from '../../IllustrationContext'
import { EAction } from '../../reducers'
import StepImage from '../StepImage'
import { addWithdrawalType } from '../Withdrawals/withdrawalsUtils'
import { validationSchema } from '../commonValidationSchemes'

const EXCEEDING_CONFIRMATION_VALUE = 1000000
const MAX_PREMIUM_VALUE = 10000000

const TYPES_OF_FUNDS = [
  { name: FundTypes.NonQualified.name, code: FundTypes.NonQualified.code },
  { name: FundTypes.Qualified.name, code: FundTypes.Qualified.code },
]

interface IFormFields {
  ownerName: string
  ownerAge?: number
  jointOwnerName: string
  jointOwnerAge?: number
  initialPremium: number
  additionalPremiumYear1: number
  typeOfFunds: string
}

const getWithdrawals = (withdrawals: any[], typeOfFunds: string) => {
  if (typeOfFunds === FundTypes.NonQualified.code) return withdrawals

  const _withdrawals = withdrawals.filter(
    w => w.type !== EWithdrawalCodeType.RMD
  )
  if (!_withdrawals.length)
    _withdrawals.push(addWithdrawalType(EWithdrawalCodeType.None))
  return _withdrawals
}

type Props = {
  actions: (isLoading?: boolean) => ReactNode
  handleNext: () => void
}

export function IssueInformation({ actions, handleNext }: Props) {
  const { store, dispatch } = useIllustrationContext()

  const {
    withdrawals,
    selectedConfig: {
      data: {
        issue: { min_age, max_age },
      },
    },
  } = store

  const handleOnSubmit = (values: IFormFields) => {
    if (formik.isValid && !hasAmountGreaterThanAllowed) {
      dispatch({
        type: EAction.UpdateStore,
        payload: {
          ...values,
          withdrawals: getWithdrawals(withdrawals, values.typeOfFunds),
          initialPremium: stripCommas(values.initialPremium),
          additionalPremiumYear1: stripCommas(values.additionalPremiumYear1),
          lifetime_payout: values.jointOwnerAge
            ? ELifetimeWithdrawal.Joint.code
            : ELifetimeWithdrawal.Single.code,
        },
      })
      handleNext()
    }
  }

  const formik = useFormik({
    initialValues: {
      ownerName: store.ownerName,
      ownerAge: store.ownerAge,
      jointOwnerName: store.jointOwnerName,
      jointOwnerAge: store.jointOwnerAge,
      initialPremium: moneyFormat(store.initialPremium),
      additionalPremiumYear1: moneyFormat(store.additionalPremiumYear1),
      typeOfFunds: store.typeOfFunds,
    },
    validationSchema: validationSchema(min_age, max_age),
    onSubmit: handleOnSubmit,
  })

  const hasAmountGreaterThanAllowed =
    Number(stripCommas(formik.values.initialPremium)) +
      Number(stripCommas(formik.values.additionalPremiumYear1)) >
    MAX_PREMIUM_VALUE

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid container rowSpacing={4} item xs={12} md={10} lg={8} xl={6}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                value={formik.values.ownerName}
                onChange={formik.handleChange}
                label="Owner Name"
                id="ownerName"
                name="ownerName"
                helperText={
                  Boolean(formik.submitCount) && formik.errors.ownerName
                }
                error={
                  Boolean(formik.submitCount) &&
                  Boolean(formik.errors.ownerName)
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="number"
                value={formik.values.ownerAge ?? ''}
                onChange={formik.handleChange}
                label="Owner Age"
                id="ownerAge"
                name="ownerAge"
                helperText={
                  Boolean(formik.submitCount) && formik.errors.ownerAge
                }
                error={
                  Boolean(formik.submitCount) && Boolean(formik.errors.ownerAge)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                value={formik.values.jointOwnerName}
                onChange={formik.handleChange}
                label="Joint Owner Name"
                id="jointOwnerName"
                name="jointOwnerName"
                helperText={
                  Boolean(formik.submitCount) && formik.errors.jointOwnerName
                }
                error={
                  Boolean(formik.submitCount) &&
                  Boolean(formik.errors.jointOwnerName)
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="number"
                value={formik.values.jointOwnerAge ?? ''}
                onChange={formik.handleChange}
                label="Joint Owner Age"
                id="jointOwnerAge"
                name="jointOwnerAge"
                helperText={
                  Boolean(formik.submitCount) && formik.errors.jointOwnerAge
                }
                error={
                  Boolean(formik.submitCount) &&
                  Boolean(formik.errors.jointOwnerAge)
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={formik.values.initialPremium}
              label="Initial Premium"
              id="initialPremium"
              name="initialPremium"
              helperText={
                Boolean(formik.submitCount) && formik.errors.initialPremium
                  ? formik.errors.initialPremium
                  : hasAmountGreaterThanAllowed
                  ? `Premiums total should not be greater than ${moneyFormat(
                      MAX_PREMIUM_VALUE
                    )}.`
                  : Number(stripCommas(formik.values.initialPremium)) >=
                    EXCEEDING_CONFIRMATION_VALUE
                  ? `Premiums exceeding ${moneyFormat(
                      EXCEEDING_CONFIRMATION_VALUE
                    )} must have home office approval.`
                  : null
              }
              onChange={event => {
                formik.setFieldValue(
                  'initialPremium',
                  moneyFormat(event.target.value, event.type)
                )
              }}
              error={
                hasAmountGreaterThanAllowed ||
                (formik.touched.initialPremium &&
                  Boolean(formik.errors.initialPremium))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={formik.values.additionalPremiumYear1}
              label="Additional Premium Year 1"
              id="additionalPremiumYear1"
              name="additionalPremiumYear1"
              helperText={
                Boolean(formik.submitCount) &&
                formik.errors.additionalPremiumYear1
                  ? formik.errors.additionalPremiumYear1
                  : Number(stripCommas(formik.values.additionalPremiumYear1)) >=
                    EXCEEDING_CONFIRMATION_VALUE
                  ? `Premiums exceeding ${moneyFormat(
                      EXCEEDING_CONFIRMATION_VALUE
                    )} must have home office approval.`
                  : null
              }
              onChange={event => {
                formik.setFieldValue(
                  'additionalPremiumYear1',
                  moneyFormat(event.target.value, event.type)
                )
              }}
              error={
                Boolean(formik.submitCount) &&
                Boolean(formik.errors.additionalPremiumYear1)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.typeOfFunds && Boolean(formik.errors.typeOfFunds)
              }
            >
              <InputLabel id="typeOfFunds">Type of Funds</InputLabel>
              <Select
                label="Type of Funds"
                labelId="typeOfFunds"
                name="typeOfFunds"
                value={formik.values.typeOfFunds}
                onChange={formik.handleChange}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
              >
                {TYPES_OF_FUNDS.map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {Boolean(formik.submitCount) && formik.errors.typeOfFunds}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {actions()}
      <StepImage image={stepImage} title="issue information" />
    </form>
  )
}
