import { TextField, InputAdornment, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useFormik } from 'formik'
import { useState, useEffect, ReactNode } from 'react'

import { EAllocationType } from '../../../../../_enums/EAllocationType'
import stepImage from '../../../../../assets/images/sales-tools/business-and-finance.png'
import { useIllustrationContext } from '../../IllustrationContext'
import { EAction } from '../../reducers'
import StepImage from '../StepImage'

function formatPercentValue(val) {
  // strip out any non numeric
  let v = val.toString().replace(/\D/g, '')

  // remove more than three digits
  v = v.replace(/^(\d{3}).*/, '$1')

  // cant be more than 100
  v = parseInt(v) > 100 ? v.replace(/^(\d{2}).*/, '$1') : v

  // if empty string default to zero
  v = v === '' ? 0 : v

  // removes leading zeros
  v = parseInt(v).toString()

  return v
}

type FormFields = {
  [key in EAllocationType]?: number
}

type Props = {
  actions: (isLoading?: boolean) => ReactNode
  handleNext: () => void
}

export function Allocations({ actions, handleNext }: Props) {
  const { store, dispatch, stepsValidator } = useIllustrationContext()
  stepsValidator('Allocations', () => {})

  const [allocationTotal, setAllocationTotal] = useState(store.allocationTotal)
  const { selectedConfig } = store
  const { strategies_config } = selectedConfig.data.crediting
  const disabledConfig = selectedConfig.data.illustration.disabled

  const isDisabled = strategyCode => {
    if (disabledConfig && disabledConfig.length >= 1) {
      let tempCode = disabledConfig.indexOf(strategyCode)
      return tempCode > -1
    } else return false
  }

  const getTotal = () =>
    Object.keys(strategies_config).reduce((sum, x) => {
      if (formik.values[x]) sum += Number(formik.values[x])
      return sum
    }, 0)

  const handleOnSubmit = (values: FormFields) => {
    if (Number(allocationTotal) === 100) {
      dispatch({
        type: EAction.UpdateStore,
        payload: {
          ...Object.keys(strategies_config).reduce((aggr, x) => {
            aggr[x] = Number(values[x])
            return aggr
          }, {}),
          allocationTotal: allocationTotal,
          savedToCloud: false,
        },
      })
      handleNext()
    }
  }

  const formik = useFormik({
    initialValues: {
      ...Object.keys(strategies_config).reduce((aggr, x) => {
        aggr[x] = store[x] ?? 0
        return aggr
      }, {}),
    },
    onSubmit: handleOnSubmit,
  })

  useEffect(() => {
    setAllocationTotal(getTotal())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik])

  function renderAllocation(allocation: string) {
    return (
      <Box key={allocation} marginBottom={4} width="auto">
        <TextField
          fullWidth
          value={formik.values[allocation]}
          onChange={e => {
            formik.setFieldValue(allocation, formatPercentValue(e.target.value))
          }}
          label={EAllocationType[allocation]}
          id={allocation}
          disabled={isDisabled(allocation)}
          name={allocation}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Box>
    )
  }

  const allocations = Object.keys(strategies_config)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container marginBottom={2} spacing={4}>
        <Grid item sm={12} md={6} lg={5}>
          {allocations
            .slice(0, Math.ceil(allocations.length / 2))
            .map(renderAllocation)}
        </Grid>
        <Grid item sm={12} md={6} lg={5}>
          {allocations
            .slice(Math.ceil(allocations.length / 2))
            .map(renderAllocation)}

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              value={allocationTotal}
              label="Allocation Total"
              id="allocationTotal"
              disabled
              name="allocationTotal"
              variant="filled"
              helperText={
                Boolean(formik.submitCount) &&
                Number(allocationTotal) !== 100 &&
                'Allocation Total must be 100%.'
              }
              error={
                Boolean(formik.submitCount) && Number(allocationTotal) !== 100
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {actions()}
      <StepImage image={stepImage} title="issue information" />
    </form>
  )
}
