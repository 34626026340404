import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ReactConfetti from 'react-confetti'

import { useLocalStorage } from '../_hooks'
import { CONFETTI_KEY } from '../constants'

export default function Confetti({
  onConfettiComplete,
  duration = 10000,
}: {
  onConfettiComplete?: () => void
  duration?: number
}) {
  const [display, setDisplay] = useLocalStorage(CONFETTI_KEY, true)
  const [recycle, setRecycle] = useState(true)

  useEffect(() => {
    let isRendered = true
    setTimeout(() => {
      if (isRendered) setRecycle(false)
    }, duration)
    return () => {
      isRendered = false
    }
  }, [])

  if (!display) return null
  return (
    <Box position="fixed" width="100vw" height="100vh" zIndex={-2}>
      <ReactConfetti
        recycle={recycle}
        onConfettiComplete={() => {
          setDisplay(false)
          onConfettiComplete?.()
        }}
      />
    </Box>
  )
}
