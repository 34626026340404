import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Card, CardImg, Row, Col } from 'reactstrap'

import tetonBonusLogo from '../../../assets/images/agent/products/teton_bonus.png'
import tetonElevationLogo from '../../../assets/images/agent/products/teton_elevation.png'
import FirelightLogin from '../../../components/agent-components/firelight'
import './TetonBonusDetail.css'

export default function TetonBonusSeriesPage() {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Teton FIA Series
          </li>
        </ol>
      </nav>

      <div className="row">
        <div className="col-md-3">
          <Card>
            <CardImg src={tetonBonusLogo} />
          </Card>
        </div>
        <div className="col-md-9 productText">
          <Row>
            <Col md="12">
              <Button
                sx={{ marginTop: 1, marginRight: 1 }}
                onClick={() =>
                  window.open(
                    'https://media.SILACins.com/annuity/Teton Bonus Brochure.pdf',
                    '_blank'
                  )
                }
              >
                Brochure
              </Button>
              <Link to={`/agent/sales-tools/illustration`}>
                <Button sx={{ marginTop: 1, marginRight: 1 }}>
                  Run an Illustration
                </Button>
              </Link>
              <Button
                sx={{ marginTop: 1, marginRight: 1 }}
                onClick={() =>
                  window.open(
                    'https://www.silacins.com/agent/training',
                    '_blank'
                  )
                }
              >
                Product Training
              </Button>
              <Link to="/agent/products/sales-materials/4">
                <Button sx={{ marginTop: 1, marginRight: 1 }}>
                  Sales Materials
                </Button>
              </Link>
              <FirelightLogin sx={{ marginTop: 1, marginRight: 1 }}>
                Start eApp
              </FirelightLogin>
            </Col>
          </Row>
          <hr />
          <br />
          <article>
            <p>
              The Tetons are a relatively young mountain range in Wyoming that
              are still growing. In the annuity world, we'd say they are still
              accumulating. The Teton Bonus Series is our product suite that
              provides the perfect combination of upfront premium bonus paired
              with accumulation and liquidity. It's a modified single premium
              fixed indexed annuity series with a 5-, 7-, 10- and 14-year
              version so that you can find the term that best suits your
              client's needs. It automatically comes with one fixed interest and
              many indexed crediting strategies. Premium bonus, annual free
              withdrawal, cumulative withdrawal, home health care, nursing care,
              terminal illness and wealth transfer benefits are all
              automatically included with this product line for no charge.
            </p>
            <Box marginTop={4} marginBottom={2}>
              <a
                rel="noreferrer"
                href="https://www.silacins.com/elevation"
                target="_blank"
              >
                <img
                  width={250}
                  alt="elevation logo"
                  src={tetonElevationLogo}
                />
              </a>
            </Box>
            <p>
              The Teton Series can now be enhanced with the optional Elevation
              or Elevation Plus Rider. Elevation provides enhanced liquidity
              benefits for an annual spread. Elevation Plus provides enhanced
              liquidity benefits and an additional premium bonus for an annual
              spread. It’s time to level up retirement!
            </p>
          </article>
        </div>
      </div>
    </div>
  )
}
