import Table, { EDataType } from '../../../../_common/_components/Table'

const CreditingStrategy = ({ data }) => {
  if (!data) return null
  return (
    <Table
      columns={[
        {
          field: 'name',
          displayName: 'Crediting Strategy',
        },
        {
          field: 'allocation',
          displayName: 'Allocation',
          options: {
            dataType: EDataType.formatPercentage,
          },
        },
        {
          field: 'current',
          displayName: 'Current Adjustment',
          options: {
            dataType: EDataType.formatPercentage,
          },
        },
        {
          field: 'min',
          displayName: 'Minimum Adjustment',
          options: {
            dataType: EDataType.formatPercentage,
          },
        },
        {
          field: 'max',
          displayName: 'Maximum Adjustment Rate',
          options: {
            dataType: EDataType.formatPercentage,
          },
        },
        {
          field: 'term',
          displayName: 'Strategy Term',
          options: {
            dataType: EDataType.number,
          },
        },
      ]}
      rows={data}
    />
  )
}

export default CreditingStrategy
