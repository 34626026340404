import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { DropdownItem } from 'reactstrap'

const ProfileOptions = () => {
  return (
    <Fragment>
      <DropdownItem tag={Link} to={'/client/profile'} className="navItem">
        My Profile
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag={Link} className="navItem" to={'/logout'}>
        Sign Out
      </DropdownItem>
    </Fragment>
  )
}

export default ProfileOptions
