import {
  IAccountValueDetails,
  IIncomeValueDetails,
  IPolicyValues,
} from '../_common/_shared/PolicyDetails/AccountValues/types'
import { IDocument } from '../_common/_shared/PolicyDetails/Documents/types'
import { IPolicy } from '../_common/types'
import { request } from './axiosService'

export const getPolicyFromIntake = async (
  policyNumber: string
): Promise<IPolicy> => {
  const response = await request({
    method: 'get',
    uri: `/intake/policies/${policyNumber}`,
  })

  return response.data
}

export const getDocuments = async (
  policyNumber: string
): Promise<Array<IDocument>> => {
  try {
    const response = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policyNumber}/documents/`,
    })

    const data: Array<IDocument> = response.data
    return data
  } catch (e) {
    throw e
  }
}

export const getCurrentAllocations = async (
  policyNumber: string
): Promise<any> => {
  try {
    const { data } = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policyNumber}/current_allocations`,
    })
    if (!Object.entries(data).length) return data

    const allocations = {}
    Object.entries(data)
      .map(([key, value]) => [key, ((value as any).percent * 100).toFixed(2)])
      .forEach(x => {
        allocations[x[0]] = x[1]
      })
    return allocations
  } catch (e) {
    throw e
  }
}

export const getRelatedPolicies = async (
  policyNumber: string
): Promise<Array<IPolicy>> => {
  try {
    const response = await request({
      method: 'get',
      uri: `/annuity-policies/policies/related_policies/${policyNumber}`,
    })
    const data: Array<IPolicy> = response.data

    return data.filter(x => x.application.policy_number !== policyNumber)
  } catch (e) {
    throw e
  }
}

export const getPolicyValues = async (
  policyNumber: string
): Promise<IPolicyValues> => {
  try {
    const response = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policyNumber}/values?type=A&type=C&type=P`,
    })
    const data: IPolicyValues = response.data
    return data
  } catch (e) {
    throw e
  }
}

export const getAccountValueDetails = async (
  policyNumber: string
): Promise<IAccountValueDetails> => {
  try {
    const response = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policyNumber}/value_details`,
    })
    const data: IAccountValueDetails = response.data
    return data
  } catch (e) {
    console.error((e as Error).message)
    throw e
  }
}

export const getIncomeValueDetails = async (
  policyNumber: string
): Promise<IIncomeValueDetails> => {
  try {
    const response = await request({
      method: 'get',
      uri: `/annuity-policies/policies/${policyNumber}/income_value_details`,
    })
    const data: IIncomeValueDetails = response.data
    return data
  } catch (e) {
    console.error((e as Error).message)
    throw e
  }
}
