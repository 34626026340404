import { useEffect, useState } from 'react'

import { EBreakpoint } from '../_enums/EBreakpoint'

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<null | EBreakpoint>(null)
  const [windowSize, setWindowSize] = useState<{
    width: null | Number
    height: null | Number
  }>({
    width: null,
    height: null,
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (!windowSize.width) return

    if (0 < windowSize.width && windowSize.width < 600) {
      setBreakPoint(EBreakpoint.XS)
    }
    if (600 < windowSize.width && windowSize.width < 900) {
      setBreakPoint(EBreakpoint.SM)
    }
    if (900 < windowSize.width && windowSize.width < 1280) {
      setBreakPoint(EBreakpoint.MD)
    }
    if (1280 < windowSize.width && windowSize.width < 1920) {
      setBreakPoint(EBreakpoint.LG)
    }
    if (windowSize.width >= 1920) {
      setBreakPoint(EBreakpoint.XL)
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])
  return breakpoint
}

export default useBreakpoint
