import { Typography } from '@mui/material'

import { formatDate } from '../utils'

function PolicyDetailsTitle({ title }) {
  return (
    <Typography component="h1" variant="h4" color="primary" marginBottom={4}>
      {title}
      <br />
      <span style={{ fontSize: '18px' }} className="text-muted">
        as of {formatDate(new Date())}
      </span>
    </Typography>
  )
}

export default PolicyDetailsTitle
