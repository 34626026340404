import { Button } from '@mui/material'
import { Box } from '@mui/material'
import axios from 'axios'
import React, { Component } from 'react'
import {
  Card,
  CardBody,
  Alert,
  Input,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
} from 'reactstrap'
import authService from '../../../_services/authService'

class ClientProfile extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
      fields: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      errors: {
        passwordMessage: false,
      },
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  submitNewPassword = e => {
    e.preventDefault()
    this.setState({
      errors: {},
    })
    let fields = this.state.fields
    if (!fields.oldPassword || !fields.newPassword || !fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'All fields are required.',
        },
      })
    } else if (fields.newPassword !== fields.confirmPassword) {
      this.setState({
        errors: {
          passwordMessage: 'New password and confirm password do not match',
        },
      })
    } else {
      axios
        .put(`${window._env_.REACT_APP_API_URL}/core/changepassword`, {
          new_password: this.state.fields.newPassword,
          old_password: this.state.fields.oldPassword,
          refresh: authService.getRawRefreshToken(),
        })
        .then(response => {
          if(response.status === 500) {            
            this.setState({
              errors: {
                passwordMessage: 'An unexpected error ocurred trying to reset the password',
              },
            })
          }
          else {            
            this.setState({
              errors: {
                passwordMessage: response.data,
              },
            })
          }
        })
        .catch(reason => {
          if(reason.response.status === 500) {
            this.setState({
              errors: {
                passwordMessage: 'An unexpected error ocurred trying to change the password'
              },
            })            
          }
          else {            
            this.setState({
              errors: {
                passwordMessage: reason.response.data
              },
            })
          }
        })
    }
  }

  render() {
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Client Profile
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <h4>Change Password</h4>
                        <Form onSubmit={this.submitNewPassword} autoComplete="off">
                          <FormGroup>
                            <Label htmlFor="password">Old Password</Label>
                            <Input
                              type="password"
                              name="oldPassword"
                              id="password"
                              onChange={this.handleChange.bind(
                                this,
                                'oldPassword'
                              )}
                              placeholder="Enter old password"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="password">New Password</Label>
                            <Input
                              type="password"
                              name="password"
                              id="password"
                              onChange={this.handleChange.bind(
                                this,
                                'newPassword'
                              )}
                              placeholder="Enter new password"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="verifyPassword">
                              Confirm Password
                            </Label>
                            <Input
                              type="password"
                              name="verifyPassword"
                              id="verifyPassword"
                              onChange={this.handleChange.bind(
                                this,
                                'confirmPassword'
                              )}
                              placeholder="Enter confirm password"
                            />
                          </FormGroup>
                          <Alert
                            color="info"
                            isOpen={Boolean(this.state.errors.passwordMessage)}
                          >
                            {this.state.errors.passwordMessage}
                          </Alert>
                          <Box paddingTop={2}>
                            <Button color="primary">Submit</Button>
                          </Box>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    )
  }
}

export default ClientProfile
