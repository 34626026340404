/* eslint-disable no-useless-constructor */
// TODO Fix constructor
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import authService from '../../_services/authService'

class Logout extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    this.props.dispatch(authService.signOut({}))
  }

  render = () => {
    return <Redirect to={'/login'} />
  }
}

// Redux state mapping
const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    showAlert: state.authentication.showAlert,
    alertMsg: state.authentication.alertMsg,
    submitted: state.authentication.submitted,
    loggingIn: state.authentication.loggingIn,
  }
}

const connectedLogout = connect(mapStateToProps)(Logout)
export { connectedLogout as Logout }
