import axios, { AxiosResponse, AxiosError } from 'axios'

export interface IRequest {
  url: string
  body?: any
  method: 'get' | 'post' | 'patch' | 'delete'
}

const get = <T>(url: string): Promise<{ data: T; headers?: any }> => {
  document.body.classList.add('wait-cursor')
  return new Promise((resolve, reject) => {
    axios
      .get(
        window._env_.REACT_APP_API_URL.concat(
          url.startsWith('/') ? url : '/'.concat(url)
        ).replace(/(\r\n|\r|\n| {2}|%20%20)/gm, '')
      )
      .then((response: AxiosResponse) => {
        return resolve(response)
      })
      .catch((response: AxiosError) => {
        return reject(response)
      })
      .finally(() => {
        document.body.classList.remove('wait-cursor')
      })
  })
}

const post = <T>(
  url: string,
  body: any
): Promise<{ data: T; headers?: any }> => {
  document.body.classList.add('wait-cursor')
  return new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then((response: AxiosResponse) => {
        const { data, headers } = response
        return resolve({ data, headers })
      })
      .catch((response: AxiosError) => {
        return reject(response)
      })
      .finally(() => {
        document.body.classList.remove('wait-cursor')
      })
  })
}

const patch = <Response>(
  url: string,
  body: any
): Promise<{ data: Response; headers?: any }> => {
  document.body.classList.add('wait-cursor')
  return new Promise((resolve, reject) => {
    axios
      .patch(url, body)
      .then((response: AxiosResponse) => {
        return resolve(response)
      })
      .catch((response: AxiosError) => {
        return reject(response)
      })
      .finally(() => {
        document.body.classList.remove('wait-cursor')
      })
  })
}

const del = <Response>(
  url: string
): Promise<{ data: Response; headers?: any }> => {
  document.body.classList.add('wait-cursor')
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((response: AxiosResponse) => {
        const { data, headers } = response
        return resolve({ data, headers })
      })
      .catch((response: AxiosError) => {
        return reject(response)
      })
      .finally(() => {
        document.body.classList.remove('wait-cursor')
      })
  })
}

export const request = Object.assign(
  async <T extends any = any>(args: IRequest): Promise<T> => {
    const url = window._env_.REACT_APP_API_URL.concat(
      args.url.startsWith('/') ? args.url : '/'.concat(args.url)
    ).replace(/(\r\n|\r|\n| {2}|%20%20)/gm, '')

    return (await (['post', 'patch'].includes(args.method)
      ? request[args.method](url, { ...args.body })
      : request[args.method](url, null))) as T
  },
  {
    get,
    post,
    patch,
    delete: del,
  }
)
