import { Grid } from '@mui/material'

import { useStore } from '../../../_hooks/use-store'
import PersonDisplay from './PersonDisplay'

function Owner() {
  const {
    store: { selectedPolicy },
  } = useStore()

  const { owner, joint_owner, annuitant, joint_annuitant } = selectedPolicy.data

  return (
    <Grid sm={12} container spacing={2}>
      <PersonDisplay personLabel="Owner" personData={owner} />
      <PersonDisplay personLabel="Joint Owner" personData={joint_owner} />
      <PersonDisplay personLabel="Annuitant" personData={annuitant} />
      <PersonDisplay personLabel="Joint Annuitant" personData={joint_annuitant} />
    </Grid>
  )
}

export default Owner
