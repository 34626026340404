import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'

import { communicationsService } from '../../../_services'
import AgentCommunicationList from './AgentCommunicationList'

export default class Policy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      communications: [],
    }
  }

  queryAPI() {
    communicationsService.agencynews.getAll(null).then(response => {
      const newsItems = []
      const results = response.data?.results

      if (!results) {
        this.setState(() => ({
          loading: false,
          communications: [],
        }))
      }

      for (let result of results) {
        const newsItem = {}

        newsItem.id = result.id
        newsItem.actionDate = result.action_date
        newsItem.type = result.type
        newsItem.subject = result.subject
        newsItem.link = result.link ?? `/agent/marketing-campaign/${result.id}`
        newsItem.isInternal = !result.link
        newsItem.description = result.description
        newsItems.push(newsItem)
      }

      this.setState(() => ({
        loading: false,
        communications: newsItems,
      }))
    })
  }

  componentDidMount() {
    this.queryAPI()
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardHeader>Communications</CardHeader>
            <CardBody>
              <AgentCommunicationList
                loading={this.state.loading}
                communications={this.state.communications}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
