import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import numeral from 'numeral'

function CashSurrenderBreakdown({ accountValues }: any) {
  return (
    <TableContainer component={Paper} className="single-value-container">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              {numeral(accountValues.total).format('$0,0.00')}
            </TableCell>
            <TableCell>Account Value</TableCell>
          </TableRow>
          {/* (dynamic if applicable) */}
          <TableRow>
            <TableCell>
              {numeral(accountValues.withdrawal_charge).format('$0,0.00')}
            </TableCell>
            <TableCell>Withdrawal Charges</TableCell>
          </TableRow>
          {/* (dynamic if applicable) */}
          <TableRow>
            <TableCell>
              {numeral(
                Number(parseFloat(accountValues.mgv_adjustment).toFixed(2))
              ).format('$0,0.00')}
            </TableCell>
            <TableCell>MGV Adjustment</TableCell>
          </TableRow>
          {/* (dynamic if applicable) */}
          <TableRow>
            <TableCell>
              {numeral(accountValues.bonus_recovery).format('$0,0.00')}
            </TableCell>
            <TableCell>Bonus Recovery</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {numeral(accountValues.mva).format('$0,0.00')}
            </TableCell>
            <TableCell>MVA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {numeral(accountValues.cash_value).format('$0,0.00')}
            </TableCell>
            <TableCell>Cash Surrender</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CashSurrenderBreakdown
