import { EWithdrawalCodeType } from '../../../_enums'
import { IllustrationStore } from '../../../types/salesTools'
import { addWithdrawalType } from './_steps/Withdrawals/withdrawalsUtils'

export const NextButtonState = {
  Ready: {
    text: 'Next',
    disabled: false,
  },
  Loading: {
    text: 'Loading',
    disabled: true,
  },
  Invalid: {
    text: 'Invalid Data',
    disabled: true,
  },
  Disabled: {
    text: 'Next',
    disabled: true,
  },
}

export const InitialIllustrationStore: IllustrationStore = {
  nextButtonState: NextButtonState.Loading,
  validSteps: {
    isAllocationsValid: false,
    isIssueInformationValid: false,
    isWithdrawalsValid: false,
  },
  showAnnualAmount: 'none',
  showPercentAV: 'none',
  showWithdrawalStart: 'none',
  showWithdrawalEnd: 'none',
  illustrationDate: new Date().toDateString(),
  agent: '',
  product: '',
  plan: '',
  issueState: '',
  ownerName: '',
  jointOwnerName: '',
  initialPremium: "0",
  additionalPremiumYear1: "0",
  typeOfFunds: '',
  allocationTotal: 0,
  lifetime_payout: 'single',
  withdrawals: [addWithdrawalType(EWithdrawalCodeType.None)],
  prepared_date: new Date().toDateString(),
  gender: 'Male',
  savedToCloud: false,
  selectedConfig: {} as any,
}

export const InitialTestIllustrationStore: IllustrationStore = {
  "nextButtonState": {
      "text": "Loading",
      "disabled": true
  },
  "validSteps": {
      "isAllocationsValid": false,
      "isIssueInformationValid": false,
      "isWithdrawalsValid": false
  },
  "showAnnualAmount": "none",
  "showPercentAV": "none",
  "showWithdrawalStart": "none",
  "showWithdrawalEnd": "none",
  "illustrationDate": "2023-08-26T19:03:12.311Z",
  "agent": "test",
  "product": "",
  "plan": "8_8",
  "issueState": "AL",
  "ownerName": "test",
  "ownerAge": 50,
  "jointOwnerName": "test",
  "jointOwnerAge": 50,
  "initialPremium": "100000.00",
  "additionalPremiumYear1": "2000.00",
  "typeOfFunds": "nonQualified",
  "allocationTotal": 100,
  "lifetime_payout": "joint",
  "withdrawals": [
      {
          "id": "1e1199f7-9f98-4939-b148-608c8679cb0f",
          "type": EWithdrawalCodeType.None,
          "amount": 0,
          "start": "0",
          "end": "0"
      }
  ],
  "prepared_date": "2023-08-26T19:03:12.311Z",
  "gender": null,
  "savedToCloud": false,
  "selectedConfig": {
      "id": 17411,
      "data": {
          "duration": 84,
          "issue": {
              "min_age": 0,
              "max_age": 90,
              "max_premium": 1000000,
              "min_premium": 10000
          },
          "min_additional_premium": 2000,
          "last_month_additional_premium": 12,
          "mgv": {
              "premium": 0.875,
              "fixed": 0.0235,
              "index": 0.0135,
              "charges": false
          },
          "income": {
              "min_rate": 0.01,
              "min_payment": 100,
              "waiting_period": 60,
              "life_contigent_waiting_period": 60
          },
          "withdrawal": {
              "charges": [
                  0.12,
                  0.12,
                  0.11,
                  0.1,
                  0.09,
                  0.07,
                  0.04,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ],
              "free": {
                  "max_amount": 1,
                  "max_percentage": 0.05,
                  "waiting_period": 12,
                  "base": "av"
              },
              "cumulative": {
                  "waiting_period": 12,
                  "max_percentage": 0.3,
                  "base": "av"
              },
              "min_amount": 100,
              "min_av": 500,
              "charge_base": "av",
              "rmd": {
                  "waiting_period": 0
              },
              "mva": {
                  "index": "DBAA",
                  "period": 84,
                  "base_rate": 0.0025,
                  "base": "av"
              },
              "free_look": 30,
              "free_when_full": 0
          },
          "based_on": "owner",
          "illustration": {
              "show_index": false,
              "disabled": [
                  "ATLAS-AP2PPR",
                  "ATLAS-AP2PS",
                  "RVP-AP2PPR",
                  "RVP-AP2PS",
                  "NASDAQ-AP2PPR",
                  "NASDAQ-AP2PS",
                  "SPDS-AP2PPR"
              ]
          },
          "report_fee": 25,
          "reprint_fee": 25,
          "issue_day": 2,
          "crediting": {
              "index": "SP500",
              "min_allocation": 2000,
              "option_budget": 0.0451,
              "net_yield": 0.0658,
              "strategies_config": {
                  "FI": {
                      "min_adjustment": 0.01,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 1
                  },
                  "AP2PC": {
                      "min_adjustment": 0.015,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 2
                  },
                  "AP2PPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 3
                  },
                  "SPDS-AP2PPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 4
                  },
                  "MP2PC": {
                      "min_adjustment": 0.0075,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 5
                  },
                  "MAPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 7
                  },
                  "ATLAS-AP2PPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 9
                  },
                  "ATLAS-AP2PS": {
                      "min_adjustment": "n/a",
                      "max_adjustment": 0.1,
                      "term": 12,
                      "sequence": 10
                  },
                  "RVP-AP2PPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 11
                  },
                  "RVP-AP2PS": {
                      "min_adjustment": "n/a",
                      "max_adjustment": 0.1,
                      "term": 12,
                      "sequence": 12
                  },
                  "NASDAQ-AP2PPR": {
                      "min_adjustment": 0.1,
                      "max_adjustment": "n/a",
                      "term": 12,
                      "sequence": 13
                  },
                  "NASDAQ-AP2PS": {
                      "min_adjustment": "n/a",
                      "max_adjustment": 0.1,
                      "term": 12,
                      "sequence": 14
                  }
              },
              "strategies": {
                  "FI": 0.035,
                  "AP2PC": 0.075,
                  "AP2PPR": 0.42,
                  "MP2PC": 0.025,
                  "MAPR": 0.75,
                  "ATLAS-AP2PPR": 1.8,
                  "ATLAS-AP2PS": -0.0325,
                  "RVP-AP2PPR": 1.9,
                  "RVP-AP2PS": -0.0375,
                  "NASDAQ-AP2PPR": 1.8,
                  "NASDAQ-AP2PS": -0.0325,
                  "SPDS-AP2PPR": 0.7
              }
          },
          "health": {
              "nursing": {
                  "payment_period": 12,
                  "multiplier": 1,
                  "waiting_period": 12,
                  "confinement_period": 90
              },
              "home_care": {
                  "payment_period": 60,
                  "multiplier": 1,
                  "waiting_period": 12,
                  "impairment_period": 90
              },
              "terminal": {
                  "payment_period": 12,
                  "multiplier": 1,
                  "waiting_period": 12
              }
          },
          "bonus": {
              "recovery": [
                  1,
                  0.9,
                  0.8,
                  0.7,
                  0.6,
                  0.5,
                  0.4,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ],
              "base": "prem_bonus",
              "schedule": {
                  "0-70": 0.06,
                  "71-80": 0.06,
                  "81-90": 0.035
              }
          },
          "info": {
              "version": "14.22",
              "rate_increase": false
          },
          "deadlines": {
              "app_received": 15,
              "app_sign": -1,
              "last_deposit": 30
          },
          "enhanced_death_benefit_basis": "None",
          "enhanced_death_benefit_payment_period_months": null
      },
      "start_date": "2023-08-14",
      "end_date": "2099-12-31",
      "error": false,
      "filing_info": "STANDARD",
      "illustration_start_date": "2023-08-14",
      "illustration_end_date": "2099-01-01",
      "application_start_date": "2023-08-14",
      "application_end_date": "2099-01-01",
      "approval_start_date": "2023-08-14",
      "approval_end_date": "2099-01-01",
      "creation_date": "2023-08-12",
      "termination_date": "2099-01-01",
      "plan": {
          "id": 8,
          "name": "7",
          "code": "7",
          "sequence": 1,
          "startdate": "2019-01-01",
          "enddate": "2099-01-01",
          "product": {
              "id": 4,
              "code": "TETONB              ",
              "name": "Teton Bonus",
              "type": "INDEX",
              "sequence": 2,
              "startdate": "2019-01-01",
              "enddate": "2099-01-01",
              "company": 1
          }
      },
      "states": [
          {
              "id": 1,
              "code": "AL",
              "name": "Alabama"
          }
      ]
  },
  "riderConfigId": 33,
  "riders": [
      {
          "id": 4,
          "rider_code": "elevation",
          "rider_name": "Elevation",
          "rider_sequence": 1,
          "rider_config_id": 33,
          "free_withdrawal_max_percent": "0.1000"
      },
      {
          "id": 5,
          "rider_code": "elevationplus",
          "rider_name": "Elevation Plus",
          "rider_sequence": 2,
          "rider_config_id": 36,
          "free_withdrawal_max_percent": "0.1000"
      }
  ],
  "FI": 20,
  "AP2PC": 20,
  "AP2PPR": 20,
  "SPDS-AP2PPR": 0,
  "MP2PC": 20,
  "MAPR": 20,
  "ATLAS-AP2PPR": 0,
  "ATLAS-AP2PS": 0,
  "RVP-AP2PPR": 0,
  "RVP-AP2PS": 0,
  "NASDAQ-AP2PPR": 0,
  "NASDAQ-AP2PS": 0
}