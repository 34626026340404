import axios from 'axios'
import React, { Component } from 'react'

// TODO change these from hard coded to dynamic
import { stateList } from '../../../_helpers/lists'
import ModalSpinner from '../../layout/ModalSpinner'
import MaterialsFilters from './MaterialsFilters'
import MaterialsTable from './MaterialsTable'

export class SalesMaterials extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        stateFilter: 'Any',
        productFilter: this.props.match.params.product || 'Any',
      },
      productList: [],
      supplies: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getProductList()
    this.getSupplies()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filters.stateFilter !== this.state.filters.stateFilter ||
      prevState.filters.productFilter !== this.state.filters.productFilter
    ) {
      this.getSupplies()
    }
  }

  getSupplies = () => {
    const url = `${window._env_.REACT_APP_API_URL}/agents/supplies`
    const { filters } = this.state
    const AxiosNoInterceptor = axios.create()

    AxiosNoInterceptor.interceptors.request.use(config => {
      // had to do this because we couldnt delete the Authorization header
      config.headers = { Accept: 'application/json, text/plain, */*' }
      return config
    })

    AxiosNoInterceptor.get(url, {
      params: {
        ...(filters.stateFilter && filters.stateFilter !== 'Any'
          ? { state: filters.stateFilter }
          : {}),
        ...(filters.productFilter && filters.productFilter !== 'Any'
          ? { product: filters.productFilter }
          : {}),
      },
    })
      .then(response => {
        // console.table(response.data)
        // const stateProducts = this.state.products
        const supplies = response.data.map(form => {
          const formData = {}
          formData.formId = form.id // form id
          formData.link = form.document_path
          formData.group = form.form_group
          formData.description = form.description
          formData.name = form.name
          formData.order = form.order
          formData.products = form.products // TODO rearrange after BE solution in place

          // const prods = []
          // for (let product of form.products) {
          //   let prod = {}
          //   prod.productId = product.id
          //   prod.name = product.name
          //   prod.formData = [formData]
          //   prods.push(prod)
          // }

          // loop over products and compare product ids
          // merge in additional forms
          // if (products.length === 0) {
          //   products = prods
          // } else {
          //   for (let prod of prods) {
          //     const productIds = []

          //     for (let product of products) {
          //       productIds.push(product.productId)
          //       if (product.productId === prod.productId) {
          //         const formData = product.formData
          //         product.formData = [...formData, ...prod.formData]
          //       }
          //     }

          //     if (!productIds.includes(prod.productId)) {
          //       products.push(prod)
          //     }
          //   }
          // }
          return formData
        })

        // TODO sort products by order
        supplies.sort((a, b) => a.order - b.order)
        // console.table('sorted products', supplies)
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          supplies,
        }))
      })
      .catch(err => console.log(err))
  }

  getProductList = () => {
    const url = `${window._env_.REACT_APP_API_URL}/intake/products/`
    const AxiosNoInterceptor = axios.create()

    AxiosNoInterceptor.interceptors.request.use(config => {
      // had to do this because we couldnt delete the Authorization header
      config.headers = { Accept: 'application/json, text/plain, */*' }
      return config
    })

    AxiosNoInterceptor.get(url)
      .then(response => {
        const productList = []
        response.data.results.map(result => productList.push(result))

        this.setState({ productList })
      })
      .catch()
  }

  filterSupplies = (supplies, product) => {
    return supplies.filter(supply =>
      supply.products.some(prod => prod.id === product)
    )
  }

  filterProductList = (list, filter) => {
    return list.filter(item => parseInt(item.id, 10) === parseInt(filter, 10))
  }

  handleFilterChange = (filter, value) => {
    this.setState(prevState => ({
      ...prevState,
      filters: { ...prevState.filters, [filter]: value },
    }))
  }

  getTitle = () => {
    const { filters, productList } = this.state

    const selectedProduct = productList.find(
      // eslint-disable-next-line
      product => product.id == filters.productFilter
    ) || { name: 'All Products' }

    return (
      selectedProduct.name +
      (filters.stateFilter === 'Any'
        ? ' - All States'
        : ' - ' +
          // eslint-disable-next-line
          stateList.find(state => state.code == filters.stateFilter).name)
    )
  }

  getFormData = (rows, headers, filter) => {
    const data =
      filter === 'Any' ? headers : this.filterProductList(headers, filter)

    const formData =
      filter === 'Any'
        ? [rows]
        : data.map(header => this.filterSupplies(rows, header.id))

    return formData[0] || []
  }

  render() {
    const { filters, productList, supplies, loading } = this.state

    const title = this.getTitle()
    const formData = this.getFormData(
      supplies,
      productList,
      filters.productFilter
    )

    return (
      <div>
        <div className="mr-3">
          <div className="row my-4">
            <div className="col-lg-4">
              <MaterialsFilters
                onFilterChange={this.handleFilterChange}
                productList={productList}
                filterValues={filters}
                stateList={stateList}
              />
            </div>
            <div className="col-lg-12">
              <ModalSpinner active={loading}>
                <MaterialsTable title={title} materials={formData} />
              </ModalSpinner>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SalesMaterials
