import axios from 'axios'
import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

import List from './List'

export default class Policy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      policies: '',
      requestStatus: '',
    }
  }

  queryAPI() {
    let APIuri = `${window._env_.REACT_APP_API_URL}/annuity-policies/policies/client` // TODO refactor to global
    let params = {
      params: {
        filter_by: 'terminated_date:true',
      },
    }
    const getPersonName = person =>
      person.first_name
        ? person.first_name
        : ' ' + person.last_name
        ? person.last_name
        : ' '

    axios
      .get(APIuri, params)
      .then(response => {
        let policies = response.data.map(p => ({
          id: p.id,
          policyNum: p.policy_number,
          product: p.product.name,
          years: p.data.application.plan,
          accountVal: p.total_account_value.total,
          owner: getPersonName(p.data.owner),
          annuitant: getPersonName(p.data.annuitant),
          issueDate: p.policy_issue_date ? p.policy_issue_date : '-',
        }))

        policies.sort((a, b) => a.issueDate - b.issueDate)

        const reqeustStatus = 'done'
        this.setState({ policies: policies })
        this.setState({ requestStatus: reqeustStatus })
      })
      .catch(err => console.log(err))
  }

  componentDidMount() {
    this.queryAPI()
  }

  render() {
    return (
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <h2 style={{ color: '#0072C0', fontFamily: 'Arial' }}>Policies</h2>

          <h5 className="muted-text" style={{ fontFamily: 'Arial' }}>
            Your annuity policies at a glance{' '}
            <small>(Current Account Values)</small>
          </h5>

          <br />

          <List
            policies={this.state.policies}
            requestStatus={this.state.requestStatus}
          />
        </Col>
      </Row>
    )
  }
}
