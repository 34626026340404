import { request } from '../axiosService'

export interface IIndex {
  id: number
  series: string
  title: string
}

export interface IResult {
  id: number
  index: IIndex
  code: string
  name: string
  math_func: string
  start_date: string
  end_date: string
  sequence: number
  reallocation_visibility_date: string
  reallocation_start_date: string
  reallocation_end_date: string
}

export interface IAnnuityStrategiesResponse {
  count: number
  next: string
  previous?: any
  results: IResult[]
}

export const getAvailableIndices = async (): Promise<string[]> => {
  const response = await request({
    method: 'get',
    uri: `/annuity-strategies/`,
  })
  const data = response.data as IAnnuityStrategiesResponse
  return [...new Set(data.results.map(x => x.index.series))]
}
