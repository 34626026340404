import { TableCell, TableRow } from '@mui/material'

import Table, { EDataType } from '../../../../_common/_components/Table'
import { formatMoneyRounded } from '../../../../_helpers/number'

const PolicyValuesExtended = ({
  store,
  data,
  footerData,
  isVega,
  hasIncomeValue,
  includeLifetime,
  ownerAge,
  jointOwnerAge,
  includeWellness,
  title,
}) => {
  return (
    <Table
      title={title}
      columns={[
        {
          field: 'years',
          displayName: 'End of Year',
        },
        {
          field: 'age',
          displayName: `${
            Math.max(ownerAge, jointOwnerAge) === jointOwnerAge
              ? 'Youngest '
              : ''
          }Owner Age`,
        },
        {
          field: 'premium',
          displayName: 'Premium',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
        {
          field: 'annual_withdrawal',
          displayName: 'Annual Withdrawal',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
        {
          field: 'icr',
          displayName: 'Interest Credit Rate',
          options: {
            dataType: EDataType.formatPercentage,
          },
        },
        {
          field: 'interest',
          displayName: 'Interest Credit Amount',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
        {
          field: 'account_value',
          displayName: 'Account Value',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
        {
          field: 'cash_value',
          displayName: 'Cash Value',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
        hasIncomeValue
          ? {
              field: 'income_credit',
              displayName: 'Income Credit',
              options: {
                dataType: EDataType.formatPercentage,
              },
            }
          : null,
        hasIncomeValue
          ? {
              field: 'income_value',
              displayName: 'Income Value',
              options: {
                dataType: EDataType.moneyRounded,
              },
            }
          : null,
        isVega
          ? {
              field: 'benefit_rate',
              displayName: 'Benefit Rate',
              options: {
                dataType: EDataType.formatPercentage,
              },
            }
          : null,
        isVega
          ? {
              field: 'benefit_value',
              displayName: 'Benefit Value',
              options: {
                dataType: EDataType.moneyRounded,
              },
            }
          : null,
        ...(includeLifetime
          ? [
              {
                field: 'lifetime_withdrawal',
                displayName: store.withdrawals.some(
                  w => w.type === 'accelerated'
                )
                  ? 'Accelerated Withdrawal'
                  : 'Lifetime Withdrawal',
                options: {
                  dataType: EDataType.moneyRounded,
                },
              },
              includeWellness
                ? {
                    field: 'wellness_withdrawal',
                    displayName: 'Wellness Withdrawal',
                    options: {
                      dataType: EDataType.moneyRounded,
                    },
                  }
                : null,
              isVega
                ? {
                    field: 'total_withdrawal',
                    displayName: 'Total Withdrawals',
                    options: {
                      dataType: EDataType.moneyRounded,
                    },
                  }
                : null,
            ].filter(Boolean)
          : []),
        Boolean(data[0].enhanced_death_benefit)
          ? {
              field: 'enhanced_death_benefit',
              displayName: 'Enhanced Death Benefit',
              options: {
                dataType: EDataType.moneyRounded,
              },
            }
          : null,
        {
          field: 'total_benefit',
          displayName: 'Total Benefit',
          options: {
            dataType: EDataType.moneyRounded,
          },
        },
      ].filter(Boolean)}
      rows={data}
      footer={
        <TableRow>
          <TableCell align="left">
            <b>Totals:</b>
          </TableCell>
          <TableCell />
          <TableCell align="right">
            {formatMoneyRounded(footerData.premium)}
          </TableCell>
          <TableCell align="right">
            {formatMoneyRounded(footerData.annual_withdrawal)}
          </TableCell>
          <TableCell />
          <TableCell align="right">
            {formatMoneyRounded(footerData.interest)}
          </TableCell>
        </TableRow>
      }
    />
  )
}

export default PolicyValuesExtended
