import * as React from 'react'

interface IPageProps {
  title: string
  children?: React.ReactNode
}

const Page = ({ title, children }: IPageProps) => {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col-md-12">{children}</div>
      </div>
    </div>
  )
}

export default Page
