// incorporate into the global declaration
//
import { IActionNotesStore } from '../components/ActionNotes/types'
import { IPolicyEventsStore } from '../components/Events/types'
import { IAgentContractingStore } from '../components/agent-components/agent-contracting/types'
import { INotification } from './types'

export interface ICommonStore {
  common: {
    notification?: INotification
  }
}

export interface IStore
  extends ICommonStore,
    IActionNotesStore,
    IAgentContractingStore,
    IPolicyEventsStore {}

const localStore: IStore = {
  common: {
    notification: undefined,
  },
  action_notes: {
    action_notes: undefined,
  },
  downline_invites: {
    downline_invites: undefined,
  },
  policy_events: {
    policy_events: undefined,
  },
}

export const getStore = <T>(): T => localStore as unknown as T
