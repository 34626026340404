import { Card,Grid, Typography } from '@mui/material'

import { formatPercentage } from '../../_helpers/number'
import { RiderConfig } from '../../types/salesTools/config'

import './riders.css'

type Props = {
  riderConfig: RiderConfig;
  isClient: boolean;
}

const RiderDetails = ({ riderConfig, isClient }: Props) => {
  return (
    <Card sx={{ padding: { xs: 2, lg: 4 }, paddingY: { xs: 4, lg: 8 } }}>
      <Grid container spacing={{ xs: 2, lg: 4 }} rowSpacing={{ xs: 4, lg: 4 }}>
        <RiderBenefits riderInfo={riderConfig} isClient={isClient} />
      </Grid>
    </Card>
  )
}
const RiderBenefits = ({ riderInfo, isClient }) => {
  if (!riderInfo?.rider_benefits) {
    return false
  }

  return riderInfo.rider_benefits.map((rb, i) => {
    return (
      <RiderBenefit benefit={rb} idx={i} key={i} isClient={isClient} />
    )
  })
}
const RiderBenefit = ({ benefit, idx, key, isClient }) => {
  return (
    <>
      <Grid item xs={8}>
        <Typography>
          <b>{benefit?.name}</b>{' '}
          {benefit?.sub_name && (
            <>
              <b>:</b> {benefit?.sub_name}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {benefit?.status && (
          <div>
            <b>Status</b>{' '}: <RiderStatus status={benefit?.status} />
          </div>
        )}
        {benefit?.primary_values && (
          <RiderPrimaryValues primary_values={benefit?.primary_values} isClient={isClient} />
        )}
      </Grid>
      <Grid item xs={12}>
        <RiderBenefitInformation
          information={benefit?.information}
          css_class_override={benefit?.css_class_override}
        />
      </Grid>
    </>
  )
}
const RiderBenefitInformation = ({ information, css_class_override }) => {
  return (
    <div
      className={`rider-card-information-section ${
        css_class_override ? css_class_override : ''
      } `}
    >
      {information.map((v, i) => {
        return (
          <div
            className={`rider-information-column ${
              v?.css_amount_of_columns
                ? v?.css_amount_of_columns
                : 'use-one-column'
            } 
            ${
              is_collapsible(v?.can_collapse, v?.description, 11)
                ? 'collapsible'
                : ''
            }`}
            key={i}
          >
            <div>
              {v.description && (
                <RiderDescriptionGrid
                  description={v?.description}
                  has_header_row={v?.has_header_row}
                />
              )}
            </div>
            {is_collapsible(v?.can_collapse, v?.description, 11) && (
              <label>
                <input type="checkbox" />
              </label>
            )}
          </div>
        )
      })}
    </div>
  )
}
const is_collapsible = (collapse_allowed, total_items, item_limit) => {
  return collapse_allowed && total_items?.length > item_limit
}
const RiderDescriptionGrid = ({ description, has_header_row }) => {
  return (
    <>
      {description.map((v, i) => {
        return (
          <div
            className={`${
              has_header_row && i === 0 ? 'rider-sub-header-row' : ''
            } data-grid-columns-${v?.length}`}
            key={i}
          >
            {<RiderDescriptionGridCell row_data={v} />}
          </div>
        )
      })}
    </>
  )
}
const RiderDescriptionGridCell = ({ row_data }) => {
  return row_data.map((v, i) => {
        return <div key={i}>{v}</div>
      })
}
const RiderPrimaryValues = ({ primary_values, isClient }) => {
  return primary_values.map((v, i) => {
      if (v.label === 'Spread' && isClient) {
        return (<></>);
      }
      else {
        return ( 
          <Typography>
            <b>{v.label}</b> {' '} : {formatPercentage(v.value)}
          </Typography>
        )
      }
  })
}
const RiderStatus = ({ status }) => {
  const style_enum = {
    Waiting: 'info',
    Active: 'active',
    Pending: 'info',
    Available: 'available',
    Used: 'info',
    Unavailable: 'info',
  }
  return <span className={`status ${style_enum[status]}`}>{status}</span>
}


export default RiderDetails
