import React from 'react'

export interface ITradeMarkDisplay {
  tradeMark: string
}

export function TradeMarkDisplay({ tradeMark }: ITradeMarkDisplay) {
  //TODO: make it work for multiple symbols defined in the array, currently it only use the first one
  const symbols = ['®']

  if (!tradeMark.includes(symbols[0])) {
    return <span>{tradeMark}</span>
  }

  const splitted = tradeMark.split(symbols[0])
  return (
    <span>
      {splitted[0]}
      <sup>{symbols[0]}</sup>
      {splitted[1]}
    </span>
  )
}
