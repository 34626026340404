import { Grid } from '@mui/material'
import moment from 'moment'

import { useStore } from '../../../_hooks/use-store'
import { formatDate } from '../../../utils'

function ContractDetails({ isMyga }) {
  const { store } = useStore()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} className="data-label">
        Product
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {store.selectedPolicy?.product?.name}
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        Product Type
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {store.selectedPolicy?.product?.type}
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        Product Term
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {isMyga
          ? store.selectedPolicy.data.application.plan
          : store.selectedPolicy?.config?.duration / 12}{' '}
        Years
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        Issue Date
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {formatDate(store.selectedPolicy?.data?.application?.issue_date)}
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        Anniversary Date
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {formatDate(
          moment(store.selectedPolicy?.data?.application?.issue_date).add(
            1,
            'year'
          )
        )}
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        End of Term
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {formatDate(
          moment(store.selectedPolicy?.data?.application?.issue_date).add(
            isMyga
              ? store.selectedPolicy.data.application.plan * 12
              : store.selectedPolicy?.config?.duration,
            'month'
          )
        )}
      </Grid>

      <Grid item xs={12} lg={6} className="data-label">
        Tax Qualification
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {store.selectedPolicy?.data?.application?.tax_qualification_status}
      </Grid>

      {/* <Grid item xs={12} lg={6} className="data-label">
        Total Free Withdrawal Amount Available
      </Grid>
      <Grid item xs={12} lg={6} className="data-value">
        {formatMoney(
          store.selectedPolicy?.account_value.max_free_withdrawal || 0
        )}{' '}
        (
        {formatPercentage(
          store.selectedPolicy?.account_value.max_free_percent || 0
        )}
        )
      </Grid> */}
    </Grid>
  )
}

export default ContractDetails
