import axios from 'axios'

import { request } from '../../_request-engine'
import { EAction } from '../reducers/app-reducers'

export const GetPolicy = async (policyNumber: string) => {
  const { data } = await request.get<any>(
    `/annuity-policies/policies/${policyNumber}`
  )

  return { type: EAction.GetPolicy, payload: { selectedPolicy: data } }
}

export const GetDocuments = async (policyNumber: string) => {
  const { data } = await request.get<any>(
    `/annuity-policies/policies/${policyNumber}/documents/`
  )

  return { type: EAction.GetDocuments, payload: { documents: data } }
}

export const GetRelatedPolicies = async (policyNumber: string) => {
  const { data } = await request.get<any>(
    `/annuity-policies/policies/related_policies/${policyNumber}`
  )
  return {
    type: EAction.GetRelatedPolicies,
    payload: data,
  }
}

export const GetEnvironment = async () => {
  try {
    const { data } = await axios.get(
      `${window._env_.REACT_APP_API_URL}/core/version`,
      {
        transformRequest: (data, headers) => {
          if (headers.Authorization) {
            delete headers.Authorization
          }
          return data
        },
      }
    )
    return {
      type: EAction.GetEnvironment,
      payload: data,
    }
  } catch (e: any) {
    return {
      type: EAction.GetEnvironment,
      payload: {
        error: 'Could not load environment variables',
      },
    }
  }
}
