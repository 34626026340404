import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

// Renders the column header and sorting
type ColumnHeaderProps = {
  displayName: string
  field: string
  properties: {
    sortBy: string
    sortDirection: 'asc' | 'desc'
  }
  handleSortClick: (field: string) => void
}

const ColumnHeader: FC<ColumnHeaderProps> = props => {
  const { displayName, field, properties } = props
  const { sortBy, sortDirection } = properties
  const iconClass =
    field === sortBy
      ? sortDirection === 'desc'
        ? 'caret-down'
        : 'caret-up'
      : ''

  return (
    <th
      className="ng-tns-c2-1"
      style={{ cursor: 'pointer' }}
      onClick={() => props.handleSortClick(field)}
    >
      {displayName}{' '}
      {iconClass === '' ? (
        ''
      ) : (
        <FontAwesomeIcon icon={iconClass} color="#7eb8f3" size="1x" />
      )}
    </th>
  )
}

export default ColumnHeader
